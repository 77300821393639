import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from '../services/registration.service';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {

    appUrl: string = ''; userId: any;hid = false;

    constructor(private authenticationService: AuthenticationService, private registrationService: RegistrationService,
        private router: Router, private toastr: ToastrService, private SpinnerService: NgxSpinnerService) {
        // this.appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/'; //Production Poiting
      //  this.appUrl = (location.href.includes('localhost') ? 'https://telemedicine-uk-qa.vmedtm.com' : location.origin) + '/';//QA Pointing
       this.appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/'; //Training Pointing
      // this.appUrl = (location.href.includes('localhost') ? 'https://doctors.uktelemedicine.in' : location.origin) + '/'; //Doctor new Pointing
      // this.appUrl = (location.href.includes('localhost') ? 'https://telemedicine-uk-dev.vmedtm.com' : location.origin) + '/'; //New Dev Pointing
        this.userId = sessionStorage.getItem('session_id')
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let httpURL = `${this.appUrl}${request.url}`;
        if (sessionStorage.getItem('isAppURL') != null) {
            if (sessionStorage.getItem('isAppURL') == '0')
                httpURL = `${request.url}`;
        }
       else if (request.url.includes('tmukqaapi')) {
            // if (sessionStorage.getItem('isAppURL') == '0')
                 httpURL = `${request.url}`;
         }
         
         else if (request.url.includes('https://test.payu.in/_payment')) {
                 httpURL = `${request.url}`;
                this.hid = true;
         }
      else  if (request.url.includes('healthidsbx')) {
            // if (sessionStorage.getItem('isAppURL') == '0')
                 httpURL = `${request.url}`;
                 this.hid = true;
         }
         //basic-info-save
        //  else  if (request.url.includes('basic-info-save')) { 
        //     alert('base-info') 
        //     this.hid = true;
        //     httpURL = `${this.appUrl}${request.url}`;
        //     let currentUser;
            
        //      currentUser = sessionStorage.getItem('currentUser');
            
        //    request = request.clone({ setHeaders: { Authorization: `${currentUser.jwt}` } });  
        //     request = request.clone({url:httpURL, setHeaders: { "x-hprid-auth": `${sessionStorage.hfrtoken}` } });
        //     return;   
        //  }

         let currentUser;
       if(this.authenticationService.currentUserValue && this.authenticationService.currentUserValue!=null){
        currentUser = this.authenticationService.currentUserValue;
       } else{
        currentUser = sessionStorage.getItem('currentUser');
       }

        // request = request.clone({ url: `${this.appUrl}${request.url}`, setHeaders: { 'TENANT_ID': '2' } });
        // request = request.clone({ url: httpURL, setHeaders: { 'TENANT_ID': '2' } });
        // request = request.clone({ url: `${this.appUrl}${request.url}`, setHeaders: { 'TENANT_ID':`${currentUser.orgnization_registration.id}`} });
        if(this.hid == false){
             request = request.clone({ url: httpURL, setHeaders: { 'TENANT_ID': '2' } });
          //  request = request.clone({ url: httpURL, setHeaders: { 'TENANT_ID': '50' } });//pont to training
            if (currentUser && currentUser.jwt) {
                request = request.clone({ setHeaders: { Authorization: `${currentUser.jwt}` } });
            }
            else {
                if (!location.href.includes('localhost'))
                    this.router.navigate(['']);
                // else
                //     this.toastr.error('jwt session expired')
            }
         }
        this.SpinnerService.show();
        return next.handle(request).pipe(
            catchError(err => {
                this.SpinnerService.show();
                if (sessionStorage.getItem('isCnfmLogOut') == '1')
                    this.sessionManagement();
                // this.authenticationService.session(this.userId);
                if (err.status === 401) {
                    sessionStorage.is401Error = true;
                    this.authenticationService.bannerSubject.next([]);
                    // if (!location.href.includes('localhost'))
                    //     this.toastr.error(err.error.message), this.router.navigate(['']);
                    // else
                    //     this.toastr.error('not authorized token expired');
                 //  document.getElementById('logOut')?.click();
                    if (err.error.message == 'Invalid login'){
                        this.toastr.error('You have already logged in another session or previous session was closed abruptly. Please try again.')

                    }
                       // else this.toastr.error(err.error.message);
                    else {
                        document.getElementById('btn_logOut')?.click();
                        //  this.toastr.error('Unfortunately your session is expired  please login');
                        this.toastr.error(err.error.message);
                        //window.location.reload();
                    }
                    //    this.toastr.error('this JWT ')
                    // this.router.navigate([''])
                }
                const error = err.error;
                return throwError(error);
            }), finalize(() => this.SpinnerService.hide()))
    }

    sessionManagement() {
        if (this.userId) {
            this.registrationService.session(this.userId).subscribe(res => {
                if (res.status === "success") {
                   // this.router.navigate(['']);
                }
            });
        }
    }
}