<div class="container-fluid pl-0 pr-0">

    <div class="row">
        <div class="col-xl-12 pl-0 pr-0">
            <div id="slide-panel">
                <a  id="opener"><img src="../../../assets/images/side_btn.png" /></a>
                <h6 class="text-white mt-3 mb-3 ml-2">Previous History</h6>
                <div>
                    <p class="ml-2 mt-1 mb-1 datepic" *ngFor="let item of benHistory"><a class="text-white"
                            (click)="openModal(item)" style="cursor: pointer;">{{item.visit_date}}</a></p>
                    <!-- <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p>
                    <p class="ml-2 mt-1 mb-1 datepic"><a href="" data-toggle="modal" data-target="#myModal"
                            class="text-white">05/10/2018</a></p> -->




                </div>
            </div>

            <div class="" style="margin-bottom:70px;">


                <section>
                    <div class="container-fluid">


                        <!-- Breadcomb -->

                        <!-- <nav aria-label="breadcrumb breadcompadding">
                                                    <ol class="breadcrumb breacombalign">
                                                        <li class="breadcrumb-item "><a >Dashboard</a></li>
                                                        <li class="breadcrumb-item active" aria-current="page">Doctor Video Call</li>
                                                    </ol>
                                                </nav> -->

                        <!-- content -->

                        <div class="row">

                            <div class="col- col-sm-4 col-lg-4 col-xl-4 mb-3 " id="smallscreen">
                                <div class="card paneldes mb-2 ">
                                    <div class="card-body panelbg p-3">
                                        <div class="row">
                                            <div class="col- col-sm-12 col-lg-12 col-xl-12 font-weight-bold ">
                                                <label class="">Doctor: &nbsp;</label>
                                                <label
                                                    class="text-success">{{docData.first_name + ' '+ docData.last_name}}</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label class="">Patient: &nbsp;</label>
                                                <label
                                                    class="font-weight-bold">{{benData.first_name + ' ' + benData.last_name}}</label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label class="">Mobile: &nbsp;</label>
                                                <label class="font-weight-bold">{{benData.mobile}}</label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label class="">Registration Number: &nbsp; </label>
                                                <label class="font-weight-bold">27 </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label class="">Gender: &nbsp;</label>
                                                <label class="font-weight-bold">{{benData.gender_id}}</label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label class="">Age: &nbsp; </label>
                                                <label class="font-weight-bold">{{benData.age}}</label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label class="">Address: &nbsp;</label>
                                                <label class="font-weight-bold">{{benData.address}} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="row">
                                    <div class="col- col-sm-12 col-md-12 col-lg-6 col-xl-6 pt-2 pb-1 pl-5">
                                        <h6>Plan of Care</h6>
                                    </div>
                                    <div
                                        class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pt-1 pb-1 pl-3 topcolchnage">
                                        <div class="pr-2 float-lg-right">
                                            <button type="button" class="btn btn-sm btn-danger text-white mr-2">End
                                                Video Call</button>
                                            <button type="button"
                                                class="btn btn-sm   btn-success  text-white">Confirm</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                
                                    <div class="col- col-sm-12 col-md-12 col-lg-12 mt-2">
                                        <div class="card paneldes">
                                            <div class="card-body panelbg p-4">

                                                <div class="row">
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class="mb-2  btn  btnstyle-1 casesheetbtnwidth  btn-secondary text-white"
                                                            data-toggle="modal" data-target="#myModal">Chief
                                                            Complaints</button>
                                                    </div>
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class=" mb-2 btn  btnstyle-2  btn-warning text-white   casesheetbtnwidth btn-secondary"
                                                            data-toggle="modal" data-target="#myModal12">History of
                                                            Present illness</button>
                                                    </div>
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class=" mb-2 btn btnstyle-3 casesheetbtnwidth btnstyle-3 btn-secondary text-white">Previous
                                                            Past History</button>
                                                    </div>
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class=" mb-2 btn btnstyle-4 casesheetbtnwidth   btn-secondary text-white">Vitals</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class="mb-2 btn  btnstyle-5 casesheetbtnwidth  btn-secondary text-white">Invesigations
                                                            Suggested </button>
                                                    </div>
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class=" mb-2 btn   btnstyle-6 text-white   casesheetbtnwidth btn-secondary text-white">Diagnosis</button>
                                                    </div>
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class=" mb-2 btn btnstyle-7 casesheetbtnwidth btnstyle-3 btn-secondary text-white">Drugs
                                                        </button>
                                                    </div>
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class=" mb-2 btn btnstyle-8 casesheetbtnwidth   btn-secondary text-white">Advice</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-auto colchnage">
                                                        <button type="button"
                                                            class="mb-2 btn  btnstyle-9  casesheetbtnwidth  btn-secondary text-white">COVID
                                                            19</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                            <!-- video Calling --->
                            <div class="col-sm-8 col-lg-8 col-xl-8  footercolsn" id="smallvideo">
                                <div class="card text-white h-100">
                                    <div class="card-body pb-5">


                                        <div id="conference_div">
                                            <div id="container" class="">
                                                <!-- <video id="localStream" autoplay controls
                                                    class='localStream doc_video'></video>
                                                <video muted autoplay controls class='remoteStream pat_video'></video> -->
                                                <openvidu-webcomponent style="display: none;"></openvidu-webcomponent>
                                            </div>
                                            <div>
                                                <input id="convId" type="hidden" />
                                            </div>
                                            <div>
                                                <ul id="log" style="display: none;">

                                                </ul>
                                                <!-- 
                                                <button onclick="window.app.leaveConversation()"
                                                    class="btn btn-sm btn-danger">Hang Up</button>&nbsp;
                                                <button class="btn btn-sm btn-success">Confirm</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12">
                                <form [formGroup]="pocForm" (ngSubmit)="onSubmit($event)">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="card">
                                                <div class="card-header bg-light p-1">
                                                    <h5 class="text-primary pl-3">Chief Complaints/ History of Present
                                                        illness*</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row" formGroupName="cc_brief_history">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Chief Complaints <span
                                                                    class="text-danger">*</span></label>
                                                            <div>
                                                                <select formControlName="chiefComplaints"
                                                                    id="chiefComplaints"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('cc_brief_history.chiefComplaints').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option
                                                                        *ngFor="let chiefComplaint of chiefComplaintList"
                                                                        [value]="chiefComplaint.id">
                                                                        {{chiefComplaint.chief_complaint}}</option>
                                                                </select>
                                                                <div *ngIf="submitted && pocForm.get('cc_brief_history.chiefComplaints').errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="pocForm.get('cc_brief_history.chiefComplaints').errors.required">
                                                                        CHief Complaints is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Other Complaints</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="otherComplaints"
                                                                    id="otherComplaints"
                                                                    (keyup)="keyUp('otherComplaints')"
                                                                    onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('cc_brief_history.otherComplaints').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <!-- <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Subtype</label>
                                                            <div>
                                                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                    [placeholder]="'subtype'" [data]="subtypeList"
                                                                    [formControl]="pocForm.get('cc_brief_history.subtype')">
                                                                </ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Other Subtype</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="otherSubtype" id="otherSubtype"
                                                                    (keyup)="keyUp('otherSubtype')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50" placeholder="Contact Person Number"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('cc_brief_history.otherSubtype').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div> -->
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Doctor Comment</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="doctorComment" id="doctorComment"
                                                                    (keyup)="keyUp('doctorComment')"
                                                                    onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('cc_brief_history.doctorComment').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">History of Present illness</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="presentIllness" id="presentIllness"
                                                                    (keyup)="keyUp('presentIllness')"
                                                                    onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('cc_brief_history.presentIllness').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="card">
                                                <div class="card-header bg-light p-1">
                                                    <h5 class="text-primary pl-3">Vitals</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row" formGroupName="vital_history">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Temperature</label>
                                                            <div class="input_wrapper">
                                                                <input type="text" formControlName="temperature"
                                                                    id="temperature" (keyup)="keyUp('temperature')"
                                                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                                    maxlength="10"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('vital_history.temperature').errors }"
                                                                    class="form-control form-control-sm text-uppercase" />
                                                                <div class="unit"> F</div>
                                                            </div>
                                                            <!-- <div id="testinside">
                                                                <span class="units">&deg C</span>
                                                                <input type="text" placeholder="Value"  formControlName="temperature" id="temperature"
                                                                (keyup)="keyUp('temperature')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                            (event.charCode >= 97 && event.charCode <= 122) ||
                                                            (event.charCode == 32 || event.charCode == 46))'
                                                                maxlength="10" placeholder="Temperature"
                                                                [ngClass]="{ 'is-invalid': submitted && pocForm.get('vital_history.temperature').errors }"
                                                                class="form-control form-control-sm text-uppercase">
                                                            </div> -->

                                                            <!-- <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="temperature" id="temperature"
                                                                    (keyup)="keyUp('temperature')" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                                    maxlength="3"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('vital_history.temperature').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div> -->
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Pulse</label>
                                                            <div>
                                                                <div class="input_wrapper">
                                                                    <input type="text" autocomplete="off"
                                                                        formControlName="pulse" id="pulse"
                                                                        (keyup)="keyUp('pulse')"
                                                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                                        maxlength="3" max="100"
                                                                        [ngClass]="{ 'is-invalid': submitted && pocForm.get('vital_history.pulse').errors }"
                                                                        class="form-control form-control-sm text-uppercase">
                                                                    <div class="unit">bpm</div>
                                                                    <div *ngIf="submitted && pocForm.get('vital_history.pulse').errors"
                                                                        class="invalid-feedback">
                                                                        <div
                                                                            *ngIf="pocForm.get('vital_history.pulse').errors">
                                                                            Maximum value is 100
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Systolic</label>
                                                            <div>
                                                                <div class="input_wrapper">
                                                                    <input type="text" autocomplete="off"
                                                                        formControlName="systolic" id="systolic"
                                                                        (keyup)="keyUp('systolic')"
                                                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                                        maxlength="3"
                                                                        [ngClass]="{ 'is-invalid': submitted && pocForm.get('vital_history.systolic').errors }"
                                                                        class="form-control form-control-sm text-uppercase">
                                                                    <div class="unit">mmHg</div>
                                                                    <div *ngIf="submitted && pocForm.get('vital_history.systolic').errors"
                                                                        class="invalid-feedback">
                                                                        <div>
                                                                            Accept value from 50 to 400
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Diastolic</label>
                                                            <div>
                                                                <div class="input_wrapper">
                                                                    <input type="text" autocomplete="off"
                                                                        formControlName="diastolic" id="diastolic"
                                                                        (keyup)="keyUp('diastolic')"
                                                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                                        maxlength="3"
                                                                        [ngClass]="{ 'is-invalid': submitted && pocForm.get('vital_history.diastolic').errors }"
                                                                        class="form-control form-control-sm text-uppercase">
                                                                    <div class="unit">mmHg</div>
                                                                    <div *ngIf="submitted && pocForm.get('vital_history.diastolic').errors"
                                                                        class="invalid-feedback">
                                                                        <div>
                                                                            Accept value from 30 to 200
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="card">
                                                <div class="card-header bg-light p-1">
                                                    <h5 class="text-primary pl-3">Past History / Allergies </h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row" formGroupName="medical_history">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">History of Past illness</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="pastIllness" id="pastIllness"
                                                                    (keyup)="keyUp('pastIllness')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('medical_history.pastIllness').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Surgical History</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="surgicalHistory"
                                                                    id="surgicalHistory"
                                                                    (keyup)="keyUp('surgicalHistory')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('medical_history.surgicalHistory').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row" formGroupName="other_history">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Family History</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="familyHistory" id="familyHistory"
                                                                    (keyup)="keyUp('familyHistory')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('other_history.familyHistory').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Personal History</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="personalHistory"
                                                                    id="personalHistory"
                                                                    (keyup)="keyUp('personalHistory')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('other_history.personalHistory').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4"></div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Dietary History</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="dietaryHistory" id="dietaryHistory"
                                                                    (keyup)="keyUp('dietaryHistory')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('other_history.dietaryHistory').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">History</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="history" id="history"
                                                                    (keyup)="keyUp('history')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('other_history.history').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-row" formGroupName="allergy">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Drugs Sensitivity</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="drugsSensitivity"
                                                                    id="drugsSensitivity"
                                                                    (keyup)="keyUp('drugsSensitivity')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('allergy.drugsSensitivity').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Allergies<span
                                                                    class="text-danger">*</span></label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="allergies" id="allergies"
                                                                    (keyup)="keyUp('allergies')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('allergy.allergies').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                                <div *ngIf="submitted && pocForm.get('allergy.allergies').errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="pocForm.get('allergy.allergies').errors.required">
                                                                        Allergies is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="card">
                                                <div class="card-header bg-light p-1">
                                                    <h5 class="text-primary pl-3">Diagnose</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row" formGroupName="diagnosis">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Provisional Diagnosis</label>
                                                            <div>
                                                                <select formControlName="provisionalDiagnosis"
                                                                    id="provisionalDiagnosis"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('diagnosis.provisionalDiagnosis').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let diagnosisl of diagnosislList"
                                                                        [value]="diagnosisl.id">
                                                                        {{diagnosisl.diagnosis_name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Other</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="other" id="other"
                                                                    (keyup)="keyUp('other')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="50"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('diagnosis.other').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="card">
                                                <div class="card-header bg-light p-1">
                                                    <h5 class="text-primary pl-3">Investigations</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row" formGroupName="investigation">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Investigation List</label>
                                                            <div>
                                                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                    [placeholder]="'Select'"
                                                                    [data]="investigationsList"
                                                                    [formControl]="pocForm.get('investigation.master')">
                                                                </ng-multiselect-dropdown> -->
                                                                <select formControlName="master" id="master"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('investigation.master').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let item of investigationsList"
                                                                        [value]="item.id">
                                                                        {{item.investigation}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Other</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="otherInvestigation"
                                                                    id="otherInvestigation" (keyup)="keyUp('other')"
                                                                    onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('investigation.otherInvestigation').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="card">
                                                <div class="card-header bg-light p-1">
                                                    <h5 class="text-primary pl-3">Drugs</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row" formGroupName="prescribed_medications">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Drug Type</label>
                                                            <div>
                                                                <select formControlName="drugType" id="drugType"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.drugType').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let drugtypes of drugtypesList"
                                                                        [value]="drugtypes.id">
                                                                        {{drugtypes.drug_type}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Drug Name <span
                                                                    class="text-danger">*</span></label>
                                                            <div>
                                                                <select formControlName="drugName" id="drugName"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.drugName').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let item of drugnamesList"
                                                                        [value]="item.id">
                                                                        {{item.drug_name}}</option>
                                                                </select>
                                                                <div *ngIf="submitted && pocForm.get('prescribed_medications.drugName').errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="pocForm.get('prescribed_medications.drugName').errors.required">
                                                                        Drug Name is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Other</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="otherDrug" id="otherDrug"
                                                                    (keyup)="keyUp('otherDrug')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.otherDrug').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Dosage<span
                                                                    class="text-danger">*</span></label>
                                                            <div>
                                                                <!-- <input type="text" autocomplete="off"
                                                                    formControlName="dosage" id="dosage"
                                                                    (keyup)="keyUp('dosage')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="100" [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.dosage').errors }"
                                                                    class="form-control form-control-sm text-uppercase"> -->
                                                                <select formControlName="dosage" id="dosage"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.dosage').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let item of dosageLst"
                                                                        [value]="item.id">
                                                                        {{item.name}}</option>
                                                                </select>

                                                                <div *ngIf="submitted && pocForm.get('prescribed_medications.dosage').errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="pocForm.get('prescribed_medications.dosage').errors.required">
                                                                        Dosage is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Direction <span
                                                                    class="text-danger">*</span></label>
                                                            <div>
                                                                <select formControlName="direction" id="direction"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.direction').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let item of directionsList"
                                                                        [value]="item.id">
                                                                        {{item.direction}}</option>
                                                                </select>
                                                                <div *ngIf="submitted && pocForm.get('prescribed_medications.direction').errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="pocForm.get('prescribed_medications.direction').errors.required">
                                                                        Direction is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Instruction </label>
                                                            <div>
                                                                <select formControlName="instruction" id="instruction"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.instruction').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let instruction of instructionList"
                                                                        [value]="instruction.id">
                                                                        {{instruction.instruction}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Number of days<span
                                                                    class="text-danger">*</span></label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="noofdays" id="noofdays"
                                                                    (keyup)="keyUp('noofdays')"
                                                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                                    maxlength="3"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.noofdays').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                                <div *ngIf="submitted && pocForm.get('prescribed_medications.noofdays').errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="pocForm.get('prescribed_medications.noofdays').errors.required">
                                                                        No of Days is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Quantity</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="quantity" id="quantity"
                                                                    (keyup)="keyUp('quantity')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('prescribed_medications.quantity').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont"></label>
                                                            <div>
                                                                <button class="btn btn-primary btn-sm" disabled>Add
                                                                    Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="card">
                                                <div class="card-header bg-light p-1">
                                                    <h5 class="text-primary pl-3">Advice</h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row" formGroupName="advice">
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Rest/Exercise</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="restExercise" id="restExercise"
                                                                    (keyup)="keyUp('restExercise')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('advice.restExercise').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Diet</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="diet" id="diet"
                                                                    (keyup)="keyUp('diet')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('advice.diet').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Follow Advice</label>
                                                            <div>
                                                                <input type="text" autocomplete="off"
                                                                    formControlName="followAdvice" id="followAdvice"
                                                                    (keyup)="keyUp('followAdvice')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                                                (event.charCode >= 97 && event.charCode <= 122) ||
                                                                (event.charCode == 32 || event.charCode == 46))'
                                                                    maxlength="100"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('advice.followAdvice').errors }"
                                                                    class="form-control form-control-sm text-uppercase">
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-1 col-md-4">
                                                            <label class="labelfont">Referral</label>
                                                            <div>
                                                                <select formControlName="referral" id="referral"
                                                                    [ngClass]="{ 'is-invalid': submitted && pocForm.get('advice.referral').errors }"
                                                                    class="form-control form-control-sm">
                                                                    <option value="">Select</option>
                                                                    <!-- <option *ngFor="let state of stateList" [value]="state.id">
                                                                                    {{state.stateName}}</option> -->
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                        <div class="col-xl-12 text-center">
                                            <!-- <input type="submit" class="btn btn-sm btn-primary"
                                                value="Submit">&nbsp;&nbsp;  
                                             Button trigger modal-->
                                            <button type="button" class="btn btn-primary btn-sm"
                                                (click)="onPreview($event)">Preview</button>
                                            <button type="button" id="btnPreviewModal" data-toggle="modal"
                                                data-target="#exampleModal" hidden></button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>


                        <div class="modal fade" id="myModal12">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content modalradius">

                                    <!-- Modal Header -->
                                    <div class="modal-header modalheaderstyle">
                                        <h6 class="modal-title p-0">Systemic Examination</h6>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>

                                    <!-- Modal body -->
                                    <div class="modal-body modaybodybg">
                                        <form>
                                            <div class="form-row">
                                                <div class="form-group mb-1 col-md-6 col-xl-6">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="inlineCheckbox1" value="option1">
                                                        <label class="form-check-label" for="inlineCheckbox1">Check
                                                            Box 1</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="inlineCheckbox2" value="option2">
                                                        <label class="form-check-label" for="inlineCheckbox2">Check
                                                            Box 2</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="inlineCheckbox2" value="option2">
                                                        <label class="form-check-label" for="inlineCheckbox2">Check
                                                            Box 2</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="inlineCheckbox1" value="option1">
                                                        <label class="form-check-label" for="inlineCheckbox1">Check
                                                            Box 1</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="inlineCheckbox2" value="option2">
                                                        <label class="form-check-label" for="inlineCheckbox2">Check
                                                            Box 2</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="inlineCheckbox2" value="option2">
                                                        <label class="form-check-label" for="inlineCheckbox2">Check
                                                            Box 2</label>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-1 col-md-6 col-xl-6">
                                                    <select class="custom-select demo" multiple="multiple">
                                                        <option value="cp">C++</option>
                                                        <option value="cs">C#</option>
                                                        <option value="oc">Object C</option>
                                                        <option value="c">C</option>
                                                        <option value="js">JavaScript</option>
                                                        <option value="php">PHP</option>
                                                        <option value="asp">ASP</option>
                                                        <option value="jsp">JSP</option>
                                                    </select>
                                                </div>
                                                <div class="form-group mb-1 col-md-6">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                                        <label class="form-check-label" for="inlineRadio1">Radio
                                                            Button 1</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                                        <label class="form-check-label" for="inlineRadio2">Radio
                                                            Button 2</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                                        <label class="form-check-label" for="inlineRadio1">Radio
                                                            Button 3</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                                        <label class="form-check-label" for="inlineRadio2">Radio
                                                            Button 4</label>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-1 mt-2 col-md-6">
                                                    <div class="form-group">
                                                        <label for="text"> Drug Name</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="form-group mb-1 mt-2 col-md-6">
                                                    <div class="form-group">
                                                        <label for="text"> Drug Name</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="form-group mb-1 mt-2 col-md-6">
                                                    <div class="form-group">
                                                        <label for="text"> Drug Select</label>
                                                        <select class="custom-select">
                                                            <option>Select1</option>
                                                            <option>Select2</option>
                                                            <option>Select3</option>
                                                            <option>Select4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>

        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-bordered table-sm small">
                    <tr class="active bg-light">
                        <td colspan="4">
                            <h6>Patient Details</h6>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Name</strong> </td>
                        <td>{{benData.first_name + '' + benData.last_name}}</td>
                        <td><strong>ID</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><strong>Phone Number</strong></td>
                        <td>{{benData.mobile}}</td>
                        <td><strong>Age</strong></td>
                        <td>{{benData.age}}</td>
                    </tr>
                    <tr>
                        <td><strong>Gender</strong></td>
                        <td>{{benData.gender_id == 1 ? 'Male' : 'Female'}}</td>
                        <td><strong>Address</strong></td>
                        <td>{{benData?.address}}</td>
                    </tr>

                    <tr>
                        <td colspan="4" class="bg-light">
                            <h6>Doctor Details</h6>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Name </strong></td>
                        <td>{{docData.first_name+ '' + docData.last_name}}</td>
                        <td><strong>Qualification</strong></td>
                        <td>{{docData.qualification_id}}</td>
                    </tr>
                    <tr>
                        <td><strong>Specialization</strong></td>
                        <td>{{docData.specialization_id}}</td>
                        <td><strong>MCI Number</strong></td>
                        <td>{{docData.mci_number}}</td>
                    </tr>
                    <!-- <tr>
                        <td><strong>Date</strong></td>
                        <td>2018-04-30 12:02</td>
                        <td><strong>Address</strong></td>
                        <td>Telangana,Hyderabad
                            kattedan,</td>
                    </tr> -->

                </table>


                <table class="table table-bordered table-sm small">
                    <tr class="bg-light">
                        <td class="active text-center" colspan="6">
                            <h6>Prescription</h6>
                        </td>

                    </tr>
                    <tr>
                        <td colspan="3"><strong>Chief Complaints/ History of Present Illness:</strong></td>
                        <td colspan="3">{{pocCc }}</td>
                    </tr>
                    <!-- <tr>
                        <td colspan="3"><strong>Medical History:</strong></td>
                        <td colspan="3">{{pocForm.get('medical_history.pastIllness').value + ',' + pocForm.get('medical_history.surgicalHistory').value}}</td>
                    </tr> -->
                    <tr>
                        <td colspan="3"><strong>Vitals</strong></td>
                        <td colspan="3">{{pocVitals}}</td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Past History & Allergies</strong></td>
                        <td colspan="3">{{pocHistory}} </td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Provisional Diagnosis</strong> </td>
                        <td colspan="3">{{pocPd}}</td>
                    </tr>
                    <tr>
                        <td colspan="3"><strong>Investigations</strong></td>
                        <td colspan="3">{{pocIn}}</td>
                    </tr>
                    <tr>
                        <!-- Doctor Note  -->
                        <td colspan="3"><strong> Doctor Note</strong></td>
                        <td colspan="3">{{pocForm.get('cc_brief_history.doctorComment').value}}</td>
                    </tr>
                    <tr>
                        <th><strong>Drug Type</strong></th>
                        <th>Drug Name</th>
                        <th>Dosage</th>
                        <th>Direction</th>
                        <th>Instruction</th>
                        <th>No of Days</th>
                    </tr>

                    <tr>
                        <td>{{pocDrug.drug_type}}</td>
                        <td>{{pocDrug.drug_name}}</td>
                        <td>{{pocDrug.dsg}}</td>
                        <td>{{pocDrug.dir}}</td>
                        <td>{{pocDrug.ins}}</td>
                        <td>{{pocDrug.noofdays}}</td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn  btn-sm btn-primary" (click)="onSubmit($event)">Submit</button>
            </div>
        </div>
    </div>
</div>