<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Payment Gateway integration
                    </div>

                    <div class="card-body">

                        <div class="row small">
                            <div class="col-xl-12 videoWrapper">
                                <iframe src="https://txncdn.payubiz.in/login" style="border:0px !important; "></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Modal -->