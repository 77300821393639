import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-ivideos',
  templateUrl: './ivideos.component.html',
  styleUrls: ['./ivideos.component.css']
})

export class IvideosComponent implements OnInit {

  @Input() url: string = ''; vdoPath2: SafeResourceUrl;

  constructor(public sntzr: DomSanitizer, public activeModal: NgbActiveModal) { }

  ngOnInit(): void { this.vdoPath2 = this.sntzr.bypassSecurityTrustResourceUrl(this.url); }

}