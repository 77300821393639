import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CorporateEmployeeService {
  tpaAPI: string = ''; webURL:string = ''; masterAPI:string = '';

  constructor(private api: ApiService) {
    this.webURL = location.href.includes('localhost') ? environment.applicationURL  : location.origin;
    this.tpaAPI = environment.axaApi;
    this.masterAPI = environment.mastersAPI;
   }
  
   get(queryString: string): Observable<any> { return this.api.get(`${environment.loginAPI}${queryString}`); }
   getsrh(queryString: string): Observable<any> { return this.api.get(`${queryString}`); }
   getMaster(queryString: string): Observable<any> { return this.api.get(`${this.masterAPI}${queryString}`); }
   save(apiMethod: string, data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${apiMethod}`, data) };
   saveEmp(apiMethod: string, data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${apiMethod}`, data) }
  }
