import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { Toast, ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Common } from 'src/app/common';
declare let $: any;
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patient-emr',
  templateUrl: './patient-emr.component.html',
  styleUrls: ['./patient-emr.component.css']
})
export class PatientEmrComponent implements OnInit {

  edtData: any; age: any = ''; ptnnme: any = ''; gndr: any = ''; phone: any = ''; area: any = '';
  emplye: any = ''; crdtDtls: any = ''; ptntMrn: any = ''; dob: any = ''; opLstGrid: any[] = []; opLstDtls: any[] = [];
  ipLstGrid: any[] = []; ipLstDtls: any[] = []; dcLstGrid: any[] = []; dcLstDtls: any[] = []; apntmntLstGrid: any[] = [];
  apntmntLstDtls: any[] = []; labLstGrid: any = []; radologyLstGrid: any = []; labLstDtls: any = {}; rdlgyLstDtls: any = {};

  billNumber: any = ''; opBlngDtls: any = ''; billDate: any = ''; mrnNumber: any = ''; ipBlngDtls: any = ''

  billingDtals: any = ''; admsnDtals: any = ''; apntmntDtls: any = ''; dcBlngDtls: any = ''; radologyDtls: any = ''; labDtls: any = '';
  weburl: any;title:any
  labModel: boolean = false;
  radModel: boolean = false;
  doctorDetails: any;
  tenantId: any;
  PatientId: any;
  doctorId: any;
  jwtId: any;
  resultNo: any;
  resultNum: any;
  order_date: string;
  lablst: any;
  doctorConsultList: any;
  consultListDtls: any;
  appUrl: any;
  lstGrid: any;
  vitalsList:  any[] = [];
  vitalsListDtls: any = {};
  dtRender: boolean;

  constructor(public activeModal: NgbActiveModal,
    private ngbMdl: NgbModal,
    private spinnerService:NgxSpinnerService,
    private regService: RegistrationService,
    public router: Router,
    private datePipe: DatePipe,
    private http: HttpClient,
    private toastr: ToastrService,
    public fb: FormBuilder) {

  }

  ngOnInit(){

    this.weburl = 'https://hmsqa.dhanushinfotech.com/'
    this.appUrl = location.href.includes('localhost') ? environment.applicationURL : location.origin;
    this.doctorDetails = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    console.log(this.doctorDetails);
    console.log(this.doctorDetails.id);
    this.tenantId = sessionStorage.getItem('TENANT_ID');
    if(sessionStorage.call_type === 'Video'){
      this.PatientId = sessionStorage.getItem('ben_id');
      sessionStorage.removeItem('call_type')
      sessionStorage.removeItem('ben_id')
    }else{
      this.PatientId = sessionStorage.getItem('patientidchat');
    }
    
    this.doctorId = this.doctorDetails?.id
     this.LoadData();
    this.getLabRadData();
    //  this.LoadLabLst();
    //  this.LoadRadlgyLst();


  }

  openModal() {



  }



  LoadURL(ctrl: string = '') {
    if (ctrl == '')
      this.router.navigate(['hims/patntReg/pr']);
    if (ctrl == 'c')
      this.router.navigate(['hims/opbilling/opcb-add']);
    if (ctrl == 's')
      this.router.navigate(['hims/opbilling/opsb-add'], { queryParams: { type: 'create' } });
    if (ctrl == 'ip')
      this.router.navigate(['hims/ipAdt/IpAdmission-add']);
    if (ctrl == 'adt')
      this.router.navigate(['hims/ipAdt/IpAdmission-add']);
    if (ctrl == 'a')
      this.router.navigate(['hims/appointments/aptmts']);
    if (ctrl == 'dc')
      this.router.navigate(['hims/cnsltsn/DrCnsltsn']);
  }

  LoadOPLst() {

  }

  LoadView(bilId: any) {

  }

  LoadApntmntLst() {

  }

  LoadApntmntView(id: any) {

  }

  LoadIPLst() {

  }

  LoadIPView(ipid: any) {

  }



  LoadDCView(bilId: any) {

  }


  async LoadLabLst() {
    // this.regService.getHimsData(`laboratory/v1/result-entry/LMC00000007`).subscribe(res => {
    //   if (res.status === 'OK') {

    //     if (res.data == null || res.data.length == 0)
    //       this.toastr.info('No records available');
    //     else {
    //       this.labLstGrid=res.data
    //     }
    //   }


    // });
    // return this.http.request(new Request('https://hmsdev.dhanushinfotech.com/laboratory/v1/result-entry/LMC00000007'))
    //     .map((returnedStuff) => {
    //       console.log(returnedStuff);
    //    });


    let response;
    response = await fetch(
      `${this.weburl}/laboratory/v1/result-entry/LMC00000007`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',

        },
      },
    )
    const res = await response.json();
    console.log('Laboratory_res', res.data);
    if (
      res.status == 'OK' && res.data != null) {
      this.labLstGrid = res.data ?? [], this.labLstDtls=this.labLstGrid[0];
      console.log('Laboratory_res', res.data);
    }

  }

  LoadLabView1(rD: any) {
    this.labDtls = '';
    this.labDtls = {
      order_no: rD.order_no, order_date: rD?.order_date, result_date: rD?.result_date, result_id: rD?.result_id,
      sample_collection_date: rD?.sample_collection_date, sample_collection_no: rD?.sample_collection_no, sample_recieved_date: rD?.sample_recieved_date,
      service_name: rD?.service_name, mrn_no: rD?.mrn_no
    }
  }

  LoadLabView(id: any) {

  }


  async LoadRadlgyLst() {
    let response;
    response = await fetch(
      `${this.weburl}/radiology/v1/result-entry/LML00000001`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',

        },
      },
    )
    const res = await response.json();
    console.log('Laboratory_res', res.data);
    if (
      res.status == 'OK' && res.data != null) {
      this.radologyLstGrid = res.data ?? [], this.rdlgyLstDtls.push(this.radologyLstGrid[0]);
      console.log('Laboratory_res', res.data);
    }
  }

  LoadRadlgyView1(rD: any) {
    this.labDtls = '';
    this.labDtls = {
      order_no: rD.order_no, order_date: rD?.order_date, result_date: rD?.result_date, result_id: rD?.result_id,
      sample_collection_date: rD?.sample_collection_date, sample_collection_no: rD?.sample_collection_no, sample_recieved_date: rD?.sample_recieved_date,
      service_name: rD?.service_name, mrn_no: rD?.mrn_no
    }
  }

  LoadRadlgyView(id: any) {

  }

  async getLabRadData() {
    // this.spinnerService.show();
    // let response;
    // response = await fetch(
      
    //   // `${this.weburl}masters/v1/emr/?doctorId=2&orgId=99&patientId=157`,
    //   `${this.weburl}masters/v1/emr/?doctorId=${this.doctorId}&orgId=99&patientId=${this.PatientId}`,
    //   // `${this.appUrl}/poc-emr/hims/getemrdata?beneficiaryId=${this.PatientId}&TENANT_ID=${this.tenantId}`,  
     
    //   {
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',

    //     },
    //   },
    // )
    // const res = await response.json();
    // console.log('Laboratory_res', res.data);

    // if (res.status == 'OK' && res.data != null) {
    //   this.labLstGrid = res.data?.laboratory,
    //    this.labLstDtls = this.labLstGrid[0];
    //    this.spinnerService.hide();
    //   // this.order_date=this.datePipe.transform(this.labLstDtls.order_date,'dd-MM-yyyy hh:MM:ss')
    //   console.log('Laboratory_res', res.data);
    // } else {
    //   // this.toastr.warning("No Data Available")
    //   this.toastr.warning("No Data Available")
    //   this.spinnerService.hide();
    // }

    // if (res.status == 'OK' && res.data != null) {
    //   this.radologyLstGrid = res.data.radiology ?? [], this.rdlgyLstDtls=this.radologyLstGrid[0];
    //   this.doctorConsultList=res.data.doctorConsultation??[],this.consultListDtls=this.doctorConsultList[0];
    //         console.log('Laboratory_res', res.data);
    //   this.spinnerService.hide();
    // } else {
    //   // this.toastr.error("No Data Available")
    //   this.toastr.warning("No Data Available")
      // this.spinnerService.hide();
    // }

  }
  LoadData() {
    this.dtRender=false
    this.spinnerService.show();
     this.regService.getEmr(this.PatientId).subscribe(res => { 
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0){
          this.dtRender=true
          this.toastr.info('No records available', 'Hospital Detail List');
          this.spinnerService.hide();
        }
        else{
    
          this.lstGrid = res.data;
          this.dtRender=true
          this.spinnerService.hide();
          this.labLstGrid = res.data?.laboratory,
          this.labLstDtls = this.labLstGrid[0];
          this.radologyLstGrid = res.data.radiology ?? [], this.rdlgyLstDtls=this.radologyLstGrid[0];
          this.doctorConsultList=res.data.doctorConsultation??[],this.consultListDtls=this.doctorConsultList[0];
          this.vitalsList=res.data.vitals??[],this.vitalsListDtls=this.vitalsList[0];
        }
          
      }
    });
  }
  openLabModel() {
    this.labModel = true

  }
  openradModel() {
    this.radModel = true


  }
  //lab report
  async openLabReport(item: any) {
    
    this.spinnerService.show();
    // let response;
    // response = await fetch(
    //   `${this.weburl}hims-services/login `,
    //   {
    //     method: 'POST',
    //     headers: {
    //       Accept: 'application/json',

    //     },
    //     body: JSON.stringify({
    //       loginname: "NWDLMU1",
    //       password: "himsqa"
    //     }),
    //   },
    // )
    // const res = await response.json();
    // console.log('Laboratory_res', res.data);
    // if (
    //   res.status == 'OK') {
    //   this.jwtId = res.data.user.jwt
    //   console.log('Laboratory_res', res.data);
    // }else{
    //   this.spinnerService.hide();
    //   this.toastr.warning("No Record Available")
    // }
    this.resultNo = item?.result_id == null ? 11000000000223 : item?.result_id
    this.LabReport();

  }

  // async LabReport() {
  //   let response;
  //   response = await fetch(
  //     `${this.weburl}hims-services/op-billing/v1/report/jaspergenrate \ `,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: this.jwtId,
  //       },
  //       body: JSON.stringify({
  //         id: 79,
  //         fileType: "pdf",
  //         resultId: this.resultNo
  //       }),
  //     },
  //   )
  //   const res = await response.json();
  //   if (res.status == 'OK') {
  //     console.log('Laboratory_res', res.data);
  //     this.spinnerService.hide();
  //     Common.showlabpharmaPDF_File(res.data?.viewPath);
  //   }else{
  //     this.spinnerService.hide();
  //     this.toastr.warning("No Record Available")  
  //   }
  // }


  LabReport(){
    let payload={
      id: 79,
      fileType: "pdf",
      resultId: this.resultNo,
      headerandfooter: true
    }
    this.regService.jasperGenerate(payload).subscribe(res => {
      if (res?.status == 'OK') {
        this.spinnerService.hide();
        this.toastr.success(res.message) 
        window.open(res.data?.viewPath,'_blank',);
        // Common.showlabpharmaPDF_File(res.data?.viewPath);

        }else 
        this.spinnerService.hide();
 
      })

    // this.spinnerService.show();
  }
  //radiology

  async openRadReport(item: any) {
    this.spinnerService.show();
    // let response;
    // response = await fetch(
    //   `${this.weburl}hims-services/login `,
    //   {
    //     method: 'POST',
    //     headers: {
    //       Accept: 'application/json',

    //     },
    //     body: JSON.stringify({
    //       loginname: "NWDLMU1",
    //       password: "himsqa"
    //     }),
    //   },
    // )
    // const res = await response.json();
    // console.log('Laboratory_res', res.data);

    // if (
    //   res.status == 'OK' && res.data != null) {
    //   this.jwtId = res.data.user.jwt
    //   console.log('Laboratory_res', res.data);
    // }else{
    //   this.spinnerService.hide();
    //   this.toastr.warning("No Record Available") 
    // }
    this.resultNum = item?.result_id == null ? 11000000000223 : item?.result_id
    this.radReport();

  }

  // async radReport() {
  //   let response;
  //   response = await fetch(
  //     `${this.weburl}hims-services/op-billing/v1/report/jaspergenrate \ `,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         //Content-Type: 'application/json',
  //         Authorization: this.jwtId,
  //       },
  //       body: JSON.stringify({
  //         id: 32,
  //         fileType: "pdf",
  //         resultId: this.resultNum
  //       }),
  //     },
  //   )
  //   const res = await response.json();
  //   if (res.status == 'OK') {
  //     console.log('Laboratory_res', res.data);
  //     this.spinnerService.hide();
  //     Common.showlabpharmaPDF_File(res.data?.viewPath);
  //   }else{
  //     this.spinnerService.hide();
  //     this.toastr.warning("No Record Available")  
  //   }
  // }
  radReport(){
    let payload={
      id: 32,
          fileType: "pdf",
          resultId: this.resultNum,
          headerandfooter: true
    }
    this.regService.jasperGenerate(payload).subscribe(res => {
      if (res?.status == 'OK') {
        this.spinnerService.hide();
        this.toastr.success(res.message) 
        window.open(res.data?.viewPath,'_blank',);
        // Common.showlabpharmaPDF_File(res.data?.viewPath);

        }else 
        this.spinnerService.hide();
 
      })

    }
  async openDctrCnsltReport(item:any){
    this.spinnerService.show();
    this.resultNum = item?.dcid == null ? 11000000000223 : item?.dcid
    this.consultationReport()
  //   let response;
  //   response = await fetch(
  //     `${this.weburl}hims-services/login `,
  //     {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',

  //       },
  //       body: JSON.stringify({
  //         loginname: "NWDLMU1",
  //         password: "himsqa"
  //       }),
  //     },
  //   )
  //   const res = await response.json();
  //   console.log('Laboratory_res', res.data);

  //   if (
  //     res.status == 'OK' && res.data != null) {
  //     this.jwtId = res.data.user.jwt
  //     console.log('Laboratory_res', res.data);
  //   }else{
  //     this.spinnerService.hide();
  //     this.toastr.warning("No Record Available") 
  //   }
  //   this.resultNum = item?.dcid == null ? 11000000000223 : item?.dcid
  //   this.consultationReport();

  // }

  // async consultationReport() {
  //   let response;
  //   response = await fetch(
  //     `${this.weburl}hims-services/op-billing/v1/report/jaspergenrate \ `,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         //Content-Type: 'application/json',
  //         Authorization: this.jwtId,
  //       },
  //       body: JSON.stringify({
  //         id: 105,
  //         fileType: "pdf",
  //         patientId: this.PatientId,
  //         opTriageId: this.doctorConsultList[0].op_triage_id,
  //         dcId: this.doctorConsultList[0].dcid
  //       }),
  //     },
  //   )
  //   const res = await response.json();
  //   if (res.status == 'OK') {
  //     console.log('Laboratory_res', res.data);
  //     this.spinnerService.hide();
  //     Common.showlabpharmaPDF_File(res.data?.viewPath);
  //   }else{
  //     this.spinnerService.hide();
  //     this.toastr.warning("No Record Available")  
  //   }
  }
  consultationReport(){
    let payload={
      id: 105,
      fileType: "pdf",
      patientId: this.doctorConsultList[0].patient_id,
      opTriageId: this.doctorConsultList[0].op_triage_id,
      dcId: this.doctorConsultList[0].dcid,
      languageCode:"en",
      headerandfooter: true
    }
    this.regService.jasperGenerate(payload).subscribe(res => {
      if (res?.status == 'OK') {
        this.spinnerService.hide(); 
        window.open(res.data?.viewPath,'_blank',);
        // Common.showlabpharmaPDF_File(res.data?.viewPath);
        this.toastr.success(res.message)
        this.spinnerService.hide();

        }else{
          this.spinnerService.hide();
        }
 
      })

    }
  }


