export enum ScreenNames {
    Consultation = 1,
    AppointmentSchedule,
    PaymentStatus,
    Support,
    AddTemplate,
    PlanofCare,
    HospitalRegistration,
    PharmacyRegistration,
    LabRegistration,
    DoctorRegistrationwithSlotscheduling,
    ActiveInActiveList,
    PatientList,
    RoleManagement,
    UserManagement,
    LabInvestigationMaster,
    DrugMaster,
    CouponManagement,
    PromotionManagement,
    CompanyRegistration,
    Orgnization,
    CustomisePrescription,
    Configuration,
    FormTemplate,
    UploadConsentForm,
    Banner,
    AddMasterValues,
    TransactionStatus,
    AgentDetails,
    ReferralDiscount,
    LicensePlan,
    RefundRequest,
    NodalOfficer,
    DoctorModule,
    EnrollCorporateEmployee,
    CorporatePlan,
    HASession,
    service,
    ApartmentAdminApproval = 40,
    CenterRegistration, 
    TenantConfigurations,
    GamSettings,
    GapRegistration,
    SubscriptionDetails,
    GamCoupons,
    AyushmanBharath,
    PaymentRate,
    DrugDispensingReport,
    DrugDispensingRevenueReport,
    PrescribedDrugsReport,
    PaymentsReport,
    PaymentsPay,
    PrescribedServicesReport = 55,
    PrescribedServicesCostReport,
    Indent,
    AddDrugsToState,
    AddDrugsToAgent,
    DiagnosticReport,
    OtcReport,
    DssReport,
    ReferralPatientList,
    HubRegistration
}