import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultation-preview',
  templateUrl: './consultation-preview.component.html',
  styleUrls: ['./consultation-preview.component.css']
})
export class ConsultationPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
