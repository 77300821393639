<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Missed Call History
        <span class="text-danger" *ngIf="msdCalVal!=0">({{msdCalVal}})</span> </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid"> <br />
        <div class="row" *ngIf="isLcl">
            <div class="col-xl-4"></div>
            <div class="col-xl-2">
                <div class="form-group">
                    <input id="frmDt" name="frmDt" type="text" autocomplete="off" onpaste="return false"
                        placeholder="From Date" #fd="bsDatepicker" (focus)="fd.show();td.hide()" bsDatepicker
                        class="form-control form-control-sm" [maxDate]="maxDt" [(ngModel)]="frmDt"
                        (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }">
                </div>
            </div>
            <div class="col-xl-2">
                <div class="form-group">
                    <input id="toDt" name="toDt" type="text" autocomplete="off" onpaste="return false"
                        placeholder="To Date" bsDatepicker #td="bsDatepicker" [maxDate]="maxDt" (focus)="td.show();fd.hide()"
                        class="form-control form-control-sm" [minDate]="minDt" onkeypress='return event.charCode == 8'
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }" [(ngModel)]="toDt">
                </div>
            </div>
            <div class="col-xl-4">
                <div class="form-group">
                    <input type="button" class="btn btn-sm btn-primary" [disabled]="frmDt==''||toDt==''" (click)="LoadGrid()" value="Submit">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table datatable id="tblMsdCal" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center">S No</th>
                                        <th class="text-center">Patient Called<br />Date & Time</th>
                                        <th class="text-center">Call Type</th>
                                        <th class="text-center">Patient<br />Name</th>
                                        <th class="text-center">Gender</th>
                                        <th class="text-center">Age</th>
                                        <th class="text-center">Registration<br />Number</th>
                                        <th class="text-center">Mobile<br />Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lstGrid;let i=index;">
                                        <td>{{i+1}}</td>
                                        <td>{{item.call_time|date:'dd-MM-yyyy HH:mm:ss'}}</td>
                                        <td>{{item.missed_call_type}}</td>
                                        <td>{{item.patient_name}}</td> 
                                        <td>{{item.gender}}</td>
                                        <td>{{item.age}}</td> 
                                        <td>{{item.patient_id}}</td>
                                        <td>{{item.mobile}}</td>
<!--                                     
                                        <td>{{item?.callTime}}</td>
                                        <td>{{item?.missedCallType}}</td>
                                        <td>{{item.beneficiaryMemberDetails ? item?.beneficiaryMemberDetails?.member_name : item?.beneficiary?.first_name + ' ' + item?.beneficiary?.last_name}}</td> 
                                        <td>{{item.beneficiaryMemberDetails ? (item?.beneficiaryMemberDetails?.gender_id == 1 ? 'Male' : 'Female') : (item?.beneficiary?.gender_id == 1 ? 'Male' : 'Female')}}</td>  
                                        <td>{{item.beneficiaryMemberDetails ? item?.beneficiaryMemberDetails?.age : item?.beneficiary?.age}}</td>
                                        <td>{{item?.beneficiary?.id}}</td>
                                        <td>{{item.beneficiaryMemberDetails ? item?.beneficiaryMemberDetails?.mobile : item?.beneficiary?.mobile}}</td>
                                     -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>