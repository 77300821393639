import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FileService } from 'src/app/shared/services/file.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
declare let $: any;


@Component({
  selector: 'app-add-species',
  templateUrl: './add-species.component.html',
  styleUrls: ['./add-species.component.css']
})
export class AddSpeciesComponent implements OnInit {
  @Input() title: string;
  @Input() species: any;
  @Input() isPoc: boolean;

  lstGrid:any;
  dtRender: boolean;
  dtOptions:any={};
  speciesForm:FormGroup;
  animalForm:FormGroup;
  userRoleId: any;
  from_date: any = new Date();
  to_date: any = new Date();
  max_date : Date;
  minDate: Date; minToDate: Date;
  isDoctor: boolean=false;
  submitted:boolean=false;
  animalName: any;
  animalTypes: any;
  breedTyp: any;
  breedName: any;
  farmerId: any;
  dtRender1: boolean;
  tableData: any=[];
  srvcTyp: any;
  submitted1:boolean=false;
  showTable: boolean=true;
  animalDetails: any;


  get f() { return this.speciesForm.controls; }
  get g() { return this.animalForm.controls; }
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , public changeDetection: ChangeDetectorRef
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private router: Router
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private registrationsrv: RegistrationService
    , private notificationService: NotificationService, private userServ: UserService
    , private masterService: MasterService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      dom: 'Bfrtip',
      processing: true,
        buttons: [
          
            {
              extend: 'excelHtml5',
              title: 'Added Species List',
              exportOptions: { columns: ':visible:not(:first-child)' }
          },
      ]

    };
    this.userRoleId=sessionStorage.getItem('logUsrRoleId')
    if(this.userRoleId == 7){
      this.isDoctor=true
    }
    this.initFormControls();
    // this.formInIt2();
    this.loadGrid();
  }

  initFormControls() {
    this.speciesForm = this.formBuilder.group({
      from_date: [new Date(), Validators.required],
      to_date: [new Date(), Validators.required],
      status:['']
    });

    this.animalForm = this.formBuilder.group({
      age: ['', Validators.required],
      animal_type_id: ['', Validators.required],
      breed_type_id: ['', Validators.required],
      farmer_id: [''],
      gender_id: ['', Validators.required],
      id: [0],
      identification_marks: null,
      is_active: [true],
      luid: [],
      modified_by: [''],
      vaccination_details: {
      },
      weight: 0,
      complaint:[''],
      service_type_id:['']
    })
    
  }


  // formInIt2() {
  //   this.animalForm = this.formBuilder.group({
  //     age: ['', Validators.required],
  //     animal_type_id: ['', Validators.required],
  //     breed_type_id: ['', Validators.required],
  //     farmer_id: [''],
  //     gender_id: ['', Validators.required],
  //     id: [0],
  //     identification_marks: null,
  //     is_active: [true],
  //     luid: [],
  //     modified_by: [''],
  //     vaccination_details: {
  //     },
  //     weight: 0
  //   })
  // }

  loadGrid(){
   this.dtRender=false
   let Api:any
   if(this.species){
   Api=`mvu/case-registration/findcasesbyprimarycase/${this.species?.case_number}`
   }else{
    if(sessionStorage.animalDetails){
      this.animalDetails=JSON.parse(sessionStorage.getItem('animalDetails'))
    }
    Api=`mvu/case-registration/findbycallerid/${this.animalDetails?.caller_id}`
   }
    this.registrationService.get(Api).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.lstGrid = res.data??[]
           this.dtRender=true
         this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.dtRender=true
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  openAddSpecis(){
    this.animalTyp();
    this.SrvcTyp();
   this.showTable=false
   this.title='Add Species'
  }

  animalTyp() {
    this.registrationService.get(`mvu/animal/getall`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.animalTypes = res.data ?? []
          // this.toastr.success(res.message)
        }
        else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);
      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  BreedTyp(value: any) {
    this.registrationService.get(`mvu/breedtype/getbyanimalid/${value}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.breedTyp = res.data ?? []
          // this.toastr.success(res.message)
        }
        else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);
      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  SrvcTyp() {
    this.registrationService.get(`mvu/serviceType/getAll`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.srvcTyp = res.data ?? []
          // this.toastr.success(res.message)
        }
        else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);
      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  typeOfAnimal(value: any) {
    this.animalName = this.animalTypes.find((e: any) => e.id == value).name;
    this.BreedTyp(value)

  }
  typeOfBreed(value: any) {
    this.breedName = this.breedTyp.find((e: any) => e.id == value).breed_type;

  }
  addAnimal() {
    this.submitted1 = true, Common.getFormValidationErrors(this.animalForm);
    if (this.animalForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    
    if (this.species) {
      this.farmerId = this.species?.farmer_id
    }else{
      this.farmerId=this.animalDetails?.farmer_id
    }
    this.dtRender1 = false;
    if(this.species){
    let obj = {
      "age": this.animalForm.value.age,
      "animal_type_id": this.animalForm.value.animal_type_id,
      "breed_type_id": this.animalForm.value.breed_type_id,
      "farmer_id": this.farmerId,
      "gender_id": this.animalForm.value.gender_id,
      "id": 0,
      "identification_marks": null,
      "is_active": true,
      "luid": this.animalForm.value.luid,
      "animalName": this.animalName,
      "breedName": this.breedName,
      "modified_by": 0,
      "mvu_id": this.species?.mvu_id,
      "caller_id":this.species?.caller_id  ,
      // "base_location_id": 6, 
      "created_by": sessionStorage.loginId, 
      "primary_case_number": this.species?.case_number, 
      "vaccination_details": {

      },
      "weight": 0,
      "is_self_registration": false, 
      "service_type_id":this.animalForm.value.service_type_id,
      "complaint":this.animalForm.value.complaint,
      "is_case_registration":true
     
    }

    this.tableData.push(obj);
    console.log(this.tableData);
    this.changeDetection.detectChanges();
    this.dtRender1 = true;
    this.submitted1=false
    this.ClearPopUp();
    this.changeDetection.detectChanges();
  }else{
    let obj = {
      "age": this.animalForm.value.age,
      "animal_type_id": this.animalForm.value.animal_type_id,
      "breed_type_id": this.animalForm.value.breed_type_id,
      "farmer_id": this.farmerId,
      "gender_id": this.animalForm.value.gender_id,
      "id": 0,
      "identification_marks": null,
      "is_active": true,
      "luid": this.animalForm.value.luid,
      "animalName": this.animalName,
      "breedName": this.breedName,
      "modified_by": 0,
      "mvu_id": this.animalDetails?.mvu_id,
      "caller_id":this.animalDetails?.caller_id,
      // "base_location_id": 6, 
      "created_by": sessionStorage.loginId, 
      "primary_case_number": this.animalDetails?.case_number, 
      "vaccination_details": {

      },
      "weight": 0,
      "is_self_registration": false, 
      "service_type_id":this.animalForm.value.service_type_id,
      "complaint":this.animalForm.value.complaint,
      "is_case_registration":true
     
    }

    this.tableData.push(obj);
    console.log(this.tableData);
    this.changeDetection.detectChanges();
    this.dtRender1 = true;
    this.submitted1=false
    this.ClearPopUp();
    this.changeDetection.detectChanges();
  
  }
  }

  ClearPopUp(){
    this.animalForm.get('animal_type_id').setValue('')
    this.animalForm.get('breed_type_id').setValue('')
    this.animalForm.get('gender_id').setValue('')
    this.animalForm.get('age').setValue('')
    this.animalForm.get('luid').setValue('')
    this.animalForm.get('service_type_id').setValue('')
    this.animalForm.get('complaint').setValue('')
    // this.title='Add Species'
  }

  delete(index) {
    this.tableData.splice(index, 1);
  }

  keyPressNumbers(event: any) {
    var inp = event.key;
    if (/[0-9%.]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphaNumeric(event: any) {
    var inp = event.key;
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphabets(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  animalSave() {

    let payload = this.tableData

    this.registrationService.post(`mvu/animals/save`, payload).subscribe({
      next: res => {
        if (res.status == 'OK') {
          let AnimalSave = res.data ?? []
          this.toastr.success(res.message)
          // this.showTable=true
          this.cancel('table')

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  cancel(name:any){
    if(name == 'table'){
      this.initFormControls();
      this.loadGrid();
      this.tableData=[]
      this.showTable=true
    }else{
      this.initFormControls();
      this.activeModal.close('changes saved');
      
    }
  
  }

  onAnimalDetails(item:any){
  let obj={
    "mobile": this.animalDetails.mobile,
    "luid": this.animalDetails.luid,
    "vaccination_details": "{}",
    "animal_type_id": item.animal_type_id,
    "id": this.animalDetails.farmer_id,
    "weight": null,
    "animal_type": item.animal_type,
    "is_active": true,
    "gender_id": item.gender_id,
    "identification_marks": null,
    "breed_type": item.breed_type,
    "photo_path": "",
    "address": this.animalDetails.address,
    "gender_name": item.gender_name,
    "breed_type_id": item.breed_type_id,
    "farmer_id": this.animalDetails.farmer_id,
    "created_on": new Date(),
    "age": null,
    "farmer_name": this.animalDetails.farmer_name,
    "animal_id": item.id,
    "call_type_id": this.animalDetails.call_type_id,
    "case_number": item.case_number,
    "case_id": item.case_id,
    "caller_id" : this.animalDetails.caller_id
  }
  sessionStorage.setItem('animalDetails',JSON.stringify(obj))
  this.activeModal.close('changes saved')
  }

}
