import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LabService {
  logTnntId: number = null;

  constructor(private api: ApiService, private authSvc:AuthenticationService) { }

  // get(queryString: string): Observable<any> { return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}`); }
  get(queryString: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}?TENANT_ID=${tntId}`); 
  }

  getServiceAndTenant(queryString: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}&TENANT_ID=${tntId}`); 
  }

  getService(queryString: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}?TENANT_ID=${tntId}`); 
  }
  // getlabpacser(queryString: string): Observable<any> { 
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}&TENANT_ID=${tntId}`); 
  // }
  // getServiceSubGrp(queryString: string): Observable<any> { 
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}&TENANT_ID=${tntId}`); 
  // }
  //new line added for retailed plan   #kranthi  07-07-2023
  // getServices(queryString: string): Observable<any> { 
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}?TENANT_ID=${tntId}`); 
  // }

  getinvestigationbydoctorid(queryString: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${environment.loginAPI}${environment.labAPI}${queryString}`); 
  }
  // save(apiMethod: string, data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.labAPI}${apiMethod}`, data) }

  save(apimethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${environment.loginAPI}${environment.labAPI}${apimethod}?TENANT_ID=${tntId}`, data); 
  }

  savelabReports(apiMethod: string, data: any): Observable<any> { 
    
    return this.api.post(`${environment.labAPI}${apiMethod}`, data) }

    getlabReports(queryString: string): Observable<any> { 
      return this.api.get(`${environment.labAPI}${queryString}`); 
    }
    // new lines added for the slot booking 05-04-2023 #chandu
    saveScheduleSlot(apimethod: string, data: any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      return this.api.post(`${environment.loginAPI}${environment.labAPI}${apimethod}?TENANT_ID=${tntId}`, data); 
    }
 //new lines added for packages with services 11-05-2023
    packageWithServices(apimethod: string, data: any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      return this.api.post(`${environment.loginAPI}${environment.labAPI}${apimethod}?TENANT_ID=${tntId}`, data); 
    }

    // new method added for the get phlebo list 06-04-2023
    getPhleboByLabid(queryString: string): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      return this.api.get(`${environment.loginAPI}${environment.registration_API}${queryString}`); 
    }
}
