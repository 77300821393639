<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="height: 450px;">
    <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
        <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
    </div>
    <div class="table-responsive">
        <table datatable id="FeatureTable" class="table table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th>S No </th>
                    <th>Feature Name</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Featurelist; let i=index">
                    <td style="width: 8%;" class="text-center">{{i+1}}</td>
                    <td>{{item.feature_name}}</td>     
                </tr>
            </tbody>
        </table>
        <div class="col-sm-12 text-center">
            <button type="button" class="btn btn-primary text-right" (click)="submitFeature()">Submit</button>
        </div>
      
    </div>  
</div>

