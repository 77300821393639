import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-previous-history',
  templateUrl: './previous-history.component.html',
  styleUrls: ['./previous-history.component.css']
})
export class PreviousHistoryComponent implements OnInit {
  @Input() history: any; title = 'Previous History'
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    
  }

}
