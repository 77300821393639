<div class="modal-header">
    <!-- <h4 class="modal-title">{{title}}</h4> -->
    <h4 class="modal-title">Ayush Doctor Registration</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="doctorForm" autocomplete="off" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Type of Doctor <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="doctor_type_id" id="doctor_type_id"
                                class="form-control form-control-sm" disabled>
                                <option value="4">VMed Ayurveda</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3" *ngIf="isShowExlsvtyCtrls">
                    <div class="form-group">
                        <label class="control-label">Hospital Unique Code </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="group_reference_code"
                                id="group_reference_code" class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="isShowExlsvtyCtrls">
                    <div class="form-group">
                        <label class="control-label">Individual Unique Code </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="individual_reference_code"
                                id="individual_reference_code" class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Exclusivity for reference code </label>
                        <div>
                            <input type="checkbox" #exclusive_reference_code formControlName="exclusive_reference_code">
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <!-- <label class="control-label">Institution Name</label>
                        <div>
                            <select formControlName="hospital_name" id="hospital_name" #Institution
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_name.errors }"
                                class="form-control form-control-sm" (change)="ayushRegHide($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let hospital of hospitalList" [value]="hospital.id">
                                    {{hospital.ayush_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_name.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_name.errors.required">Institution Name is required</div>
                            </div>
                            (change)="ayushRegHide($event.target.value)"
                        </div> -->

                          <label class="control-label">Institution Name</label>
                        <div>
                            <select formControlName="ayush_id" id="ayush_id" #Institution
                                [ngClass]="{ 'is-invalid': submitted && f.ayush_id.errors }"
                                class="form-control form-control-sm" >
                                <option value="">Select</option>
                                <option *ngFor="let a of AyushhospitalList" [value]="a.id">
                                    {{a.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.ayush_id.errors" class="invalid-feedback">
                                <div *ngIf="f.ayush_id.errors.required">Institution Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Ayurveda Reg Number
                            <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="reg_no" [attr.disabled]="ayush_Reg?null:true"
                                class="form-control form-control-sm" maxlength="15">
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Name <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="first_name" id="first_name"
                                maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Surname <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="last_name" id="last_name"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Date of Birth <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="birth_date" id="birth_date"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }" [maxDate]="minDate"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.birth_date.errors" class="invalid-feedback">
                                <div *ngIf="f.birth_date.errors.required">
                                    Date of Birth
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Gender <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="gender_id" id="gender_id"
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let gender of genderList" [value]="gender.id">{{gender.gender_name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Mobile <span class="text-danger">*</span>
                          
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile" id="mobile" maxlength="10"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                                <div *ngIf="f.mobile.errors.minLength">Enter Valid Number</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Email <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="email" id="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="50"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Enter Valid Email</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Reg. Number <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mci_number" id="mci_number"
                                [ngClass]="{ 'is-invalid': submitted && f.mci_number.errors }"
                                class="form-control form-control-sm" maxlength="15">
                            <div *ngIf="submitted && f.mci_number.errors" class="invalid-feedback">
                                <div *ngIf="f.mci_number.errors.required">Reg. Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">State of(Reg. Number) <span class="text-danger">*</span> </label>
                        <div>
                            <!-- <input type="text" autocomplete="off" formControlName="mci_number_state_id"
                                id="mci_number_state_id" maxlength="20" class="form-control form-control-sm"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                placeholder="Enter Numerics only"> -->
                            <select formControlName="mci_number_state_id" id="mci_number_state_id"
                                [ngClass]="{ 'is-invalid': submitted && f.mci_number_state_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.mci_number_state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.mci_number_state_id.errors.required">State (Reg. Number) is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">State <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="state_id" id="state_id" #State
                                (change)="locationChanged(State.value,'district')"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">State is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Registration Expiry Date<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="reg_expiry_date" id="reg_expiry_date"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }"
                                class="form-control form-control-sm">
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-xl-3" style="display: none !important;">
                    <div class="form-group">
                        <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="location_type_id" id="location_type_id" #locationId
                                [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                (change)="locationChanged('','location')" class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let location of locationTypeList" [value]="location.id">
                                    {{location.location_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">District <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District
                                (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">District is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" style="display: none !important;">
                    <div class="form-group">
                        <label class="control-label">Mandal <span class="text-danger"
                                *ngIf='+locationId.value!=2'>*</span> </label>
                        <div>
                            <select formControlName="mandal_id" id="mandal_id" #Mandal
                                [attr.disabled]="+locationId.value==2?true:null"
                                (change)="locationChanged(Mandal.value,'village')"
                                [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                    {{mandal.mandal_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                <div *ngIf="f.mandal_id.errors.required">Mandal is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group" *ngIf="+locationId.value==2">
                        <label class="control-label">City 
                            <!-- <span class="text-danger">*</span> -->
                        </label>
                        <div>
                            <select formControlName="city_id" id="city_id"
                                [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                                [attr.disabled]="+locationId.value==1?true:null" class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let city of cityList" [value]="city.id">
                                    {{city.city_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                <div *ngIf="f.city_id.errors.required">City is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group"  style="display: none !important;" *ngIf="+locationId.value!=2">
                        <label class="control-label">Village <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="village_id" id="village_id"
                                [attr.disabled]="+locationId.value==2?true:null"
                                [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let village of villageList" [value]="village.id">
                                    {{village.village_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                <div *ngIf="f.village_id.errors.required">Village is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="pin_code" id="pin_code"
                                (keyup)="keyUp('pin_code')"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6"
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors?.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.pattern">Enter Valid Pin Code</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Marital Status<span class="text-danger">*</span></label>
                        <div>
                            <select formControlName="marital_status_id" id="marital_status_id"
                                [ngClass]="{ 'is-invalid': submitted && f.marital_status_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let marital of maritalList" [value]="marital.id">{{marital.marital_status}}</option>                                
                            </select>
                            <div *ngIf="submitted && f.marital_status_id.errors" class="invalid-feedback">
                                <div *ngIf="f.marital_status_id.errors.required">Marital Status is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Qualification <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="qualification_id" id="qualification_id"
                                [ngClass]="{ 'is-invalid': submitted && f.qualification_id.errors }" #QualificationId
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let qualification of qualificationsList" [value]="qualification.id">
                                    {{qualification.qualification}}</option>
                            </select>
                            <div *ngIf="submitted && f.qualification_id.errors" class="invalid-feedback">
                                <div *ngIf="f.qualification_id.errors.required">Qualification is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Specialization <span class="text-danger">*</span>
                        </label>
                        <div>
                            <select formControlName="specialization_id" id="specialization_id"
                                [ngClass]="{ 'is-invalid': submitted && f.specialization_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let specialization of specializationList" [value]="specialization.id">
                                    {{specialization.specialization}}</option>
                            </select>
                            <div *ngIf="submitted && f.specialization_id.errors" class="invalid-feedback">
                                <div *ngIf="f.specialization_id.errors.required">Specialization is required</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Super Speciality Degree</label>
                        <div>
                            <select formControlName="super_qualification_id" id="super_qualification_id"
                                (change)="superSecialityDegree($event.target.value)"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let superSpecialityDegree of superSpecialityDegreeList"
                                    [value]="superSpecialityDegree.id">{{superSpecialityDegree.qualification}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Super Specialisation</label>
                        <div>
                            <select formControlName="super_specialization_id" id="super_specialization_id"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let super of super_SpecialisationList" [value]="super.id">
                                    {{super.specialization}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Working College/Hospital</label>
                        <!-- <span  class="text-danger">*</span> -->
                        <div>
                            <!-- <select formControlName="hospital_working_in_presently" id="hospital_working_in_presently"
                                class="form-control form-control-sm" [attr.disabled]="+TypeOfDoctor.value==1?true:null"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_working_in_presently.errors }"
                                (change)="onHospitalWorking('dd', $event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let hospital of hospitalList" [value]="hospital.id">
                                    {{hospital.hospital_name}}</option>
                            </select> -->
                            <input class="form-control form-control-sm" formControlName="other_hospital"
                                type="text">
                            <!-- <div *ngIf="submitted && f.hospital_working_in_presently.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_working_in_presently.errors.required">Hospital Name is required
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Experience (Years) <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="years_experience"
                                id="years_experience" maxlength="30"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.years_experience.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.years_experience.errors" class="invalid-feedback">
                                <div *ngIf="f.years_experience.errors.required">Experience is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Other Hospital</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="other_hospital" id="other_hospital"
                                class="form-control form-control-sm" maxlength="100"
                                [attr.disabled]="+TypeOfDoctor.value==1?true:null"
                                (change)="onHospitalWorking('other',$event.target.value)"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Experience (Years) <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="years_experience"
                                id="years_experience" maxlength="30"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.years_experience.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.years_experience.errors" class="invalid-feedback">
                                <div *ngIf="f.years_experience.errors.required">Experience is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Audio Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="audio_consultation_price"
                                id="audio_consultation_price" maxlength="5"
                                [ngClass]="{ 'is-invalid': submitted && f.audio_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.audio_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.audio_consultation_price.errors.required">Audio Consultation Rate is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Video Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="video_consultation_price"
                                id="video_consultation_price" maxlength="5"
                                [ngClass]="{ 'is-invalid': submitted && f.video_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.video_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.video_consultation_price.errors.required">Video Consultation Rate is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- </div>
            <div class="row"> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Available on Days <span class="text-danger">*</span>
                        </label>
                        <div>
                            <ng-multiselect-dropdown [settings]="dropdownSettings_weekDays" [placeholder]="'All'"
                                [data]="weekDaysList" formControlName="weekday_id"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Available From Time<span class="text-danger">*</span></label>
                        <div>
                            <timepicker formControlName="from_time" [showMeridian]="isMeridian"></timepicker>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Available To Time<span class="text-danger">*</span></label>
                        <div>
                            <timepicker formControlName="to_time" [showMeridian]="isMeridian"></timepicker>
                        </div>
                    </div>
                </div> -->
                <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Consultation Time
                        </label>
                        <div>
                            <!-- <input type="text" autocomplete="off" formControlName="max_consultation_time"
                                id="max_consultation_time" placeholder="Maximum Consultation Time"
                                class="form-control form-control-sm"> -->
                            <select formControlName="max_consultation_time" id="max_consultation_time"
                                class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.max_consultation_time.errors }">
                                <option value="">Select</option>
                                <option value="10">10 Minutes</option>
                                <option value="15" selected>15 Minutes</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Languages Preferred <span class="text-danger">*</span>
                        </label>
                        <div>
                            <ng-multiselect-dropdown [settings]="dropdownSettings_language" [placeholder]="'All'"
                                [data]="languagesList" formControlName="doctor_languages"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="radio" formControlName="is_active" [value]="true">Active
                            <input type="radio" formControlName="is_active" [value]="false">In Active
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Send Notifications to Mobile<span
                                class="text-danger">*</span></label>
                        <div>
                            <input type="checkbox" formControlName="send_notification_to_mobile">
                        </div>
                    </div>
                </div> -->
                <!-- </div>
            <div class="row" *ngIf="+doctorForm.get('doctor_type_id').value==2"> -->
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2">
                    <div class="form-group">
                        <label class="control-label">Bank Account Number<span class="text-danger">*</span>
                        </label>
                        <div>

                            <input type="text" autocomplete="off" formControlName="bank_account_number"
                                id="bank_account_number" class="form-control form-control-sm" maxlength="20"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('bank_account_number').invalid }">
                            <!-- (keyup)="keyUp('bank_account_number')" -->
                            <div *ngIf="submitted && doctorForm.get('bank_account_number').invalid"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('bank_account_number').errors.required">Bank Account
                                    Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2">
                    <div class="form-group">
                        <label class="control-label"> Bank Name <span class="text-danger">*</span>
                        </label>
                        <div>
                            <select formControlName="branch_name" id="branch_name" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('branch_name').errors }">
                                <option value="">Select</option>
                                <option *ngFor="let item of bankNamesList" [value]="item.id">
                                    {{item.bank_name}}</option>
                            </select>
                            <div *ngIf="submitted && doctorForm.get('branch_name').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('branch_name').errors.required">Bank Name is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2">
                    <div class="form-group">
                        <label class="control-label"> Branch Location <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="branch_location" id="branch_location"
                                class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('branch_location').invalid }"
                                (keyup)="keyUp('branch_location')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) ||
                            (event.charCode == 32 || event.charCode == 46))' maxlength="50">
                            <div *ngIf="submitted && doctorForm.get('branch_location').invalid"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('branch_location').errors.required">Branch
                                    location is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+doctorForm.get('doctor_type_id').value==2">
                    <div class="form-group">
                        <label class="control-label">IFSC Code<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="ifsc_code" id="ifsc_code"
                                class="form-control form-control-sm" (keyup)="keyUp('ifsc_code')"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('ifsc_code').invalid }"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'
                                maxlength="11">
                            <div *ngIf="submitted && doctorForm.get('ifsc_code').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('ifsc_code').errors.required">IFSC Code is
                                    required</div>
                                <div *ngIf="doctorForm.get('ifsc_code').errors.pattern">Enter Valid IFSC, ex:
                                    SBIN0000156</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div>
            <div class="row"> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Aadhar Number<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="aadhar_number" id="aadhar_number"
                                (keyup)="keyUp('aadhar_number')"
                                [ngClass]="{ 'is-invalid': submitted && f.aadhar_number.errors }"
                                class="form-control form-control-sm" maxlength="12"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && doctorForm.get('aadhar_number').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('aadhar_number').errors.required">Aadhar Number is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Address </label>
                        <div>
                            <!-- [attr.disabled]="(+TypeOfDoctor.value==2)?null:true" -->
                            <textarea rows="2" class="form-control form-control-sm"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)' maxlength="100"
                                formControlName="home_address"></textarea>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-3">
                    <div class="form-group" *ngIf="showCharge">
                        <label class="control-label">Audio Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="audio_consultation_price"
                                id="audio_consultation_price" maxlength="5"
                                [ngClass]="{ 'is-invalid': submitted && f.audio_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.audio_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.audio_consultation_price.errors.required">Audio Consultation Rate is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group" *ngIf="showCharge">
                        <label class="control-label">Video Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="video_consultation_price"
                                id="video_consultation_price" maxlength="5"
                                [ngClass]="{ 'is-invalid': submitted && f.video_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.video_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.video_consultation_price.errors.required">Video Consultation Rate is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" [ngClass]="showCharge?'':'d-none'">
                    <div class="form-group">
                        <label class="control-label"> is Health Worker</label>
                        <div>
                            <input type="checkbox" #isHW formControlName="isHW" (change)="HW.value=''">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" [ngClass]="showCharge?'':'d-none'">
                    <div class="form-group">
                        <label class="control-label"> Health Worker</label>
                        <div>
                            <!-- [attr.disabled]="isHW.checked?null:true" -->
                            <select formControlName="hlthWorker" id="hlthWorker"
                            [attr.disabled]="isHW.checked?null:true" class="form-control form-control-sm" #HW>
                                <option value="">Select</option>
                                <option *ngFor="let hw of hwList" [value]="hw.id">{{hw.first_name+' '+hw.last_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Clinic Address </label>
                        <div>
                            <textarea rows="2" class="form-control form-control-sm" formControlName="clinic_address"
                                id="clinic_address"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                                maxlength="100" (keyup)="keyUp('clinic_address')"
                                [attr.disabled]="(+TypeOfDoctor.value==1)?true:null"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Hospital Address </label>
                        <div>
                            <textarea rows="2" class="form-control form-control-sm" formControlName="hospital_address"
                                id="hospital_address"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                                maxlength="100" [attr.disabled]="(+TypeOfDoctor.value==3)?true:null"></textarea>
                        </div>
                    </div>
                </div> -->
                <!-- </div>
            <div class="row"> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Address for Prescription</label>
                        <div>
                            <select formControlName="prescription_address" id="prescription_address"
                                [attr.disabled]="(+TypeOfDoctor.value==2)?null:true"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option value="1">Home</option>
                                <option value="2">Clinic</option>
                                <option value="3">Hospital</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pharmacy Preferred</label>
                        <div>
                            <select formControlName="pharmacy_id" id="pharmacy_id"
                                [attr.disabled]="(+TypeOfDoctor.value==2)?null:true"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let pharmacy of pharmacyList" [value]="pharmacy.id">
                                    {{pharmacy.pharmacy_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Lab Preferred</label>
                        <div>
                            <select formControlName="lab_id" id="lab_id"
                                [attr.disabled]="(+TypeOfDoctor.value==2)?null:true"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let lab of labList" [value]="lab.id">{{lab.lab_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> is Health Worker</label>
                        <div>
                            <input type="checkbox" #isHW formControlName="isHW" (change)="HW.value=''">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Health Worker</label>
                        <div>
                            <select formControlName="hlthWorker" id="hlthWorker"
                                [attr.disabled]="isHW.checked?null:true" class="form-control form-control-sm" #HW>
                                <option value="">Select</option>
                                <option *ngFor="let hw of hwList" [value]="hw.id">{{hw.first_name+' '+hw.last_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Assistant Details</label>
                        <div>
                            <input type="checkbox" #AssistantDetails formControlName="assistant_detail">
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-xl-3">

                </div> -->
                <!-- </div>

            <div class="row"> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Assistant Name<span class="text-danger"
                                *ngIf='AssistantDetails.checked'>*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="AssistantDetails.checked?null:true" autocomplete="off"
                                formControlName="assistant_first_name" id="assistant_first_name"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_first_name').invalid }"
                                class="form-control form-control-sm" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                                maxlength="50" (keyup)="keyUp('assistant_first_name')">
                            <div *ngIf="submitted && doctorForm.get('assistant_first_name').errors"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_first_name').errors.required">Assistant Name is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Contact Number<span class="text-danger"
                                *ngIf='AssistantDetails.checked'>*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="AssistantDetails.checked?null:true" autocomplete="off"
                                maxlength="12" formControlName="assistant_contact_number" id="assistant_contact_number"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_contact_number').invalid }"
                                class="form-control form-control-sm"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="10"
                                (keyup)="keyUp('assistant_contact_number')">
                            <div *ngIf="submitted && doctorForm.get('assistant_contact_number').errors"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_contact_number').errors.required">Contact
                                    Number is required</div>
                                <div *ngIf="doctorForm.get('assistant_contact_number').errors.minLength">Enter Valid
                                    Number</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Email<span class="text-danger"
                                *ngIf='AssistantDetails.checked'>*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="AssistantDetails.checked?null:true" autocomplete="off"
                                formControlName="assistant_email" id="assistant_email" maxlength="30"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_email').invalid }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && doctorForm.get('assistant_email').errors" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_email').errors.required">Email is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Send Notification</label>
                        <div>
                            <input type="checkbox" [attr.disabled]="AssistantDetails.checked?null:true"
                                formControlName="notification_to_assistant">
                        </div>
                    </div>
                </div> -->
                
                <div class="col-xl-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="file" class="d-none" id="digitalCertificate" #digitalCertificate
                                            formControlName="digitalFile" (change)="singleFileUpload($event,1,'8')"
                                            [ngClass]="{'is-invalid': submitted && f.digitalFile.invalid }">
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="digitalCertificate.click()">Digital Signature </p> <span
                                            class="text-danger">*</span>
                                        <p class="small mb-1">Png format only</p>
                                        <div *ngIf="submitted && f.digitalFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.digitalFile.errors.required">Digital Signature is required
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="mciCertificate" #mciCertificate
                                            formControlName="qualificationFile"
                                            (change)="singleFileUpload($event,2,'9')"
                                            [ngClass]="{'is-invalid': submitted && f.qualificationFile.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white"
                                            (click)="mciCertificate.click()">Highest Qualification Certificate <span
                                                class="text-danger">*</span></a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="mciCertificate.click()">Registration Certificate</p>
                                        <span class="text-danger">*</span>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.qualificationFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.qualificationFile.errors.required">Registration Certificate
                                                is
                                                required
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="bioData" #bioData
                                            (change)="singleFileUpload($event,3,'4')">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="bioData.click()">Bio Data</a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="bioData.click()">Bio Data</p>
                                        <p class="small mb-1">word or pdf format only</p>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="photo" #photo
                                            (change)="singleFileUpload($event,4,'5')">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="photo.click()">Photo </a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="photo.click()">Photo </p><span class="text-danger">*</span>
                                        <p class="small mb-1">jpg,bmp format only</p>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="audioRecord" #audioRecord
                                            (change)="singleFileUpload($event,5,'10')">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="audioRecord.click()">Video
                                            Record </a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="audioRecord.click()">Video
                                            Record </p>
                                        <p class="small mb-1">mp4 format only</p>
                                    </td>
                                    <!-- <td>
                                        <input type="file" class="d-none" id="audioRecord" #aAdhar
                                            formControlName="aadharFile" (change)="singleFileUpload($event,6,'12')"
                                            [ngClass]="{'is-invalid': submitted && f.aadharFile.invalid }">
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="aAdhar.click()">Aadhar </p><span class="text-danger">*</span>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.aadharFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.aadharFile.errors.required">Aadhar image is required
                                            </div>
                                        </div>
                                    </td> -->
                                    <!-- <td>
                                        <input type="file" class="d-none" id="audioRecord" #PanCard
                                            formControlName="panFile" (change)="singleFileUpload($event,7,'13')"
                                            [ngClass]="{'is-invalid': submitted && f.panFile.invalid }">
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="PanCard.click()">Pan Card </p><span class="text-danger">*</span>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.panFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.panFile.errors.required">Pan Card image is required
                                            </div>
                                        </div>
                                    </td> -->
                                </tr>
                                <tr>
                                    <td>{{uploadFileList[0].file_name}}</td>
                                    <td>{{uploadFileList[1].file_name}}</td>
                                    <td>{{uploadFileList[2].file_name}}</td>
                                    <td>{{uploadFileList[3].file_name}}</td>
                                    <td>{{uploadFileList[4].file_name}}</td>
                                    <!-- <td>{{uploadFileList[5].file_name}}</td>
                                    <td>{{uploadFileList[6].file_name}}</td> -->
                                </tr>
                                <tr>
                                    <td class="text-center"><a *ngIf="lstDrDocs.dgtlSign!=''" style="cursor: pointer;"
                                            [href]="lstDrDocs.dgtlSign" target="_blank">View</a></td>
                                    <td class="text-center"><a *ngIf="lstDrDocs.highQlfcn!=''" style="cursor: pointer;"
                                            [href]="lstDrDocs.highQlfcn" target="_blank">View</a></td>
                                    <td class="text-center"><a *ngIf="lstDrDocs.bioData!=''" style="cursor: pointer;"
                                            [href]="lstDrDocs.bioData" target="_blank">View</a></td>
                                    <td class="text-center"><a *ngIf="lstDrDocs.photo!=''" style="cursor: pointer;"
                                            [href]="lstDrDocs.photo" target="_blank">View</a></td>
                                    <td class="text-center"><a *ngIf="lstDrDocs.videoRcrd!=''" style="cursor: pointer;"
                                            [href]="lstDrDocs.videoRcrd" target="_blank">View</a></td>
                                    <!-- <td class="text-center"><a *ngIf="lstDrDocs.aadhaar!=''" style="cursor: pointer;"
                                            [href]="lstDrDocs.aadhaar" target="_blank">View</a></td>
                                    <td class="text-center"><a *ngIf="lstDrDocs.panCard!=''" style="cursor: pointer;"
                                            [href]="lstDrDocs.panCard" target="_blank">View</a></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" [disabled]="isSubmit_Disabled?true:null" class="btn btn-sm btn-primary" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>