<div class="row">
    <div class="container-fluid pl-0 pr-0">
        
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Customise Prescriptions 
                        
                    </div>

                    <div class="card-body">

                        <div class="row small">

                            <div class="col-xl-4">
                                <div class="form-group">
                                    <label class="control-label">Hospital Name
                                    </label>
                                    <div>
                                        <input type="text" class="form-control form-control-sm" placeholder="Hospital Name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8">
                                <div class="form-group">
                                    <label class="control-label">Hospital Address
                                    </label>
                                    <div>
                                        <textarea class="form-control form-control-sm" rows="1" placeholder="Hospital Address">

                                        </textarea>
                                     
                                    </div>
                                </div>
                            </div>
                         
                        
                           </div>
                         
                   
                        <div class="row small">

                            <div class="col-xl-4">
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="ChiefComplaints">
                                    <label class="custom-control-label" for="ChiefComplaints">Chief Complaints</label>
                                </div>
                            
                                <div class="custom-control custom-control-right custom-switch mb-2" >
                                    <input type="checkbox" class="custom-control-input" id="DoctorComment">
                                    <label class="custom-control-label" for="DoctorComment">Doctor Comment</label>
                                </div>
                           

                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="HistoryofPresentillness">
                                    <label class="custom-control-label" for="HistoryofPresentillness">History of Present illness</label>
                                </div>
                       

                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Temperature">
                                    <label class="custom-control-label" for="Temperature">Temperature</label>
                                </div>
                        

                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Pulse">
                                    <label class="custom-control-label" for="Pulse">Pulse</label>
                                </div>
                        
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Systolic/Diastolic">
                                    <label class="custom-control-label" for="Systolic/Diastolic">Systolic/Diastolic</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="PastHistory">
                                    <label class="custom-control-label" for="PastHistory">Past History</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Allergies">
                                    <label class="custom-control-label" for="Allergies">Allergies</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="ProvisionalDiagnosisDiagnosis">
                                    <label class="custom-control-label" for="ProvisionalDiagnosisDiagnosis">Provisional Diagnosis/ Diagnosis  </label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="LabInvestigations">
                                    <label class="custom-control-label" for="LabInvestigations">Lab Investigations</label>
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="DrugType">
                                    <label class="custom-control-label" for="DrugType">Drug Type</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="DrugName">
                                    <label class="custom-control-label" for="DrugName">Drug Type</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Duration">
                                    <label class="custom-control-label" for="Duration">Duration</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Dosage">
                                    <label class="custom-control-label" for="Dosage">Dosage</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="RelationwithFood">
                                    <label class="custom-control-label" for="RelationwithFood">Relation with Food</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Period">
                                    <label class="custom-control-label" for="Period">Period</label>
                                </div>
                                 <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="DrugInstruction">
                                    <label class="custom-control-label" for="DrugInstruction">Drug Instruction</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="RestExercise">
                                    <label class="custom-control-label" for="RestExercise">Rest/Exercise</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Diet">
                                    <label class="custom-control-label" for="Diet">Diet</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="FollowAdvice">
                                    <label class="custom-control-label" for="FollowAdvice">Follow Advice</label>
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Referral">
                                    <label class="custom-control-label" for="Referral">Referral</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="FollowupDate">
                                    <label class="custom-control-label" for="FollowupDate">Follow up Date</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Newfields-1">
                                    <label class="custom-control-label" for="Newfields-1">New fields-1</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Newfields-2">
                                    <label class="custom-control-label" for="Newfields-2">New fields-2</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Newfields-3">
                                    <label class="custom-control-label" for="Newfields-3">New fields-3</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Newfields-4">
                                    <label class="custom-control-label" for="Newfields-4">New fields-4</label>
                                </div>
                                <div class="custom-control custom-control-right custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" id="Newfields-5">
                                    <label class="custom-control-label" for="Newfields-5">New fields-5</label>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <div class="row">
<div class="col-xl-12 text-center">
    <button type="button" class="btn btn-sm btn-secondary"  data-toggle="modal" data-target="#myModal1">Preview</button>
    &nbsp; &nbsp;
<button type="button" class="btn btn-sm btn-primary">Save </button>


</div>

                        </div>
           
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">  Customise Prescriptions Preview</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">

            <table border="0" style="background-color:#f8f6f6;">
            
              <tbody><tr>
                <td style="padding: 20px;">
            
                  <table class="" border="0" cellpadding="4" cellspacing="4" style="width: 100%; font-size:14px; background-color: #f8f6f6; padding: 4px; background-image: url('./img/backgound.png');  background-repeat: no-repeat;
            background-attachment:cover; background-position: center;">
              <tbody>
              <tr style="height:2px;">  <td colspan="6"></td></tr>
            
           
            
        
            
              <tr><td colspan="6">
            
                <table cellpadding="2">
            
                  <tbody><tr><td><h6 class="mb-0">Chief Complaints/ History of Present Illness: </h6></td></tr>
                
                  <tr><td> Fever, Cough, Cold, Headche, Blurrd vision, anxiety, frustration  </td></tr>
                  <tr style="height: 5px;"><td></td></tr>
                  <tr><td><h6 class="mb-0">Vitals</h6></td></tr>
                
                  <tr><td> Vitals </td></tr>
                  <tr style="height: 5px;"><td></td></tr>
                  <tr><td><h6 class="mb-0">Past History &amp; Allergies:</h6></td></tr>
                
                  <tr><td> Allergies were discovered over 150 years ago, however despite historical developments mankind is still searching for a cure. In 1859, Charles Harrison Blackley sneezed. A doctor in Manchester, England, Blackley suffered  </td></tr>
                  <tr style="height: 5px;"><td></td></tr>
                 
                  <tr><td><h6 class="mb-0">Provisional Diagnosis </h6></td></tr>
                
                  <tr><td>  VIRAL Fever </td></tr>
                  <tr style="height: 5px;"><td></td></tr>
                  <tr><td><h6 class="mb-0">Investigations </h6></td></tr>
                
                  <tr><td>  CBP, SMEAR FOR MP, WIDAL</td></tr>
                  <tr style="height: 5px;"><td></td></tr>
                  <tr><td><h6 class="mb-0">Advice  </h6></td></tr>
                
                  <tr><td>  REVIEW SOS/ AFTER 5 DAYS</td></tr>
                </tbody></table>
              </td>
            </tr>
            <tr style="height:2px;">  <td colspan="6"></td></tr>
            <tr>  <td colspan="6">
            
              <table border="0" style="width:100%;" cellpadding="2">
            
              <tbody><tr style="background-color: #17c5bb; color: #ffffff;">
            
                <th>S.No</th>
                <th>Drug Type</th>
                <th>Drug Name</th>
                <th>Dosage</th>
                <th>Direction</th>
                <th>Instructions</th>
                <th>No of Days</th>
            
              </tr>
              <tr>
                <td>1</td>
                <td>Tab</td>
                <td>AZEE</td>
                <td>500MG</td>
                <td>O - X – X</td>
                <td>Before Food</td>
                <td>3</td>
            
              </tr>
              <tr>
                <td>2</td>
                <td>Tab</td>
                <td>AZEE</td>
                <td>500MG</td>
                <td>O - X – X</td>
                <td>Before Food</td>
                <td>3</td>
            
              </tr>
              <tr>
                <td>3</td>
                <td>Syp</td>
                <td>ASCORIL</td>
                <td>5ML</td>
                <td>O - X – X</td>
                <td>After Food</td>
                <td>14</td>
            
              </tr>
            </tbody></table>
            
            
            </td></tr>
            
            <tr>  <td colspan="6">
            
              <hr style="border:1px solid #17c5bb">
            </td></tr>
            
            <tr>  <td colspan="6">
            <table cellpadding="2">
            
              <tbody><tr><td colspan="2"><h5>Lab Test</h5></td></tr>
            
              <tr><td>1</td><td> CBP </td></tr>
              <tr><td>2</td><td> Liver Function Test  </td></tr>
              <tr><td>3</td><td> CBP </td></tr>
              <tr><td>4</td><td> Liver Function Test  </td></tr>
            </tbody></table>
              
            </td></tr>
            
            <tr>  <td colspan="6">
              <table cellpadding="2">
              
                <tbody><tr><td><h5>Follow Up</h5></td></tr>
              
                <tr><td> 2 Weeks </td></tr>
               
              </tbody></table>
                
              </td></tr>
              <!-- <tr>  <td colspan="6">
                <table cellpadding="2">
                
                  <tr><td><h5>Doctor Advice</h5></td></tr>
                
                  <tr><td> Stay Home, Aviod fatty and Spicy Foods Drink of Lot of water </td></tr>
                 
                </table>
                  
                </td></tr> -->
            
            
            </tbody></table>
            
                  </td>
                </tr>
            
                <tr style="background-color:#f2f2f2;"> <td colspan="6" align="center">
            
                  <hr style="border:1px solid #17c5bb; margin-bottom:0px;">
                  <small style="font-size: 14px; text-align: center;">Vidmed Pharmacy Pharmacy, Ameerpet, Hyderabad, Telangana, Contact : 9849012835 </small>
                </td></tr>
                
            </tbody></table>
            </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-sm btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>