import { RegistrationService } from 'src/app/shared/services/registration.service';
import { Component, OnInit,Input } from '@angular/core';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Common } from 'src/app/common';
declare let register: any;
declare let doctortodoctorcall: any;
declare let agenttodoctorcall: any;
declare let socketStaus:any;
declare let updateStatus:any;
declare let $: any;
declare let JqueryReady: any;
declare let agenttodoctorcallfamilymember:any;
@Component({
  selector: 'app-active-doctors-list',
  templateUrl: './active-doctors-list.component.html',
  styleUrls: ['./active-doctors-list.component.css']
})
export class ActiveDoctorsListComponent implements OnInit {
  activelistofdoctors: any; userName = ''; obj3; drData;recentdoctorid;totaldoc=false;singledoc=true;
  alldocdivbtn=true;roleId:any;pagetitle='';
  constructor(config: NgbModalConfig
    ,public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private regService: RegistrationService
    , private reportService: ReportService
    , private modalService: NgbModal
    , private masterService: MasterService
    , private authSvc: AuthenticationService
    , private chatservice: ChatService
    ,private router: Router
  ) { }

  ngOnInit(): void {
    this.roleId = this.authSvc.roleId;
    if(sessionStorage.getItem('patlistA')=='patlistagent'){
      this.activedoctorsList();
      sessionStorage.setItem('patlistA','');
    }else{
    this.drData = JSON.parse(sessionStorage.getItem('DoctorDetails'))
    var fn = sessionStorage.getItem('firstname');
    var ln = sessionStorage.getItem('lastname')
    this.userName = this.drData.first_name + ' ' + this.drData.last_name;
    this.recentdoctors();
    setTimeout(() => {
      this.activedoctorsList();
  },1000);
}
this.pagetitle=sessionStorage.getItem('familymemberscreenT');
  }
  activedoctorsList() {
    this.regService.activeDoctorsList().subscribe(res => {
      if (res.length == 0) {
        this.activelistofdoctors = res;
        this.activelistofdoctors = [];
        Common.loadDataTable(this.activelistofdoctors, '#tablel');
      } else {
        // for(let i=0;i<res.length;i++){
        //   res[i].userId=res[i].userId.split('_');
        // }
        if(this.recentdoctorid){
          var docidr=this.recentdoctorid
        res.forEach(function(item,i){
          if(item.doctorId == docidr){
            res.splice(i, 1);
            res.unshift(item);
          }
        });
        this.activelistofdoctors = res;
        Common.loadDataTable(this.activelistofdoctors, '#tablel');
      }
      else{
        this.activelistofdoctors = res;
        Common.loadDataTable(this.activelistofdoctors, '#tablel');
      }
      }
    })
  }
  consultDoctor(item: any) {
    //sessionStorage.setItem('joingroup','');
    sessionStorage.setItem('call_type_id',1+'');
    sessionStorage.setItem('doctortodoctor','doctortodoctorcall');
    if(this.roleId==7){
    sessionStorage.setItem('owndoc',this.drData.id)
    }
    //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
    if(socketStaus()==1){
      //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
      //updateStatus(item.userId,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
      if(this.roleId==7){
      sessionStorage.setItem('logindocuser',this.drData.id);
      }
      let fromId = (item.doctorId).toString();
        sessionStorage.setItem('fromdocid',fromId);
      doctortodoctorcall('doc_'+item.doctorId,'pat_'+sessionStorage.getItem('callingpatient_id'),this.userName,'Consulting', this.drData.id)
      this.toastr.info("Calling to"+' '+item.name);
      //stopTimer();
					//document.getElementById("videoCallModald").click();
          //(<HTMLInputElement>document.getElementById('toId')).value='doc_'+item.doctorId
					//playSound();
          // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['/home/poc']);
          // });
          this.activeModal.close('');
    }
  }
  recentdoctors(){
    this.regService.recentdoctor(sessionStorage.getItem('patientidchat')).subscribe(res => {
      if (res.data.length == 0) {
        this.totaldoc=true;
        this.singledoc=false;
        this.recentdoctorid='';
      }else{
        this.totaldoc=false;
        this.singledoc=true;
        this.recentdoctorid=res.data[0].doctor_id;
      }
    })
  }
  alldocclick(){
    this.totaldoc=true;
    this.singledoc=false;
    this.alldocdivbtn=false;
  }
  consultDoctoragent(item: any) {
    //sessionStorage.setItem('joingroup','');
    sessionStorage.setItem('agenttodoctor','agenttodoctorcall');
    sessionStorage.setItem('fromrefpat','');
    //sessionStorage.setItem('patientidchat',patient_data.id);
    sessionStorage.setItem('mem_type','true');
    sessionStorage.setItem('agentdoctorid',item.doctorId);
    //sessionStorage.setItem('callingpatient_id',patient_data.id)
    let payload = {
      "patient_id": sessionStorage.getItem('callingpatient_id'),
      "doctor_id": item.doctorId,
      "patient_name": sessionStorage.getItem('agentcallpname'),
      "patient_email": ''
    }
    this.regService.generateConsulation('POST', payload).subscribe(res => {
      if (res.status == 'OK') {
        let fromId = (item.doctorId).toString();
        let toId = (sessionStorage.getItem('callingpatient_id')).toString();
        $("#fromId").val("doc_" + fromId);
        $("#toId").val("pat_" + toId);
        $("#order_no").val(res.data.order_no);
        if(sessionStorage.getItem('isfmlymembr')!='familymember'){
        $("#is_self").val(true);
        }else{
          $("#is_self").val(false);
        }
        $("#patient_name").val(sessionStorage.getItem('agentcallpname'));
        $("#patient_interaction_id").val(res.data.consultation_id);
        sessionStorage.setItem('chat_consultationid',res.data.consultation_id);
        $("#patient_info").val(JSON.stringify({ "patient_interaction_id": res.data.consultation_id, "order_no": res.data.order_no, "hospital_id": "", "symptoms": "", "is_self": true }));
        sessionStorage.isPatient = true;
        if(socketStaus()==1){
          //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
          //updateStatus(item.userId,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
          if(this.roleId==7){
          sessionStorage.setItem('logindocuser',this.drData.id);
          }
          agenttodoctorcall('doc_'+item.doctorId,'pat_'+sessionStorage.getItem('callingpatient_id'),sessionStorage.getItem('agentcallpname'),'Consulting')
          this.toastr.info("Calling to"+' '+item.name);
          //stopTimer();
              //document.getElementById("videoCallModald").click();
              //(<HTMLInputElement>document.getElementById('toId')).value='doc_'+item.doctorId
              //playSound();
              // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              //   this.router.navigate(['/home/poc']);
              // });
              this.activeModal.close('');
        }
       // this.router.navigate(['home/poc'])
          // .then((e) => {
          //   let ws = JSON.parse(sessionStorage.getItem('webSkt')); // JqueryReady(); // answerCall(fromId, toId);
          //   Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); });
          //   if (e)
          //     console.log("Navigation is successful!");
          //   else
          //     console.log("Navigation has failed!");
          // });

      }
      else {
        this.toastr.warning(res.message, 'Generate Consulation');
      }
    });
    if(this.roleId==7){
    sessionStorage.setItem('owndoc',this.drData.id)
    }
    //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
    // if(socketStaus()==1){
    //   //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
    //   //updateStatus(item.userId,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
    //   if(this.roleId==7){
    //   sessionStorage.setItem('logindocuser',this.drData.id);
    //   }
    //   doctortodoctorcall('doc_'+item.doctorId,'pat_'+sessionStorage.getItem('callingpatient_id'),this.userName,'')
    //   this.toastr.info("Calling to"+' '+item.name);
    //   //stopTimer();
		// 			//document.getElementById("videoCallModald").click();
    //       //(<HTMLInputElement>document.getElementById('toId')).value='doc_'+item.doctorId
		// 			//playSound();
    //       // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //       //   this.router.navigate(['/home/poc']);
    //       // });
    //       this.activeModal.close('');
    // }
  }
  consultDoctoragentfm(item:any){
    sessionStorage.setItem('agenttodoctor','agenttodoctorcall');
    sessionStorage.setItem('fromrefpat','');
    //sessionStorage.setItem('patientidchat',patient_data.id);
    sessionStorage.setItem('mem_type','true');
    sessionStorage.setItem('agentdoctorid',item.doctorId)
    //sessionStorage.setItem('callingpatient_id',patient_data.id)
    let payload = {
      "patient_id": sessionStorage.getItem('callingpatient_id_fm'),
      "doctor_id": item.doctorId,
      "patient_name": sessionStorage.getItem('agentcallpname'),
      "patient_email": ''
    }
    this.regService.generateConsulation('POST', payload).subscribe(res => {
      if (res.status == 'OK') {
        let fromId = (item.doctorId).toString();
        sessionStorage.setItem('fromdocid',fromId);
        let toId = (sessionStorage.getItem('callingpatient_id')).toString();
        $("#fromId").val("doc_" + fromId);
        $("#toId").val("pat_" + toId);
        $("#order_no").val(res.data.order_no);
        if(sessionStorage.getItem('isfmlymembr')!='familymember'){
        $("#is_self").val(true);
        }else{
          $("#is_self").val(false);
        }
        $("#patient_name").val(sessionStorage.getItem('agentcallpname'));
        $("#patient_interaction_id").val(res.data.consultation_id);
        sessionStorage.setItem('chat_consultationid',res.data.consultation_id);
        $("#patient_info").val(JSON.stringify({ "patient_interaction_id": res.data.consultation_id, "order_no": res.data.order_no, "hospital_id": "", "symptoms": "", "is_self": false }));
        sessionStorage.isPatient = true;
        if(socketStaus()==1){
          //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
          //updateStatus(item.userId,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
          if(this.roleId==7){
          sessionStorage.setItem('logindocuser',this.drData.id);
          }
          agenttodoctorcallfamilymember('doc_'+item.doctorId,'pat_'+sessionStorage.getItem('callingpatient_id_fm'),sessionStorage.getItem('agentcallpname'),'Consulting')
          this.toastr.info("Calling to"+' '+item.name);
         
              this.activeModal.close('');
        }
     
      }
      else {
        this.toastr.warning(res.message, 'Generate Consulation');
      }
    });
    if(this.roleId==7){
    sessionStorage.setItem('owndoc',this.drData.id)
    }
   
  }
  consultDoctorfm(item:any){
    sessionStorage.setItem('call_type_id',1+'');
    sessionStorage.setItem('doctortodoctor','doctortodoctorcall');
    if(this.roleId==7){
    sessionStorage.setItem('owndoc',this.drData.id)
    }
    //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
    if(socketStaus()==1){
      //register('pat_' + this.drData.id, 'doc_' + item.doctorId, this.drData.first_name + ' ' + this.drData.last_name, '7', '')
      //updateStatus(item.userId,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
      if(this.roleId==7){
      sessionStorage.setItem('logindocuser',this.drData.id);
      }
      let fromId = (item.doctorId).toString();
        sessionStorage.setItem('fromdocid',fromId);
      //doctortodoctorcall('doc_'+item.doctorId,'pat_'+sessionStorage.getItem('callingpatient_id'),this.userName,'Consulting')
      agenttodoctorcallfamilymember('doc_'+item.doctorId,'pat_'+sessionStorage.getItem('callingpatient_id_fm'),this.userName,'Consulting',this.drData.id)
      this.toastr.info("Calling to"+' '+item.name);
      //stopTimer();
					//document.getElementById("videoCallModald").click();
          //(<HTMLInputElement>document.getElementById('toId')).value='doc_'+item.doctorId
					//playSound();
          // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['/home/poc']);
          // });
          this.activeModal.close('');
    }
  }
}
