import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Avail, Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { PreviousHistoryComponent } from 'src/app/modules/modals/previous-history/previous-history.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { PrakrutiConsitution, GeneralAssmnt, RogaPariksha, AyushPOC, IPrakLookUpsNew, benDepAtchmnts }from 'src/app/shared/models/FieldModels';
import { ApiService } from 'src/app/shared/services/api.service';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { FileService } from 'src/app/shared/services/file.service';
import { CovidComponent } from '../../configuration/covid/covid.component';
import { Ayush_POC } from 'src/app/shared/models/ayush-poc';
import { ImagePreviewComponent } from '../../modals/image-preview/image-preview.component';
import { UserService } from 'src/app/shared/services/user.service';


declare let $: any;
declare let connect: any;
declare let answerCall: any;
declare let stopVideo: any;
declare const stopVideoayush: any;
declare let stopVideoPocGiven: any;
declare const updtLogoutSocket: any;
declare const disconnect: any;

const resolvedPromise = Promise.resolve(null);


@Component({
  selector: 'app-new-poc-ayush',
  templateUrl: './new-poc-ayush.component.html',
  styleUrls: ['./new-poc-ayush.component.css'],
  providers: [DatePipe]
})
export class NewPocAyushComponent implements OnInit {
  pocAyuForm: FormGroup; paySumForm: FormGroup;
  is_self: boolean =true;
  // @Input() lab: string;
  // @Input() pharm: string;
  // @Input() labDisc: any;
  // @Input() phrmDisc: any;
  labDisc = $('#labsPerc').val();
  phrmDisc = $('#labPhrmPerc').val();
  orgId = JSON.parse(sessionStorage.getItem('currentTenant'));
  enableConsultationTypeInPOC:boolean;
  stageCounts = { MILD: 0, MODERATE: 0, SEVERE: 0 };
  family_member = { first_name: '', last_name: '', age: null, gender_id: null, mobile: null, address: null, member_mobile: null, id: null };
  subtypeList: Array<any> = []; investigationMaster: Array<any> = []; chiefComplaintList: Array<any> = []; diagnosislList: Array<any> = [];
  drugNameLst: Array<any> = []; directionLst: Array<any> = []; dosageLst: Array<any> = []; selectedItems = []; benHistory = [];
  selectedList = []; qualificationsList = []; superSpecialityDegreeList = []; isDigital = false; isDgtlOptns = window.orientation == undefined;

  dropdownSettings = {}; ccdropdownSettings = {}; drgDDStngs = {}; prevItem = {}; minDate: Date; endTime: Date; submitted = false; benId = 0;
  drugtypesList: any; drugnamesList: any; investigationsList: any; directionsList: any; instructionList: any; userData: any; docData: any;

  startTime = this.datePipe.transform((new Date()), 'HH:mm:ss'); // { first_name: '', last_name: '', mobile: null, age: null, address: null, gender_id: null };
  doc = { first_name: '', last_name: '', qualification: '', superspecialiy: '', mci: null, contact: null, address: null, display_mobile_no: false };
  pocDrug = { drug_type: '', drug_name: '', dsg: '', dir: '', ins: '', noofdays: '' };
  benData: any; dependtData: any; pocCc = ''; pocVitals = ''; pocHistory = ''; pocPd = ''; pocIn = ''; pocAdvice = ''; medName: ''; medType: '';

  // Ayush
  pcCtrls = new PrakrutiConsitution(); genAsmnt = new GeneralAssmnt(); rogPrksh = new RogaPariksha(); ayurvedPOC = new AyushPOC();
  vatTtlCnt = 0; pitTtlCnt = 0; kafTtlCnt = 0; isPocSave = false; isShowPC = false; presPoc = new AyushPOC(); ptntPres = new AyushPOC();
  vatTS = ''; pitTS = ''; kafTS = ''; vatTxt = 'Vata = '; pitTxt = 'Pitta = '; kafTxt = 'Kapha = '; agType = ''; agScre = ''; agName = '';
  btType = ''; btScre = ''; btName = ''; wtType = ''; wtScre = ''; wtName = ''; scType = ''; scScre = ''; scName = '';
  stType = ''; stScre = ''; stName = ''; htType = ''; htScre = ''; htName = ''; spType = ''; spScre = ''; spName = '';
  apType = ''; apScre = ''; apName = ''; tpType = ''; tpScre = ''; tpName = ''; paType = ''; paScre = ''; paName = '';
  bmType = ''; bmScre = ''; bmName = ''; siType = ''; siScre = ''; siName = ''; wsType = ''; wsScre = ''; wsName = '';
  myType = ''; myScre = ''; myName = ''; fmType = ''; fmScre = ''; fmName = ''; gbType = ''; gbScre = ''; gbName = '';
  prkrtTxt = ''; consType = 'New'; dgtlSignUrl = ''; roleId = ''; rpDooshya = ''; systolic = ''; diastolic = ''; payPres = 's'; haType = '';
  lstPrkCnstHdrs = ['Age', 'Body Type', 'Weight Gain Pattern', 'Skin Complexion', 'Skin Texture', 'Hair Type', 'Sleep Pattern', 'Appetite',
    'Taste Preference', 'Physical Activity', 'Bowel Movement', 'Given a situation, how do you go about it?', 'Working Style', 'Memory',
    'If Female, Menstrual Cycle', 'If Male, Growth of Beard']; lstGAHdrs = ['Stool/Mala', 'Urination/Mutra', 'Tongue/Jihva', 'Appetite/ Agni']; // 'Pulse/Nadi', 
  lstRPHdrs = ['Prakruti', 'Dosha', 'Anubandha Dosha', 'Dooshya', 'Bala', 'Desha', 'Kala', 'Analam', 'Vaya', 'Satwa', 'Aahara', 'Aahara Shakti',
    'Roga (Disease) Avastha'];
  lstDrtn = ['M', '12 Hrs', '24 Hrs', '8 Hrs'];
  lstPHHdrs = []; lstRogPrksh = []; lstPH1 = []; lstPH2 = [];
  dshyLst = []; appUrl = ''; lstCCSlctd = []; lstPstHstry = []; lstGenAsmnt = []; lstDrgDur = []; prakConstList = []; lstSVCs = []; lstFreq = [];
  lstSvcPrcdr = []; lstDrgType = []; lstDrgs = []; lstDosge = []; lstDrgDrctn = []; lstPnchKrma = []; lstDrgsData = []; lstPrvDgns = [];
  lstPresFreq = []; lstDT = []; lstDN = []; lstSlctdDT = []; lstSlctdDN = []; presCC = ''; presHI = ''; presDgns = ''; docDgtlSign = '';
  nts1 = ''; cnsltTxt = ''; scktIntrctnStsId = ''; newCT = ''; bdAtchmnt = new benDepAtchmnts(); ptntIntrnPL = new patientInteractionPL();
  dateMin: Date; docId: any; cnsltRoute: any; pocData: any; benAge = 0; hwId = 0; savePOCId = 0; idPOC = 0; usrLgnId = 0; gdrId = 0;
  IsConsultReq = false; IsMedBill = false; isDocChrgbl = false; isFields: boolean; isNewDoc: boolean; isLoad = true; prevBal: number = null;
  isConsultationForDependent: boolean = false; dependentId: number; patientId: number; isCnstFeeSlctd = false; isMedSlctd = false;
  isPatientPaid = false; isSaveDsbld = false; isOthrDrg = false; isLcl = location.href.includes('localhost'); drtnTxt = ''; lstDurtn = [];
  // styl = `width: 100%; font-size:14px; background-color: #f8f6f6; background-image: url('../../../assets/images/logo_ayu_pres.png');
  //   background-repeat: no-repeat; background-attachment:cover; background-position: center; padding: 4px;`; img = '';
  // Ayush
  isIOS: boolean; isLclVdoHdn = true; roomName = 'eTest'; yourName = 'Dev'; prkIntStsId = 0; // Twilio controls
  //#region Video Uplaod
  isCamAcsd: boolean; httpOptions: any; imgPath = ''; upldFile = ''; isPrvwHdn = true; dgtlFile: any; isDgtl = 'Upload';
  public showWebcam = false; public allowCameraSwitch = true; public multipleWebcamsAvailable = false; public deviceId: string;
  public videoOptions: MediaTrackConstraints = {}; public errors: WebcamInitError[] = []; public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>(); private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  //#endregion
  lstStates: any[] = []; lstRegns: any[] = []; lstDist: any[] = []; lstTelgLab: any[] = []; lstTelgPhrm: any[] = [];
  labAddrs = ''; phrmcyAddrs = '';
  state_id: string = '';
  district_id: string = '';
  region_id: string = '';
  covid19Obj = { stageView: '' };
  provisionalDiagnosisSettings: any = {};
  drugTypeSettings: any = {};
  drugNameSettings: any = {};
  chiefComplaintsSettings: any = {};
  provisionalDiagnosis: Array<any> = [];
  emergencyComment = '';
  discoonecttype = 'Doctor Disconnected';
  videocall_startdatatime = new Date();
  instruction_NotExist: string = `All cold items, sour items like curd, heavy items like deep fried food, fermented items like bread and other bakery items, stale food,
  maida / refined flour, urad, sprouts, refined & processed food, and all types of non-vegetarian food items like egg, meat, fish, etc.
  should be avoided.`;
  ayushAnupaanList: Array<{ id: number, anupaan_name: string }> = [];covidformdatashow=false;pocdivshow=true;
  covidbtn=true;pocbtn=false;gdrIdnew:any;
  pocItem: any;
  isEdit: any;
  ptntInfo: string;
  depDtls: any;
  member_mobile2: any;
  pocmember: boolean;
  memberData: any;
  followUpDataList: any=[];
  depId: any;
  vtlDt: string;
  vtlTm: string;
  vitalsList: any;
  ConsultationList: any;
  Flagslist: any;
  constructor(public toastr: ToastrService
    , public homeComponent: HomeComponent
    , public changeDetection: ChangeDetectorRef
    , private formBuilder: FormBuilder
    , private registrationService: RegistrationService
    , private masterService: MasterService
    , public config: NgbModalConfig
    , private userServ: UserService
    , private modalService: NgbModal
    , public activeModal: NgbActiveModal
    , private authServ: AuthenticationService
    , private apiSvc: ApiService
    , private datePipe: DatePipe
    , private router: Router
    , private fileSvc: FileService
    , private http: HttpClient) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.minDate = new Date();
    this.endTime = new Date();
    // if (!customElements.get('openvidu')) {
    //   customElements.define('openvidu', HTMLElement);
    // }
    this.homeComponent.isDoctor = false;
    (<any>window).isPrescriptionSumitted = false;
  }
  // ngOnDestroy(): void {
  //   sessionStorage.isPatient = false;
  //   (<HTMLInputElement>document.getElementById('is_self')).value = 'true';
  //   (<HTMLInputElement>document.getElementById('dependentId')).value = '';
  // }
  ngOnDestroy(): void {
    sessionStorage.isPatient = false;
    this.changeMenu();
    (<HTMLInputElement>document.getElementById('is_self')).value = 'true';
      (<HTMLInputElement>document.getElementById('dependentId')).value = '';
   
  }
  ngOnInit(): void {
    sessionStorage.setItem('pocSave','0');
    this.initFormControls(), this.initPayFormControls(),
    this.onPageLoad();
    this.getUserPermission(); 
    const fromPatient = JSON.parse(sessionStorage.isPatient || 'false');
    if (fromPatient) {
      let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideo'));
      dragElement.style.display = 'none';
    }
    this.accordianExpand();
    let callOS: string = (<HTMLInputElement>document.getElementById('appOS')).value, ws = JSON.parse(sessionStorage.getItem('webSkt'));
    sessionStorage.removeItem('pocSave'); // this.authServ.isIOSMob.subscribe(s => this.isIOS = s);
    if (callOS == 'APPLE' && sessionStorage.getItem('webSkt') != null) {
      let patId = (<HTMLInputElement>document.getElementById("toId")).value;
      this.isLclVdoHdn = false, this.isIOS = true, connect(), answerCall(ws.fromId, patId, ws.userType, ws.qlfn, ws.expn, ws.qlfnName, ws.splName, ws.doc); //, this.onPageLoad();

    }
    else
      this.isIOS = false; // this.onPageLoad();
   // , this.roleId = location.origin.includes('localhost') ? '7' : this.roleId;
sessionStorage.setItem('covidcomp','')
sessionStorage.setItem('covidpocn','')
sessionStorage.setItem('pastcovidn','')
sessionStorage.setItem('coviddaten','')
  }

  activeSpinner() {
    this.masterService.activateSpinner();
  }

  deactivateSpinner() {
    this.masterService.deactivateSpinner()
  }

  onPageLoad() {
    this.leftMenu(), this.vatTS = `${this.vatTxt}${this.vatTtlCnt}`, this.pitTS = `${this.pitTxt}${this.pitTtlCnt}`,
      this.kafTS = `${this.kafTxt}${this.kafTtlCnt}`, this.prkrtTxt = `${this.vatTxt}${this.vatTtlCnt}, ${this.pitTxt}${this.pitTtlCnt}, ${this.kafTxt}${this.kafTtlCnt}`,
      this.appUrl = location.href.includes('localhost') ? Avail.lclUrl : location.origin + '/'; //, this.ayurvedPOC.cc_brief_history.ccRmrks = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'investigation',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.ccdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };
    this.drgDDStngs = {
      singleSelection: false,
      idField: 'id',
      textField: 'frequency',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };
    this.drugTypeSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_type',
      // enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.drugNameSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_name',
      // enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.provisionalDiagnosisSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      // enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.chiefComplaintsSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'chief_complaint',
      // enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
    };
    this.LoadDefaultData(), ['gaLST', 'rpLST'].forEach(e => { sessionStorage.removeItem(e); });
    ['Diabetes', 'Heart Disease', 'Hypertension '].forEach(e => { this.lstPHHdrs.push({ name: e, isChk: false }); });
    let no = (<HTMLInputElement>document.getElementById('toId')).value, id = no.replace('pat_', '');
    this.pocItem = this.isEdit ? JSON.parse(sessionStorage.getItem('pocEdit')) : null;
    this.is_self = (<HTMLInputElement>document.getElementById('is_self')).value == 'true' ? true : false;
    if (!this.is_self) { // == false
      let result = this.pocItem ? this.pocItem?.advice.family_member : (<HTMLInputElement>document.getElementById('family_member')).value || null;
      this.ptntInfo = (<HTMLInputElement>document.getElementById('patient_info')).value || null;

      if (result)
        this.family_member = JSON.parse(result),
          this.depDtls = JSON.parse(result);
      this.member_mobile2 = this.family_member.member_mobile;
      if (this.ptntInfo)
        this.dependentId = +JSON.parse(this.ptntInfo).familyMemberId;
      // POC Submit issue here
      if (Object.keys(sessionStorage).includes('pocEditFmember')) {
        if (sessionStorage.getItem('pocEditFmember'))
          this.pocmember = true;
        this.memberData = JSON.parse(sessionStorage.getItem('pocEditFmember'));

      }
      //  console.log('member Data and family member', this.memberData, this.family_member);
      if (this.isOffLinePOC() == true) {
        // alert('1111')
        this.depDtls = { first_name: '', last_name: '', gender_id: '', age: '', member_mobile: '', mobile: '', address: '' };
        this.depDtls.first_name = this.memberData?.member_name;
        this.depDtls.last_name = '';
        this.depDtls.gender_id = this.memberData?.gender_id;
        this.depDtls.age = this.memberData?.age;
        this.depDtls.member_mobile = this.memberData?.mobile;
        this.depDtls.address = '';
      }
      // this.family_data = this.family_membe
      // console.clear()
      // console.log(ptntInfo, this.depId)
    }  
    
    this.isPatientPaid = (<HTMLInputElement>document.getElementById('isPaid')).value == 'true' ? true : false,
      
      this.scktIntrctnStsId = (<HTMLInputElement>document.getElementById('patient_interaction_id')).value,
      //this.dependentId = +(<HTMLInputElement>document.getElementById('dependentId')).value, 
      this.dependentId = +sessionStorage.getItem('callingpatient_id_fm')
      this.patientId = +id;
    // this.benId = +id; //, this.idPOC = +(<HTMLInputElement>document.getElementById('calPocId')).value;    
    this.benId = this.patientId, 
    this.roleId = sessionStorage.getItem('logUsrRoleId') != null ? sessionStorage.getItem('logUsrRoleId') : '';
    this.cnsltRoute = this.roleId == '7' ? ['/home/consultation/cn'] : ['/home/consultation/consultHW'], this.ayurvedPOC.id = this.savePOCId,
      this.cnsltTxt = this.roleId == '7' ? 'Doctor Consultation' : 'Health Worker Consultation';
      let benefData:any=JSON.parse(sessionStorage.getItem('pocEditFmember'))
      let pId=0;
      if(sessionStorage.pocEditFmember){
        pId=benefData?.beneficiary_id
      }else{
        pId=+id
      }
    if (sessionStorage.getItem('webSkt') != null)
      this.usrLgnId = JSON.parse(sessionStorage.getItem('webSkt')).fromId.split(`_`)[1];
    if (this.benId != 0)
      Promise.all([this.getBenDetails(pId), 
        
        this.getBenHistoryDetails(this.benId), this.getAllMaster()]);
    if (this.isLcl)
      this.getAllMaster();
      this.getAllVitals();
      this.getallConsultations();
    this.userData = this.authServ.currentUserValue, this.dateMin = new Date(), this.dateMin.setDate(this.dateMin.getDate()), this.dataSum();
    // this.loadLabServices()
  }

  onEpaperChng(isChkd: boolean) {
    this.pocAyuForm.get('is_epaper').setValue(isChkd);
  }
  getAllVitals() {
    if (this.is_self == false && this.memberData != undefined || this.memberData != null) {
      this.depId = this.memberData.id;
    }
    this.vtlDt = '', this.vtlTm = '', this.registrationService.getByAllVitals(this.is_self ? this.benId : this.depId, this.is_self).subscribe(res => {
      // this.vtlDt = '', this.vtlTm = '', this.registrationService.getByAllVitals(this.benId,this.depId,).subscribe(res => {

      if (res.status === 'OK') {
        let result = res.data;
        // if (this.is_self)
        //   this.vitalsList = result.filter(f => f.beneficiary_member_id == null);
        // else
        //   this.vitalsList = result.filter(f => f.beneficiary_member_id == this.depId);
        // if (this.vitalsList.length > 0) {
        //   let vtlCapDt = '';
        //   if (this.vitalsList[0].capture_date) {
        //     vtlCapDt = this.vitalsList[0].capture_date.split(' ');
        //     if (vtlCapDt) {
        //       if (vtlCapDt[0] == this.datePipe.transform((new Date()), "yyyy-MM-dd")) {
        //         this.vtlDt = this.datePipe.transform(this.vitalsList[0].capture_date, "dd-MM-yyyy"),
        //           this.vtlTm = this.datePipe.transform(this.vitalsList[0].capture_date, "hh:mm:ss aa");
        //          this.pocAyuForm.get('vital_history.temperature').setValue(this.vitalsList[0].temperature);
        //          this.pocAyuForm.get('vital_history.pulse').setValue(this.vitalsList[0].pulse_rate);
        //          this.pocAyuForm.get('vital_history.systolic').setValue(this.vitalsList[0].bp_value.toString().split('/')[0]);
        //          this.pocAyuForm.get('vital_history.diastolic').setValue(this.vitalsList[0].bp_value.toString().split('/')[1]);
               
        //         //  this.pocAyuForm.get('vital_history.diabetic_value').setValue(this.vitalsList[0].diabetic_value);
        //         //  this.pocAyuForm.get('vital_history.oxigen_count').setValue(this.vitalsList[0].oxigen_count);
        //         //  this.pocAyuForm.get('vital_history.height').setValue(this.vitalsList[0].height);
        //         //  this.pocAyuForm.get('vital_history.weight').setValue(this.vitalsList[0].weight);
        //       }
        //     }
        //   }
        // }
        if(result.length>0){
          this.pocAyuForm.patchValue({
            vital_history: {
              temperature:result[0].temperature,
              pulse:result[0].pulse_rate+'',
              systolic:result[0].bp_value.toString().split('/')[0],
              diastolic:result[0].bp_value.toString().split('/')[1]
            }
          });
          this.pocAyuForm.value.covid19.general_examination.patchValue({
            SPO2:result[0]?.oxigen_count ,
            PULSE: result[0]?.pulse_rate+'' ,
            TEMPERATURE:result[0]?.temperature ,
            RR: '',
            WEIGHT: result[0]?.weight,
            Systolic: result[0]?.bp_value.toString().split('/')[0],
            Diastolic: result[0]?.bp_value.toString().split('/')[1]
          })
          // this.pocAyuForm.get('vital_history.temperature').setValue(result[0].temperature);
          // this.pocAyuForm.get('vital_history.pulse').setValue(result[0].pulse_rate);
          // this.pocAyuForm.get('vital_history.systolic').setValue(result.bp_value.toString().split('/')[0]);
          // this.pocAyuForm.get('vital_history.diastolic').setValue(result.bp_value.toString().split('/')[1]);
        
        }
     
      }
    });
  }

  LoadDefaultData() {
    this.drugNameLst = [{ id: 1, name: 'Drug 1' }, { id: 2, name: 'Drug 2' }, { id: 3, name: 'Drug 3' }, { id: 4, name: 'Drug 4' }]
    this.directionLst = [{ id: 1, name: 'Direction 1' }, { id: 2, name: 'Direction 2' }, { id: 3, name: 'Direction 3' }, { id: 4, name: 'Direction 4' }]
    this.dosageLst = [{ id: 1, name: 'X-0-0' }, { id: 2, name: '0-X-0' }, { id: 3, name: '0-0-X' }, { id: 4, name: 'X-X-0' }, { id: 5, name: '0-X-X' }, { id: 6, name: ' X-X-X' }, { id: 7, name: 'X-0-X' }]
  }

  getPOCDatabyId() {
    this.getPHData();
    if (this.idPOC != 0 && this.idPOC != null && this.idPOC != undefined) {
      this.registrationService.getAyushDataById(`findbypocid?pocId=${this.idPOC}`).subscribe(res => {
        this.pocData = res.data;
        if (this.roleId == '7')
          this.ayurvedPOC.health_worker_id = res.data.health_worker_id != null ? +res.data.health_worker_id.id : 0;
        this.ptntIntrnPL = {
          doctor_id: this.roleId == '7' ? +JSON.parse(sessionStorage.getItem('DoctorDetails')).id : 0,
          healthworker_id: this.roleId == '9' ? +JSON.parse(sessionStorage.getItem('hwDetails')).id : 0,
          benficiary_id: this.is_self == true ? this.benId : 0,
          dependent_id: this.is_self == false ? this.dependentId : 0,
          patient_interaction_id: this.scktIntrctnStsId == '' ? +res.data.patient_interaction.id : +this.scktIntrctnStsId,
        };
        this.registrationService.getByPtntIntrctnID(`patientinteractionlog/findpatientinteractionstatus`, this.ptntIntrnPL).subscribe(resp => {
          this.prkIntStsId = resp.data[0]?.interaction_status_id?resp.data[0]?.interaction_status_id:0,
            this.ayurvedPOC.id = (this.consType == 'New' ? 0 : (this.roleId == '7' && this.prkIntStsId == 10 ? 0 : this.idPOC));
          if ((this.roleId == '7' && (this.pocData.doctor_id.id == JSON.parse(sessionStorage.getItem('DoctorDetails')).id)) ||
            (this.roleId == '9' && (this.pocData.health_worker_id?.id == JSON.parse(sessionStorage.getItem('hwDetails')).id))) {
            this.consType = (this.pocData.other_history.consultationType != '' && (this.roleId == '7' && this.prkIntStsId == 13)) ?
              this.pocData.other_history.consultationType : 'New';
            if (this.roleId == '7') {
              if(this.prkIntStsId==13){
              this.registrationService.getHWByDrId(this.pocData.doctor_id.id).subscribe(dr => {
                  this.hwId = dr.data.length > 0 ? dr.data[0].id : 0, this.bindPOCData(this.pocData);
                });
              }
              if(this.prkIntStsId==10){
                this.registrationService.getHWByDrId(this.pocData.doctor_id.id).subscribe(dr => {
                   this.bindPOCData(this.pocData);
                  });
                }else{
                  this.bindPOCData(this.pocData);
                }
             
            }
            else
              this.bindPOCData(this.pocData);
          }
          else
            this.idPOC = this.ayurvedPOC.id == 0 ? 0 : this.idPOC;
            this.bindPOCData(this.pocData);
        });
      });
    }
  }


  getPOCDatabyIdOld() {
    this.getPHData();
    if (this.idPOC != 0 && this.idPOC != null && this.idPOC != undefined) {
      this.registrationService.getAyushDataById(`findbypocid?pocId=${this.idPOC}`).subscribe(res => {
        this.pocData = res.data;
        if (this.roleId == '7')
          this.ayurvedPOC.health_worker_id = res.data.health_worker_id != null ? +res.data.health_worker_id.id : 0;
        this.registrationService.getByID(`patientinteractionlog/findbypatientinteractionid?patientInteractionId=`,
          this.scktIntrctnStsId == '' ? res.data.patient_interaction.id : this.scktIntrctnStsId).subscribe(resp => {  // patient_interaction_id
            this.prkIntStsId = resp.data[0]?.interaction_status_id,
              this.ayurvedPOC.id = (this.consType == 'New' ? 0 : (this.roleId == '7' && this.prkIntStsId == 10 ? 0 : this.idPOC));
            if ((this.roleId == '7' && (this.pocData.doctor_id.id == JSON.parse(sessionStorage.getItem('DoctorDetails')).id)) ||
              (this.roleId == '9' && (this.pocData.health_worker_id?.id == JSON.parse(sessionStorage.getItem('hwDetails')).id))) {
              this.consType = (this.pocData.other_history.consultationType != '' && (this.roleId == '7' && this.prkIntStsId == 13)) ?
                this.pocData.other_history.consultationType : 'New';
              if (this.roleId == '7') {
                this.registrationService.getHWByDrId(this.pocData.doctor_id.id).subscribe(dr => {
                  this.hwId = dr.data.length > 0 ? dr.data[0].id : 0, this.bindPOCData(this.pocData);
                });
              }
              else
                this.bindPOCData(this.pocData);
            }
            else
              this.idPOC = this.ayurvedPOC.id == 0 ? 0 : this.idPOC;
              this.bindPOCData(this.pocData);
          });
      });
    }
  }
  openModal(item: any = null) {
    let ar = [];
    if (Object.keys(this.pocAyuForm.value.covid19).length == 0)
      ar.push(item);
    else
      ar.push(this.pocAyuForm.value.covid19);
    const modalRef = this.modalService.open(CovidComponent, { scrollable: true, size: 'xl', windowClass: 'modal-zindex' });
    modalRef.componentInstance.title = 'Covid 19';
    modalRef.componentInstance.isPOC = true;
    modalRef.componentInstance.history = ar;
    modalRef.componentInstance.consultData = ar;
    modalRef.result.then(result => {
      if (result && result != 'Close click')
        this.pocAyuForm.patchValue({ covid19: result }), console.log(this.pocAyuForm.value);

    }, (reason) => { });
  }

  // gotoConsultation() {
  //   this.discoonecttype = 'Patient Disconnected';
  //   this.toastr.success('Consultation is Cancelled');
  //   stopVideoayush(false);
  // }
  gotoConsultation() {

    this.withOut_PrescriptionSumitted();
    if (Object.keys(sessionStorage).includes('fromrefpat')) {
      if (sessionStorage.getItem('fromrefpat') == 'referalpatientList') {
        sessionStorage.setItem('fromrefpat', '')
        this.toastr.success('Consultation is Cancelled');

      stop();
      this.router.navigate(['/home/consultation/referalpatientlist']);
      } else {
        this.discoonecttype = 'Patient Disconnected';
        this.toastr.success('Consultation is Cancelled');

        stopVideo();
        this.router.navigate(['/home/consultation/referalpatientlist']);
        
      }
    } else {
      this.discoonecttype = 'Patient Disconnected';
      this.toastr.success('Consultation is Cancelled');
      stopVideo();
      this.router.navigate(['/home/consultation/referalpatientlist']);
    
    }
}

  // withOut_PrescriptionSumitted() {
  //   let onlyFirstTime: boolean = (<any>window).isPrescriptionSumitted;
  //   if (!onlyFirstTime) {
  //     //alert('withOut_PrescriptionSumitted');
  //     (<any>window).isPrescriptionSumitted = true;
  //     // const dr = _.find(this.doctordetailsfromstorage, (data) => { return data.id })
  //     let data = {
  //       "id": 0,
  //       "beneficiary_id": this.benId,
  //       "doctor_id": this.docData?.id.toString(),
  //       "call_date": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd'),
  //       "disconnect_type": this.discoonecttype,
  //       "dependent_id": this.dependentId,
  //       "call_start_time": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd HH:mm:ss'),
  //       "call_disconnect_time": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  //     }
  //     this.registrationService.disconnectCallApi(data).subscribe(resp => {
  //       // if (resp.status == 'OK') {
  //       //   this.toastr.success(resp.message);
  //       // }
  //     })
  //   }
  // }
  withOut_PrescriptionSumitted() {
    let onlyFirstTime: boolean = (<any>window).isPrescriptionSumitted;
    if (!onlyFirstTime) {
      //alert('withOut_PrescriptionSumitted');
      (<any>window).isPrescriptionSumitted = true;
      // const dr = _.find(this.doctordetailsfromstorage, (data) => { return data.id }) chanduuuuu
      let data = {
        "id": 0,
        "patient_interaction_id": this.pocAyuForm.get('patient_interaction_id').value,
        "beneficiary_id": this.benId,
        "doctor_id": this.docData?.id.toString(),
        "call_date": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd'),
        "disconnect_type": this.discoonecttype,
        "memeber_id": this.dependentId,
        "call_start_time": this.datePipe.transform(this.videocall_startdatatime, 'yyyy-MM-dd HH:mm:ss'),
        "call_disconnect_time": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      }
      //chandu 22-09-2022
      this.registrationService.disconnectCallApi(data).subscribe(resp => {
        this.registrationService.deleteSessionbydocId(this.docData?.id).subscribe(res=>{

        });
        // if (resp.status == 'OK') {
        //   this.toastr.success(resp.message);
        // }
      })
    }
  }
  emergencyExit(emergency_Comment: string = '') {
    // this.pocAyuForm.patchValue({ advice: { doctorComment: this.emergencyComment } });
    this.ayurvedPOC.beneficiary_id = this.benId.toString();
    this.ayurvedPOC.doctor_id = JSON.parse(sessionStorage.getItem('DoctorDetails')).id;
    this.ayurvedPOC.advice.doctorComment = emergency_Comment;//this.emergencyComment;
    this.ayurvedPOC.prakruti_constitution = [];
    //this.ayurvedPOC.call_type_id = '9';
    this.onSave();
  }

  //  new method for prakruthi constitution 12-06-2023
  bindPrakruthiPOCData(result: any) {
    this.loadLabServices();
    let idHW = result.health_worker_id != null ? result.health_worker_id.id : 0;
    this.chiefComplaintList.forEach(cc => { cc.isChk = false; }), this.lstPHHdrs.forEach(ph => { ph.isChk = false; }), this.lstDrgsData = [], this.lstPnchKrma = [];
    // if (this.consType == 'New' && this.hwId != idHW) {
    
      this.pocAyuForm.patchValue({
        prakruti_consitution: {
          pcAge: result.prakruti_constitution?.filter(f => f.name == 'Age')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBodyType: result.prakruti_constitution?.filter(f => f.name == 'Body Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWtGainPtrn: result.prakruti_constitution?.filter(f => f.name == 'Weight Gain Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinCmplxn: result.prakruti_constitution?.filter(f => f.name == 'Skin Complexion')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinTextr: result.prakruti_constitution?.filter(f => f.name == 'Skin Texture')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcHairType: result.prakruti_constitution?.filter(f => f.name == 'Hair Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSleepPatrn: result.prakruti_constitution?.filter(f => f.name == 'Sleep Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcAppetite: result.prakruti_constitution?.filter(f => f.name == 'Appetite')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcTastePref: result.prakruti_constitution?.filter(f => f.name == 'Taste Preference')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcPhysAcvty: result.prakruti_constitution?.filter(f => f.name == 'Physical Activity')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBowelMnmt: result.prakruti_constitution?.filter(f => f.name == 'Bowel Movement')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSituation: result.prakruti_constitution?.filter(f => f.name == 'Given a situation, how do you go about it?')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWrkngStyle: result.prakruti_constitution?.filter(f => f.name == 'Working Style')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcMemory: result.prakruti_constitution?.filter(f => f.name == 'Memory')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBerdGrwth: this.gdrId == 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Male, Growth of Beard')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
          pcFemMenstr: this.gdrId != 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Female, Menstrual Cycle')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
        },
       
    
        type_of_consultation:'',
         physical_consultation_required: '', lab_id: '', covid19: '',
        pharmacy_id: '',
      });
    this.getAllVitals();
  
 
   
    this.activeModal.close();
  }

  // end lines
  bindPOCData(result: any) {
    this.loadLabServices();
    let idHW = result.health_worker_id != null ? result.health_worker_id.id : 0;
    this.chiefComplaintList.forEach(cc => { cc.isChk = false; }), this.lstPHHdrs.forEach(ph => { ph.isChk = false; }), this.lstDrgsData = [], this.lstPnchKrma = [];
    // if (this.consType == 'New' && this.hwId != idHW) {
    if (this.consType == 'New' && (this.hwId != idHW || ((result.doctor_id.id == JSON.parse(sessionStorage.getItem('DoctorDetails')).id))) && (this.prkIntStsId == 10 || this.prkIntStsId==0)) {
      // if (this.consType == 'New') {

      this.pocAyuForm.patchValue({
        prakruti_consitution: {
          pcAge: result.prakruti_constitution?.filter(f => f.name == 'Age')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBodyType: result.prakruti_constitution?.filter(f => f.name == 'Body Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWtGainPtrn: result.prakruti_constitution?.filter(f => f.name == 'Weight Gain Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinCmplxn: result.prakruti_constitution?.filter(f => f.name == 'Skin Complexion')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinTextr: result.prakruti_constitution?.filter(f => f.name == 'Skin Texture')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcHairType: result.prakruti_constitution?.filter(f => f.name == 'Hair Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSleepPatrn: result.prakruti_constitution?.filter(f => f.name == 'Sleep Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcAppetite: result.prakruti_constitution?.filter(f => f.name == 'Appetite')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcTastePref: result.prakruti_constitution?.filter(f => f.name == 'Taste Preference')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcPhysAcvty: result.prakruti_constitution?.filter(f => f.name == 'Physical Activity')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBowelMnmt: result.prakruti_constitution?.filter(f => f.name == 'Bowel Movement')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSituation: result.prakruti_constitution?.filter(f => f.name == 'Given a situation, how do you go about it?')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWrkngStyle: result.prakruti_constitution?.filter(f => f.name == 'Working Style')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcMemory: result.prakruti_constitution?.filter(f => f.name == 'Memory')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBerdGrwth: this.gdrId == 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Male, Growth of Beard')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
          pcFemMenstr: this.gdrId != 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Female, Menstrual Cycle')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
        },
        cc_brief_history: { dctrComplaints: '', notes2: '', presentIllness: '', chiefComplaints: '', notes1: [], otherComplaints: '', },
        vital_history: { temperature: '', pulse: '', systolic: '', diastolic: '', }, other_history: { history: '', allergies: '', },
        general_assessment: { pulse_nadi: '', stool_mala: '', urination_mutra: '', tongue_jihva: '', appetite_agni: '', },
        roga_pariksha: {
          prakruti: '', anubandha_dosha: '', dooshya: [], bala: '', desha: '', kala: '', analam: '', vaya: '', satwa: '',
          aahara: '', aahara_shakti: '', roga_dis_avastha: '',
        }, diagnosis: { provisionalDiagnosis: [] }, health_worker_observation: '',
        advice: { rest_exercise: '', diet: '', follow_advice: '', referral: '', doctorComment: '', followupDate: '',is_self:'' },
        drugs: { instruction: '' }, follow_up_date: '',
        type_of_consultation:'',
         physical_consultation_required: '', lab_id: '', covid19: '',
        pharmacy_id: '',
      });
    this.getAllVitals();
      // this.pocAyuForm.get('cc_brief_history.notes1').setValue([]);
      [this.state_id, this.region_id, this.district_id] = ['', '', ''];
      [this.lstRegns, this.lstDist, this.lstTelgLab, this.lstTelgPhrm] = [[], [], [], []];
      [this.labAddrs, this.phrmcyAddrs] = ['', ''];

      $('#labsPerc').val('');
      $('#labPhrmPerc').val('');
      this.onChangPC('ag', result.prakruti_constitution?.filter(f => f.name == 'Age')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('bt', result.prakruti_constitution?.filter(f => f.name == 'Body Type')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('wt', result.prakruti_constitution?.filter(f => f.name == 'Weight Gain Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('sc', result.prakruti_constitution?.filter(f => f.name == 'Skin Complexion')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('st', result.prakruti_constitution?.filter(f => f.name == 'Skin Texture')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ht', result.prakruti_constitution?.filter(f => f.name == 'Hair Type')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('sp', result.prakruti_constitution?.filter(f => f.name == 'Sleep Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ap', result.prakruti_constitution?.filter(f => f.name == 'Appetite')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('tp', result.prakruti_constitution?.filter(f => f.name == 'Taste Preference')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('pa', result.prakruti_constitution?.filter(f => f.name == 'Physical Activity')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('bm', result.prakruti_constitution?.filter(f => f.name == 'Bowel Movement')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('si', result.prakruti_constitution?.filter(f => f.name == 'Given a situation, how do you go about it?')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ws', result.prakruti_constitution?.filter(f => f.name == 'Working Style')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('my', result.prakruti_constitution?.filter(f => f.name == 'Memory')[0]?.prakruti_lookup_values[0]?.attribute_name);
      if (this.gdrId != 1) {
        this.pocAyuForm.value.prakruti_consitution.pcFemMenstr = result.prakruti_constitution?.filter(f => f.name == 'If Female, Menstrual Cycle')[0]?.prakruti_lookup_values[0]?.attribute_name;
        this.onChangPC('fm', this.pocAyuForm.value.prakruti_consitution.pcFemMenstr);
      }
      else {
        this.pocAyuForm.value.prakruti_consitution.pcBerdGrwth = result.prakruti_constitution?.filter(f => f.name == 'If Male, Growth of Beard')[0]?.prakruti_lookup_values[0]?.attribute_name;
        this.onChangPC('gb', this.pocAyuForm.value.prakruti_consitution.pcBerdGrwth);
      }
      [this.vatTS, this.pitTS, this.kafTS] = ['Vata = ' + result.vata_score, 'Pitta = ' + result.pita_score, 'Kapha = ' + result.kafa_score];
      this.dshyLst = [], this.selectedList = [], this.lstCCSlctd = [], this.lstFreq.forEach(fq => { Object.assign(fq, { isChk: false }); });
    }else  if (this.consType == 'New' && (this.hwId != idHW || ((result.doctor_id.id == JSON.parse(sessionStorage.getItem('DoctorDetails')).id))) && this.prkIntStsId == 12) {
      // if (this.consType == 'New') {

      this.pocAyuForm.patchValue({
        prakruti_consitution: {
          pcAge: result.prakruti_constitution?.filter(f => f.name == 'Age')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBodyType: result.prakruti_constitution?.filter(f => f.name == 'Body Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWtGainPtrn: result.prakruti_constitution?.filter(f => f.name == 'Weight Gain Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinCmplxn: result.prakruti_constitution?.filter(f => f.name == 'Skin Complexion')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinTextr: result.prakruti_constitution?.filter(f => f.name == 'Skin Texture')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcHairType: result.prakruti_constitution?.filter(f => f.name == 'Hair Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSleepPatrn: result.prakruti_constitution?.filter(f => f.name == 'Sleep Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcAppetite: result.prakruti_constitution?.filter(f => f.name == 'Appetite')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcTastePref: result.prakruti_constitution?.filter(f => f.name == 'Taste Preference')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcPhysAcvty: result.prakruti_constitution?.filter(f => f.name == 'Physical Activity')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBowelMnmt: result.prakruti_constitution?.filter(f => f.name == 'Bowel Movement')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSituation: result.prakruti_constitution?.filter(f => f.name == 'Given a situation, how do you go about it?')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWrkngStyle: result.prakruti_constitution?.filter(f => f.name == 'Working Style')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcMemory: result.prakruti_constitution?.filter(f => f.name == 'Memory')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBerdGrwth: this.gdrId == 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Male, Growth of Beard')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
          pcFemMenstr: this.gdrId != 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Female, Menstrual Cycle')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
        },
        cc_brief_history: { dctrComplaints: '', notes2: '', presentIllness: '', chiefComplaints: '', notes1: [], otherComplaints: '', },
        vital_history: { temperature: '', pulse: '', systolic: '', diastolic: '', }, other_history: { history: '', allergies: '', },
        general_assessment: { pulse_nadi: '', stool_mala: '', urination_mutra: '', tongue_jihva: '', appetite_agni: '', },
        roga_pariksha: {
          prakruti: '', anubandha_dosha: '', dooshya: [], bala: '', desha: '', kala: '', analam: '', vaya: '', satwa: '',
          aahara: '', aahara_shakti: '', roga_dis_avastha: '',
        }, diagnosis: { provisionalDiagnosis: [] }, health_worker_observation: '',
        advice: { rest_exercise: '', diet: '', follow_advice: '', referral: '', doctorComment: '', followupDate: '',is_self:'' },
        drugs: { instruction: '' }, follow_up_date: '',
        type_of_consultation:'',
         physical_consultation_required: '', lab_id: '', covid19: '',
        pharmacy_id: '',
      });
    this.getAllVitals();
      // this.pocAyuForm.get('cc_brief_history.notes1').setValue([]);
      [this.state_id, this.region_id, this.district_id] = ['', '', ''];
      [this.lstRegns, this.lstDist, this.lstTelgLab, this.lstTelgPhrm] = [[], [], [], []];
      [this.labAddrs, this.phrmcyAddrs] = ['', ''];

      $('#labsPerc').val('');
      $('#labPhrmPerc').val('');
      this.onChangPC('ag', result.prakruti_constitution?.filter(f => f.name == 'Age')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('bt', result.prakruti_constitution?.filter(f => f.name == 'Body Type')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('wt', result.prakruti_constitution?.filter(f => f.name == 'Weight Gain Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('sc', result.prakruti_constitution?.filter(f => f.name == 'Skin Complexion')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('st', result.prakruti_constitution?.filter(f => f.name == 'Skin Texture')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ht', result.prakruti_constitution?.filter(f => f.name == 'Hair Type')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('sp', result.prakruti_constitution?.filter(f => f.name == 'Sleep Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ap', result.prakruti_constitution?.filter(f => f.name == 'Appetite')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('tp', result.prakruti_constitution?.filter(f => f.name == 'Taste Preference')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('pa', result.prakruti_constitution?.filter(f => f.name == 'Physical Activity')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('bm', result.prakruti_constitution?.filter(f => f.name == 'Bowel Movement')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('si', result.prakruti_constitution?.filter(f => f.name == 'Given a situation, how do you go about it?')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ws', result.prakruti_constitution?.filter(f => f.name == 'Working Style')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('my', result.prakruti_constitution?.filter(f => f.name == 'Memory')[0]?.prakruti_lookup_values[0]?.attribute_name);
      if (this.gdrId != 1) {
        this.pocAyuForm.value.prakruti_consitution.pcFemMenstr = result.prakruti_constitution?.filter(f => f.name == 'If Female, Menstrual Cycle')[0]?.prakruti_lookup_values[0]?.attribute_name;
        this.onChangPC('fm', this.pocAyuForm.value.prakruti_consitution.pcFemMenstr);
      }
      else {
        this.pocAyuForm.value.prakruti_consitution.pcBerdGrwth = result.prakruti_constitution?.filter(f => f.name == 'If Male, Growth of Beard')[0]?.prakruti_lookup_values[0]?.attribute_name;
        this.onChangPC('gb', this.pocAyuForm.value.prakruti_consitution.pcBerdGrwth);
      }
      [this.vatTS, this.pitTS, this.kafTS] = ['Vata = ' + result.vata_score, 'Pitta = ' + result.pita_score, 'Kapha = ' + result.kafa_score];
      this.dshyLst = [], this.selectedList = [], this.lstCCSlctd = [], this.lstFreq.forEach(fq => { Object.assign(fq, { isChk: false }); });
    }
    else {
    
      let ccKey = Object.keys(result.cc_brief_history.chiefComplaints).filter(f => f == 'name');
      this.prkrtTxt = `${this.vatTxt}${result.vata_score}, ${this.pitTxt}${result.pita_score}, ${this.kafTxt}${result.kafa_score}`, this.lstDrgDur = [];
      this.pocAyuForm.patchValue({
        prakruti_consitution: {
          pcAge: result.prakruti_constitution?.filter(f => f.name == 'Age')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBodyType: result.prakruti_constitution?.filter(f => f.name == 'Body Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWtGainPtrn: result.prakruti_constitution?.filter(f => f.name == 'Weight Gain Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinCmplxn: result.prakruti_constitution?.filter(f => f.name == 'Skin Complexion')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSkinTextr: result.prakruti_constitution?.filter(f => f.name == 'Skin Texture')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcHairType: result.prakruti_constitution?.filter(f => f.name == 'Hair Type')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSleepPatrn: result.prakruti_constitution?.filter(f => f.name == 'Sleep Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcAppetite: result.prakruti_constitution?.filter(f => f.name == 'Appetite')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcTastePref: result.prakruti_constitution?.filter(f => f.name == 'Taste Preference')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcPhysAcvty: result.prakruti_constitution?.filter(f => f.name == 'Physical Activity')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBowelMnmt: result.prakruti_constitution?.filter(f => f.name == 'Bowel Movement')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcSituation: result.prakruti_constitution?.filter(f => f.name == 'Given a situation, how do you go about it?')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcWrkngStyle: result.prakruti_constitution?.filter(f => f.name == 'Working Style')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcMemory: result.prakruti_constitution?.filter(f => f.name == 'Memory')[0]?.prakruti_lookup_values[0]?.attribute_name ?? '',
          pcBerdGrwth: this.gdrId == 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Male, Growth of Beard')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
          pcFemMenstr: this.gdrId != 1 ? result.prakruti_constitution?.filter(f => f.name == 'If Female, Menstrual Cycle')[0]?.prakruti_lookup_values[0]?.attribute_name : '',
        },
        cc_brief_history: {
          dctrComplaints: result.cc_brief_history.doctorComments, notes2: result.cc_brief_history.notes2, presentIllness: result.cc_brief_history.presentIllness,
          chiefComplaints: [], notes1: result.cc_brief_history.notes1, otherComplaints: result.cc_brief_history.otherComplaints,
          // chiefComplaints: result.cc_brief_history.chiefComplaints, notes1: result.cc_brief_history.notes1, otherComplaints: result.cc_brief_history.otherComplaints,
        },
        vital_history: {
          temperature: result.vital_history.temperature, pulse: result.vital_history.pulse+'',
          systolic: result.vital_history.systolic, diastolic: result.vital_history.diastolic,
        },
        
        other_history: { history: result.other_history.history, allergies: result.allergy.allergies, past_history: result.other_history.past_history },
        general_assessment: {
          pulse_nadi: result.general_assessment?.filter(f => f.name == 'Pulse/Nadi')[0]?.assesment_lookup_values[0]?.id ?? '',
          stool_mala: result.general_assessment?.filter(f => f.name == 'Stool/Mala')[0]?.assesment_lookup_values[0]?.id ?? '',
          urination_mutra: result.general_assessment?.filter(f => f.name == 'Urination/Mutra')[0]?.assesment_lookup_values[0]?.id ?? '',
          tongue_jihva: result.general_assessment?.filter(f => f.name == 'Tongue/Jihva')[0]?.assesment_lookup_values[0]?.id ?? '',
          appetite_agni: result.general_assessment?.filter(f => f.name == 'Appetite/ Agni')[0]?.assesment_lookup_values[0]?.id ?? '',
        },
        roga_pariksha: {
          prakruti: this.prkrtTxt, dosha: result.roga_pariksha?.filter(f => f.name == 'Dosha')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          anubandha_dosha: result.roga_pariksha?.filter(f => f.name == 'Anubandha Dosha')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          dooshya: result.roga_pariksha?.filter(f => f.name == 'Dooshya')[0]?.roga_pariksha_lookup_values, // dooshya?? '': '',
          bala: result.roga_pariksha?.filter(f => f.name == 'Bala')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          desha: result.roga_pariksha?.filter(f => f.name == 'Desha')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          kala: result.roga_pariksha?.filter(f => f.name == 'Kala')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          analam: result.roga_pariksha?.filter(f => f.name == 'Analam')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          vaya: result.roga_pariksha?.filter(f => f.name == 'Vaya')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          satwa: result.roga_pariksha?.filter(f => f.name == 'Satwa')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          aahara: result.roga_pariksha?.filter(f => f.name == 'Aahara')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          aahara_shakti: result.roga_pariksha?.filter(f => f.name == 'Aahara Shakti')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
          roga_dis_avastha: result.roga_pariksha?.filter(f => f.name == 'Roga (Disease) Avastha')[0]?.roga_pariksha_lookup_values[0]?.id ?? '',
        },
        diagnosis: { provisionalDiagnosis: result.diagnosis.provisionalDiagnosis },

        covid19: result.covid19,
        advice: {
          rest_exercise: result.advice?.restExercise, diet: result.advice?.diet, follow_advice: result.advice?.followAdvice,
          referral: result.advice?.referral, doctorComment: '', followupDate: '',is_self:this.pocAyuForm.get('is_self')
        }, health_worker_observation: result.health_worker_observation,
        drugs: { instruction: result.ayush_prescribed_medications?.instruction },
        followupDate: result.followupDate,
        // type_of_consultation:result.type_of_consultation,
        physical_consultation_required: result.physical_consultation_required,
      });
      this.getAllVitals();
      this.pocAyuForm.get('cc_brief_history.notes1').setValue(result.cc_brief_history.notes1);
      this.pocAyuForm.get('cc_brief_history.chiefComplaints').setValue([]);

      Ayush_POC.enableOrDisable(this.pocAyuForm);

      if (result.pharmacy_id || result.lab_id)
        this.load_LabandPharmacy(result);
      this.onChangPC('ag', result.prakruti_constitution?.filter(f => f.name == 'Age')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('bt', result.prakruti_constitution?.filter(f => f.name == 'Body Type')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('wt', result.prakruti_constitution?.filter(f => f.name == 'Weight Gain Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('sc', result.prakruti_constitution?.filter(f => f.name == 'Skin Complexion')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('st', result.prakruti_constitution?.filter(f => f.name == 'Skin Texture')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ht', result.prakruti_constitution?.filter(f => f.name == 'Hair Type')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('sp', result.prakruti_constitution?.filter(f => f.name == 'Sleep Pattern')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ap', result.prakruti_constitution?.filter(f => f.name == 'Appetite')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('tp', result.prakruti_constitution?.filter(f => f.name == 'Taste Preference')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('pa', result.prakruti_constitution?.filter(f => f.name == 'Physical Activity')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('bm', result.prakruti_constitution?.filter(f => f.name == 'Bowel Movement')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('si', result.prakruti_constitution?.filter(f => f.name == 'Given a situation, how do you go about it?')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('ws', result.prakruti_constitution?.filter(f => f.name == 'Working Style')[0]?.prakruti_lookup_values[0]?.attribute_name);
      this.onChangPC('my', result.prakruti_constitution?.filter(f => f.name == 'Memory')[0]?.prakruti_lookup_values[0]?.attribute_name);
      if (this.gdrId != 1) {
        this.pocAyuForm.value.prakruti_consitution.pcFemMenstr = result.prakruti_constitution?.filter(f => f.name == 'If Female, Menstrual Cycle')[0]?.prakruti_lookup_values[0]?.attribute_name;
        this.onChangPC('fm', this.pocAyuForm.value.prakruti_consitution.pcFemMenstr);
      }
      else {
        this.pocAyuForm.value.prakruti_consitution.pcBerdGrwth = result.prakruti_constitution?.filter(f => f.name == 'If Male, Growth of Beard')[0]?.prakruti_lookup_values[0]?.attribute_name;
        this.onChangPC('gb', this.pocAyuForm.value.prakruti_consitution?.pcBerdGrwth);
      }
      [this.vatTS, this.pitTS, this.kafTS] = ['Vata = ' + result.vata_score, 'Pitta = ' + result.pita_score, 'Kapha = ' + result.kafa_score];
      let dshList = [...this.pocAyuForm.value.roga_pariksha?.dooshya];
      this.pocAyuForm.value.roga_pariksha.dooshya = dshList, this.dshyLst = dshList, this.selectedList = dshList;
      this.chiefComplaintList.forEach(e => {
        if (result.cc_brief_history.chiefComplaints.filter(f => f.chief_complaint == e.chief_complaint).length > 0)
          e.isChk = true;
      }), this.lstCCSlctd = this.chiefComplaintList;
      result.ayush_investigation?.master?.forEach(e => {
        this.lstPnchKrma.push({
          svcId: e.servicesubgroup.id, svcName: e.servicesubgroup.name, prcdId: e.servicesubgroup.services.id,
          prcdName: e.servicesubgroup.services.name, rmrk: e.servicesubgroup.remarks, isDel: false
        });
      });
      this.lstFreq.forEach(fq => { Object.assign(fq, { isChk: false }); });
      // result.ayush_prescribed_medications?.drugs?.forEach((e, i) => { //line commented for new drug change done
      result.ayush_prescribed_medications?.drugs?.forEach((e, i) => {
        console.log('result....',result)
        let dur = '', frq = e.frequency;
        // e.frequency.forEach(f => { dur += dur == '' ? f.name : `,${f.name}`; });
        this.lstDrgsData.push({
          dtId: e.id, drgId: e.drugName.id, dtTxt: e.drugType, drgTxt: e.drugName.name, otherDrug: '', rmrk: e.remarks, isDel: false,
          freqLst: JSON.parse(sessionStorage.getItem('freqLST')), duration: dur, dosage: e.dosage.id, relfood: e.direction.id, days: e.days,
          anupaan: e.anupaan.id
        });
        const index: number = this.lstDrgsData.length - 1;
        const freqList = frq.split(',');
        this.lstDrgsData[index].durLstNM = [];
        this.lstDrgsData[index].durLst = freqList.length > 0 ? freqList : [];
        this.lstDrgsData[index].freqLst.forEach(fq => {
          if (freqList.includes(fq.frequency)) {
            fq.isChk = true;
            this.lstDrgsData[index].durLstNM.push(fq);
            // this.lstDrgsData[index].durLst = [fq.frequency];
          }
        });
      });
      result.other_history.past_history.split(',').forEach(e => {
        if (this.lstPHHdrs.filter(f => f.name == e).length > 0)
          this.lstPHHdrs.filter(f => f.name == e)[0].isChk = true;
      });
      this.lstPHHdrs.forEach(e => { this.lstPstHstry.push({ disease: e.name, isChk: e.isChk }); });
      if (result.cc_brief_history?.ccRmrks?.length > 0) {
        if (result.cc_brief_history?.ccRmrks?.length == this.chiefComplaintList.length)
          this.ayurvedPOC.cc_brief_history.ccRmrks = result.cc_brief_history?.ccRmrks;
      }
    }
    this.activeModal.close();
  }

  load_LabandPharmacy(result: any) {
    let [pharmacyId, labId] = ['', ''];
    if (Object.keys(result.pharmacy_id).length > 0) {
      this.state_id = result.pharmacy_id.state_id;
      this.district_id = result.pharmacy_id.district_id;
      this.region_id = result.pharmacy_id.region_id;
      pharmacyId = result.pharmacy_id.id;
      labId = (Object.keys(result.lab_id).length > 0) ? result.lab_id.id : '';
    }
    else if (Object.keys(result.lab_id).length > 0) {
      this.state_id = result.lab_id.state_id;
      this.district_id = result.lab_id.district_id;
      this.region_id = result.lab_id.region_id;
      labId = result.lab_id.id;
    }
    this.pocAyuForm.patchValue({ lab_id: labId, pharmacy_id: pharmacyId });
    if (+this.state_id > 0)
      this.LoadRegionsbyStateId(this.state_id);
    if (+this.region_id > 0)
      this.LoadDistbyRegion(this.region_id);
    if (+this.district_id > 0)
      this.LoadLabPharmabyDist(this.district_id);
  }

  controlFormControlChange() {
    Ayush_POC.updateStage(this.pocAyuForm, this.covid19Obj);

    this.pocAyuForm.get('covid19.presenting_complaints').valueChanges.subscribe(e => {
      const notes1FormControl = this.pocAyuForm.get('cc_brief_history.notes1');
      let symptoms: string = '';
      this.stageCounts = { MILD: 0, MODERATE: 0, SEVERE: 0 };

      e?.forEach((d: any) => {
        if (d.check_box) {
          symptoms += symptoms.length == 0 ? d.symptoms : (',' + d.symptoms);
          if (d.stage == 'MILD')
            this.stageCounts.MILD++;
          else if (d.stage == 'MODERATE')
            this.stageCounts.MODERATE++;
          else if (d.stage == 'SEVERE')
            this.stageCounts.SEVERE++;
        }
      });
      notes1FormControl.setValue(this.chiefComplaintList.filter(e => symptoms.split(',').includes(e.chief_complaint)))

    });
    Ayush_POC.general_examination(this.pocAyuForm);
  }

  initFormControls() {
    this.pocAyuForm = this.formBuilder.group({
      is_epaper: [false],
      beneficiary_id: [''],
      doctor_id: [''],
      lab_id: [''],
      pharmacy_id: [''],
      covid19: this.formBuilder.group({
        patient_identified: this.formBuilder.group({
          investigations: [''],
          investigations_date: [''],
          ct_value: [{ value: '', disabled: true }],
          name: [{ value: '', disabled: true }],
          reason_for_testing: [''],
          reason_date: [''],
          specify: [{ value: '', disabled: true }],
        }),
        co_morbidities: this.formBuilder.group({
          DM: [''],
          hypertension: [''],
          COPD: [''],
        }),
        symptoms: [false],
        presenting_complaints: this.formBuilder.array([]),
        present_illness: [''],
        otherName: [{ value: '', disabled: true }],
        general_examination: this.formBuilder.group({
          SPO2: ['', [Validators.min(40), Validators.max(205), Validators.maxLength(3)]],
          PULSE: ['', [Validators.min(30), Validators.max(200)]],
          TEMPERATURE: ['', [Validators.min(90), Validators.max(110)]],
          RR: ['', [Validators.min(12), Validators.max(40)]],
          WEIGHT: ['', [Validators.min(1), Validators.max(200.99), Validators.maxLength(6)]],
          Systolic: ['', [Validators.min(50), Validators.max(400)]],
          Diastolic: ['', [Validators.min(30), Validators.max(200)]]
        }),
        test: this.formBuilder.group({
          RT_PCR: [''],
          HRCT: [''],
          CRP: [''],
          IL6: [''],
          D_DIMER: [''],
          S_FERRITIN: [''],
          LDH: [''],
          ESRL: [''],
        }),
        clinical_evalution_of_the_patient: this.formBuilder.group({
          started_home_isolation: [''],
          medical_advice: [false],
          vaccination_status: [''],
          // status_Date: [''],
          vaccine_name: [{ value: '', disabled: true }],
          other_name: [{ value: '', disabled: true }],
          vaccination_date: [{ value: '', disabled: true }],
          symptom_status: [''],
          // asymptom_status: ['']
        }),
        medicines_being_taken: this.formBuilder.group({
          Medicines: [''],
          other_Rx_for_COVID19: [''],
          any_other_medicines: [''],
        }),
        stage: [''],
      }),
      visit_date: [this.datePipe.transform((new Date()), 'yyyy-MM-dd HH:mm:ss')],
      visit_start_time: [this.datePipe.transform(this.minDate, 'HH:mm:ss')],
      visit_end_time: [''],
      physical_consultation_required: [false],
      follow_up_date: [''],
      type_of_consultation: ['', Validators.required],
      prakruti_consitution: this.formBuilder.group({
        // pcShw: [''],
        pcAge: ['', Validators.required],
        pcBodyType: ['', Validators.required],
        pcWtGainPtrn: ['', Validators.required],
        pcSkinCmplxn: ['', Validators.required],
        pcSkinTextr: ['', Validators.required],
        pcHairType: ['', Validators.required],
        pcSleepPatrn: ['', Validators.required],
        pcAppetite: ['', Validators.required],
        pcTastePref: ['', Validators.required],
        pcPhysAcvty: ['', Validators.required],
        pcBowelMnmt: ['', Validators.required],
        pcSituation: ['', Validators.required],
        pcWrkngStyle: ['', Validators.required],
        pcMemory: ['', Validators.required],
        pcFemMenstr: ['', Validators.required],
        pcBerdGrwth: ['', Validators.required],
      }),
      cc_brief_history: this.formBuilder.group({
        chiefComplaints: ['', Validators.required],
        otherComplaints: [''],
        dctrComplaints: [''],
        presentIllness: [''],
        notes1: [''],
        notes2: [''],
      }),
      vital_history: this.formBuilder.group({
        temperature: [{ value: '', disabled: false }, [Validators.min(90), Validators.max(110)]],
        pulse: [{ value: '', disabled: false }, Validators.max(200)],
        systolic: [{ value: '', disabled: false }, [Validators.min(50), Validators.max(400)]],
        diastolic: [{ value: '', disabled: false }, [Validators.min(30), Validators.max(200)]],
      }),
      other_history: this.formBuilder.group({
        history: [''],
        allergies: [''],
      }),
      general_assessment: this.formBuilder.group({
        pulse_nadi: [''],
        stool_mala: [''],
        urination_mutra: [''],
        tongue_jihva: [''],
        appetite_agni: [''],
      }),
      roga_pariksha: this.formBuilder.group({
        prakruti: [''],
        dosha: [''],
        anubandha_dosha: [''],
        dooshya: [[]],
        bala: [''],
        desha: [''],
        kala: [''],
        analam: [''],
        vaya: [''],
        satwa: [''],
        aahara: [''],
        aahara_shakti: [''],
        roga_dis_avastha: [''],
      }),
      allergy: this.formBuilder.group({
        allergies: ['', Validators.required],
      }),
      diagnosis: this.formBuilder.group({
        provisionalDiagnosis: [[]]
      }),
      pancha_karma: this.formBuilder.group({
        services: [''],
        procedure: [''],
        remarks: [''],
      }),
      advice: this.formBuilder.group({
        rest_exercise: [''],
        diet: [''],
        followupDate: [''],
        follow_advice: [''],
        referral: [''],
        is_self:[this.is_self]
        
      }),
      health_worker_id: [''],
      health_worker_observation: [''],
      investigation: this.formBuilder.group({
        master: [''],
        otherInvestigation: ['']
      }),
      prescribed_medications: this.formBuilder.array([]),
      medical_history: this.formBuilder.group({}),
      drugs: this.formBuilder.group({
        drugType: [''],
        drugName: [''], // , Validators.required
        otherDrug: [''],
        instruction: [''],
        // pcShw: [''],
      }),
      remarks: this.formBuilder.group({}), //Not there in UI
      visit_no: [''],
      call_type_id: this.isOffLinePOC() ? 9 : 1,
      // "teleconference_file_path": "/home",
      "referred_visit_id": "2",
      "beneficiary_visit_logs": [''],
      patient_interaction_id: [+(<HTMLInputElement>document.getElementById('patient_interaction_id')).value],
      order_no: [+(<HTMLInputElement>document.getElementById('order_no')).value],
    });
    // this.pocAyuForm.patchValue({investigation:{master:[]}});
    this.controlFormControlChange();
  }

  initPayFormControls() {
    this.paySumForm = this.formBuilder.group({
      id: [0],
      medical_record_id: [0],
      payment_status_id: [2],
      payment_status: ['Not Paid'],
      consultation_fee_required: [this.IsConsultReq],
      medicine_billable: [this.IsMedBill],
      cash_on_delivery: [false],
      delivery_charge: [0],
      home_delivery: [false],
      consultation_fee: [null],
      add_balance_amount: [false],
      // lab_id: [''],
      // pharmacy_id: [''],
      discount_percentage: [0, [Validators.max(100), Validators.min(0)]],
      discount_amount: [0],
      previous_balance_amount: [{ value: 0, disabled: true }],
      discount: ['r'], //
      total: [null], //
      overall_amount: [{ value: null, disabled: true }], //
      total_bill_amount: [{ value: null, disabled: true }],
      payable_amount: [{ value: null, disabled: true }],
      invoice_amount: [null],
      due_amount: [{ value: 0, disabled: true }],
      prescribed_drugs: this.formBuilder.array([]),
    });
  }

  onSysDia() {
    this.systolic = this.pocAyuForm.value.vital_history.systolic;
    this.diastolic = this.pocAyuForm.value.vital_history.diastolic;

    if (this.systolic != '' && this.diastolic == '') {
      this.pocAyuForm.get('vital_history.diastolic').setValidators([Validators.required, Validators.min(30), Validators.max(200)]);
      this.pocAyuForm.get('vital_history.diastolic').updateValueAndValidity();
    }
    else if (this.systolic == '' && this.diastolic != '') {
      this.pocAyuForm.get('vital_history.systolic').setValidators([Validators.required, Validators.min(50), Validators.max(400)]);
      this.pocAyuForm.get('vital_history.systolic').updateValueAndValidity();
    }
    else {
      if (+this.diastolic > 0 && +this.systolic > 0) {
        if (+this.diastolic >= +this.systolic) {
          this.pocAyuForm.get('vital_history.diastolic').setErrors({ 'dia': true });
          this.toastr.error('Systolic should be greater than Diastolic');
        }
      }
      else if ((this.diastolic == '' && this.systolic == '') || (+this.systolic >= +this.diastolic)) {
        this.pocAyuForm.get('vital_history.diastolic').setErrors(null);
        // this.pocAyuForm.get('vital_history.systolic').clearValidators();
        // this.pocAyuForm.get('vital_history.diastolic').clearValidators();
        this.pocAyuForm.get('vital_history.systolic').setValidators([Validators.min(50), Validators.max(400)]);
        this.pocAyuForm.get('vital_history.diastolic').setValidators([Validators.min(30), Validators.max(200)]);
        this.pocAyuForm.get('vital_history.systolic').updateValueAndValidity();
        this.pocAyuForm.get('vital_history.diastolic').updateValueAndValidity();
      }
    }
  }

  onChngCT() {
    document.getElementById('headingOne').click();
    if (this.pocData != null && this.pocData != undefined) {
      Ayush_POC.clearCovid19Form(this.pocAyuForm);
      this.consType = this.roleId == '9' ? this.newCT : this.consType;
      this.isLoad = this.isLoad == true ? false : this.isLoad;
      if(this.consType==='New'){
        setTimeout(() => {
          this.lstDrgsData=[];
          this.lstPnchKrma=[];
          this.presPoc.investigation.master=[];
          this.presPoc.prescribed_medications.drugs=[];
          // this.pocAyuForm.reset();
          // this.getAllMaster();
          // this.controlFormControlChange();
          this.changeDetection.detectChanges();
          this.getPOCDatabyId();
          // this.bindPrakruthiPOCData(this.pocData);
        }, 1000);
     
        // this.registrationService.getAyushDataById(`findbypocid?pocId=${this.idPOC}`).subscribe(res => {
        //  this.pocData=null;
        //   this.pocData = res.data;
        // });
        // this.bindPOCData(this.pocData);
       
      
      }else{
        this.bindPOCData(this.pocData);
      }
      
      // consType form.reset();

    }
  }

  get f() { return this.pocAyuForm.controls; }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  getBenHistoryDetails(id: any) {
    this.registrationService.getDataById('findbybeneficiaryid?beneficiaryId=', id).
      subscribe(res => { this.benHistory = res.data; }, err => { this.toastr.error(err) });
  }

  LoadRegionsbyStateId(stateId: any) {
    this.lstRegns = [], this.lstDist = [], this.lstTelgLab = [], this.lstTelgPhrm = [], this.labAddrs = '', this.phrmcyAddrs = '',
      $('#labPhrmPerc').val(''), $('#labsPerc').val('');
    if (this.lstStates.length > 0 && stateId) {
      this.masterService.getAll('getregionsbystateid?stateId=' + stateId).subscribe(r => {
        if (r)
          this.lstRegns = r.data;
      });
    }
  }

  LoadDistbyRegion(rgnId: any) {
    this.lstDist = [], this.lstTelgLab = [], this.lstTelgPhrm = [], this.labAddrs = '', this.phrmcyAddrs = '',
      $('#labPhrmPerc').val(''), $('#labsPerc').val('');
    if (rgnId) {
      this.masterService.getAll('getdistrictsbyregionid?regionId=' + rgnId).subscribe(r => {
        if (r)
          this.lstDist = r.data;
      })
    }
  }


  LoadLabPharmabyDist(distId: any) {
    if (distId) {
      const labsList = this.registrationService.getAll('lab/getlabbydistrictid?districtId=' + distId);
      const phrmList = this.registrationService.getAll('pharmacy/getpharmacybydistrictid?districtId=' + distId);
      forkJoin([labsList, phrmList]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available', 'Plan of Care');
              else {
                const assignList = {
                  '0': () => this.lstTelgLab = list.data,
                  '1': () => this.lstTelgPhrm = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          if (this.lstTelgLab.length > 0)
            this.onLabsChng(this.pocAyuForm.value.lab_id);
          if (this.lstTelgPhrm.length > 0)
            this.onPhrmcChng(this.pocAyuForm.value.pharmacy_id);
        },
      });
    }
  }


  onLabsChng(labId: any) {
    $('#labsPerc').val(''), this.labAddrs = '';
    if (labId) {
      let labData = this.lstTelgLab.filter(f => f.id === +labId)[0], sId = $('#labStateId option:selected').val(),
        rId = $('#labRegnId option:selected').val(), dId = $('#labDistId option:selected').val(), labName = labData.lab_name,
        state = this.lstStates.filter(f => f.id === +sId)[0]?.state_name, region = this.lstRegns.filter(f => f.id === +rId)[0]?.region_name,
        district = this.lstDist.filter(f => f.id === +dId)[0]?.district_name, cntct = labData.lab_conatct_no, adrs = labData.address,
        prsnName = labData.contact_person_name + ' ' + labData.contact_person_last_name, pin = labData.pin_code;
      $('#labsPerc').val(labData.discount ?? 0);
      this.labAddrs = `${labName}, ${prsnName}, ${adrs}, ${district}, ${region}, ${state}, ${pin}, Lab Contact: ${cntct}`;
    }
  }

  onPhrmcChng(phrmId: any) {
    $('#labPhrmPerc').val(''), this.phrmcyAddrs = '';
    if (phrmId) {
      let phrmData = this.lstTelgPhrm.filter(f => f.id === +phrmId)[0], sId = $('#labStateId option:selected').val(),
        rId = $('#labRegnId option:selected').val(), dId = $('#labDistId option:selected').val(), phrmName = phrmData.pharmacy_name,
        state = this.lstStates.filter(f => f.id === +sId)[0]?.state_name, region = this.lstRegns.filter(f => f.id === +rId)[0]?.region_name,
        district = this.lstDist.filter(f => f.id === +dId)[0]?.district_name, cntct = phrmData.contact_person_mobile, adrs = phrmData.address,
        prsnName = phrmData.contact_person_name + ' ' + phrmData.contact_person_last_name, pin = phrmData.pin_code;
      $('#labPhrmPerc').val(phrmData.discount ?? 0);
      this.phrmcyAddrs = `${phrmName}, ${prsnName}, ${adrs}, ${district}, ${region}, ${state}, ${pin}, Pharmacy Contact: ${cntct}`;
    }
  }


  getBenDetails(id: any) {
    setTimeout(() => {
      this.registrationService.getByID('beneficiary/getbeneficiaryrecordbyid?beneficiaryId=',id).
      subscribe(res => {
        console.log('res',)
        this.benData = res.data, this.gdrId = res.data.gender_id, this.benAge = +this.benData.age;
        if (!this.is_self) {
          this.registrationService.getByID('beneficiarymemberdetails/getbyid?id=', this.dependentId)
            .subscribe(dep => { 
              this.dependtData = dep.data, this.gdrIdnew = dep.data.gender_id; }, err => { this.toastr.error(err) });
        }
        // console.log("dependtData",this.dependtData);
      }, err => { this.toastr.error(err) });
    }, 2000);
  
  }
 
  

  // getDoctorDetails() {
  //   this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails')), this.docDgtlSign = '', this.isDocChrgbl = false;
  //   if (this.docData) {
  //     let qualificationName = this.qualificationsList.filter(f => f.id == this.docData.qualification_id)[0]?.qualification;
  //     let superDegree = this.qualificationsList.filter(f => f.id == this.docData.qualification_id)[0]?.ayush_specializations.
  //       filter(f => f.id == this.docData.specialization_id)[0]?.specialization;
  //     let adrs = this.docData.doctor_address.filter(f => f.display_on_prescription == true)[0]?.address;
  //     this.doc = {
  //       first_name: this.docData.first_name, last_name: this.docData.last_name, qualification: qualificationName,
  //       superspecialiy: superDegree, mci: this.docData.mci_number, address: adrs, contact: this.docData.mobile,
  //       display_mobile_no: this.docData.display_mobile_no || false
  //     };
  //     if (this.docData.doctor_documents.filter(f => f.file_type_id == Avail.DrFTId)[0]?.document_path)
  //       this.docDgtlSign = this.appUrl + Avail.fileUrl + Avail.DrTxt + this.docData.id + `/${Avail.DrFTId}/` + this.docData.doctor_documents.filter(f => f.file_type_id == Avail.DrFTId)[0].document_path;
  //     this.isDocChrgbl = this.docData.charge_amt == 1;
  //   }
  // }

  // getHWDetails() {
  //   if (sessionStorage.getItem('webSkt') != null) {
  //     let hwId = +(JSON.parse(sessionStorage.getItem('webSkt')).fromId.replace('hw_', ''));
  //     this.registrationService.getDocbyHWID('doctorhealthworker/getbyhealthworkerid?health_worker_id=', hwId).subscribe(hw => {
  //       this.docId = hw.data[0].doctor_registration.id, this.GetPOCId(this.docId); // 'healthworkerId',
  //     });
  //   }
  //}

  getDoctorDetails() {
    this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails')), this.docDgtlSign = '', this.isDocChrgbl = false;
    if (this.docData) {
      let qualificationName:any = this.qualificationsList.filter(f => f.id == this.docData.qualification_id)[0]?.qualification;
      
      // let superDegree:any = this.qualificationsList.filter(f => f.id == this.docData.qualification_id).
      //   filter(f => f.id == this.docData.specialization_id)[0]?.specialization;
      let superDegree:any = this.qualificationsList.filter(f => f.id == this.docData.qualification_id)[0]?.ayush_specializations?.length>0?
      this.qualificationsList.filter(f => f.id == this.docData.qualification_id)[0]?.ayush_specializations.filter(f => f.id == this.docData.specialization_id)[0]?.specialization:'';
      
      let adrs = this.docData.doctor_address.filter(f => f.display_on_prescription == true)[0]?.address;
     setTimeout(() => {
      this.doc = {
        first_name: this.docData.first_name, last_name: this.docData.last_name, qualification: qualificationName,
        superspecialiy: superDegree, mci: this.docData.mci_number, address: adrs, contact: this.docData.mobile,
        display_mobile_no: this.docData.display_mobile_no || false
      };
     }, 1500);
     
      if (this.docData.doctor_documents.filter(f => f.file_type_id == Avail.DrFTId)[0]?.document_path)
        // this.docDgtlSign = this.appUrl + Avail.fileUrl + Avail.DrTxt + this.docData.id + `/${Avail.DrFTId}/` + this.docData.doctor_documents.filter(f => f.file_type_id == Avail.DrFTId)[0].document_path;
        this.docDgtlSign = this.appUrl + Avail.vmedFileUrlNew + Avail.DrTxt + this.docData.id + `/DigitalSignature/` + this.docData.doctor_documents.filter(f => f.file_type_id == Avail.DrFTId)[0].document_path;
     
        this.isDocChrgbl = this.docData.charge_amt == 1;
    }
  }
  getHWDetails() {
    if (sessionStorage.getItem('webSkt') != null) {
      let hwId = +(JSON.parse(sessionStorage.getItem('webSkt')).fromId.replace('hw_', ''));
      this.registrationService.getDocbyHWID('doctorhealthworker/getbyhealthworkerid?health_worker_id=', hwId).subscribe(hw => {
        this.docId = hw.data[0].doctor_registration.id, this.GetPOCId(this.docId); // 'healthworkerId',
      });
    }
  }
  
  // openModal(item: any = null) {
  //   let ar = []; ar.push(item);
  //   const modalRef = this.modalService.open(PreviousHistoryComponent, { scrollable: true, size: 'xl' });
  //   modalRef.componentInstance.history = ar;
  //   modalRef.result.then(result => {
  //     // here call Table load API
  //     // alert('Success1');
  //   }, (reason) => { });
  // }

async getAllMaster() {
    this.activeSpinner();
    const prakruticonsts = this.masterService.getAll('ayushprakurti/getall');
    const gnrlAsmt = this.masterService.getAll('ayushassesmentlookup/getall');
    const rogPrksha = this.masterService.getAll('ayushrogapariksha/getall');
    const pnchKrmaPrcds = this.masterService.getAll('ayushservicegroup/getall');
    // const drug_types = this.masterService.getAll('ayushdrugtype/getall'); commented for below line added for new API 27-05-2023
    const drug_types = this.registrationService.getAyushDrugsListNew();
    const dosage = this.masterService.getAll('ayushdosage/getall');
    const drugdirect = this.masterService.getAll('ayushdirection/getall');
    const provDgns = this.masterService.getAll('ayushprovisionaldiag/getall');
    const frequency = this.masterService.getAll('ayushfrequency/getall');
    const chiefComplaint = this.masterService.getAll('ayushchiefcomplaintslookup/getall');  // getallchiefcomplaints
    const diagnosis = this.masterService.getAll('getalldiagnosis');
    const drugtypes = this.masterService.getAll('getalldrugtypes');
    const drugnames = this.masterService.getAll('getalldrugs');
    // const investigations = this.masterService.getAll('getallinvestigations');
    const investigations = this.registrationService.getLabServicesList();
    const directions = this.masterService.getAll('getalldirections');
    const instruction = this.masterService.getAll('getallinstruction');
    // const qualifications = this.masterService.getAll('ayushqualification/getall'); // getallqualifications
    const qualifications = this.masterService.getAll('getallqualifications'); // getallqualifications
    const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    const stateList = this.masterService.getAll('getallstates');
    const anupaanList = this.masterService.getAll('masteranupaan/getallanupaan');

    forkJoin([chiefComplaint, diagnosis, drugtypes, drugnames, investigations, directions, instruction, qualifications, superSpecialityDegree,
      prakruticonsts, gnrlAsmt, rogPrksha, pnchKrmaPrcds, drug_types, dosage, drugdirect, provDgns, frequency, stateList, anupaanList]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                this.toastr.warning('No records available', 'Plan of Care');
              else {
                const assignList = {
                  '0': () => this.reOrderCC(list.data),
                  '1': () => this.diagnosislList = list.data,
                  '2': () => this.drugtypesList = list.data,
                  '3': () => this.drugnamesList = list.data,
                  '4': () => this.investigationsList = [],
                  '5': () => this.directionsList = list.data,
                  '6': () => this.instructionList = list.data,
                  '7': () => this.qualificationsList = list.data,
                  '8': () => this.superSpecialityDegreeList = list.data,
                  '9': () => this.setPrakConstValues(list.data),
                  '10': () => this.setGenAssVals(list.data),
                  '11': () => this.setRogPrkshaVals(list.data),
                  // '12': () => this.lstSVCs = list.data[0].ayush_service_sub_groups,
                  '12': () => this.lstSVCs = this.investigationsList,
                  // '12': () => this.lstSVCs = this.investigationsList.forEach(re=>{re.ayush_service_sub_groups}) ,
                  '13': () => this.lstDrgType = list.data,
                  '14': () => this.lstDosge = list.data,
                  '15': () => this.lstDrgDrctn = list.data,
                  '16': () => this.lstPrvDgns = list.data,
                  '17': () => this.setFreqVals(list.data),
                  '18': () => this.lstStates = list.data,// setNewFreqVals(list.data),
                  '19': () => this.ayushAnupaanList = list.data,// setNewFreqVals(list.data),
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => { this.deactivateSpinner(); 
          // console.error('something wrong occurred: ', JSON.stringify(err))
          console.error('something wrong occurred: ', err) },
        complete: () => {
          this.deactivateSpinner();
          if (this.roleId == '7')
            this.getDoctorDetails(), this.GetPOCId(this.docData.id),this.getBalbyDocAndBen(this.docData.id);
          if (this.roleId == '9')
            this.getHWDetails();
        },
      });
      this.loadLabServices();
  }
loadLabServices(){
  this.investigationsList=[];
  setTimeout(() => {
    this.registrationService.getLabServicesList().subscribe(res=>{
      const investigations:any = res.data;
      if(investigations.length>0){
        investigations.forEach(res=>{
          if(res.ayush_service_sub_groups && res.ayush_service_sub_groups.length>0){
            res.ayush_service_sub_groups.map(data=>{
              this.investigationsList.push(data);
            })
           
          }
         
        })
        this.lstSVCs=[];
        this.lstSVCs=res.data[0]?.ayush_service_sub_groups;
      }
    });
  }, 2500);
 
}
  getBalbyDocAndBen(val: any) {
    this.registrationService.getBlance(`paymentsummary/getbalancebydoctoridandbeneficiaryid?doctorId=${val}&beneficiaryId=${this.benId}`).
      subscribe(res => { this.prevBal = res.data.previous_balance }, err => { this.toastr.error(err) });
  }

  GetPOCId(val: any) {
    this.followUpDataList=[];
    val=0;
    //this.registrationService.getAyushDataById(`findbydoctororhelathworkerandbenficiaryid?${key}=${val}&beneficiaryId=${this.benId}`).
    if (!this.is_self) {
      this.registrationService.getAyushDataById(`findbydoctoranddependentid?doctorId=${val}&dependentId=${this.dependentId}`).subscribe
        (res => { 
         
          res.data.map(res=>{
      if(res.is_ayush_poc){
        this.followUpDataList.push(res);
      }
          })
          this.idPOC = res.data.length > 0 ? res.data[0].id : 0, this.getPOCDatabyId(); }, err => { this.toastr.error(err) });
    }
    else {
      this.registrationService.getAyushDataById(`findbydoctorandbenficiaryid?doctorId=${val}&beneficiaryId=${this.benId}`).subscribe(res => {
      res.data.map(res=>{
      if(res.is_ayush_poc){
        this.followUpDataList.push(res);
      }
          })    
        this.idPOC = res.data.length > 0 ? res.data[0].id : 0, this.getPOCDatabyId();
      }, err => { this.toastr.error(err) });
    }

    // if (!this.is_self) {
    //   this.registrationService.getFollowUp(`getpreviousconsultationbybeneficairyid?dependentId=${this.dependentId}&typeOfConsultation=Ayush`).subscribe
    //     (res => { 
    //       this.followUpDataList=res.data;
    //       this.idPOC = res.data.length > 0 ? res.data[0].id : 0, this.getPOCDatabyId(); }, err => { this.toastr.error(err) });
    // }
    // else {
    //   this.registrationService.getFollowUp(`getpreviousconsultationbybeneficairyid?beneficiaryId=${this.benId}&typeOfConsultation=Ayush`).subscribe(res => {
    //   this.followUpDataList=res.data;      
    //     this.idPOC = res.data.length > 0 ? res.data[0].id : 0, this.getPOCDatabyId();
    //   }, err => { this.toastr.error(err) });
    // }
  }
  
  followUpSelect(item:any,type:any){
    if(type==='Followup'){
      if(item.is_epaper){
     this.toastr.error('No Follow Up for Digital Prescription')
      }else{
        this.bindPOCData(item)
      }
      
      this.followupClose();
    }else{
      // let link = document.createElement("a");
      // if (docPath != '' && docPath != null)
      //   this.imgPath = `${this.orgn}/${environment.viewFilePath}${Avail.VstFilesTxt}${docPath.split('_')[0]}/${environment.prescription}/${docPath}`, link.setAttribute('href', this.imgPath) ,link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'), link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
      // else
      //   this.toastr.info('No files found');
    this.followupClose();
    }
  
  }

  reOrderCC(lstCC: any[]) {
    this.chiefComplaintList = [], lstCC.forEach(e => { Object.assign(e, { isChk: false, chief_complaint: e.name }); });
    this.ayurvedPOC.cc_brief_history.ccRmrks = [], lstCC.forEach(e => { delete e.name }), lstCC.forEach((e, i) => {
      this.chiefComplaintList.push(lstCC[lstCC.length - i - 1]), this.ayurvedPOC.cc_brief_history.ccRmrks.push({ index: i, value: '', dsbld: true });
    });
  }

  setPrakConstValues(lstPC: any[]) {
    this.pcCtrls = new PrakrutiConsitution(), this.prakConstList = lstPC,
      this.lstPrkCnstHdrs.forEach((e, i) => {
        const pcList = {
          '0': () => this.pcCtrls.age = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '1': () => this.pcCtrls.body_type = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '2': () => this.pcCtrls.wt_gain_ptrn = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '3': () => this.pcCtrls.skin_cmplxn = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '4': () => this.pcCtrls.skin_txtr = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '5': () => this.pcCtrls.hair_type = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '6': () => this.pcCtrls.sleep_ptrn = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '7': () => this.pcCtrls.appetite = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '8': () => this.pcCtrls.taste_pref = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '9': () => this.pcCtrls.phy_act = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '10': () => this.pcCtrls.bowel_mvmnt = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '11': () => this.pcCtrls.situation = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '12': () => this.pcCtrls.working_style = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '13': () => this.pcCtrls.memory = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '14': () => this.pcCtrls.fem_msntr = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
          '15': () => this.pcCtrls.grwth_berd = lstPC.filter(f => f.name == e).length > 0 ? lstPC.filter(f => f.name == e)[0].prakruti_lookup_values : [],
        }
        pcList[i]();
      });
  }

  onChangPC(type: string, val: string) {
    if (val != null && val != undefined) {
      [this.vatTtlCnt, this.pitTtlCnt, this.kafTtlCnt] = [0, 0, 0];
      if (type == 'ag') {
        let age = val != '' ? this.pcCtrls.age.filter(f => f.attribute_name == val)[0] : null;
        this.agType = val != '' ? age.score_type.name : '', this.agScre = val != '' ? age.score.toString() : '', this.agName = val;
      }
      else if (type == 'bt') {
        let bodyType = val != '' ? this.pcCtrls.body_type.filter(f => f.attribute_name == val)[0] : null;
        this.btType = val != '' ? bodyType.score_type.name : '', this.btScre = val != '' ? bodyType.score.toString() : '', this.btName = val;
      }
      else if (type == 'wt') {
        let wtGainPtrn = val != '' ? this.pcCtrls.wt_gain_ptrn.filter(f => f.attribute_name == val)[0] : null;
        this.wtType = val != '' ? wtGainPtrn.score_type.name : '', this.wtScre = val != '' ? wtGainPtrn.score.toString() : '', this.wtName = val;
      }
      else if (type == 'sc') {
        let skinCmplxn = val != '' ? this.pcCtrls.skin_cmplxn.filter(f => f.attribute_name == val)[0] : null;
        this.scType = val != '' ? skinCmplxn.score_type.name : '', this.scScre = val != '' ? skinCmplxn.score.toString() : '', this.scName = val;
      }
      else if (type == 'st') {
        let skinTxtr = val != '' ? this.pcCtrls.skin_txtr.filter(f => f.attribute_name == val)[0] : null;
        this.stType = val != '' ? skinTxtr.score_type.name : '', this.stScre = val != '' ? skinTxtr.score.toString() : '', this.stName = val;
      }
      else if (type == 'ht') {
        let hairType = val != '' ? this.pcCtrls.hair_type.filter(f => f.attribute_name == val)[0] : null;
        this.htType = val != '' ? hairType.score_type.name : '', this.htScre = val != '' ? hairType.score.toString() : '', this.htName = val;
      }
      else if (type == 'sp') {
        let sleepPtrn = val != '' ? this.pcCtrls.sleep_ptrn.filter(f => f.attribute_name == val)[0] : null;
        this.spType = val != '' ? sleepPtrn.score_type.name : '', this.spScre = val != '' ? sleepPtrn.score.toString() : '', this.spName = val;
      }
      else if (type == 'ap') {
        let apette = val != '' ? this.pcCtrls.appetite.filter(f => f.attribute_name == val)[0] : null;
        this.apType = val != '' ? apette.score_type.name : '', this.apScre = val != '' ? apette.score.toString() : '', this.apName = val;
      }
      else if (type == 'tp') {
        let tstePref = val != '' ? this.pcCtrls.taste_pref.filter(f => f.attribute_name == val)[0] : null;
        this.tpType = val != '' ? tstePref.score_type.name : '', this.tpScre = val != '' ? tstePref.score.toString() : '', this.tpName = val;
      }
      else if (type == 'pa') {
        let phyAct = val != '' ? this.pcCtrls.phy_act.filter(f => f.attribute_name == val)[0] : null;
        this.paType = val != '' ? phyAct.score_type.name : '', this.paScre = val != '' ? phyAct.score.toString() : '', this.paName = val;
      }
      else if (type == 'bm') {
        let bwlMvmnt = val != '' ? this.pcCtrls.bowel_mvmnt.filter(f => f.attribute_name == val)[0] : null;
        this.bmType = val != '' ? bwlMvmnt.score_type.name : '', this.bmScre = val != '' ? bwlMvmnt.score.toString() : '', this.bmName = val;
      }
      else if (type == 'si') {
        let sittn = val != '' ? this.pcCtrls.situation.filter(f => f.attribute_name == val)[0] : null;
        this.siType = val != '' ? sittn.score_type.name : '', this.siScre = val != '' ? sittn.score.toString() : '', this.siName = val;
      }
      else if (type == 'ws') {
        let wrkngStyle = val != '' ? this.pcCtrls.working_style.filter(f => f.attribute_name == val)[0] : null;
        this.wsType = val != '' ? wrkngStyle.score_type.name : '', this.wsScre = val != '' ? wrkngStyle.score.toString() : '', this.wsName = val;
      }
      else if (type == 'my') {
        let mmry = val != '' ? this.pcCtrls.memory.filter(f => f.attribute_name == val)[0] : null;
        this.myType = val != '' ? mmry.score_type.name : '', this.myScre = val != '' ? mmry.score.toString() : '', this.myName = val;
      }
      else if (type == 'fm') {
        let fmlMens = val != '' ? this.pcCtrls.fem_msntr.filter(f => f.attribute_name == val)[0] : null;
        this.fmType = val != '' ? fmlMens.score_type.name : '', this.fmScre = val != '' ? fmlMens.score.toString() : '', this.fmName = val;
      }
      else {
        let grwthBerd = val != '' ? this.pcCtrls.grwth_berd.filter(f => f.attribute_name == val)[0] : null;
        this.gbType = val != '' ? grwthBerd.score_type.name : '', this.gbScre = val != '' ? grwthBerd.score.toString() : '', this.gbName = val;
      }
      let gId = this.gdrId;
      [this.agType, this.btType, this.wtType, this.scType, this.stType, this.htType, this.spType, this.apType, this.tpType, this.paType,
      this.bmType, this.siType, this.wsType, this.myType, (gId != 1 ? this.fmType : this.gbType)].forEach((e, i) => {
        if (e != '') {
          this.vatTtlCnt += (e == 'Vata' ? (i == 0 ? +this.agScre : (i == 1 ? +this.btScre : (i == 2 ? +this.wtScre : (i == 3 ? +this.scScre :
            (i == 4 ? +this.stScre : (i == 5 ? +this.htScre : (i == 6 ? +this.spScre : (i == 7 ? +this.apScre : (i == 8 ? +this.tpScre :
              (i == 9 ? +this.paScre : (i == 10 ? +this.bmScre : (i == 11 ? +this.siScre : (i == 12 ? +this.wsScre : (i == 13 ? +this.myScre :
                (gId != 1 ? +this.fmScre : +this.gbScre))))))))))))))) : 0);  // (i==14 ? +this.fmScre : +this.gbScre)
          this.pitTtlCnt += (e == 'Pitta' ? (i == 0 ? +this.agScre : (i == 1 ? +this.btScre : (i == 2 ? +this.wtScre : (i == 3 ? +this.scScre :
            (i == 4 ? +this.stScre : (i == 5 ? +this.htScre : (i == 6 ? +this.spScre : (i == 7 ? +this.apScre : (i == 8 ? +this.tpScre :
              (i == 9 ? +this.paScre : (i == 10 ? +this.bmScre : (i == 11 ? +this.siScre : (i == 12 ? +this.wsScre : (i == 13 ? +this.myScre :
                (gId != 1 ? +this.fmScre : +this.gbScre))))))))))))))) : 0);  // (i==14 ? +this.fmScre : +this.gbScre)
          this.kafTtlCnt += (e == 'Kapha' ? (i == 0 ? +this.agScre : (i == 1 ? +this.btScre : (i == 2 ? +this.wtScre : (i == 3 ? +this.scScre :
            (i == 4 ? +this.stScre : (i == 5 ? +this.htScre : (i == 6 ? +this.spScre : (i == 7 ? +this.apScre : (i == 8 ? +this.tpScre :
              (i == 9 ? +this.paScre : (i == 10 ? +this.bmScre : (i == 11 ? +this.siScre : (i == 12 ? +this.wsScre : (i == 13 ? +this.myScre :
                (gId != 1 ? +this.fmScre : +this.gbScre))))))))))))))) : 0);  // (i==14 ? +this.fmScre : +this.gbScre)
        }
      });
      this.vatTS = `${this.vatTxt}${this.vatTtlCnt}`, this.pitTS = `${this.pitTxt}${this.pitTtlCnt}`, this.kafTS = `${this.kafTxt}${this.kafTtlCnt}`,
        this.prkrtTxt = `${this.vatTxt}${this.vatTtlCnt}, ${this.pitTxt}${this.pitTtlCnt}, ${this.kafTxt}${this.kafTtlCnt}`;
    }
  }

  setGenAssVals(lstGA: any[]) {
    this.genAsmnt = new GeneralAssmnt(), this.lstGenAsmnt = lstGA, this.lstGAHdrs.forEach((e, i) => {
      const gaList = {
        /* '0': () => this.genAsmnt.pulse_nadi = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
        '1': () => this.genAsmnt.stool_mala = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
        '2': () => this.genAsmnt.urination_mutra = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
        '3': () => this.genAsmnt.tongue_jihva = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
        '4': () => this.genAsmnt.appetite_agni = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [], */
        '0': () => this.genAsmnt.stool_mala = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
        '1': () => this.genAsmnt.urination_mutra = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
        '2': () => this.genAsmnt.tongue_jihva = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
        '3': () => this.genAsmnt.appetite_agni = lstGA.filter(f => f.name == e).length > 0 ? lstGA.filter(f => f.name == e)[0].assesment_lookup_values : [],
      }
      gaList[i]();
    });
  }

  setRogPrkshaVals(lstRP: any[]) {
    this.rogPrksh = new RogaPariksha(), this.lstRogPrksh = lstRP, this.lstRPHdrs.forEach((e, i) => {
      const rpList = {
        '0': () => this.rogPrksh.prakruti = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '1': () => this.rogPrksh.dosha = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '2': () => this.rogPrksh.anubandha_dosha = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '3': () => this.rogPrksh.dooshya = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '4': () => this.rogPrksh.bala = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '5': () => this.rogPrksh.desha = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '6': () => this.rogPrksh.kala = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '7': () => this.rogPrksh.analam = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '8': () => this.rogPrksh.vaya = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '9': () => this.rogPrksh.satwa = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '10': () => this.rogPrksh.aahara = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '11': () => this.rogPrksh.aahara_shakti = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
        '12': () => this.rogPrksh.roga_dis_avastha = lstRP.filter(f => f.name == e).length > 0 ? lstRP.filter(f => f.name == e)[0].roga_pariksha_lookup_values : [],
      }
      rpList[i]();
    });
  }

  onSvcChng(val: any) {
    this.lstSvcPrcdr = [], this.pocAyuForm.patchValue({ pancha_karma: { procedure: '' } });
    if (val != '') {
      // this.registrationService.getAll(`ayushservicesubgroup/getbyid?id=${val}`).toPromise().
      this.registrationService.getAyushServices(val).toPromise().
        then((res: any) => { this.lstSvcPrcdr = res.data.ayush_services; });
    }
  }

  onDrugTypeChange(type: string, item: any) {
    this.lstDrgs = [];
    if (item && type == 's')
      this.lstDrgs = this.lstDrgType.filter(f => f.id == item.id)[0].drugs;
    // else if (item && type == 'd')
    //   this.lstDrgs = this.lstDrgType.filter(f => f.id == item.id)[0].drugs;

  }

  isAddPK() {
    this.lstPnchKrma.push({
      svcId: this.pocAyuForm.value.pancha_karma.services, 
      prcdId: this.pocAyuForm.value.pancha_karma.procedure,
      svcName: this.lstSVCs.filter(f => f.id == this.pocAyuForm.value.pancha_karma.services)[0].name,
      prcdName: this.lstSvcPrcdr.filter(f => f.id == this.pocAyuForm.value.pancha_karma.procedure)[0].name,
      rmrk: this.pocAyuForm.value.pancha_karma.remarks, isDel: false
    }), this.lstPnchKrma = this.lstPnchKrma.filter(f => f.isDel == false), this.lstSvcPrcdr = [],
      this.pocAyuForm.patchValue({ pancha_karma: { services: '', procedure: '', remarks: '' } });
  }

  isDelPK(indx: number) {
    this.lstPnchKrma[indx].isDel = true, this.lstPnchKrma = this.lstPnchKrma.filter(f => f.isDel == false);
  }

  othrDrgChng() {
    this.pocAyuForm.patchValue({ drugs: { drugType: '', drugName: '', otherDrug: '' } }), this.lstDrgs = [];
  }

  onDrugAdd() {
    if (this.pocAyuForm.value.drugs.otherDrug == '') {
      if (this.pocAyuForm.value.drugs.drugType == '' || this.pocAyuForm.value.drugs.drugName == '') {
        this.toastr.warning('Please choose drugs');
        return;
      }
      if (this.lstDrgsData.filter(f => f.drgId == this.pocAyuForm.value.drugs.drugName).length > 0) {
        this.toastr.info('Drug already exist');
        this.pocAyuForm.patchValue({ drugs: { drugType: '', drugName: '', otherDrug: '' } }), this.lstDrgs = [];
        return;
      }
    }
    else {
      if (this.lstDrgsData.filter(f => f.drgTxt == this.pocAyuForm.value.drugs.otherDrug.toString().trim()).length > 0) {
        this.toastr.info('Drug already exist');
        this.pocAyuForm.patchValue({ drugs: { drugType: '', drugName: '', otherDrug: '' } }), this.lstDrgs = [];
        return;
      }
    }
    let freq = JSON.parse(sessionStorage.getItem('freqLST'));
    if (this.pocAyuForm.value.drugs.drugType != '' && this.pocAyuForm.value.drugs.drugName != '')
      this.lstDrgsData.push({
        dtId: this.pocAyuForm.value.drugs.drugType[0].id, drgId: this.pocAyuForm.value.drugs.drugName[0].id, durLst: [],
        dtTxt: this.lstDrgType.filter(f => f.id == this.pocAyuForm.value.drugs.drugType[0].id)[0].drug_type, durLstNM: [],
        drgTxt: this.lstDrgs.filter(f => f.id == this.pocAyuForm.value.drugs.drugName[0].id)[0].drug_name, freqLst: freq,
        otherDrug: this.pocAyuForm.value.drugs.otherDrug, duration: '', dosage: '', relfood: '', anupaan: '', rmrk: '', days: '', isDel: false,
      });
    if (this.pocAyuForm.value.drugs.otherDrug != '')
      this.lstDrgsData.push({
        dtId: this.lstDrgType.filter(f => f.drug_type == 'OTHERS')[0]?.id.toString() ?? '', drgId: '0', dtTxt: 'OTHERS',
        drgTxt: this.pocAyuForm.value.drugs.otherDrug.toString().trim(), otherDrug: this.pocAyuForm.value.drugs.otherDrug.toString().trim(),
        freqLst: freq, durLst: [], durLstNM: [], duration: '', dosage: '', relfood: '', anupaan: '', rmrk: '', days: '', isDel: false,
      });
    this.pocAyuForm.patchValue({ drugs: { drugType: '', drugName: '', otherDrug: '' } }), this.lstDrgs = [];
  }

  onDrugDel(indx: number) {
    this.lstDrgsData[indx].isDel = true, this.lstDrgsData = this.lstDrgsData.filter(f => f.isDel == false);
  }

  onChngCC(isChk: boolean, indx: number) {
    let cbData = this.chiefComplaintList[indx];
    if (this.lstCCSlctd.filter(f => f.id == cbData.id).length == 0)
      this.lstCCSlctd.push({ id: cbData.id, chief_complaint: cbData.chief_complaint, isChk: isChk });
    else
      this.lstCCSlctd.filter(f => f.id == cbData.id)[0].isChk = isChk;
  }

  onChngPH(isChk: boolean, val: string) {
    // if (this.lstPstHstry.filter(f => f.disease == val).length == 0)
    //   this.lstPstHstry.push({ disease: val, isChk: isChk });
    // else
    //   this.lstPstHstry.filter(f => f.disease == val)[0].isChk = isChk;
    if(isChk==true){
      let [value, data] = [this.pocAyuForm.value.other_history.history, (this.pocAyuForm.value.other_history.history).toUpperCase().split(',')];
      if (!data.includes(val.toUpperCase()))
        this.pocAyuForm.patchValue({ other_history: { history: value == '' ? val : (value + ',' + val).replace(/^,/, '') } });
    }else{
      // let [value, data] = [this.pocAyuForm.value.other_history.history, (this.pocAyuForm.value.other_history.history).toUpperCase().split(',')];
      // if (!data.includes(val.toUpperCase()))
      //   this.pocAyuForm.patchValue({ other_history: { history: value == '' ? val : (value + ',' + '').replace(/^,/, '') } });
      // this.pocAyuForm.value.other_history.history;
      // if(this.pocAyuForm.value.other_history.history.includes(',')){
      // var strArray = this.pocAyuForm.value.other_history.history.split(',');
      // }else{
      //   var strArray = this.pocAyuForm.value.other_history.history;
      // }
      //       for (var i = 0; i < strArray.length; i++) {
      //           if (strArray[i] === val) {
      //               strArray.splice(i, 1);
      //               this.pocAyuForm.patchValue({ other_history: { history: strArray }});
      //           }
                
      //       }
      //       strArray;
            //this.pocAyuForm.patchValue({ other_history: { history: strArray }});
    }
    
  }

  onDurItemSlct(item: any) {

  }

  onDurItemDeSlct(item: any) {

  }

  onDurMCSlct(type: string, indx: number, item: any) {
    /* this.lstDurtn = [];
    if (type == 's')
      this.lstDurtn.push(item.frequency)
    else
      this.lstDurtn = this.lstDurtn.filter(f => f != item.frequency);
    this.lstDrgsData[indx].durLst = this.lstDurtn; console.log(this.lstDurtn), console.log(this.lstDrgsData[indx].durLstNM) */
    if (type == 's')
      this.lstDrgsData[indx].durLst.push(item.frequency)
    else
      this.lstDrgsData[indx].durLst = this.lstDrgsData[indx].durLst.filter(f => f != item.frequency);
  }

  onDurChng(val: string, indx: number, isChkd: boolean) {
    this.lstDrgsData[indx].freqLst.filter(f => f.frequency == val)[0].isChk = isChkd, this.lstDrgsData[indx].duration = '';
    this.lstDrgsData[indx].freqLst.forEach(e => {
      if (e.isChk)
        this.lstDrgsData[indx].duration += this.lstDrgsData[indx].duration == '' ? e.frequency : `,${e.frequency}`;
    });
  }

  onCCRmrksChng(val: string, indx: number) {
    this.ayurvedPOC.cc_brief_history.ccRmrks.filter(f => f.index == indx)[0].value = val;
  }

  onDrgRmrk(val: string, indx: number) {
    this.lstDrgsData[indx].rmrk = val;
  }

  onChng(type: string, val: any, indx: number) {
    if (type == 'D')
      this.lstDrgsData[indx].dosage = val;
    else if (type == 'anupaan')
      this.lstDrgsData[indx].anupaan = val;
    else
      this.lstDrgsData[indx].relfood = val;
  }

  setFreqVals(freqLst: any[]) {
    let newFreq = [];
    //freqLst.filter(f => f.id < 6)
    this.lstFreq = freqLst, this.lstDrtn.forEach((e, i) => { this.lstFreq[i].frequency = this.lstDrtn[i]; });
    newFreq = this.lstFreq, newFreq.forEach(e => { Object.assign(e, { isChk: false }); }), sessionStorage.setItem('freqLST', JSON.stringify(newFreq));
  }

  setNewFreqVals(freqLst: any[]) {
    freqLst.forEach(e => { Object.assign(e, { isChk: false }); }), sessionStorage.setItem('freqLST', JSON.stringify(freqLst)), this.lstFreq = freqLst;
  }

  onPreview(event: any) {
    event.preventDefault();
    // this.getFormValidationErrors();
    this.submitted = true, this.tableRowCheckValidations();
    if (this.pocAyuForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    // else if(this.getRowFormControls.controls.length == 0) {
    //   this.toastr.info('Please Add Medications');
    //   return;
    // }
    else {
      if (this.getRowFormControls.controls.length == 0)
        this.toastr.warning('Medicines are not entered');

      if (this.pocAyuForm.value.drugs.instruction != '')
        this.getRowFormControls.value.forEach((e, i) => {
          this.getRowFormControls.at(i).patchValue({ instruction: this.pocAyuForm.value.drugs.instruction });
          // e.instruction = this.pocAyuForm.value.drugs.instruction;
        });

      this.pocCc = this.pocVitals = this.pocHistory = this.pocPd = this.pocIn = this.pocAdvice = '';
      let f = this.pocAyuForm.value, cc;

      if (f.cc_brief_history.chiefComplaints != '')
        f.cc_brief_history.chiefComplaints.forEach((e, i) => {
          cc = (i == 0) ? e.chief_complaint : (cc + ' ,' + e.chief_complaint);
        });
      this.pocCc = cc + (f.cc_brief_history.otherComplaints != '' ? (', ' + f.cc_brief_history.otherComplaints) : '') + (f.cc_brief_history.presentIllness != '' ? (', ' + f.cc_brief_history.presentIllness) : '');

      this.pocVitals = (f.vital_history.temperature != '' ? (('Temperature: ') + f.vital_history.temperature) : '') + (f.vital_history.pulse != '' ? (';' + ('Pulse: ') + f.vital_history.pulse) : '')
        + (f.vital_history.systolic != '' ? (';' + ('Systolic: ') + f.vital_history.systolic) : '') + (f.vital_history.diastolic != '' ? (';' + ('Diastolic: ') + f.vital_history.diastolic) : '');

      this.pocHistory = (f.other_history.history != '' ? (', ' + f.other_history.history) : '') + (f.allergy.allergies != '' ? (', ' + f.allergy.allergies) : '');

      this.pocAdvice = f.advice.restExercise + (f.advice.diet != '' ? (', ' + f.advice.diet) : '') + (f.advice.followAdvice != '' ? (', ' + f.advice.followAdvice) : '') + (f.advice.referral != '' ? (', ' + f.advice.referral) : '');

      this.pocVitals = this.pocVitals.replace(/^,/, '').replace(/^;/, '');
      this.pocHistory = this.pocHistory.replace(/^,/, '').replace(/^;/, '');
      // this.pocPd = this.pocPd.replace(/^,/, '').replace(/^;/, '');
      // this.pocIn = this.pocIn.replace(/^,/, '').replace(/^;/, '');
      this.pocAdvice = this.pocAdvice.replace(/^,/, '').replace(/^;/, '');
      document.getElementById('btnPreviewModal').click();
    }
  }

  leftMenu() {
    $(document).ready(function () {
      const btnOffsetTop = $('.videoposition')[0].offsetTop;
      const btnHeight = $('.videoposition')[0].scrollHeight;

      $(window).scroll(function () {
        if (btnOffsetTop + btnHeight <= window.pageYOffset) {
          // $('.videoposition').addClass('videofixed');
        } else {
          // $('.videoposition').removeClass('videofixed');
        }
      });
    });

    $(() => {
      $('#opener').on('click', function () {
        var panel = $('#slide-panel');
        if (panel.hasClass("visible"))
          panel.removeClass('visible').animate({ 'margin-left': '-253px', 'cursor': 'pointer' });
        else
          panel.addClass('visible').animate({ 'margin-left': '-25px' });
        return false;
      });
    });
  }

  changeMenu() {
    this.homeComponent.isDoctor = true;	
    const value ={	
     screen_id:72,	
     module_id:6	
     }	
     if (this.roleId == '7') {
      this.router.navigate(['/home/consultation/cn']).then(r=>{	
        setTimeout(() => {
          this.homeComponent.sidemenu(value,'another')	
        }, 100);	
        stopVideo();	
        stopVideoayush();
       })	
    }else{
      this.router.navigate(['/home/consultation/consultHW']).then(r=>{	
        setTimeout(() => {
          this.homeComponent.sidemenu(value,'another')	
        }, 100);	
        stopVideo();	
        stopVideoayush();
       })	
    }
  }

  onCCButtonSelect(val: String) {
    let data = this.chiefComplaintList.find(f => f.chief_complaint == val);

    if (this.selectedList.filter(f => f.id == data.id).length == 0)
      this.selectedList.push(data);
    else
      this.selectedList.forEach((x, i) => { if (x.id == data.id) this.selectedList.splice(i, 1); });

    this.selectedList = [...this.selectedList];
    this.pocAyuForm.patchValue({ cc_brief_history: { chiefComplaints: this.selectedList } });
  }

  onItemDeSelect(item: any) {
    let data = this.selectedList;
    data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
    this.selectedList = [...data];
  }

  onItemSelect(item: any) {
    if (this.selectedList.filter(f => f.id == item.id).length == 0)
      this.selectedList.push(item);

    this.selectedList = [...this.selectedList];
  }

  onDrgSlct(ctrl: string, type: string, item: any) {
    if (ctrl == 'dt') {
      if (type == 's') {
        let data = this.lstSlctdDT;
        data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
        this.lstSlctdDT = [...data];
      }
      else {
        if (this.lstSlctdDT.filter(f => f.id == item.id).length == 0)
          this.lstSlctdDT.push(item);
        this.lstSlctdDT = [...this.lstSlctdDT];
      }
    }
    else {

    }
  }

  onPHButtonSelect(txt: String) {
    let [value, data] = [this.pocAyuForm.value.other_history.history, (this.pocAyuForm.value.other_history.history).toUpperCase().split(',')];
    if (!data.includes(txt.toUpperCase()))
      this.pocAyuForm.patchValue({ other_history: { history: value == '' ? txt : (value + ',' + txt).replace(/^,/, '') } });
  }

  onRowDDChange(index: any, value: any) {
    this.getRowFormControls.at(index).patchValue({ count: '' });
    if (value != '')
      this.getRowFormControls.at(index).get('count').enable();
    else
      this.getRowFormControls.at(index).get('count').disable();
  }

  tableRowCheckValidations() {
    (this.getRowFormControls.value).forEach((e, i) => {
      if (e.mrng == false && e.aftr == false && e.evng == false)
        this.getRowFormControls.at(i).get('mrng').setErrors({ 'invalid': true });
    });
    // console.log(this.getRowFormControls.value), console.log(this.getRowFormControls.controls);
  }

  onCheckbox(index) {
    let c = this.getRowFormControls.at(index);
    if (this.submitted && (c.get('mrng').value || c.get('aftr').value || c.get('evng').value))
      c.get('mrng').setErrors(null);
  }

  ValidateDrugs(): boolean {
    let isMand = true;
    if (this.roleId == '7') {
      if(this.enableConsultationTypeInPOC){
        let type = this.pocAyuForm.value.type_of_consultation;
        if(type==''){
         this.toastr.info('Please Provide Type of Consultation');
         return;
        }
        }
      for (let i = 0; i < this.lstDrgsData.length; i++) {
        if ((this.lstDrgsData[i].dosage == '' || this.lstDrgsData[i].relfood == '' || this.lstDrgsData[i].anupaan == '' ) && isMand == true)
          isMand = false;
      }; 
      if (isMand)
        document.getElementById('presCnfm').click();
      else
        this.toastr.warning('Please select drug fields');
    }
    return isMand;
    
  }

  bindPayload() {
    if (this.ValidateDrugs()) {
      let freq = [], frqlst = [], gId = this.gdrId, dtTime = this.datePipe.transform(this.minDate, 'yyyy-MM-dd HH:mm:ss'),
        pkHdrs = this.lstPrkCnstHdrs.filter(f => f != (gId == 1 ? 'If Female, Menstrual Cycle' : 'If Male, Growth of Beard')),
        dt = this.datePipe.transform(new Date(), 'HH:mm:ss'), ordrNo = (<HTMLInputElement>document.getElementById('order_no')).value;
      // Prakruti
      this.ayurvedPOC.prakruti_constitution = [], pkHdrs.forEach((e, i) => {
        let plv: IPrakLookUpsNew[] = [], data = this.prakConstList.filter(f => f.name == e)[0],
          val = (i == 0 ? this.agName : (i == 1 ? this.btName : (i == 2 ? this.wtName : (i == 3 ? this.scName : (i == 4 ? this.stName :
            (i == 5 ? this.htName : (i == 6 ? this.spName : (i == 7 ? this.apName : (i == 8 ? this.tpName : (i == 9 ? this.paName : (i == 10 ? this.bmName :
              (i == 11 ? this.siName : (i == 12 ? this.wsName : (i == 13 ? this.myName : (gId != 1 ? this.fmName : this.gbName)))))))))))))));
        if (val != '') {
          plv.push({
            score: (i == 0 ? +this.agScre : (i == 1 ? +this.btScre : (i == 2 ? +this.wtScre : (i == 3 ? +this.scScre : (i == 4 ? +this.stScre :
              (i == 5 ? +this.htScre : (i == 6 ? +this.spScre : (i == 7 ? +this.apScre : (i == 8 ? +this.tpScre : (i == 9 ? +this.paScre :
                (i == 10 ? +this.bmScre : (i == 11 ? +this.siScre : (i == 12 ? +this.wsScre : (i == 13 ? +this.myScre :
                  (gId != 1 ? +this.fmScre : +this.gbScre))))))))))))))), // (i==14 ? +this.fmScre : +this.gbScre)
            attribute_name: (i == 0 ? this.agName : (i == 1 ? this.btName : (i == 2 ? this.wtName : (i == 3 ? this.scName : (i == 4 ? this.stName :
              (i == 5 ? this.htName : (i == 6 ? this.spName : (i == 7 ? this.apName : (i == 8 ? this.tpName : (i == 9 ? this.paName : (i == 10 ? this.bmName :
                (i == 11 ? this.siName : (i == 12 ? this.wsName : (i == 13 ? this.myName : (gId != 1 ? this.fmName : this.gbName))))))))))))))), // (i==14 ? this.fmName : this.gbName)
            score_type: (i == 0 ? this.agType : (i == 1 ? this.btType : (i == 2 ? this.wtType : (i == 3 ? this.scType : (i == 4 ? this.stType :
              (i == 5 ? this.htType : (i == 6 ? this.spType : (i == 7 ? this.apType : (i == 8 ? this.tpType : (i == 9 ? this.paType : (i == 10 ? this.bmType :
                (i == 11 ? this.siType : (i == 12 ? this.wsType : (i == 13 ? this.myType : (gId != 1 ? this.fmType : this.gbType))))))))))))))), // (i==14 ? this.fmType : this.gbType)
          });
          this.ayurvedPOC.prakruti_constitution.push({ id: data.id, name: data.name, prakruti_lookup_values: plv });
        }
      });
      // Chief Complaints && History of Past Illness
      this.ayurvedPOC.cc_brief_history.chiefComplaints = [],
        // this.ayurvedPOC.cc_brief_history.chiefComplaints = this.lstCCSlctd.filter(f => f.isChk == true),
        this.ayurvedPOC.cc_brief_history.otherComplaints = this.pocAyuForm.value.cc_brief_history.otherComplaints,
        this.ayurvedPOC.cc_brief_history.doctorComments = this.pocAyuForm.value.cc_brief_history.dctrComplaints,
        this.ayurvedPOC.cc_brief_history.presentIllness = this.pocAyuForm.value.cc_brief_history.presentIllness,
        this.ayurvedPOC.cc_brief_history.notes1 = this.pocAyuForm.value.cc_brief_history.notes1,
        this.ayurvedPOC.cc_brief_history.notes2 = this.pocAyuForm.value.cc_brief_history.notes2;
      // Vitals
      this.ayurvedPOC.vital_history.temperature = this.pocAyuForm.get('vital_history.temperature').value;
      this.ayurvedPOC.vital_history.pulse = this.pocAyuForm.get('vital_history.pulse').value;
      this.ayurvedPOC.vital_history.systolic = this.pocAyuForm.get('vital_history.systolic').value;
      this.ayurvedPOC.vital_history.diastolic = this.pocAyuForm.get('vital_history.diastolic').value;
      // Past History
      this.ayurvedPOC.other_history.history = this.pocAyuForm.value.other_history.history,
        this.ayurvedPOC.allergy.allergies = this.pocAyuForm.value.other_history.allergies; // this.lstPstHstry --> missed
      let cc = '';
      if (this.lstPstHstry.length != 0)
        this.lstPstHstry.forEach((e, i) => { if (e.isChk) cc = (i == 0) ? e.disease : (cc + ',' + e.disease); });
      this.ayurvedPOC.other_history.past_history = cc;
      // General Assessment
      let GA = null;
      if (sessionStorage.getItem('gaLST') == null)
        sessionStorage.setItem('gaLST', JSON.stringify(this.lstGenAsmnt)), GA = this.lstGenAsmnt;
      else
        GA = JSON.parse(sessionStorage.getItem('gaLST'));
      this.ayurvedPOC.general_assessment = [], this.lstGAHdrs.forEach((e, i) => {
        let data = GA.filter(f => f.name == e)[0], lookUps = data?.assesment_lookup_values;
        data.assesment_lookup_values = [];
        if (i == 0 && this.pocAyuForm.value.general_assessment.stool_mala != '')
          data.assesment_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.general_assessment.stool_mala)[0]);
        else if (i == 1 && this.pocAyuForm.value.general_assessment.urination_mutra != '')
          data.assesment_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.general_assessment.urination_mutra)[0]);
        else if (i == 2 && this.pocAyuForm.value.general_assessment.tongue_jihva != '')
          data.assesment_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.general_assessment.tongue_jihva)[0]);
        else if (this.pocAyuForm.value.general_assessment.appetite_agni != '')
          data.assesment_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.general_assessment.appetite_agni)[0]);
        this.ayurvedPOC.general_assessment.push(data);
      });
      // Roga Pariksha
      let RP = null;
      if (sessionStorage.getItem('rpLST') == null) {
        sessionStorage.setItem('rpLST', JSON.stringify(this.lstRogPrksh)), RP = this.lstRogPrksh;
      }
      else
        RP = JSON.parse(sessionStorage.getItem('rpLST'));
      this.ayurvedPOC.roga_pariksha = [], this.lstRPHdrs.forEach((e, i) => {
        let data = RP.filter(f => f.name == e)[0], lookUps = data.roga_pariksha_lookup_values;
        data.roga_pariksha_lookup_values = []; //, data.roga_pariksha_lookup_values.push(lookUps.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.prakruti)[0]);
        if (i == 1 && this.pocAyuForm.value.roga_pariksha.dosha != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.dosha)[0]);
        else if (i == 2 && this.pocAyuForm.value.roga_pariksha.anubandha_dosha != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.anubandha_dosha)[0]);
        else if (i == 3)
          data.roga_pariksha_lookup_values = this.selectedList; // data.roga_pariksha_lookup_values.push(lookUps.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.dooshya)[0]);
        else if (i == 4 && this.pocAyuForm.value.roga_pariksha.bala != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.bala)[0]);
        else if (i == 5 && this.pocAyuForm.value.roga_pariksha.desha != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.desha)[0]);
        else if (i == 6 && this.pocAyuForm.value.roga_pariksha.kala != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.kala)[0]);
        else if (i == 7 && this.pocAyuForm.value.roga_pariksha.analam != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.analam)[0]);
        else if (i == 8 && this.pocAyuForm.value.roga_pariksha.vaya != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.vaya)[0]);
        else if (i == 9 && this.pocAyuForm.value.roga_pariksha.satwa != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.satwa)[0]);
        else if (i == 10 && this.pocAyuForm.value.roga_pariksha.aahara != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.aahara)[0]);
        else if (i == 11 && this.pocAyuForm.value.roga_pariksha.aahara_shakti != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.aahara_shakti)[0]);
        else if (this.pocAyuForm.value.roga_pariksha.roga_dis_avastha != '')
          data.roga_pariksha_lookup_values.push(lookUps?.filter(f => f.id == this.pocAyuForm.value.roga_pariksha.roga_dis_avastha)[0]);
        this.ayurvedPOC.roga_pariksha.push(data);
      });
      // Diagnose
      this.ayurvedPOC.diagnosis.provisionalDiagnosis = this.pocAyuForm.value.diagnosis.provisionalDiagnosis;

      this.ayurvedPOC.covid19 = this.pocAyuForm.value.covid19;


      // Panchakarma
      this.ayurvedPOC.investigation.master = [], this.lstPnchKrma.forEach((e, i) => {
        this.ayurvedPOC.investigation.master.push({
          id: 1, servicegroup: 'PANCHAKARMA', servicesubgroup: {
            id: e.svcId, name: e.svcName, services: { id: e.prcdId, name: e.prcdName }, remarks: e.rmrk
          }
        });
      });
      // Advice
      this.ayurvedPOC.advice.diet = this.pocAyuForm.value.advice.diet, this.ayurvedPOC.advice.restExercise = this.pocAyuForm.value.advice.rest_exercise,
        this.ayurvedPOC.advice.followAdvice = this.pocAyuForm.value.advice.follow_advice, this.ayurvedPOC.advice.referral = this.pocAyuForm.value.advice.referral;
        this.ayurvedPOC.advice.is_self= this.pocAyuForm.value.advice.is_self,
        this.ayurvedPOC.advice.followupDate= this.datePipe.transform(this.pocAyuForm.value.follow_up_date, 'yyyy-MM-dd HH:MM:SS');

      this.ayurvedPOC.prescribed_medications.drugs = [], this.lstDrgsData.forEach((e, i) => {
        this.ayurvedPOC.prescribed_medications.drugs.push({
          id: e.dtId, drugType: e.dtTxt, drugName: { id: e.drgId, name: e.drgTxt },
          dosage: { id: e.dosage, name: this.lstDosge.filter(f => f.id == e.dosage)[0].dosage }, remarks: e.rmrk, days: e.days,
          direction: { id: e.relfood, name: this.lstDrgDrctn.filter(f => f.id == e.relfood)[0].direction },
          anupaan: { id: e.anupaan, name: this.ayushAnupaanList.filter(f => f.id == e.anupaan)[0].anupaan_name },
          frequency: e.durLst.length > 0 ? e.durLst.toString() : '',
        });
      }), this.ayurvedPOC.prescribed_medications.instruction = this.pocAyuForm.value.drugs.instruction;
      // Others
      this.ayurvedPOC.vata_score = this.vatTtlCnt, this.ayurvedPOC.pita_score = this.pitTtlCnt, this.ayurvedPOC.kafa_score = this.kafTtlCnt,
        // this.ayurvedPOC.visit_no = this.benHistory != null && this.ayurvedPOC.id == 0 ? this.benHistory.length + 1 : 1,
        this.ayurvedPOC.visit_no = this.consType == 'New' ? 0 : (this.benHistory?.length > 0 && this.ayurvedPOC.id == 0 ? this.benHistory?.length + 1 : 1),
        this.ayurvedPOC.referred_visit_id = '2', this.ayurvedPOC.beneficiary_visit_logs = [], this.ayurvedPOC.beneficiary_id = `${this.benId}`,
        this.ayurvedPOC.call_type_id = this.isOffLinePOC() ? '13' : '1', this.ayurvedPOC.visit_date = dtTime, this.ayurvedPOC.visit_start_time = dtTime.split(' ')[1], // this.docId = this.docData?.id,
        this.ayurvedPOC.doctor_id = this.roleId == '9' ? JSON.parse(sessionStorage.getItem('hwDetails')).doctor_health_worker[0]?.doctor_registration?.id  : this.docData?.id, this.ayurvedPOC.visit_end_time = dt,
        this.ayurvedPOC.patient_interaction_id = +(<HTMLInputElement>document.getElementById('patient_interaction_id')).value,
        this.ayurvedPOC.order_no = (ordrNo == '' || ordrNo == null) ? '0' : ordrNo, this.ayurvedPOC.other_history.consultationType = this.consType,
        this.ayurvedPOC.health_worker_observation = this.pocAyuForm.value.health_worker_observation,
        this.ayurvedPOC.physical_consultation_required = this.pocAyuForm.value.physical_consultation_required,
        this.ayurvedPOC.type_of_consultation=this.pocAyuForm.value.type_of_consultation
        this.ayurvedPOC.health_worker_id = (this.roleId == '9') ? JSON.parse(sessionStorage.getItem('webSkt')).fromId.replace('hw_', '') : this.hwId;
      if (this.pocAyuForm.value.follow_up_date != '' && this.pocAyuForm.value.follow_up_date != null)
        this.ayurvedPOC.follow_up_date = this.datePipe.transform(this.pocAyuForm.value.follow_up_date, 'yyyy-MM-dd HH:MM:SS');
    }
    else
      this.toastr.warning('Please select drug fields');
  }

  // changeMenu() {	
  //   this.homeComponent.isDoctor = true;	
  //  const value ={	
  //   screen_id:72,	
  //   module_id:6	
  //   }	
  //  this.router.navigate(['/home/consultation/cn']).then(r=>{	
  //   setTimeout(() => {	
  //     this.homeComponent.sidemenu(value,'another')	
  //   }, 100);	
  //   stopVideo();	
  //  })	
  	
 	
  // }

  onSave() {
    let ordrNo: string = (<HTMLInputElement>document.getElementById('order_no')).value || sessionStorage.orderNo;
    this.ayurvedPOC.id = (this.roleId == '9' ? 0 : ((this.roleId == '7' && (this.hwId == 0 || this.prkIntStsId == 10)) ? 0 : this.idPOC)),
      this.ayurvedPOC.user_type_id = +this.roleId, this.ayurvedPOC.visit_end_time = this.datePipe.transform((new Date()), 'HH:mm:ss'), // , this.ayurvedPOC.id = (this.consType == 'New' ? 0 : this.idPOC),
      this.isPocSave = false, this.ayurvedPOC.dependent_id = this.is_self == false ? this.dependentId : null,
      this.ayurvedPOC.guardian_id = (this.benAge < 18) ? (this.benData.guardians?.length > 0 ? this.benData.guardians[0]?.id : null) : null,
      this.ayurvedPOC.consultation_fee_required = this.paySumForm.value.consultation_fee_required, this.ayurvedPOC.medicine_billable =
      this.paySumForm.value.medicine_billable, this.ayurvedPOC.is_epaper = this.pocAyuForm.get('is_epaper').value, this.isSaveDsbld = true,
      this.ayurvedPOC.order_no =(ordrNo == '' || ordrNo == '0' || ordrNo == null || ordrNo == undefined) ? null : ordrNo,
      this.ayurvedPOC.visit_date = this.datePipe.transform((new Date()), 'yyyy-MM-dd ') + this.startTime, this.ayurvedPOC.visit_start_time = this.startTime;
    if (this.ayurvedPOC.is_epaper)
      this.ayurvedPOC.patient_interaction_id = +this.scktIntrctnStsId;
      // const sendData = JSON.parse(JSON.stringify(this.pocForm.getRawValue()));
      if (sessionStorage.getItem('call_type') == 'Audio') {
        this.ayurvedPOC.call_type_id = '9';
      }
      else if (this.isOffLinePOC() == true) {
        this.ayurvedPOC.call_type_id = '13';

      } else {
        this.ayurvedPOC.call_type_id  = '1';

      }
      
    //Covid-19 form
    const covidForm: any = JSON.parse(JSON.stringify(this.pocAyuForm.getRawValue().covid19));
    const presenting_complaints: Array<any> = covidForm.presenting_complaints;
    presenting_complaints.forEach(e => {
      if (e.check_box && e.day_of_consultation.includes('T'))
        e.day_of_consultation = this.datePipe.transform(e.day_of_consultation, 'dd-MM-yyyy');
    });

    if (covidForm.patient_identified.investigations_date.includes('T'))
      covidForm.patient_identified.investigations_date = this.datePipe.transform(covidForm.patient_identified.investigations_date, 'dd-MM-yyyy');
    if (covidForm.patient_identified.reason_date.includes('T'))
      covidForm.patient_identified.reason_date = this.datePipe.transform(covidForm.patient_identified.reason_date, 'dd-MM-yyyy');
    if (covidForm.clinical_evalution_of_the_patient.vaccination_date.includes('T'))
      covidForm.clinical_evalution_of_the_patient.vaccination_date = this.datePipe.transform(covidForm.clinical_evalution_of_the_patient.vaccination_date, 'dd-MM-yyyy');

    const pocSaveData: any = this.ayurvedPOC;
    if( this.pocAyuForm.get('is_epaper').value){
      pocSaveData.covid19={};
      pocSaveData.investigation={};
      pocSaveData.prescribed_medications=[];
    } else{
      pocSaveData.covid19 = covidForm;
      pocSaveData.covid19.presenting_complaints = presenting_complaints;
    }
 
    Object.assign(pocSaveData, { home_delivery: this.paySumForm.value.home_delivery });

    if (!this.is_self) {
      pocSaveData.advice.family_member = this.is_self ? null : this.family_member;
      pocSaveData.advice.family_member.first_name = this.memberData.member_name;
      pocSaveData.advice.family_member.age = this.memberData.age;
      pocSaveData.advice.family_member.gender_id = this.memberData.gender_id;
      pocSaveData.advice.family_member.member_mobile = this.memberData.mobile;
      pocSaveData.advice.family_member.id = this.memberData.id;
      pocSaveData['dependent_id'] = this.memberData.id;
    }
    else {
      pocSaveData.advice.family_member = this.family_member;
      let memData = this.memberData !== null ? this.memberData : null
      if (memData !== null && memData !== undefined) {
        pocSaveData.advice.family_member.first_name = this.memberData.member_name;
        pocSaveData.advice.family_member.age = this.memberData.age;
        pocSaveData.advice.family_member.gender_id = this.memberData.gender_id;
        pocSaveData.advice.family_member.member_mobile = this.memberData.mobile;
        pocSaveData.advice.family_member.id = this.memberData.id;
        pocSaveData['dependent_id'] = this.memberData.id;
      } else {
        pocSaveData.advice.family_member.first_name = this.family_member.first_name;
        pocSaveData.advice.family_member.age = this.family_member.age;
        pocSaveData.advice.family_member.gender_id = this.family_member.gender_id;
        pocSaveData.advice.family_member.member_mobile = this.family_member.mobile;
        pocSaveData.advice.family_member.id = this.family_member.id;
        pocSaveData['dependent_id'] = this.family_member.id;
      }
    }
      if (this.isOffLinePOC() == false) {
        try {
          pocSaveData.advice.family_member.member_mobile = this.member_mobile2
        }
        catch (ex) {
          console.log(ex)
        }
      }
      if (!this.is_self){
        pocSaveData.advice.family_member.id = this.dependentId > 0 ? this.dependentId : null;
        pocSaveData.cc_brief_history.chiefComplaints = pocSaveData.cc_brief_history.chiefComplaints.filter(e => e.chief_complaint !== 'Other');
        pocSaveData.diagnosis.provisionalDiagnosis = pocSaveData.diagnosis.provisionalDiagnosis.filter(e => e.diagnosis_name !== 'Other');
      }
   
    //chandu poc check for family member cal 01-06-2022
    if (this.pocmember == true && this.memberData && this.memberData !== null) {
      pocSaveData.beneficiary_id = this.memberData.beneficiary_id;
      // pocSaveData.member_id= this.depId
    } else {
      pocSaveData.beneficiary_id = this.benId;
      // pocSaveData.member_id= this.depId
    }
    if (!this.is_self) {
      pocSaveData.dependent_id = pocSaveData.advice.family_member.id;
      if (typeof (pocSaveData.advice.family_member.age) == "string") {
        pocSaveData.advice.family_member.age = parseInt(pocSaveData.advice.family_member.age);
      }
      if (typeof (pocSaveData.advice.family_member.gender_id) == "string") {
        pocSaveData.advice.family_member.gender_id = parseInt(pocSaveData.advice.family_member.gender_id);
      
    }
  }
    // [pocSaveData.nodal_officer_id, pocSaveData.covid19_scanning] = [pocSaveData.covid19.nodal_officer_id||'', pocSaveData.covid19.covid19_scanning||''];

    // delete pocSaveData.covid19?.nodal_officer_id || '';
    // delete pocSaveData.covid19?.covid19_scanning || '';
    pocSaveData.is_ayush_poc=true
    pocSaveData.advice.is_self = this.is_self;
    pocSaveData.lab_id = this.pocAyuForm.value.lab_id;
    pocSaveData.pharmacy_id = this.pocAyuForm.value.pharmacy_id;
    pocSaveData.cc_brief_history.chiefComplaints = pocSaveData.cc_brief_history.notes1 || [];
    pocSaveData.diagnosis.provisionalDiagnosis = pocSaveData.diagnosis.provisionalDiagnosis || [];
    pocSaveData.cc_brief_history.notes1 = pocSaveData.cc_brief_history.notes1 || [];
if(sessionStorage.getItem('covidcomp') !=''){
  pocSaveData.complications=sessionStorage.getItem('covidcomp');
}
if(sessionStorage.getItem('covidpocn') !=''){
  pocSaveData.covid_poc=true;
}
else{
  pocSaveData.covid_poc=false;
}
if(sessionStorage.getItem('pastcovidn') !=''){
  pocSaveData.past_covid=true;
}
if(sessionStorage.getItem('coviddaten') !=null){
  let datecovid=this.datePipe.transform(sessionStorage.getItem('coviddaten'), 'yyyy-MM-dd 00:00:00')
  pocSaveData.covid_date=datecovid;
}
    this.registrationService.saveAyushPOC('save-ayush-doctor-poc', pocSaveData).subscribe(res => {
      if (this.isIOS)
        sessionStorage.setItem('pocSave', '1'), disconnect();
      setTimeout(() => { this.isSaveDsbld = false; }, 5000);
      if (res.status == "OK") {
        sessionStorage.setItem('fromdocid', null);
        sessionStorage.setItem('myId', null);
        sessionStorage.removeItem('call_type');
        (<any>window).isPrescriptionSumitted = true;
        document.getElementById('btnPresClose').click();
        (<any>window).isPrescriptionSumitted = true;
        const intData = {
          beneficiary_id: this.benId, created_on: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'), id: 0,
          interaction_status_id: this.roleId == '9' ? 13 : 10, is_active: true, patient_interaction_id: this.ayurvedPOC.patient_interaction_id
        };
      
        // stopVideoPocGiven();
        // const value ={	
        //   screen_id:72,	
        //   module_id:6	
        //   }	
        //  this.router.navigate(['/home/consultation/cn']).then(r=>{	
        //   setTimeout(() => {	
        //     this.homeComponent.sidemenu(value,'another')	
        //   }, 1500);	
        //   stopVideo();	
        //  })	
        this.registrationService.updatePatIntrcn('savepatientinteractionlog', intData).subscribe(int => {
          if (int) {
            this.isPocSave = true, this.toastr.success(`Record ${this.ayurvedPOC.id == 0 ? 'Updated' : 'Saved'} Successfully`),
              ['gaLST', 'rpLST'].forEach(e => { sessionStorage.removeItem(e); }), this.submitted = false,
              this.ayurvedPOC.id = res.data.id;
            if (this.roleId == '7') {
              if (this.docData.charge_amt == 1)
                this.savePaySum(res.data.id);
              else
                this.saveAtchmnts();
                this.changeMenu()
                // stopVideoayush(true);
                // document.getElementById('btnPresClose').click(), stopVideoayush(true);
            }
            else
              this.saveAtchmnts();
              this.changeMenu()
              // stopVideoayush(true);
              document.getElementById('btnPresClose').click(), stopVideoayush(true);
          }
        });
        this.changeMenu()
      }
    },(err:HttpErrorResponse)=>{
      this.toastr.error(err.message)});
  }
  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        
        for (let i = 0; i < res.data.length; i++) {
          // if (res.data[i].feature_id.feature_name == "doctorPocfilter") {
          //   this.pocpatientHistory = res.data[i].patient;
          // }
          // if (res.data[i].feature_id.feature_name == "isNDHMEnable") {
          //   this.shwABHDetails = res.data[i].patient;
          // }
          // if (res.data[i].feature_id.feature_name == "isEnableCovid19") {
          //   this.isEnableCovid19 = res.data[i].patient;
          // }
          // //Adive Field Enabled
          // if (res.data[i].feature_id.feature_name == "isEnableAdviceAllField") {
          //   this.isEnableAdviceAllField = res.data[i].patient;
          // }
          // if (res.data[i].feature_id.feature_name == "hideOtherDrugLabelinPOC") {
          //   if (res.data[i].patient) {
          //     this.hideOtherDrugLabel = true;
          //   } else {
          //     this.hideOtherDrugLabel = false;
          //   }
          
          // }
          if (res.data[i].feature_id.feature_name == "enableConsultationTypeInPOC") {
            this.enableConsultationTypeInPOC = res.data[i].patient;
          }
        }
      }
    });
  }
 
  getallConsultations() {
    this.masterService.getAll(`getallconsultations?TENANT_ID=${this.orgId}`).subscribe(res => {
      if (res.status === 'OK') {
        this.ConsultationList = res.data
        // this.pocForm.patchValue({
        //   type_of_consultation: this.ConsultationList[-1].consultationType
        // })

      } else {
        this.toastr.error(res.message);
      }
    })
  }
  openPres(benId: any) {
    this.presCC = '', this.presHI = '', this.nts1 = '';
    this.nts1 = this.pocAyuForm.value.prakruti_consitution.cc_brief_history.notes1;
    if (benId != null && benId != undefined) {
      this.registrationService.getAyushDataById(`findbypocid?pocId=${benId}`).subscribe(res => {
        this.presPoc = res.data,
        this.presPoc.cc_brief_history.chiefComplaints.forEach(e => {
          let txt = e.chief_complaint + ` - ` + this.ayurvedPOC.cc_brief_history.ccRmrks[5 - e.id].value;
          this.presCC += this.presCC == '' ? txt : `., ${txt}`;
        });
        if (this.presPoc.cc_brief_history.otherComplaints != '')
          this.presHI += `"Other Complaints": ${this.presPoc.cc_brief_history.otherComplaints}`;
        if (this.presPoc.cc_brief_history.doctorComments != '')
          this.presHI += `${this.presHI == '' ? `"Doctor Comments": ${this.presPoc.cc_brief_history.doctorComments}` :
            `, "Doctor Comments": ${this.presPoc.cc_brief_history.doctorComments}`}`;
        if (this.presPoc.cc_brief_history.presentIllness != '')
          this.presHI += `${this.presHI == '' ? `"History of Present Illness": ${this.presPoc.cc_brief_history.presentIllness}` :
            `, "History of Present Illness": ${this.presPoc.cc_brief_history.presentIllness}`}`;
        // this.presDgns = this.presPoc.diagnosis.provisionalDiagnosis == '' ? '' : this.lstPrvDgns.filter(f => f.id == this.presPoc.diagnosis.provisionalDiagnosis)[0].name;
        this.presPoc.prescribed_medications.drugs.forEach(e => {
          let txt = '';
          e.frequency.forEach(f => { txt += txt == '' ? f.name : `, ${f.name}` }), this.lstPresFreq.push(txt);
        });
        document.getElementById('btnPreviewModal').click();
      }, err => { this.toastr.error(err) });
    }
  }

  previewPrescription(isMdl: boolean = true) {
    this.bindPayload(), this.presPoc = this.ayurvedPOC, this.rpDooshya = '', this.presHI = ''; this.nts1 = '', this.presCC = '';
    const provisional: any = this.presPoc.diagnosis.provisionalDiagnosis;
    if (Array.isArray(this.pocAyuForm.value.cc_brief_history.notes1))
      this.nts1 = this.pocAyuForm.value.cc_brief_history.notes1.map(e => e.chief_complaint).toString();
    this.provisionalDiagnosis = provisional[0]?.name || '';
    this.presPoc.cc_brief_history.chiefComplaints.forEach(e => {
      let txt = e.chief_complaint + ` - ` + this.ayurvedPOC.cc_brief_history.ccRmrks[5 - e.id].value;
      this.presCC += this.presCC == '' ? txt : `; ${txt}`;
    }), this.lstPresFreq = [];
    if (this.presPoc.cc_brief_history.otherComplaints != '')
      this.presHI += `"Other Complaints": ${this.presPoc.cc_brief_history.otherComplaints}`;
    if (this.presPoc.cc_brief_history.doctorComments != '')
      this.presHI += `${this.presHI == '' ? `"Doctor Comments": ${this.presPoc.cc_brief_history.doctorComments}` :
        `, "Doctor Comments": ${this.presPoc.cc_brief_history.doctorComments}`}`;
    if (this.presPoc.cc_brief_history.presentIllness != '')
      this.presHI += `${this.presHI == '' ? `"History of Present Illness": ${this.presPoc.cc_brief_history.presentIllness}` :
        `, "History of Present Illness": ${this.presPoc.cc_brief_history.presentIllness}`}`;

    this.presPoc.prescribed_medications.drugs.forEach(e => { this.lstPresFreq.push(e.frequency); }),
      this.selectedList.forEach(e => { this.rpDooshya += this.rpDooshya == '' ? e.name : `,${e.name}`; });
    if (this.roleId == '7') {
      this.endTime = new Date(); //, this.docData == undefined ? JSON.parse(sessionStorage.getItem('DoctorDetails')) : this.docData;
      this.paySumForm.patchValue({
        consultation_fee:0, 
        consultation_fee_required: this.IsConsultReq, discount_percentage: 0, discount_amount: 0,
        medicine_billable: this.IsMedBill, previous_balance_amount: this.prevBal, home_delivery: false, add_balance_amount: false,
        total_bill_amount: this.IsConsultReq == true ? this.docData.video_consultation_price : 0,
        payable_amount: this.IsConsultReq == true ? this.docData.video_consultation_price : 0,
        invoice_amount: this.IsConsultReq == true ? this.docData.video_consultation_price : 0
      });
      this.bindPaySum();
      if (isMdl) {
        if (this.docData.charge_amt == 1)
          document.getElementById('btnPreviewModalNew').click(), setTimeout(() => { this.payPres = 's'; }, 200);
        else
          document.getElementById('btnPreviewModal').click();
      }
    }
  }

  getPHData() {
    if (this.usrLgnId != null && this.usrLgnId != undefined && this.usrLgnId > 0) {
      let url = '';
      if (this.is_self)
        url = `getpreviousconsultationbydoctorandbeneficiaryid?${this.roleId == '7' ? 'doctorId' : 'healthWorkerId'}=${this.usrLgnId}&beneficiaryId=${this.benId}`;
      else
        url = `getpreviousconsultationbydoctoranddependentid?${this.roleId == '7' ? 'doctorId' : 'healthWorkerId'}=${this.usrLgnId}&dependentId=${this.dependentId}`;
//  if (this.is_self)
//         url = `getpreviousconsultationbybeneficairyid?beneficiaryId=${this.benId}&typeOfConsultation=Ayush`;
//       else
//       url = `getpreviousconsultationbybeneficairyid?dependentId=${this.dependentId}&typeOfConsultation=Ayush`;
      if (url != '') {
        [this.lstPH1, this.lstPH2] = [[], []], this.registrationService.getBenHstryDataByDHId(url).subscribe(res => {
          let phData = res.data.medical_past_history;
          // this.lstPH1 = phData.filter(f => f.patient_history_records.length > 0), this.lstPH2 = phData.filter(f => f.patient_attached_records.length > 0);
          this.lstPH1 = phData.filter(f => f.patient_history_records?.length > 0);
          phData.forEach(e => {
            let atchData = e.patient_attached_records?.filter(f => f.file_type_id == Avail.RegFTId);
            if (atchData.length > 0)
              atchData.forEach(a => { this.lstPH2.push(a); });
          });
        }, err => { this.toastr.error(err) });
      }
    }
  }

  onViewPH(pocId: any, files: any, type: string) {
    if (files.length > 0) {
      let filePrfx = '', filePath = '';

      if (type == 'h') {
        filePrfx = this.appUrl + Avail.fileUrl + Avail.VstFilesTxt + pocId + `/${Avail.VstFleFTId}/`;
        filePath = files.filter(f => f.file_type_id == Avail.VstFleFTId)[0]?.document_path;
      }
      else {
        let atchFile = files.filter(f => f.file_type_id != Avail.VstFleFTId && f.file_type_id != Avail.InvcFTId);
        filePath = atchFile.length > 0 ? atchFile[atchFile.length - 1].document_path : null;
        if (filePath != null)
          filePrfx = this.appUrl + Avail.fileUrl + Avail.VstFilesTxt + pocId + '/' + atchFile[0]?.file_type_id + '/'; //`/${atchFile[0]?.file_type_id}/`;
      }

      if (filePath != undefined)
        this.onViewDoc(`${filePrfx}${filePath}`);
      else
        this.toastr.info('No file found');
    }
    else
      this.toastr.info('No file found');
  }

  onViewDocPH(item: any, type: string) {
    if (item.document_path != '' && item.document_path != null && item.document_path != undefined) {
      let filePath = this.appUrl + Avail.fileUrl + (this.isConsultationForDependent == true ? Avail.MemDocsTxt : Avail.BnfcryTxt);
      if (type == 'a')
        this.onViewDoc(filePath + item.document_path.split('_')[0] + `/${item.file_type_id}/${item.document_path}`);
    }
  }

  onPresClose() {
    document.getElementById('pocCncl').click(); // this.router.navigate(['home/consultation']);
  }
  
  followupClose(){
    document.getElementById('followUpclose').click(); 
  }

  // viewID() {
  //   alert('View ID check');
  //   if (this.benData) {
  //     let idPath = this.benData.beneficiary_documents.filter(f => f.file_type_id == Avail.BnfcryFTId)[0]?.document_path, filePath = '';
  //     if (idPath) {
  //       filePath = this.appUrl + Avail.fileUrl + Avail.BnfcryTxt + this.benData.id + `/${Avail.BnfcryFTId}/${idPath}`;
  //       if (filePath != undefined)
  //         this.onViewDoc(filePath);
  //       else
  //         this.toastr.info('No file found');
  //     }
  //   }
  //   else
  //     this.toastr.info('No file found');
  // }
  viewID() {
 
    if (this.benData) {
    let  filePath = '';
      let idPath = this.benData.beneficiary_documents.filter(f => f.file_type_id == 23)[0]?.document_path;
     if(idPath!= undefined){
      filePath=  `${this.registrationService.viewFilePath}` + 'BENEFICIARY/' + this.benId + '/IdCard/' +idPath;
     }else{
      this.toastr.info('No File Found');
     }
    }


  }
  viewKinID() {
    if (this.benData) {
      let idPath = this.benData.guardians, grdnData = idPath.length > 0 ? idPath[idPath.length - 1] : [];
      if (grdnData.beneficiary_member_documents.length > 0) {
        let fileData = grdnData.beneficiary_member_documents[0],
          filePath = this.appUrl + Avail.fileUrl + `${Avail.MemDocsTxt}${grdnData.id}/${fileData.file_type_id}/${fileData.document_path}`;
        if (filePath != undefined)
          this.onViewDoc(filePath);
        else
          this.toastr.info('No file found');
      }
    }
    else
      this.toastr.info('No file found');
  }

  viewDepID() {
    if (this.dependtData) {
      let depFile = this.dependtData.beneficiary_member_documents.length > 0 ? this.dependtData.beneficiary_member_documents : [],
        fileData = depFile.filter(f => f.file_type_id == Avail.BnfcryFTId);
      if (fileData.length > 0) {
        let filePath = this.appUrl + Avail.fileUrl + `${Avail.MemDocsTxt}${this.dependtData.id}/${Avail.BnfcryFTId}/${fileData[0].document_path}`;
        if (filePath != undefined)
          this.onViewDoc(filePath);
        else
          this.toastr.info('No file found');
      }
      else
        this.toastr.info('No file found');
    }
    else
      this.toastr.info('No file found');
  }

  onViewDoc(docPath: any) {
    let link = document.createElement("a");

    link.setAttribute('href', docPath);
    link.setAttribute('visibility', 'hidden');
    link.setAttribute('display', 'none');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    link.click();
  }

  bindPaySum() {
    this.resetControls();
    this.presPoc.prescribed_medications.drugs.forEach(e => {
      this.addRow(e);
    });
  }

  resetControls() {
    const arr = this.paySumForm.controls.prescribed_drugs as FormArray;
    while (0 !== arr.length)
      arr.removeAt(0);
  }

  addRow(item: any) {
    this.medName = ''; this.medType = '', [this.medName, this.medType] = [item.drugName.name, item.drugType];
    const control = this.paySumForm.get('prescribed_drugs') as FormArray;
    control.push(this.tableRowForm());
  }

  tableRowForm(): FormGroup {
    return this.formBuilder.group({
      type: this.medType,
      name: this.medName,
      amount: [null] // Validators.pattern('^[0-9]*\.[0-9]{4}$')
    });
  }

  get getRowFormControls() {
    const control = this.paySumForm.get('prescribed_drugs') as FormArray;
    return control;
  }

  deleteRow(index: number) {
    const control = this.pocAyuForm.get('prescribed_drugs') as FormArray;
    control.removeAt(index);
  }

  chkDueAmnt(type: string) {
    if (+this.paySumForm.get('invoice_amount').value < 0)
      this.toastr.error('Invoice Amount should not be less than 0');
    else if (+this.paySumForm.get('payable_amount').value < +this.paySumForm.get('invoice_amount').value)
      this.toastr.error('Invoice Amount should be less than or equal to Payable Amount');
    else if (this.chkDrgAmnts())
      this.toastr.error('Amount for drugs should not be empty');
    else {
      if (type == 'n')
        this.payPres = 'p';
      else
        this.onSave();
    }
  }

  chkDrgAmnts(): boolean {
    let nullCnt = 0;
    if (this.paySumForm.value.medicine_billable) {
      this.getRowFormControls.controls.forEach(e => {
        if (e.value.amount == null || e.value.amount == 0 || e.value.amount == '')
          nullCnt++;
      });
    }
    return nullCnt != 0;
  }

  dataSum() {
    let [feeReq, billable, amount, fee, damnt, dperc, dType, aB] = [false, false, 0, 0, 0, 0, '', false]; dType = (dType == '' ? 'r' : dType);
    this.paySumForm.get('prescribed_drugs').valueChanges.subscribe(values => {
      resolvedPromise.then(() => {
        amount = values.reduce((acc, cur) => acc + +cur.amount, 0);
        this.paySumForm.patchValue({ total: +amount });
        this.total(billable ? amount : 0, feeReq, fee), this.onDiscount(damnt), this.totalBill(dType, dperc, damnt);
      });
    });
    this.paySumForm.get('consultation_fee_required').valueChanges.subscribe(val => {
      // this.isCnstFeeSlctd = val, 
      feeReq = val, this.total(billable ? amount : 0, feeReq, fee), this.onDiscount(damnt); this.totalBill(dType, dperc, damnt);
    });
    this.paySumForm.get('consultation_fee').valueChanges.subscribe(val => {
      fee = +val; this.total(billable ? amount : 0, feeReq, fee), this.onDiscount(damnt); this.totalBill(dType, dperc, damnt);
    });
    this.paySumForm.get('medicine_billable').valueChanges.subscribe(val => {
      // this.isMedSlctd = val,
      billable = val, this.total(billable ? amount : 0, feeReq, fee), this.onDiscount(damnt); this.totalBill(dType, dperc, damnt);
    });
    this.paySumForm.get('discount_amount').valueChanges.subscribe(vals => {
      damnt = +vals; this.onDiscount(damnt); this.totalBill(dType, dperc, damnt);
    });
    this.paySumForm.get('discount_percentage').valueChanges.subscribe(vals => {
      dperc = +vals; this.onDiscount(damnt); this.totalBill(dType, dperc, damnt);
    });
    this.paySumForm.get('add_balance_amount').valueChanges.subscribe(vals => {
      aB = vals; this.totalBill(dType, dperc, damnt)
    });
    this.paySumForm.get('invoice_amount').valueChanges.subscribe(vals => {
      let inv = vals; this.checkInv(inv), this.paySumForm.patchValue({ due_amount: +this.paySumForm.get('payable_amount').value - inv });
    });
    this.paySumForm.get('discount').valueChanges.subscribe(val => {
      this.paySumForm.patchValue({ discount_amount: 0, discount_percentage: 0 });
      dType = val; this.totalBill(dType == '' ? 'r' : dType, dperc, damnt);
    });
  }

  total(amount: any, feeReq: any, fee: any) {
    if (feeReq)
      this.paySumForm.patchValue({ overall_amount: +amount + +fee });
    else
      this.paySumForm.patchValue({ overall_amount: +amount });
  }

  onDiscount(val: any) {
    if (+val > +this.paySumForm.get('overall_amount').value)
      this.paySumForm.get('discount_amount').setErrors({ 'max': true }), setTimeout(() => { this.paySumForm.value.discount_amount = 0; }, 1000);
    else
      this.paySumForm.get('discount_amount').setErrors(null);
  }

  totalBill(type: any, perc: any, amount: any) {
    let [pval, val, tcost, prevBal, addBal] = [0, 0, +this.paySumForm.get('overall_amount').value, +this.paySumForm.get('previous_balance_amount').value, this.paySumForm.get('add_balance_amount').value];
    if (type == 'p') {
      pval = (tcost / 100) * perc;
      val = tcost - pval;
    }
    else if (type == 'r')
      val = tcost - amount;
    else
      val = tcost;

    let inv = addBal ? (prevBal + val) : val
    this.paySumForm.patchValue({ total_bill_amount: val, payable_amount: inv, invoice_amount: inv });
  }

  checkInv(val: any) {
    if (+val > +this.paySumForm.get('payable_amount').value)
      this.paySumForm.get('invoice_amount').setErrors({ 'max': true });
    else
      this.paySumForm.get('invoice_amount').setErrors(null);
  }

  savePaySum(id: any) {
    this.paySumForm.patchValue({ medical_record_id: id });
    ['invoice_amount', 'total_bill_amount', 'payable_amount', 'overall_amount'].forEach(e => {
      if (this.paySumForm.get(e).value == null)
        this.paySumForm.get(e).setValue(0);
    });
    let sendData = this.paySumForm.getRawValue();
    ["discount", 'total'].forEach(el => { delete sendData[el]; });
// Save pay sum 04-05-2023
    // this.registrationService.save('paymentsummary/saveandupdate', sendData).subscribe(res => {
    //   this.saveAtchmnts('p'); // document.getElementById('btnMdlClose').click(), stopVideoayush(true);
    // }, err => { this.toastr.error(err); });
    this.registrationService.savePaysum('paymentsummary/saveandupdate', sendData).subscribe(res => {
      this.saveAtchmnts('p'); // document.getElementById('btnMdlClose').click(), stopVideoayush(true);
    }, err => { this.toastr.error(err); });
  }

  saveAtchmnts(type: string = 's') {
    if (this.lstPH2.length > 0 && sessionStorage.getItem('dtlsDrHw') != null) {
      let dataDrHw = JSON.parse(sessionStorage.getItem('dtlsDrHw')), apiUrl = !this.is_self ?
        'beneficiarymemberdocumentdoctoraccess/saveandupdate' : 'beneficiarydocumentdoctoraccess/saveandupdate';
      this.bdAtchmnt.data = [], this.lstPH2.forEach(e => {
        if (!this.is_self) {
          if (e.beneficiary_member_documents_doctor_access.length == 0 || e.beneficiary_member_documents_doctor_access == null)
            this.bdAtchmnt.data.push({ beneficiary_document_id: e.id, doctor_id: +dataDrHw.doctorId });
        }
        else {
          if (e.beneficiary_documents_doctor_access.length == 0 || e.beneficiary_documents_doctor_access == null)
            this.bdAtchmnt.data.push({ beneficiary_document_id: e.id, doctor_id: +dataDrHw.doctorId });
        }
      });
      if (this.bdAtchmnt.data) {
        this.registrationService.saveAtchmnts(apiUrl, this.bdAtchmnt.data).subscribe(res => {
          if (res)
            this.closeMdls(type);
        });
      }
    }
    else
      this.closeMdls(type);
  }

  // closeMdls(type: string = 's') {
  //   if (type == 'p')
  //     document.getElementById('btnMdlClose')?.click(), stopVideoayush(true);
  //   else
  //     document.getElementById('btnPresClose')?.click(), stopVideoayush(true);
  // }
  closeMdls(type: string = 's') {
    if (type == 'p')
      document.getElementById('btnMdlClose')?.click(), stopVideo(true),stopVideoayush(true);
    else
      document.getElementById('btnPresClose')?.click(), stopVideo(true),stopVideoayush(true);
  }
  //#region WebCam
  onFile(evnt: any) {
    if (evnt.target.files.length > 0) {
      var fileExtsn: string = evnt.target.files[0].type;
      if (fileExtsn.includes('jpg') || fileExtsn.includes('jpeg') || fileExtsn.includes('png'))
        this.dgtlFile = evnt.target.files[0], this.upldFile = evnt.target.files[0].name;
      else
        this.toastr.error('Please upload image files like jpg/jpeg/png');
    }
  }

  viewFile() {
    if (this.dgtlFile) {
      let view_File = this.dgtlFile;
      const fileExtension: string = view_File.name.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
        const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
        if (view_File) {
          const reader = new FileReader();
          reader.readAsDataURL(view_File);
          reader.onload = (event: any) => { modalRef.componentInstance.image = event.target.result; };
        }
      }
    }
    else
      this.toastr.info('No file found');
  }

  uploadImg() {
    let eFile = null;
    if (this.isDgtl == 'Upload') {
      if (this.dgtlFile)
        eFile = this.dgtlFile;
    }
    else {
      if (this.webcamImage)
        eFile = this.dataURItoBlob(this.webcamImage.imageAsDataUrl);
    }
    if (eFile != null) {
      this.activeSpinner();
      this.fileSvc.uploadFile(Common.form_Data('0', eFile, this.benId.toString(), Avail.BnfcryTxt.replace('/', ''),
        Avail.MnlPresFTId.toString(), this.pocAyuForm.get('patient_interaction_id').value, true)).subscribe(s => {
          if (s.status == "success")
            this.toastr.success('File Uploaded successfully'), this.ayurvedPOC.beneficiary_id = this.benId.toString(),
              this.ayurvedPOC.doctor_id = JSON.parse(sessionStorage.getItem('DoctorDetails')).id, this.deactivateSpinner(), this.onSave();
          else
            this.deactivateSpinner(), this.toastr.error(s.status);
        });
    }
  }

  dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]), mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length), ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ab], { type: mimeString });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  //#endregion

  onCancel() {
    // updtLogoutSocket(),
    //  document.getElementById('btnCnsltNav').click();
    this.withOut_PrescriptionSumitted();
    this.gotoConsultation();
  }

  stop() { stopVideoayush(); }

  showHideVideo() {
    if (this.isOffLinePOC() == false) {
      // let dragElement: any = (<HTMLDivElement>document.getElementsByClassName('videoalign')[0]);
      let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideo'));
      dragElement.style.display = (dragElement.style.display == 'none') ? 'block' : 'none';
    }
  }

  isOffLinePOC() {
    return JSON.parse(sessionStorage.isPatient || 'false')
  }

  accordianExpand() {
    $(document).ready(function () {
      $(".collapse.show").each(function () {
        $(this).prev(".card-header").find(".fa").addClass("fa-chevron-down").removeClass("fa-chevron-right");
      });

      $(".collapse").on('show.bs.collapse', function () {
        $(this).prev(".card-header").find(".fa").removeClass("fa fa-chevron-right").addClass("fa fa-chevron-down");
      }).on('hide.bs.collapse', function () {
        $(this).prev(".card-header").find(".fa").removeClass("fa fa-chevron-down").addClass("fa fa-chevron-right");
      });
    });
  }
  coviddiv(){
    this.covidformdatashow=true;
    this.pocdivshow=false;
    this.covidbtn=false;
    this.pocbtn=true;
    sessionStorage.setItem('covidpocn','true');
  }
  pocdiv(){
    this.covidformdatashow=false;
    this.pocdivshow=true;
    this.covidbtn=true;
    this.pocbtn=false;
    sessionStorage.setItem('covidpocn','false')
  }
  historyclick(value:any){
    (<HTMLInputElement>document.getElementById('history')).value=value;
  }
}

export class patientInteractionPL {
  doctor_id: number = 0;
  benficiary_id: number = 0;
  healthworker_id: number = 0;
  dependent_id: number = 0;
  patient_interaction_id: number = 0;
}

function SortData(key: any) {
  return function (a: any, b: any) {
    if (a[key] > b[key])
      return 1;
    else if (a[key] < b[key])
      return -1;
    return 0;
  }
}