<div class="card margingtop" style="border-radius: 1rem;">
    <div class="card-body pt-0 bg_color small">
        <div class="">
            <span class="text-center patient-info text-white pl-2 pr-2 pt-1 pb-1"
                style="display:table;margin:0 auto;">Primary Beneficiary Information</span>
        </div>
        <p class="mb-2"><span><strong>Name</strong></span>:<span>
                {{benData?.first_name + ' '+ benData?.middle_name +' ' + benData?.last_name}}</span></p>
        <p class="mb-2"><span><strong>Gender</strong></span>:&nbsp;<span>{{gdrId == 1 ? 'Male' :
                'Female'}}</span>
            <span class="ml-4"><strong>Age</strong></span>:&nbsp;<span>{{benData?.age}}</span>
        </p>
        <p class="mb-2"><span><strong>Reg Number</strong></span>:&nbsp;<span>{{benData?.id}} </span></p>
        <p class="mb-2"><span><strong>Mobile
                    Number</strong></span>:&nbsp;<span>{{benData?.mobile}}</span></p>
        <p class="mb-2"><span><strong>Address</strong></span>:&nbsp;<span>{{benData?.address}}</span>
        </p>
        <p class="mb-2" hidden><span><strong>Symptons</strong></span>:&nbsp;<span>Shivering, Cough,
                Fever,
                Running</span></p>
        <div class="float-left">
            <button class="badge badge-info" type="button" (click)="view_ID()">
                <i class="fa fa-id-card" aria-hidden="true"></i>&nbsp;ID Proof
            </button>
        </div>
    </div>
</div>