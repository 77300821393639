<div class="modal-header">
    <h4 class="modal-title">Image View</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <img [src]="image" class="img-fluid" />
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark btn-sm" (click)="activeModal.close('Close click')">Close</button>
</div>