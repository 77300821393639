<div id="slide-panel" class="d-none" style="margin-left: -253px; cursor: pointer !important;">
    <a id="opener"><img src="../../../assets/images/side_btn.png" /></a>
    <div>
        <!-- <nav>
            <a id="btnCnsltNav" class="nav-link text-white" routerLinkActive="active" [routerLink]="cnsltRoute"
                (click)="changeMenu()">{{cnsltTxt}}</a>
                ///home/consultation/cn
            <a class="nav-link text-white" routerLinkActive="active" [routerLink]="['/home/consultation/appointment']"
                (click)="changeMenu()">Appointment Schedule</a>
            <a class="nav-link text-white" routerLinkActive="active" [routerLink]="['/home/consultation/payment']"
                (click)="changeMenu()"> Payment Status</a>
            <a class="nav-link text-white" routerLinkActive="active" [routerLink]="['/home/consultation/support']"
                (click)="changeMenu()">Support</a>
        </nav> -->

        <nav>
            <a id="btnCnsltNav" class="nav-link text-white" routerLinkActive="active" 
              (click)="changeMenu()">Doctor Consultation</a>
            <a class="nav-link text-white" routerLinkActive="active" 
              (click)="changeMenu()">Appointment Schedule</a>
            <a class="nav-link text-white" routerLinkActive="active" 
              (click)="changeMenu()"> Payment Status</a>
            <a class="nav-link text-white" routerLinkActive="active"
              (click)="changeMenu()">Support</a>
          </nav>
    </div>
</div>