import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { MasterService } from 'src/app/shared/services/master.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Common } from 'src/app/common';

@Component({
  selector: 'app-corporateplanscontrols',
  templateUrl: './corporateplanscontrols.component.html',
  styleUrls: ['./corporateplanscontrols.component.css']
})

export class CorporateplanscontrolsComponent implements OnInit {
  
  @Input() editData: any; showw: boolean = false; showDDiscount: boolean = false; corporateform: FormGroup; submitted = false; maxDiscAmnt = 0;
  
  constructor(public activeModal: NgbActiveModal
    , private masterService: MasterService
    , private userService: UserService
    , private formbuilder: FormBuilder
    , private toastr: ToastrService) {
    this.activeModal.close('changes saved')
  }

  ngOnInit(): void {
    this.formcontrols(), this.patchingdata();
  }

  formcontrols() {
    this.corporateform = this.formbuilder.group({
      id: [0],
      plan: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]],
      plan_for: ['Consultation'],
      plan_type: [''],
      subscription_type: ['Monthly'],
      discount_type: [''],
      discount_amt_percentage: [''],
      max_calls_per_employee: [null],
      max_discount_amount: [''],
      max_discount_amount_perc: [''],
      isLmtd: [null, Validators.required],
      max_employee_count: [null, [Validators.min(1), Validators.max(10000)]],
      is_recurring: [true],
      is_active: [true]
    })
  }

  patchingdata() {
    if (this.editData) {
      this.corporateform.patchValue(this.editData), this.corporateform.get('isLmtd').setValue(+this.corporateform.get('max_calls_per_employee').value != 0);
      let discType = this.corporateform.get('discount_type').value;
      if (this.corporateform.get('plan_type').value == 'Subscription')
        this.showsubscription();
      if (this.corporateform.get('plan_type').value == 'Discount')
        this.showdiscount();
      this.corporateform.get('max_calls_per_employee').setValue(this.corporateform.get('max_calls_per_employee').value == 0 ? null : this.corporateform.get('max_calls_per_employee').value),
        this.corporateform.get('max_employee_count').setValue(this.corporateform.get('max_employee_count').value == 0 ? null : this.corporateform.get('max_employee_count').value), this.onChngDT(),
        this.corporateform.get('max_discount_amount').setValue(this.editData.max_discount_amount == 0 ? null : this.editData.max_discount_amount),
        this.corporateform.get('max_discount_amount_perc').setValue(this.editData.max_discount_amount_perc == 0 ? null : this.editData.max_discount_amount_perc),
        this.corporateform.get('discount_amt_percentage').setValue(this.editData.discount_amt_percentage == 0 ? null : this.editData.discount_amt_percentage);
      if (discType != '') {
        if (discType != 'Flat')
          this.corporateform.get('max_discount_amount_perc').setValue(this.corporateform.get('max_discount_amount').value), this.corporateform.get('max_discount_amount').setValue(null);
      }
    }
  }

  onChngDT() {
    let discType = this.corporateform.get('discount_type').value;
    if (discType != '') {
      if (discType != 'Flat') {
        this.corporateform.get('max_discount_amount').setValue(''), this.corporateform.get('discount_amt_percentage').setValue(''),
          this.corporateform.get('max_discount_amount_perc').setValue(''), this.corporateform.get('max_discount_amount').setValidators(null),
          this.corporateform.get('discount_amt_percentage').setValidators([Validators.required, Validators.min(1), Validators.max(100)]),
          this.corporateform.get('max_discount_amount_perc').setValidators([Validators.required, Validators.min(1), Validators.max(1000)]);
      }
      else {
        this.corporateform.get('max_discount_amount').setValue(''), this.corporateform.get('discount_amt_percentage').setValue(''),
          this.corporateform.get('max_discount_amount_perc').setValue(''), this.corporateform.get('discount_amt_percentage').setValidators(null),
          this.corporateform.get('max_discount_amount').setValidators([Validators.required, Validators.min(1), Validators.max(1000)]),
          this.corporateform.get('max_discount_amount_perc').setValidators(null);
      }
    }
    else {
      this.corporateform.get('max_discount_amount').setValue(''), this.corporateform.get('discount_amt_percentage').setValue(''),
        this.corporateform.get('max_discount_amount_perc').setValue(''), this.corporateform.get('max_discount_amount_perc').setValidators(null),
        this.corporateform.get('max_discount_amount').setValidators(null), this.corporateform.get('discount_amt_percentage').setValidators(null);
    }
    this.corporateform.get('max_discount_amount').updateValueAndValidity(), this.corporateform.get('max_discount_amount_perc').updateValueAndValidity(),
      this.corporateform.get('discount_amt_percentage').updateValueAndValidity();
  }

  checkedlimt() {
    setTimeout(() => {
      let lmtedvalue = this.corporateform.get('isLmtd').value ?? true;
      this.corporateform.get('max_calls_per_employee').setValue('');
      if (lmtedvalue)
        this.corporateform.get('max_calls_per_employee').setValidators([Validators.required, Validators.min(1), Validators.max(1000)]);
      else
        this.corporateform.get('max_calls_per_employee').setValidators(null);
      this.corporateform.get('max_calls_per_employee').updateValueAndValidity();
    }, 100);
  }

  get f() { return this.corporateform.controls; }

  onSubmit(event: any) {
    this.submitted = true, Common.getFormValidationErrors(this.corporateform);
    if (this.corporateform.invalid) {
      this.toastr.error('Please fill all mandatory fields');
      return;
    }
    const postData = JSON.parse(JSON.stringify(this.corporateform.getRawValue()));
    if (this.corporateform.get('discount_type').value != 'Flat')
      postData.max_discount_amount = postData.max_discount_amount_perc;
    delete postData.isLmtd, delete postData.max_discount_amount_perc;
    if (this.corporateform.get('id').value == 0)
      delete postData.id;
    ['discount_amt_percentage', 'max_calls_per_employee', 'max_discount_amount', 'max_employee_count'].forEach(e => {
      postData[e] = postData[e] == null ? 0 : +postData[e];
    });
    this.userService.save('mastersubscriptionplan/save', JSON.stringify(postData)).subscribe(res => {
      if (res.status === 'OK') {
        this.toastr.success(res.message, 'Corporate Plans');
        this.activeModal.close('changes saved');
      }
      else
        this.toastr.error(res.message)
    });
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  showsubscription() {
    this.showw = true, this.showDDiscount = false, this.corporateform.get('discount_type').setValidators(null), 
      this.corporateform.get('max_discount_amount_perc').setValue(null), this.corporateform.get('discount_type').updateValueAndValidity();
  }

  showdiscount() {
    this.showDDiscount = true, this.showw = false, this.corporateform.get('discount_type').setValidators([Validators.required]), this.corporateform.get('discount_type').updateValueAndValidity();
    if (this.editData) {
      let discType = this.editData.discount_type;
      if (discType == 'Flat')
        this.corporateform.get('max_discount_amount_perc').setValue(null), this.corporateform.get('discount_amt_percentage').setValue(null);
      else
        this.corporateform.get('max_discount_amount_perc').setValue(this.editData.max_discount_amount), this.corporateform.get('max_discount_amount').setValue(null);
    }
  }

}