import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-primary-beneficiary',
  templateUrl: './primary-beneficiary.component.html',
  styleUrls: ['./primary-beneficiary.component.css']
})
export class PrimaryBeneficiaryComponent implements OnInit {
  @Output() viewID = new EventEmitter<void>();
  @Input() benData: any;
  @Input() gdrId: number;
  constructor() { }

  ngOnInit(): void {
  }

  view_ID(): void { this.viewID.emit(); }

}
