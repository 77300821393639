<div class="modal-header">
    <h4 class="modal-title">View Vitals</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <img [src]="image" class="img-fluid" /> -->
    <div class="card-body">
        <div class="table-responsive">

            <table datatable  *ngIf="dtRender" class="table table-striped table-bordered table-sm">
                <thead>
                    <tr>
                        <th>S No</th>
                        <th class="singleLine"> Temperature<div class="unit">(&deg;F)</div></th>
                        <th>Sugar (mg/dl)</th>
                        <th>BP (mm Hg)</th>
                        <th>SPO2</th>
                        <th>Height(cm)</th>
                        <th>Weight(kg)</th>
                        <th>BMI</th>
                        <th>Pulse rate (bpm)</th>
                        <th>Date</th>
                        <th>ECG</th>
                        <th>Stethoscope</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vital of vitalsList;let ind=index;">
                        <td>{{(ind+1)}}</td>
                        <td>{{vital.temperature}}</td>
                        <td>{{vital.diabetic_value}}</td>
                        <td>{{vital.bp_value}}</td>
                        <td>{{vital.oxigen_count}}</td>
                        <td>{{vital.height}}</td>
                        <td>{{vital.weight}}</td>
                        <td *ngIf="vital?.bmi == null"></td>
                        <td *ngIf="vital?.bmi">{{vital?.bmi}}(<span *ngIf="vital?.bmi" [style.color]="vital?.color">{{vital?.bmiName}}</span>)</td>
                        <td>{{vital.pulse_rate}}</td>
                        <td>{{vital.capture_date | date :'dd-MM-yyyy hh:mm:ss aa'}}</td>
                        <td><a class="pointer" (click)="onViewDoc(17, vital)">View File</a></td>
                        <td><a class="pointer" (click)="onViewDoc(18, vital)">View File</a></td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>
<div class="modal-footer">
    <button type="button"  id="viewVitalButton" class="btn btn-outline-dark btn-sm" (click)="activeModal.close('Close click')">Close</button>
</div>