import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HomeComponent } from '../../home/home.component';
declare const stopVideoayush: any;
@Component({
  selector: 'app-poc-left-menu',
  templateUrl: './poc-left-menu.component.html',
  styleUrls: ['./poc-left-menu.component.css']
})
export class PocLeftMenuComponent implements OnInit {

  @Input() cnsltTxt: string;
  @Input() cnsltRoute: string;
  @Output() change_Menu = new EventEmitter<void>();
  constructor(public homeComponent: HomeComponent,private router: Router) { }

  ngOnInit(): void {


   }
   changeMenu() {
    this.change_Menu.emit();
  }



}
