<form [formGroup]="form" autocomplete="off">
    <div class="card-body">
        <h6>HOW WAS THE PATIENT IDENTIFIED AS COVID-19 POSITIVE? (RESTRICT TO A SINGLE
            CHOICE ONLY)</h6>
        <div formGroupName="patient_identified">
            <div class="row">
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">Investigations:</label>
                    <select class="form-control form-control-sm" id="investigations" formControlName="investigations">
                        <option value="">Select</option>
                        <option *ngFor="let item of investigationsList" [value]="item.name">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">Date</label>
                    <input type="email" class="form-control form-control-sm" id="investigations_date" bsDatepicker
                        [maxDate]="maxDate"
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                        onpaste="return false" onkeypress='return event.charCode == 8'
                        formControlName="investigations_date" placeholder="Date">
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">CT Value</label>
                    <input type="text" class="form-control form-control-sm" id="ct_value" formControlName="ct_value"
                        placeholder="CT Value">
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">Name</label>
                    <input type="text" class="form-control form-control-sm" id="exampleFormControlInput1"
                        onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                        maxlength="50" formControlName="name" placeholder="Name">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">REASON FOR TESTING</label>
                    <select class="form-control form-control-sm" id="reason_for_testing"
                        formControlName="reason_for_testing">
                        <option value="">Select</option>
                        <option *ngFor="let item of reasonList" [value]="item.name">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">Date</label>
                    <input type="text" class="form-control form-control-sm" id="reason_date" bsDatepicker
                        [maxDate]="maxDate"
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                        onpaste="return false" onkeypress='return event.charCode == 8' formControlName="reason_date"
                        placeholder="Date">
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">Specify</label>
                    <input type="text" class="form-control form-control-sm" id="specify" formControlName="specify"
                        placeholder="Specify">
                </div>
            </div>
        </div>
        <div class="row" formGroupName="co_morbidities">
            <div class="form-group col-xl-12">
                <label for="">Co-morbidities</label><br />

                <div class="custom-control custom-checkbox  custom-control-inline">
                    <input class="custom-control-input" id="DM" type="checkbox" value="DM"
                        (change)="onChange($event.target,'co_morbidities.DM')" formControlName="DM">
                    <label class="custom-control-label" for="DM">DM </label>
                </div>
                <div class="custom-control custom-checkbox  custom-control-inline">
                    <input class="custom-control-input" id="Hypertension" type="checkbox" value="Hypertension"
                        formControlName="hypertension" (change)="onChange($event.target,'co_morbidities.hypertension')">
                    <label class="custom-control-label" for="Hypertension">Hypertension</label>
                </div>
                <div class="custom-control custom-checkbox  custom-control-inline">
                    <input class="custom-control-input" id="COPD" type="checkbox" value="COPD"
                        (change)="onChange($event.target,'co_morbidities.COPD')" formControlName="COPD">
                    <label class="custom-control-label" for="COPD">COPD</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-xl-12">
                <!-- <label for="">Symptoms</label><br /> -->

                <div class="custom-control custom-checkbox  custom-control-inline">
                    <input class="custom-control-input" id="symptoms" type="checkbox" formControlName="symptoms">
                    <label class="custom-control-label" for="symptoms">Symptoms </label>
                </div>

            </div>
        </div>
        <div *ngIf="form.get('symptoms').value">
            <h6>PRESENTING COMPLAINTS (COVID-19 RELATED SYMPTOMS)</h6>
            <div class="table-responsive" formGroupName="presenting_complaints">
                <table class="table bordered-0 table-sm" style="width: 100%;">
                    <thead>
                        <tr>
                            <th class="border-0" style="width: 20%;">Symptoms</th>
                            <th class="border-0" style="width: 5%;">Select</th>
                            <th class="border-0" style="width: 20%;">Day of consultation
                            </th>
                            <th class="border-0" style="width: 20%;">Stage</th>
                            <th class="border-0" style="width: 20%;">Remarks, if any</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let control of presenting_complaints().controls; let i=index" [formGroupName]="i">
                            <td class="border-0" style="width: 20%;">{{control.get('symptoms').value}}</td>
                            <td class="border-0" style="width: 5%;">
                                <div class="form-group custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" formControlName="check_box" [id]="'check_box_'+i"
                                        (change)="onSymptomsChanged($event,i)" class="custom-control-input">
                                    <label class="custom-control-label" [for]="'check_box_'+i"></label>
                                </div>
                            </td>
                            <td class="border-0" style="width: 20%;">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm" id="day_of_consultation"
                                        bsDatepicker [maxDate]="maxDate"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                        onpaste="return false" onkeypress='return event.charCode == 8'
                                        formControlName="day_of_consultation" placeholder="Day of consultation">
                                </div>
                            </td>
                            <td class="border-0" style="width: 20%;">
                                <div lass="form-group">
                                    <select class="form-control form-control-sm" id="stage" formControlName="stage">
                                        <option value="">Select Stage</option>
                                        <option value="MILD">MILD</option>
                                        <option value="MODERATE">MODERATE</option>
                                        <option value="SEVERE">SEVERE</option>
                                    </select>
                                </div>

                            </td>
                            <td class="border-0" style="width: 20%;">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm"
                                        formControlName="remarks_if_any" id="remarks_if_any"
                                        placeholder="Remarks, if any">
                                    <!-- <select class="form-control form-control-sm" id="remarks_if_any"
                                        formControlName="remarks_if_any">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of remarksList" [value]="item.name">{{item.name}}
                                        </option>
                                    </select> -->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-xl-3 col-sm-6" *ngIf="form.get('symptoms').value">
                <label for="">Other Name</label>
                <input type="text" class="form-control form-control-sm" id="otherName" formControlName="otherName"
                    placeholder="Other Name">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">H/O Of illness</label>
                <input type="text" class="form-control form-control-sm" id="present_illness"
                    formControlName="present_illness" placeholder="present illness" >
            </div>
        </div>

        <h6>GENERAL EXAMINATION</h6>

        <div class="row" formGroupName="general_examination">
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">SPO2</label>
                <div class="input_wrapper">
                    <input type="text" class="form-control form-control-sm" id="SPO2" formControlName="SPO2"
                        placeholder="SPO2" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) '
                        [ngClass]="{ 'is-invalid': form.get('general_examination.SPO2').errors }"
                        (keyup)="keyUp('SPO2')" maxlength="3">
                    <div class="unit">%</div>
                    <div *ngIf="form.get('general_examination.SPO2').errors" class="invalid-feedback">
                        <div *ngIf="form.get('general_examination.SPO2').errors.min">Accept value from 40 to 205
                        </div>
                        <div *ngIf="form.get('general_examination.SPO2').errors.max">Accept value from 40 to 205
                        </div>
                        <div *ngIf="form.get('general_examination.SPO2').errors.required">SPO2% is required</div>
                    </div>
                </div>
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">PULSE</label>
                <div class="input_wrapper">
                    <input type="text" class="form-control form-control-sm" id="PULSE" formControlName="PULSE"
                        placeholder="PULSE" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                        maxlength="3" max="200" (keyup)="keyUp('PULSE')"
                        [ngClass]="{ 'is-invalid': form.get('general_examination.PULSE').errors }">
                    <div class="unit">bpm</div>
                    <div *ngIf=" form.get('general_examination.PULSE').errors" class="invalid-feedback">
                        <div *ngIf="form.get('general_examination.PULSE').errors.min">Accept value from is 30 to 200
                        </div>
                        <div *ngIf="form.get('general_examination.PULSE').errors.max">Accept value from is 30 to 200
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">TEMPERATURE</label>
                <div class="input_wrapper">
                    <input type="text" class="form-control form-control-sm" id="TEMPERATURE"
                        formControlName="TEMPERATURE" maxlength="10" (keyup)="keyUp('TEMPERATURE')"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) ||event.charCode == 46 '
                        placeholder="TEMPERATURE" [ngClass]="{ 'is-invalid': form.get('general_examination.TEMPERATURE').errors }">
                    <div class="unit">&deg; F</div>
                    <div *ngIf="form.get('general_examination.TEMPERATURE').errors" class="invalid-feedback">
                        <div>
                            Accept value from 90 to 110
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">Respiratory Rate</label>
                <input type="text" class="form-control form-control-sm" id="RR" formControlName="RR" placeholder="Respiratory Rate Per Minute"
                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57) ' maxlength="2"
                    [ngClass]="{ 'is-invalid': form.get('general_examination.RR').errors }">
                    <div *ngIf="form.get('general_examination.RR').errors" class="invalid-feedback">
                        <div>
                            Accept value from 12 to 40
                        </div>
                    </div>
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">WEIGHT</label>
                <div class="input_wrapper">
                    <input type="text" class="form-control form-control-sm" id="WEIGHT" formControlName="WEIGHT"
                        placeholder="WEIGHT" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) '
                        maxlength="6" class="form-control form-control-sm text-uppercase" (keyup)="keyUp('WEIGHT')"
                        [ngClass]="{ 'is-invalid': form.get('general_examination.WEIGHT').errors}">
                    <div class="unit">kg</div>
                    <div *ngIf="form.get('general_examination.WEIGHT').errors" class="invalid-feedback">
                        <div *ngIf="form.get('general_examination.WEIGHT').errors.min">Accept value from 1 to 200.99</div>
                        <div *ngIf="form.get('general_examination.WEIGHT').errors.max">Accept value from 1 to 200.99</div>
                      </div>
                </div>
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">Systolic</label>
                <div class="input_wrapper">
                    <input type="text" autocomplete="off" formControlName="Systolic" id="Systolic"
                        (keyup)="keyUp('Systolic')" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                        maxlength="3" class="form-control form-control-sm text-uppercase" (change)="onSysDia()"
                        [ngClass]="{ 'is-invalid': form.get('general_examination.Systolic').errors}">
                    <div class="unit">mmHg</div>
                    <div *ngIf="form.get('general_examination.Systolic').errors"
                    class="invalid-feedback">
                    <div
                        *ngIf="form.get('general_examination.Systolic').errors.min">
                        Accept
                        value from 50 to 400 </div>
                    <div
                        *ngIf="form.get('general_examination.Systolic').errors.max">
                        Accept
                        value from 50 to 400 </div>
                    <div
                        *ngIf="form.get('general_examination.Systolic').errors.sys">
                        Enter Valid Value
                    </div>
                    <div
                        *ngIf="form.get('general_examination.Systolic').errors.required">
                        Systolic is required
                    </div>
                </div>
                </div>
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">Diastolic</label>
                <div class="input_wrapper">
                    <input type="text" autocomplete="off" formControlName="Diastolic" id="Diastolic"
                        (keyup)="keyUp('Diastolic')" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) '
                        maxlength="3" class="form-control form-control-sm text-uppercase" (change)="onSysDia()"
                        [ngClass]="{ 'is-invalid': form.get('general_examination.Diastolic').errors}">
                    <div class="unit">mmHg</div>
                    <div *ngIf="form.get('general_examination.Diastolic').errors"
                    class="invalid-feedback">
                    <div
                        *ngIf="form.get('general_examination.Diastolic').errors.min">
                        Accept
                        value from 30 to 200</div>
                    <div
                        *ngIf="form.get('general_examination.Diastolic').errors.max">
                        Accept
                        value from 30 to 200</div>
                    <div
                        *ngIf="form.get('general_examination.Diastolic').errors.dia">
                        Diastolic should be less than Systolic
                    </div>
                    <div
                        *ngIf="form.get('general_examination.Diastolic').errors.required">
                        Diastolic is required
                    </div>
                </div>
                </div>
            </div>
        </div>

        <h6>Test</h6>

        <div class="row" formGroupName="test">
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">RT PCR</label>
                <input type="text" class="form-control form-control-sm" id="RT_PCR" formControlName="RT_PCR"
                    placeholder="RT PCR">
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">HRCT</label>
                <input type="text" class="form-control form-control-sm" id="HRCT" formControlName="HRCT"
                    placeholder="HRCT">
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">CRP</label>
                <input type="text" class="form-control form-control-sm" id="CRP" formControlName="CRP"
                    placeholder="CRP">
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">IL6</label>
                <input type="text" class="form-control form-control-sm" id="IL6" formControlName="IL6"
                    placeholder="IL6">
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">D-DIMER</label>
                <input type="text" class="form-control form-control-sm" id="D_DIMER" formControlName="D_DIMER"
                    placeholder="D-DIMER">
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">S.FERRITIN</label>
                <input type="text" class="form-control form-control-sm" id="S_FERRITIN" formControlName="S_FERRITIN"
                    placeholder="S.FERRITIN">
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">LDH</label>
                <input type="text" class="form-control form-control-sm" id="LDH" formControlName="LDH"
                    placeholder="LDH">
            </div>
            <div class="form-group col-xl-3 col-sm-6">
                <label for="">ESR</label>
                <input type="text" class="form-control form-control-sm" id="ESRL" formControlName="ESRL"
                    placeholder="ESR">
            </div>
        </div>

        <h6>CLINICAL EVALUATION OF THE PATIENT</h6>
        <div formGroupName="clinical_evalution_of_the_patient">
            <div class="row">
                <div class="form-group col-xl-6">
                    <label for="" class="mr-2">Has the patient undergone home
                        isolation as per medical advice?</label><br />
                    <div class="custom-control custom-radio custom-control-inline ">
                        <input class="custom-control-input" id="isolation_Yes" type="radio"
                            formControlName="medical_advice" [value]="true">
                        <label class="custom-control-label" for="isolation_Yes"> Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" id="isolation_No" type="radio"
                            formControlName="medical_advice" [value]="false">
                        <label class="custom-control-label" for="isolation_No">No</label>
                    </div>
                </div>
                <div class="form-group col-xl-6">
                    <label for="">No. of Days since the patient
                        started home
                        isolation</label>
                    <input type="text" class="form-control form-control-sm col-xl-6 col-sm-6"
                        id="started_home_isolation" formControlName="started_home_isolation"
                        placeholder="Patient isolation">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">Vaccination Status</label>
                    <select class="form-control form-control-sm" formControlName="vaccination_status"
                        id="vaccination_status">
                        <option value="">Select</option>
                        <option *ngFor="let item of VaccinesStatusList" [value]="item.name">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">If vaccinated, Vaccine
                        Name</label>
                    <select class="form-control form-control-sm" formControlName="vaccine_name" id="vaccine_name">
                        <option value="">Select</option>
                        <option *ngFor="let item of VaccinesList" [value]="item.name">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label for="">Date</label>
                    <input type="text" class="form-control form-control-sm" formControlName="vaccination_date"
                        bsDatepicker [maxDate]="maxDate"
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                        onpaste="return false" onkeypress='return event.charCode == 8' id="vaccination_date"
                        placeholder="Date">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-xl-6 col-sm-6">
                    <label for="">Symptom Status &nbsp;&nbsp;</label>

                    <div class="custom-control custom-radio custom-control-inline ">
                        <input class="custom-control-input" formControlName="symptom_status" id="Symptomatic"
                            type="radio" value="Symptomatic">
                        <label class="custom-control-label" for="Symptomatic"> Symptomatic</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" formControlName="symptom_status" id="Asymptomatic"
                            type="radio" value="Asymptomatic">
                        <label class="custom-control-label" for="Asymptomatic">Asymptomatic</label>
                    </div>
                </div>
                <div class="form-group col-xl-6 col-sm-6">
                    <label for="">Other</label>
                    <input type="text" class="form-control form-control-sm" placeholder="Other Name"
                        formControlName="other_name" id="other_name">

                </div>
            </div>
        </div>
        <h6>Medicines being taken by the patient for COVID 19: (Select appropriate
            options from the
            list of medicines taken/being taken)</h6>
        <div class="row" formGroupName="medicines_being_taken">
            <div class="form-group col-xl-6 col-sm-6">
                <!-- multi select -->
                <label for="">Medicines</label>
                <ng-multiselect-dropdown [settings]="MedicinesSettings" [placeholder]="'Select'" [data]="MedicinesList"
                    formControlName="Medicines">
                </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-xl-12 col-sm-6">
                <label for="">Other Rx for COVID 19</label>
                <textarea class="form-control form-control-sm" rows="3" id="other_Rx_for_COVID19"
                    formControlName="other_Rx_for_COVID19" placeholder="Other Rx for COVID 19"></textarea>
            </div>
            <div class="form-group col-xl-12 col-sm-12">
                <label for="">Any other medicine(s) that is/are
                    being taken by patient (AYUSH medicines/Home remedies etc)</label>
                <textarea class="form-control form-control-sm" rows="3" id="any_other_medicines"
                    formControlName="any_other_medicines" placeholder="Any other medicine(s)"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-xl-6 col-sm-6">
                <label for="">Stages:</label>
                <select class="form-control form-control-sm" id="stage" formControlName="stage">
                    <option value="">Select</option>
                    <option *ngFor="let item of stageList" [value]="item.name">{{item.name}}</option>
                </select>
            </div>
            <div class="form-group col-xl-6 col-sm-6">
                <table class="table bordered-0 table-sm" style="width: 100%;">
                    <thead>
                        <tr>
                            <th class="text-center">MILD</th>
                            <th class="text-center">MODERATE</th>
                            <th class="text-center">SEVERE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">{{stages.MILD}}</td>
                            <td class="text-center">{{stages.MODERATE}}</td>
                            <td class="text-center">{{stages.SEVERE}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>