<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row small">
            <div class="col-xl-3"></div>
            <div class="col-xl-6">
                <webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                    *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                    [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                    (initError)="handleInitError($event)"></webcam>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="camOnOff()" [hidden]="isShow">On/Off</button> <button type="button" (click)="toggleWebcam()" [hidden]="isShow">Show/Hide</button>
    <input type="submit" class="btn btn-sm btn-primary" value="Upload" (click)="triggerSnapshot();Upload()">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="onClose()">Exit</button>
</div>