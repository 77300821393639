<div id="cnfrmModal" class="modal fade" style="z-index: 99999;">
    <div class="modal-dialog modal-confirm" style="padding-left: 8%;">
        <div class="modal-content" style="width: 80%;">
            <div class="modal-header" style="background: whitesmoke;">
                <div class="icon-box">
                    <i class="material-icons">&#xE88E;</i>
                </div>
                <h4 class="modal-title">Prakruti Confirmation!</h4>
                <div class="icon-box">
                    <i class="material-icons" data-dismiss="modal" style="cursor: pointer;" title="Close">&#xE888;</i>
                </div>
            </div>
            <div class="modal-body">
                <p class="text-center">Do you want <b>Prakruti Constitution</b> details in <b
                        style="text-decoration: underline;">Prescription</b><b>.?</b></p>
            </div>
            <div class="modal-footer">
                <div class="row" hidden>
                    <div class="col-md-6">
                        <button type="button" class="btn btn-sm btn-success btn-block" data-dismiss="modal"
                            (click)="isShowPC=true">Yes</button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="btn btn-sm btn-dark btn-block" data-dismiss="modal"
                            (click)="isShowPC=false">No</button>
                    </div>
                </div>
                <table style="width: 100%;">
                    <tr>
                        <td style="width: 48%;">
                            <button type="button" class="btn btn-sm btn-info btn-block" data-dismiss="modal"
                                (click)="isShowPC=true;onPrescription();">Yes</button>
                        </td>
                        <td style="width: 4%"></td>
                        <td style="width: 48%;">
                            <button type="button" class="btn btn-sm btn-secondary btn-block" data-dismiss="modal"
                                (click)="isShowPC=false;onPrescription();">No</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>