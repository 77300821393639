<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<!-- <div class="modal-body">

    <div class="container-fluid">

<form>
    <div class="form-row">
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">History of Present illness</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">History of Past illness</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">Surgical Histroy</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">Family Histroy</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">Personal History</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">Dietary History</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group mb-1 col-md-12 m-0">
            <label class="labelfontotherheadings">Any Other Relevant
                History</label>
        </div>
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">Menstrual History</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group mb-1 col-md-6">
            <label class="labelfont">Dietary History</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group  col-md-6">
            <label class="labelfont">Child Developement History</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="form-group  col-md-6">
            <label class="labelfont">Others</label>
            <textarea class="form-control  form-control-sm" rows="2"></textarea>
        </div>
        <div class="col-md-12 text-center">
            <button type="button"
                class="btn btnwidth  btn-secondary ">Save</button>
        </div>
    </div>
</form>
</div>
</div>  -->

<div class="modal-body">
    <table class="table table-bordered table-sm small" *ngFor="let item of history">
        <tr class="active bg-light">
            <td colspan="4">
                <h6>Patient Details</h6>
            </td>
        </tr>
        <tr>
            <td><strong>Name</strong> </td>
            <td>{{item.beneficiary_id.first_name + ''+ item.beneficiary_id.last_name}}</td>
            <td><strong>ID</strong></td>
            <td>{{item.beneficiary_id.id}}</td>
        </tr>
        <tr>
            <td><strong>Phone Number</strong></td>
            <td>{{item.beneficiary_id.mobile}}</td>
            <td><strong>Age</strong></td>
            <td>{{item.beneficiary_id.age}}</td>
        </tr>
        <tr>
            <td><strong>Gender</strong></td>
            <td>{{item.beneficiary_id.gender_id == 1 ? 'Male':'Female'}}</td>
            <td><strong>Address</strong></td>
            <td>{{item.beneficiary_id.address}}</td>
        </tr>

        <tr>
            <td colspan="4" class="bg-light">
                <h6>Doctor Details</h6>
            </td>
        </tr>
        <tr>
            <td><strong>Name </strong></td>
            <td>{{item.doctor_id.first_name+''+item.doctor_id.last_name}}</td>
            <td><strong>Qualification</strong></td>
            <td>{{item.doctor_id.qualification_id}}</td>
        </tr>
        <tr>
            <td><strong>Specialization</strong></td>
            <td>{{item.doctor_id.specialization_id}}</td>
            <td><strong>MCI Number :</strong></td>
            <td>{{item.doctor_id.mci_number}}</td>
        </tr>
        <!-- <tr>
            <td><strong>Date</strong></td>
            <td>2018-04-30 12:02</td>
            <td><strong>Address</strong></td>
            <td>Telangana,Hyderabad
                kattedan,</td>
        </tr> -->

    </table>


    <table class="table table-bordered table-sm small" *ngFor="let item of history">
        <tr class="bg-light">
            <td class="active text-center" colspan="6">
                <h6>Prescription</h6>
            </td>

        </tr>
        <tr>
            <td colspan="2"><strong>Chief Complaints/ History of Present Illness:</strong></td>
         <td colspan="10">{{item.cc_brief_history.chiefComplaints + ',' +item.cc_brief_history.otherComplaints+ ','+item.cc_brief_history.subtype+ ','
               + item.cc_brief_history.otherSubtype+ ','+item.cc_brief_history.chiefComplaints+ ',' +item.cc_brief_history.chiefComplaints}}</td>
        </tr>
        <tr>
            <td colspan="2"><strong>Medical History:</strong></td>
            <td colspan="10">{{item.medical_history.pastIllness + ',' + item.medical_history.surgicalHistory}}</td>
        </tr>
        <tr>
            <td colspan="2"><strong>Vitals History:</strong></td>
            <td colspan="10">{{item.vital_history.temperature + ',' + item.vital_history.pulse  + ',' + item.vital_history.systolic + ',' + item.vital_history.diastolic}}</td>
        </tr>
        <tr>
            <td colspan="2"><strong>Past History & Allergies</strong></td>
            <td colspan="10">{{item.other_history.familyHistory + ',' + item.other_history.personalHistory + ',' + item.other_history.dietaryHistory+ ',' + item.other_history.history
                + ',' + item.allergy.drugsSensitivity+ ',' + item.allergy.allergies}}</td>
        </tr>
        <tr>
            <td colspan="2"><strong>Provisional Diagnosis</strong> </td>
            <td colspan="10">{{item.diagnosis.provisionalDiagnosis + ',' +item.diagnosis.other}}</td>
        </tr>
        <tr>
            <td colspan="2"><strong>Investigations</strong></td>
            <td colspan="10">{{item.investigation.master + ',' +item.investigation.otherInvestigation}}</td>
        </tr>
        <tr>
            <td colspan="2"><strong>Advice</strong></td>
            <td colspan="10">{{item.advice.restExercise + ',' +item.advice.diet+ ',' +item.advice.followAdvice+ ',' +item.advice.referral}}</td>
        </tr>
        <tr>
            <th><strong>Drug Type</strong></th>
            <th>Drug Name</th>
            <th>Dosage</th>
            <th>Direction</th>
            <th>Instruction</th>
            <th>No of Days</th>
        </tr>

        <tr>

            <td>{{item.prescribed_medications.drugType}}</td>
            <td>{{item.prescribed_medications.drugName}}</td>
            <td>{{item.prescribed_medications.dosage}}</td>
            <td>{{item.prescribed_medications.direction}}</td>
            <td>{{item.prescribed_medications.instruction}}</td>
            <td>{{item.prescribed_medications.noofdays}}</td>
        </tr>
    </table>




</div>