import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Common } from 'src/app/common';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FileService } from 'src/app/shared/services/file.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { ImagePreviewComponent } from 'src/app/modules/modals/image-preview/image-preview.component';

@Component({
  selector: 'app-laboratory-registration',
  templateUrl: './laboratory-registration.component.html',
  styleUrls: ['./laboratory-registration.component.css'],
  providers: [DatePipe]
})

export class LaboratoryRegistrationComponent implements OnInit {

  @Input() isEditable: boolean = false;
  @Input() title: string;
  @Input() labData: any;
  @Input() isSubmit_Disabled: boolean = false;

  labForm: FormGroup; submitted = false; medName = ''; medId = 0;
  maxdate : Date;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  hospitalList: Array<any> = [];
  labList: Array<any> = [];
  investigationlist: any;
  investigations: Array<any> = [];
  investigationsEditLst: Array<any> = [];
  bankNamesList: Array<any> = [];
  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string, document_path: string }> = [];
  isGNM = environment.isGNM; isSAGE = environment.isSAGE;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; isNIG = environment.isNIG;
  getAllcenters: any; getagents: any
  Flagslist: any;
  hideLabInvestigationButton: boolean;
  hideLogisticDetailFields: boolean;
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private registrationService: RegistrationService,
    private authServ: AuthenticationService,
    private apiServ: ApiService,
    private fileService: FileService,
    private datePipe: DatePipe
    , private userServ: UserService
    , private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.initFormControls(), this.locationChanged('', 'state');
    //  this.locationChanged('36', 'district');//QA Pointing
  //  this.locationChanged('', 'district');//Training
    this.defaultLocations(), this.getAllMasters(), this.getAllInvestigations();
    this.registrationService.getallagentspharmacy().subscribe(data => {
      // this.getagents = data.data;
      this.getagents=data.data.sort((a,b)=>(a.first_name[0]>b.first_name[0])?1:-1);

    })
    if (this.isNIG)
      this.labForm.get('ifsc_code').setValidators(null), this.labForm.get('ifsc_code').updateValueAndValidity();
    this.getallcentersGrid();
    this.getUserPermission();
  }

  initFormControls() {
    this.labForm = this.formBuilder.group({
      id: ['0'],
      user_id: [null],
      lab_name: ['', Validators.required],
      lab_conatct_no: ['', Validators.required],
      contact_person_name: ['', Validators.required],
      contact_person_mobile: ['', [Validators.required, Validators.minLength(+this.maxLngth)]],
      registration_no: ['', Validators.required],
      location_type_id: ['', Validators.required],
      //  state_id: [36, Validators.required],//QA Pointing
      state_id: ['', Validators.required], // Training
      district_id: ['', Validators.required],
      mandal_id: [''],
      city_id: [''],
      village_id: [''],
      shop_no: [''],
      agent_id: [null],
      // center_id:[''],
      address: ['', Validators.required],
      pin_code: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      whatsapp_number: ['', Validators.pattern(this.mobPtrn)],
      bank_account_number: [''],
      ifsc_code: ['', Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')],
      branch_name: [''],
      branch_location: [''],
      nabl_expiry_date: [''],
      registration_exp_date: ['', Validators.required],
      is_headoffice: [false],
      nabl: [false],
      isHospitalLab: [false],
      hospital_id: [''],
      hospitalAddress: [{ value: '', disabled: true }],
      registeredLab: [''],
      login_name: [''],
      password: [''],
      lab_investigation: this.formBuilder.array([]),

      regFile: ['', Validators.required],
      nablFile: [''],
      digitalsignature: ['', Validators.required],
      account_activated: [true],
      is_active: [true],
      "latitude": "60",
      "longitude": "60",
      

      lab_sample_collection_person: [[]],
      isLogistic: [false],
      person_name: [''],
      collection_person_email: ['', Validators.email],
      collection_person_mobile_no: ['', [Validators.pattern(this.mobPtrn), Validators.maxLength(+this.maxLngth)]],
    });
  }

  defaultLocations() {
    ['RegistrationCertificate', 'NABLCertificate', 'DigitalSignature'].forEach((element, ind) => this.uploadFileList.push({ id: 0, reg_id: ind, name: element, File: null, file_name: '', extensions: '', fileType: ['1', '3', '8'][ind], document_path: '' }));
    ['png,jpg,jpeg,pdf', 'png,jpg,jpeg,pdf', 'png,jpg,jpeg,pdf'].forEach((item, i) => this.uploadFileList[i].extensions = item);
  }

  get f() { return this.labForm.controls; }

  isHospitalChanged(val: any) {
    if (val != '' && val != undefined) {
      let item = this.hospitalList.find(f => f.id == val);
      Promise.all([this.locationChanged(item.state_id, 'district'),
      item.locationtype_id == 1 ? (this.locationChanged(item.district_id, 'mandal'),
        this.locationChanged(item.mandal_id, 'village')) : this.locationChanged(item.district_id, 'city')])
        .then(() => {
          this.labForm.patchValue({
            hospitalAddress: this.hospitalList.filter(f => f.id == val)[0].address, state_id: item.state_id,
            location_type_id: item.locationtype_id, district_id: item.district_id, mandal_id: item.mandal_id, village_id: item.village_id,
            city: item.city_id, address: item.address
            // center_id:item.center_id
          });
        });
    }
    else {
      // this.labForm.patchValue({
      //   hospitalAddress: '', 
      //   state_id: '', 
      //   location_type_id: '', 
      //   district_id: '', 
      //   mandal_id: '', 
      //   village_id: '', 
      //   city: '', 
      //   address: ''
      //   // ,center_id:''
      // });
      this.initFormControls();
      this.districtList = this.mandalList = this.villageList = this.cityList = [];
    }
  }

  onRegLabChange(val: any) {
    if (val != '') {
      let [item, hospId] = [this.labList.find(f => f.id == val), this.labForm.value.hospital_id];

      this.initFormControls();
      Promise.all([this.locationChanged(item.state_id, 'district'),
      item.location_type_id == 1 ? (this.locationChanged(item.district_id, 'mandal'),
        this.locationChanged(item.mandal_id, 'village')) : this.locationChanged(item.district_id, 'city')])
        .then(() => {
          let data = item;
          if (item.nabl_expiry_date != '')
            data.nabl = true;
          this.labForm.patchValue(data);
          if (hospId != '')
            this.labForm.patchValue({
              hospital_id: hospId, isHospitalLab: true,
              hospitalAddress: this.hospitalList.filter(f => f.id == hospId)[0].address,
              registeredLab: item.id
            }); //this.isHospitalChanged(data.hospital_id.id);
          this.labForm.controls['regFile'].disable();
          this.labForm.controls['nablFile'].disable();
          this.labForm.controls['digitalsignature'].disable();
        });
    }
    else {
      let [hosid, hosAdr] = [this.labForm.value.hospital_id, this.labForm.getRawValue().hospitalAddress];
      this.initFormControls();
      this.districtList = this.mandalList = this.villageList = this.cityList = [];
      this.labForm.patchValue({ isHospitalLab: true, hospital_id: hosid, hospitalAddress: hosAdr });
      this.labForm.controls['regFile'].enable();
      this.labForm.controls['nablFile'].enable();
      this.labForm.controls['digitalsignature'].enable();
    }
  }

  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == 9) {
        if (+val[0] == environment.mobValdValSAGE)
          this.labForm.get(type).setValue('');
      }
      if (+this.maxLngth == 10) {
        if (+val[0] < environment.mobValdValIND)
          this.labForm.get(type).setValue('');
      }
    }
  }

  onSubmit(event: any) {
    if(this.labForm.value.is_active==true){
      this.labForm.value.account_activated=true;
    }
    else{
      this.labForm.value.account_activated=false;
    }
    event.preventDefault();
    this.submitted = true;
    this.getFormValidationErrors();
    if (this.getRowFormControls.invalid) {
      this.toastr.warning('Please Enter Rate for all selected Lab Investigation Names');
      return;
    } else if (this.labForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      this.labForm.patchValue({
        location_type_id: +this.labForm.value.location_type_id, state_id: +this.labForm.value.state_id, district_id: +this.labForm.value.district_id,
        city_id: +this.labForm.value.city_id, mandal_id: +this.labForm.value.mandal_id, village_id: +this.labForm.value.village_id,
        pin_code: +this.labForm.value.pin_code, hospital_id: +this.labForm.value.hospital_id
      })
      let user = this.authServ.currentUserValue, roleID = +sessionStorage.getItem('logUsrRoleId'), crntTentId = +sessionStorage.getItem('currentTenant');
      this.labForm.patchValue({ login_name: this.labForm.value.email, password: +this.labForm.value.id == 0 ? '123456' : this.labData ? this.labData.user_registration.password : '' });
      const sendData = JSON.parse(JSON.stringify(this.labForm.getRawValue()));
      if (sendData.agent_id == 0) {
        sendData.agent_id = null
      }
      Object.assign(sendData, {
        "alternate_mobile_no": user?.alternate_mobile_no, "contact_person_last_name": "",
        "registration_source_id": user?.master_registration_source?.id, "user_types": [{ user_type_id: "5" }],
        "branch_id": user?.orgnization_branch?.id ?? 0, "organization_id": roleID == 1 ? crntTentId : user?.orgnization_registration?.id,
      });

      sendData.registration_exp_date = this.datePipe.transform(sendData.registration_exp_date, 'yyyy-MM-dd HH:mm:ss');

      if (sendData.nabl_expiry_date != '')
        sendData.nabl_expiry_date = this.datePipe.transform(sendData.nabl_expiry_date, 'yyyy-MM-dd HH:mm:ss');

      if (+sendData.id == 0)
        delete sendData.id, delete sendData.user_id;

      if (sendData.isLogistic) {
        let logData = [];
        logData.push({
          "id": sendData.lab_sample_collection_person.length == 0 ? 0 :
            sendData.lab_sample_collection_person[sendData.lab_sample_collection_person.length - 1]?.id,
          "is_active": true, "person_name": sendData.person_name, "created_on": null,
          "collection_person_mobile_no": sendData.collection_person_mobile_no, "collection_person_email": sendData.collection_person_email
        });
        if (logData.length > 0)
          sendData.lab_sample_collection_person = logData;
      }

      ["regFile", 'nabl', 'nablFile', 'digitalsignature', 'isHospitalLab', 'hospitalAddress', 'registeredLab', 'lab_investigation',
        'isLogistic', 'person_name', 'collection_person_email', 'collection_person_mobile_no'].forEach(el => { delete sendData[el]; })
        
     if(this.labForm.value.is_active==true){
      sendData.account_activated=true;
     }else{
      sendData.account_activated=false;
     }
      this.registrationService.save('lab/saveandupdatelabregistration', sendData).subscribe(res => {
        let apiResponse = res;
        if (apiResponse.status == "OK") {
          this.activeModal.close('changes saved');
          let id: number = +(sendData.id ?? 0);
          this.save(apiResponse.data.id, this.investigations);

          if (id == 0) {
            const notificationData = { email: sendData.email, mobile_no: +sendData.contact_person_mobile, name: sendData.lab_name };
            this.send_Notification(notificationData);
          }

          // this.saveFile(apiResponse.data.id);
          // this.activeModal.close('changes saved');
          // this.initFormControls();
          // this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Laboratory Registration');
          if (this.uploadFileList.some(f => f.File != null))
            this.saveFile(apiResponse.data.id, id);
          else
            this.showMessage(id);
        }
      }, error => this.toastr.error(error.message));
    }
  }

  showMessage(id: number) {
    this.activeModal.close('changes saved');
    this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Laboratory Registration');
  }

  setLocationTypeValidators() {
    const [city_idControl, mandal_idControl, village_idControl] = [this.labForm.get('city_id'), this.labForm.get('mandal_id'), this.labForm.get('village_id')];

    this.labForm.get('location_type_id').valueChanges
      .subscribe(locationType => {
        if (this.isNIG)
          village_idControl.setValue(''), village_idControl.setValidators(null);
        if (+locationType == 2) {
          city_idControl.setValidators(null);
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }
        else if (locationType == 1) {
          city_idControl.setValidators(null);
          city_idControl.setValue('');
          mandal_idControl.setValidators([Validators.required]);
          if (!this.isNIG)
            village_idControl.setValidators([Validators.required]);
        }

        city_idControl.updateValueAndValidity();
        mandal_idControl.updateValueAndValidity();
        village_idControl.updateValueAndValidity();
        // this.locationChanged('', 'location');
      });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];

        // this.labForm.get('state_id').setValue('');
        this.labForm.get('district_id').setValue('');
        this.labForm.get('mandal_id').setValue('');
        this.labForm.get('village_id').setValue('');
        this.labForm.get('city_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
          //  query = `getstatebyid?stateId=36`; //QA Pointing
       query = `getstatebyid?stateId=${id}`; //Training Pointing
        this.labForm.get('mandal_id').setValue('');
        this.labForm.get('city_id').setValue('');
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
        this.labForm.get('village_id').setValue('');
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  pick(obj, keys) {
    return keys.map(k => k in obj ? { [k]: obj[k] } : {})
      .reduce((res, o) => Object.assign(res, o), {});
  }

  getFormValidationErrors() {
    Object.keys(this.labForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.labForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  restFormValues() {
    this.initFormControls();
    this.setLocationTypeValidators();
  }

  singleFileUpload(event: any, fileNumber: number, fileType: string) {
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 10)));
    // console.log("currentfile: ",currentfile);
    
      // Checking type of file
      let validate: any= this.fileService.isValidFileName(fileList.files[0].name);
      if(!validate){
       this.toastr.warning(`Please give Valid File Format`);
       return;
      }
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        // fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          return false;
        }
      }
      // The size of the file.
      if (currentfile >= (1024 * 10)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 10MB', 'Upload File Size Limit ');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = fileList.files[0].name;
        this.uploadFileList[fileNumber].fileType = fileType;
        if (this.labData) {
          let f = this.labData.lab_documents.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType);
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }

  saveFile(id: string, record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'LAB', item.fileType)));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  isHospitalab(value) {
    if (!value)
      this.labForm.patchValue({ hospital_id: '', hospitalAddress: '', registeredLab: '' })
  }

  getAllMasters() {
    const locationType = this.masterService.getAll('getalllocationtypes');
    const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');
    const lab = this.registrationService.getAll('lab/getalllabregistrations');
    const bankName = this.masterService.getAll('getallmasterbankdetails');

    forkJoin([locationType, hospital, lab, bankName]).subscribe(
      {
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available');
              // this.toastr.info('No records available', 'Pharmacy List');
              else {
                const assignList = {
                  '0': () => this.locationTypeList = list.data,
                  '1': () => this.hospitalList = list.data,
                  '2': () => this.labList = list.data,
                  '3': () => this.bankNamesList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          this.editLab();
        }
      });

  }
  cancelFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null;
    view_File.file_name = '';
  }
  // getUserPermission() {
  //   let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
  //   this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
  //     if (res.status == 'OK') {
  //       this.Flagslist = res.data;
  //       for (let i = 0; i < res.data.length; i++) {

  //         if (res.data[i].feature_id.feature_name == "hideLogisticDetailsinLabReg") {
  //           if (res.data[i].patient) {
  //             this.hideLogisticDetailFields = true;
  //           } else {
  //             this.hideLogisticDetailFields = false;
  //           }

  //         }
  //         if (res.data[i].feature_id.feature_name == "hideLabInvestigationButton") {
  //           if (res.data[i].patient) {
  //             this.hideLabInvestigationButton = true;
  //           } else {
  //             this.hideLabInvestigationButton = false;
  //           }

  //         }
  //       }
  //     }
  //   });
  // }
  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        for (let i = 0; i < res.data.length; i++) {

          if (res.data[i].feature_id.feature_name == "hideLogisticDetailsinLabReg") {
            if (res.data[i].patient) {
              this.hideLogisticDetailFields = true;
            } else {
              this.hideLogisticDetailFields = false;
            }

          }
          if (res.data[i].feature_id.feature_name == "hideLabInvestigationButton") {
            if (res.data[i].patient) {
              this.hideLabInvestigationButton = true;
            } else {
              this.hideLabInvestigationButton = false;
            }

          }
        }
      }
    });
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    const doctor_id = +this.labForm.get('id').value;
    const fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (fileExtension == 'pdf')
      (view_File.File) ? Common.showPDF_File(URL.createObjectURL(view_File.File)) : Common.showPDF_File(`${this.registrationService.viewFilePath}LAB/${doctor_id}/${view_File.name}/${view_File.document_path}`);
    else if (['jpg', 'jpeg', 'bmp', 'png'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File); // read file as data url
        // called once readAsDataURL is completed
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      else {
        imagePath = `${this.registrationService.viewFilePath}LAB/${doctor_id}/${view_File.name}/${view_File.document_path}`;
        modalRef.componentInstance.image = imagePath;
      }
    }
  }

  editLab() {
    if (this.labData) {
      this.labData.lab_documents.forEach(f => {
        const docu = this.uploadFileList.find(e => e.fileType == f.file_type_id);
        if (docu) {
          docu.id = f.id;
          docu.document_path = f.document_path;
          docu.file_name = f.file_name;
        }
      });
      this.labForm.controls.regFile.clearValidators();
      this.labForm.controls.regFile.updateValueAndValidity();
      this.labForm.controls.account_activated.enabled;
      // this.labForm.controls.is_active.disable();
      this.labForm.controls.digitalsignature.clearValidators();
      this.labForm.controls.digitalsignature.updateValueAndValidity();

      this.getInvestigations(this.labData.id);
      Promise.all([this.locationChanged(this.labData.state_id, 'district'),
      this.labData.location_type_id == 1 ? (this.locationChanged(this.labData.district_id, 'mandal'),
        this.locationChanged(this.labData.mandal_id, 'village')) : this.locationChanged(this.labData.district_id, 'city')])
        .then(() => {
          let [data, address] = [this.labData, ''];

          if (this.labData.nabl_expiry_date != '')
            data.nabl = false;
          this.labForm.patchValue(data);

          if (this.labData.hospital_id) {
            // data.isHospitalLab = true;
            address = this.hospitalList.filter(f => f.id == data.hospital_id.id)[0]?.address;
            this.labForm.patchValue({ isHospitalLab: true, hospital_id: data.hospital_id.id, hospitalAddress: address });
          }
          if (this.labData.registration_exp_date)
            this.labForm.get('registration_exp_date').setValue(new Date(this.labData.registration_exp_date));
          if (this.labData.nabl_expiry_date)
            this.labForm.get('nabl_expiry_date').setValue(new Date(this.labData.nabl_expiry_date));
          let labLogData = this.labData?.lab_sample_collection_person;
          setTimeout(() => {
            this.labForm.patchValue({
              agent_id: data.agent_id
            })
            var disable = document.getElementById('agent_id')
            disable.setAttribute("enabled", 'true');
          }, 2000);
          if (labLogData.length > 0) {
            this.labForm.patchValue({
              isLogistic: true, person_name: labLogData[labLogData.length - 1]?.person_name,
              collection_person_email: labLogData[labLogData.length - 1]?.collection_person_email, lab_sample_collection_person:
                labLogData, collection_person_mobile_no: labLogData[labLogData.length - 1]?.collection_person_mobile_no,
            });
          }
          else
            this.labForm.patchValue({ isLogistic: false, person_name: '', collection_person_email: '', collection_person_mobile_no: '' });
        });
    }
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  getAllInvestigations() {
    this.masterService.getAll('getallinvestigations').subscribe(res => {
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Lab List');
        else
          this.investigationlist = res.data;
        this.addLabInvestigations();
        // Common.loadDataTable(this.investigationlist, '#LabInvestigation');
      }
    });
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string }) {
    const postData = {
      "email": payload.email,
      "mobile_no": payload.mobile_no,
      "user_id": 5,
      "user_type": "lab", // "Lab User",
      "name": payload.name.toUpperCase(),
      "login_name": this.labForm.value.email,
    }
    this.notificationService.sendNotification(postData).subscribe();
  }

  tableRowForm(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      chk: [false],
      investigation_id: this.medId,
      investigation_name: this.medName,
      unit_price: [{ value: '', disabled: true }],
      is_active: [true]
    });
  }

  get getRowFormControls() {
    const control = this.labForm.get('lab_investigation') as FormArray;
    return control;
  }

  addRow(item) {
    this.medName = ''; this.medId = 0;
    [this.medName, this.medId] = [item.investigation, item.id];

    const control = this.labForm.get('lab_investigation') as FormArray;
    control.push(this.tableRowForm());
  }

  addLabInvestigations() {
    this.investigationlist.forEach(e => {
      this.addRow(e);
    });
    Common.loadDataTable(this.getRowFormControls.controls, '#LabInvestigation');
  }

  onCheckbox(index, value) {
    if (value)
      this.getRowFormControls.at(index).get('unit_price').setValidators(Validators.required), this.getRowFormControls.at(index).get('unit_price').enable();
    else
      this.getRowFormControls.at(index).get('unit_price').clearValidators(), this.getRowFormControls.at(index).get('unit_price').disable();
    this.getRowFormControls.at(index).get('unit_price').updateValueAndValidity();
  }

  onSaveInvestigations() {
    if (this.getRowFormControls.invalid) {
      this.toastr.warning('Please Enter price for all selected Lab Investigation Names');
      return;
    }
    let data = []; this.investigations = [];
    this.getRowFormControls.getRawValue().forEach(e => { if (e.chk) data.push(e); });
    data.forEach(e => {
      delete e.chk;
      if (this.investigationsEditLst.length > 0) {
        this.investigationsEditLst.forEach(e => {
          let item = data.filter(f => f.investigation_id == e.investigation_id);
          if (item.length == 0) {
            e.is_active = false;
            ["created_on", 'lab_id', 'updated_on'].forEach(el => { delete e[el]; })
            data.push(e);
          }
        });
      }
    });
    this.investigations = data, document.getElementById('myModalClose').click();
  }

  save(id: string, data: any) { //save investigation
    let payload = { lab_id: +id, investigations: data };
    if (data.length > 0) {
      this.registrationService.save('savelabinvestigation', payload).subscribe(res => {
        this.toastr.success('Investigations added')
      }, error => { this.toastr.error(error) });
    }
  }

  getInvestigations(id: number) { //get investigations for Edit
    this.investigationsEditLst = [], this.registrationService.getByLabID('getlabinvestigationsbylabid/?labId=', id).subscribe(res => {
      this.investigationsEditLst = res.data, this.EditInvestigation();
    }, error => { this.toastr.error(error) });
  }

  EditInvestigation() {
    if (this.investigationsEditLst.length > 0)
      this.getRowFormControls.value.forEach((e, i) => {
        let d = this.investigationsEditLst.filter(f => f.investigation_id == e.investigation_id);
        if (d.length > 0)
          this.getRowFormControls.at(i).patchValue({ id: d[0].id, chk: true, unit_price: d[0].unit_price }), this.getRowFormControls.at(i).get('unit_price').enable();
      });
    this.getRowFormControls.controls.sort((a, b) => ((a.get('chk').value) === b.get('chk').value) ? 0 : (a.get('chk').value) ? -1 : 1),
      Common.loadDataTable(this.getRowFormControls.controls, '#LabInvestigation');
  }

  onNabl(value) {
    if (value)
      this.labForm.controls.nabl_expiry_date.setValidators(Validators.required), this.labForm.controls.nablFile.setValidators(Validators.required);
    else
      this.labForm.controls.nabl_expiry_date.clearValidators(), this.labForm.controls.nablFile.clearValidators();
    this.labForm.controls.nabl_expiry_date.updateValueAndValidity(), this.labForm.controls.nablFile.updateValueAndValidity();
  }

  isChngLog() {
    let logsLst = ['person_name', 'collection_person_mobile_no', 'collection_person_email'];
    if (this.labForm.get('isLogistic').value)
      logsLst.forEach(e => { this.labForm.get(e).setValidators([Validators.required]); });
    else
      logsLst.forEach(e => { this.labForm.get(e).setValidators(null); });
    logsLst.forEach(e => { this.labForm.get(e).updateValueAndValidity(), this.labForm.get(e).setValue(''); });
  }
  getallcentersGrid() {
    let payreqt = {
      "state_id": 0,
      "district_id": 0,
      "location_type_id": 0,
      "mandal_id": 0
    }
    this.registrationService.getAllCenters(payreqt).subscribe(res => {
      // this.getAllcenters=data.data;
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.getAllcenters = res.data;
        //Common.loadDataTable(this.getAllcenters, '#centerregTable');
      }

    })
  }
  agentchange(agentid) {
    if (agentid != 'null') {
      this.registrationService.checklab(agentid).subscribe(res => {
        if (res.data.length == 0 || res.data == null) {
          this.registrationService.getallagentdatabyid(agentid).subscribe(res => {
            if (res.data) {
              let agentdata = res.data;
              var d = new Date();
              d.setMonth(d.getMonth() + 12);
              // var val=Math.floor(Math.random() * 9000000000) + 1
              //var val = Math.floor(1000 + Math.random() * 9000000000);
              //var val=Math.floor(Math.random() * 10000000000);
              var val = Math.floor(Math.random() * parseInt('1' + '5'.repeat(10 - 1)) + parseInt('6' + '9'.repeat(10 - 1)));
              this.locationChanged(agentdata.state_id, 'district')
              this.locationChanged(agentdata.district_id, 'mandal')
              this.locationChanged(agentdata.district_id, 'city')
              this.locationChanged(agentdata.mandal_id, 'village');
              setTimeout(() => {
                this.labForm.patchValue({
                  
                  address: agentdata.address,
                  state_id: agentdata.state_id,
                  location_type_id: agentdata.location_type_id,
                  district_id: agentdata.district_id,
                  city_id: agentdata.city_id,
                  village_id: agentdata.village_id,
                  mandal_id: agentdata.mandal_id,
                  lab_conatct_no: val,
                  contact_person_mobile: val,
                  //email:agentdata.mobile+agentdata.email,
                  email: agentdata.mobile + agentdata.pin_code + '@gmail.com',
                  pin_code: agentdata.pin_code,
                  bank_account_number: agentdata.bank_account_number,
                  bank_id: agentdata.bank_id,
                  branch_location: agentdata.branch_location,
                  ifsc_code: agentdata.ifsc_code,
                  is_active: agentdata.is_active,
                  name_as_per_bank: agentdata.name_as_per_bank,
                  pan_no: agentdata.pan_no,
                  lab_name: agentdata.first_name + ' ' + 'Lab',
                  registration_no: agentdata.mobile,
                  contact_person_name: agentdata.first_name,
                  registration_exp_date: d,
                  account_activated: true
                })
                // this.getallcentersGrid();
              }, 1000)
            }
          })
        } else {
          this.toastr.error("Lab Already Exist With this Agent")
        }
      })
    } else {
      this.initFormControls();
    }


  }
}