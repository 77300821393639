<div class="row">
    <div class="container-fluid pl-0 pr-0">
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-header-actions mx-auto">
            <div class="card-header">
             
                <div class="col-xl-7">
                  {{lable}} User Manual
                 </div>
                  <div class="col-xl-2">
                   <label>Select Language</label>
                 </div>
                   <div class="col-xl-3">
                   <select class="form-control form-control-sm" [(ngModel)]='languageId' (change)="changeLanguage($event.target.value)" >
                     <option value="0">Select</option>
                     <option value="1">English</option>
                     <option value="2">Hindi</option>
                     <!-- <option *ngFor='let item of languagesList' [value]="item.id">
                       {{item.language_name}}</option> -->
                   </select>
                  </div>
              
    </div>
          <div class="card-body">
            <div class="col-xl-12">
                <div id="htmldiv" class="unselectable" 
                style="font-size: 16px;color:#4c5a73"
                [innerHTML]="htmlContent"></div>
              </div>
          </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>