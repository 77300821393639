import { HttpErrorResponse } from '@angular/common/http';
import { Component, DoCheck, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-user-manual-global',
  templateUrl: './user-manual-global.component.html',
  styleUrls: ['./user-manual-global.component.css']
})
export class UserManualGlobalComponent implements OnInit {
languageId:any=1;
lable:'';
sId:any;
mId:any;
htmlContent:any
  videoIdMatch: any;
  languagesList: any;
  constructor(private activatedRoute: ActivatedRoute,
    private regS:RegistrationService,
    private router: Router,
    private toastr:ToastrService,
    private masterService:MasterService) {}
  ngOnInit() {
    this.languagesDD();
    this.lable = this.activatedRoute.snapshot.params['lable'];
    this.sId = this.activatedRoute.snapshot.params['id'];
    this.mId = this.activatedRoute.snapshot.params['mid'];
    this.getUserManual(this.sId ,this.mId)
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.lable = this.activatedRoute.snapshot.params['lable'];
        this.sId = this.activatedRoute.snapshot.params['id'];
        this.mId = this.activatedRoute.snapshot.params['mid'];
        this.languageId='1';
        this.getUserManual(this.sId ,this.mId)
      }
    });

  }

  changeLanguage(value:any){
    this.languageId=value
    this.getUserManual(this.sId,this.mId)
  }

  getUserManual(sId,mId){
    this.htmlContent="";
    let endPoint = `user/user-guide-mapping/gethtmlcontent/${mId}/${sId}/${this.languageId}/true`
    this.regS.get(endPoint).subscribe({
      next: res => {
        if (res.status == 'OK') {
          if(res.data==null || res.data.length==0){
            this.toastr.info(res.message);
          }else{
            this.htmlContent = res.data?.html_content?.content??"";
          //  this.extractVideoId( this.htmlContent)
            // console.log('hmltContent',JSON.parse(this.htmlContent))
          //  this.htmlContent.forEach(element => {
          //   console.log('element',element)
          //   this.extractVideoId()
          // });        
          
          }
        } else {
          let errMsg: string = res.message;
          
        }
      },
      error: (err: HttpErrorResponse) => {
       

      },
      complete: () => {
    
      },
    });
   

  }

  extractVideoId(link: string): string {
    this.videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    this.constructEmbedUrl(this.videoIdMatch)
    return this.videoIdMatch ? this.videoIdMatch : null;
    
  }

  constructEmbedUrl(videoId: string): string {
    return `https://www.youtube.com/embed/${videoId}`;
    
  }
  languagesDD(){
    this.masterService.getAll(`getalllanguages`,).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.languagesList = res.data??[]
  
  
        } else {
          let errMsg: string = res.message;
          
        }
      },
      error: (err: HttpErrorResponse) => {
       
  
      },
      complete: () => {
    
      },
    });
  }

}
