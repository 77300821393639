<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="OrgPlanForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Plan <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm text-uppercase" formControlName="license_plan"
                                [ngClass]="{ 'is-invalid': submitted && f.license_plan.errors }"
                                [attr.disabled]="isEdit ? true : null" #Plan>
                                <option value="">Select</option>
                                <option *ngFor="let plan of planList" [value]="plan.id">{{plan.license_plan}}</option>
                            </select>
                            <div *ngIf="submitted && f.license_plan.errors" class="invalid-feedback">
                                <div *ngIf="f.license_plan.errors.required">Plan is required</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Plan <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm text-uppercase" #Plan
                                [ngClass]="{ 'is-invalid': submitted && f.license_plan.errors }"
                                [attr.disabled]="isEdit ? true : null" formControlName="license_plan">
                                <option [value]="plnName">{{plnName}}</option>
                            </select>
                            <div *ngIf="submitted && f.license_plan.errors" class="invalid-feedback">
                                <div *ngIf="f.license_plan.errors.required">Plan is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Hospitals <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="4"
                                (change)="onValChng($event.target.value, 'hospital_count', 1000)"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_count.errors }"
                                class="form-control form-control-sm" formControlName="hospital_count">
                            <div *ngIf="submitted && f.hospital_count.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_count.errors.required">Hospitals is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Doctors <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="4"
                                (change)="onValChng($event.target.value, 'doctor_count', 1000)"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.doctor_count.errors }"
                                class="form-control form-control-sm" formControlName="doctor_count">
                            <div *ngIf="submitted && f.doctor_count.errors" class="invalid-feedback">
                                <div *ngIf="f.doctor_count.errors.required">Doctors is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Health Care Worker <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="4"
                                (change)="onValChng($event.target.value, 'agent_count', 1000)"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.agent_count.errors }"
                                class="form-control form-control-sm" formControlName="agent_count">
                            <div *ngIf="submitted && f.agent_count.errors" class="invalid-feedback">
                                <div *ngIf="f.agent_count.errors.required">Health Care Worker is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Patients <span *ngIf="!isChk" class="text-danger">*</span> </label>
                        <div>
                            <input autocomplete="off" maxlength="5" [attr.disabled]="isChk ? true : null"
                                type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                (change)="onValChng($event.target.value, 'patient_count', 10000, true)"
                                [ngClass]="{ 'is-invalid': submitted && f.patient_count.errors }" #Ptnt
                                class="form-control form-control-sm" formControlName="patient_count">
                            <div *ngIf="submitted && f.patient_count.errors" class="invalid-feedback">
                                <div *ngIf="f.patient_count.errors.required">Patients is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="Plan.value=='3' || plnId == 3" style="margin-top: 3%">
                    <div class="form-group">
                        <label class="pointer chk2">
                            <input type="checkbox" style="width: auto !important;" [checked]="isChk"
                                (change)="onChngCB()" /> <strong> Unlimited</strong>
                        </label>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Labs <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="4"
                                (change)="onValChng($event.target.value, 'lab_count', 1000)"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.lab_count.errors }"
                                class="form-control form-control-sm" formControlName="lab_count">
                            <div *ngIf="submitted && f.lab_count.errors" class="invalid-feedback">
                                <div *ngIf="f.lab_count.errors.required">Labs is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Pharmacies <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" [maxlength]="plnId==1?5:4"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                (change)="onValChng($event.target.value, 'pharmacy_count', plnId==1?10000:1000)"
                                [ngClass]="{ 'is-invalid': submitted && f.pharmacy_count.errors }"
                                class="form-control form-control-sm" formControlName="pharmacy_count">
                            <div *ngIf="submitted && f.pharmacy_count.errors" class="invalid-feedback">
                                <div *ngIf="f.pharmacy_count.errors.required">Pharmacies is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>