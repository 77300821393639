import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';

import { FileService } from 'src/app/shared/services/file.service';
import { Common, Avail } from 'src/app/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manualprescription',
  templateUrl: './manualprescription.component.html',
  styleUrls: ['./manualprescription.component.css']
})

export class ManualprescriptionComponent implements OnInit {
  
  constructor(public activeModal: NgbActiveModal, private fileSvc: FileService, private toastr: ToastrService) { }  
  @Input() title: string; @Input() consId: any; @Input() benId: any; @Input() piId: any; isCamAcsd: boolean; isShow = !window.location.href.includes('localhost');
  public showWebcam = true; public allowCameraSwitch = true; public multipleWebcamsAvailable = false; public deviceId: string; lclStream: any;
  public videoOptions: MediaTrackConstraints = { }; public errors: WebcamInitError[] = []; public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>(); private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public orgn = window.location.href.includes('localhost') ? environment.applicationURL : window.origin; imgPath = ''; isCamOn = true;

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => { this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1; }).catch((err) => { console.log("An error occurred: " + err), this.isCamAcsd = false, this.toastr.info('Please provide Camera access') }), sessionStorage.setItem('isMPSave', '0'); // this.checkWebCams();
  }

  checkWebCams() {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((s) => { this.isCamAcsd = true, this.lclStream = s,
      WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => { this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1; }); }).
      catch((err) => { console.log("An error occurred: " + err), this.isCamAcsd = false, this.toastr.info('Please provide Camera access');
    });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]), mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0], ab = new ArrayBuffer(byteString.length), ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ab], { type: mimeString }); // 'application/pdf' 'image/jpeg'
  }

  Upload() {
    if (this.webcamImage) {
      this.fileSvc.uploadFile(Common.form_Data('0', this.dataURItoBlob(this.webcamImage.imageAsDataUrl), this.benId, // consId
        Avail.BnfcryTxt.replace('/', ''), Avail.MnlPresFTId.toString(), this.piId.toString(), true)).subscribe(s => { this.webcamImage = null; // VstFilesTxt  // VstFleFTId
        if (s.status == "success")
          this.toastr.success('Uploaded Successfully'), this.toggleWebcam(), sessionStorage.setItem('isMPSave', '1'), this.activeModal.close('Close Click');
        else
          this.toastr.error(s.status);
        /* let res = s.response, link = document.createElement("a");
        this.imgPath = `${this.orgn}/${environment.viewFilePath}${Avail.VstFilesTxt}${res.document_path.split('_')[0]}/${Avail.MnlPres}/${res.document_path}`;
        link.setAttribute('href', this.imgPath) ,link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'), link.setAttribute('target', '_blank');
        setTimeout(() => { document.body.appendChild(link), link.click(), this.activeModal.close('Close Click'); }, 5000); */
      });
    }
  }

  camOnOff() {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((s) => { s.getTracks()[0].stop(); });
  }

  onClose() {
    this.toggleWebcam(), sessionStorage.setItem('isMPSave', '0'), this.activeModal.close('Cross click');
  }

}