import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FileService } from 'src/app/shared/services/file.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { ImagePreviewComponent } from 'src/app/modules/modals/image-preview/image-preview.component';

@Component({
  selector: 'app-pharmacy-registration',
  templateUrl: './pharmacy-registration.component.html',
  styleUrls: ['./pharmacy-registration.component.css'],
  providers: [DatePipe]
})

export class PharmacyRegistrationComponent implements OnInit {

  @Input() isEditable: boolean = false;
  @Input() title: string;
  @Input() pharmaData: any;
  @Input() isSubmit_Disabled: boolean = false;

  pharmacyForm: FormGroup;
  submitted = false;
  maxdate : Date;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  hospitalList: Array<any> = [];
  pharmacyList: Array<any> = [];
  bankNamesList: Array<any> = [];
  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string, document_path: string }> = [];
  isWhatsAppInvalid = false;
  getAllcenters: any;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; isNIG = environment.isNIG; getagents: any;
  Flagslist: any;
  hideLogisticDetailFields: boolean;
  isGNM = environment.isGNM; isSAGE = environment.isSAGE;
  exp_date: any=null;

  constructor(public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private masterService: MasterService,
    private authServ: AuthenticationService,
    private fileService: FileService
    , private userServ: UserService
    , private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.initFormControls(), this.locationChanged('', 'state'),
      //  this.locationChanged('36', 'district')//QA Pointing
     this.locationChanged('', 'district')//Training
    this.defaultLocations(), this.getAllMasters(); this.getallcentersGrid(); // this.setLocationTypeValidators();
    this.registrationService.getallagentspharmacy().subscribe(data => {
      // this.getagents = data.data;
      this.getagents=data.data.sort((a,b)=>(a.first_name[0]>b.first_name[0])?1:-1);
    })
    if (this.isNIG)
      this.pharmacyForm.get('ifsc_code').setValidators(null), this.pharmacyForm.get('ifsc_code').updateValueAndValidity();
    this.getUserPermission();
  }

  initFormControls() {
    this.pharmacyForm = this.formBuilder.group({
      id: ['0'],
      user_id: [null],
      pharmacy_name: ['', Validators.required],
      // pharmacyContactNo: ['', Validators.required],
      pharmacy_conatct_no: ['', Validators.required],
      contact_person_name: ['', Validators.required],
      contact_person_mobile: ['', Validators.required], // , Validators.minLength(10)
      registration_no: ['', Validators.required],
      location_type_id: ['', Validators.required],
      //  state_id: [36, Validators.required], //QA pointing
     state_id: ['', Validators.required],//Training
      district_id: ['', Validators.required],
      mandal_id: [''],
      city_id: [''],
      village_id: [''],
      shop_no: [''],
      agent_id: [null],
      // center_id:[''],
      pin_code: ['', Validators.required],
      address: ['', Validators.required],
      whatsapp_number: ['', [Validators.pattern(this.mobPtrn)]], // , Validators.minLength(10)
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      bank_account_number: [''],
      branch_name: [''],
      branch_location: [''],
      ifsc_code: ['', Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')],
      // pharmacy_documents:[''],
      registration_exp_date: ['', Validators.required],
      contact_person_last_name: [''],

      account_activated: [this.pharmaData?.account_activated==true?true:false],
      is_headoffice: [false],
      isHospitalPharmacy: [false],
      hospital_id: [''],
      hospitalAddress: [{ value: '', disabled: true }],
      registeredPharmacy: [''],
      regFile: ['', Validators.required],
      digitalsignature: ['', Validators.required],
      login_name: [''],
      password: [''],
      // pharmacy_documents:this.formBuilder.array([]),
      // is_active: [false],
      is_active: [this.pharmaData?.is_active==true?true:false],

      pharmacy_delivery_person: [[]],
      isLogistic: [false],
      person_name: [''],
      delivery_person_email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      delivery_person_mobile_no: ['', [Validators.pattern(this.mobPtrn), Validators.maxLength(+this.maxLngth)]],
    });
  }

  defaultLocations() {
    ['RegistrationCertificate', 'DigitalSignature', 'nablCert'].forEach((element, ind) => this.uploadFileList.push({ id: 0, reg_id: ind, name: element, File: null, file_name: '', extensions: '', fileType: ['1', '8', '2'][ind], document_path: '' }));
    ['png,jpg,jpeg,pdf', 'png,jpg,jpeg,pdf', 'png,jpg,jpeg,pdf'].forEach((item, i) => this.uploadFileList[i].extensions = item);
  }

  isHospitalChanged(val: any) {
    if (val != '' && val != undefined) {
      let item = this.hospitalList.find(f => f.id == val);
      Promise.all([this.locationChanged(item.state_id, 'district'),
      item.locationtype_id == 1 ? (this.locationChanged(item.district_id, 'mandal'),
        this.locationChanged(item.mandal_id, 'village')) : this.locationChanged(item.district_id, 'city')])
        .then(() => {
          this.pharmacyForm.patchValue({
            hospitalAddress: this.hospitalList.filter(f => f.id == val)[0].address, state_id: item.state_id,
            location_type_id: item.locationtype_id, district_id: item.district_id, mandal_id: item.mandal_id, village_id: item.village_id,
            city: item.city_id, address: item.address
          });
        });
    }
    else {
      this.pharmacyForm.patchValue({
        hospitalAddress: '', state_id: '', location_type_id: '', district_id: '', mandal_id: '',
        village_id: '', city: '', address: ''
      }), this.districtList = [], this.mandalList = [], this.villageList = [], this.cityList = [];
    }
  }

  onRegPharmacyChange(val: any) {
    if (val != '') {
      let [item, hospId] = [this.pharmacyList.find(f => f.id == val), this.pharmacyForm.value.hospital_id];
     
      this.initFormControls();
      Promise.all([this.locationChanged(item.state_id, 'district'),
      item.location_type_id == 1 ? (this.locationChanged(item.district_id, 'mandal'),
        this.locationChanged(item.mandal_id, 'village')) : this.locationChanged(item.district_id, 'city')])
        .then(() => {
          let data = item;
          let checkId = this.pharmacyForm.value.id = 0;

          this.pharmacyForm.patchValue(data);
          data = checkId
          if (hospId != '')
            this.pharmacyForm.patchValue({
              hospital_id: hospId, isHospitalPharmacy: true,
              hospitalAddress: this.hospitalList.filter(f => f.id == hospId)[0].address,
              registeredPharmacy: item.id
            }); //this.isHospitalChanged(data.hospital_id.id);
          this.pharmacyForm.controls['regFile'].disable();
          this.pharmacyForm.controls['digitalsignature'].disable();
        });
    }
    else {
      let [hosid, hosAdr] = [this.pharmacyForm.value.hospital_id, this.pharmacyForm.getRawValue().hospitalAddress];
      this.initFormControls();
      this.districtList = this.mandalList = this.villageList = this.cityList = [];
      this.pharmacyForm.patchValue({ isHospitalPharmacy: true, hospital_id: hosid, hospitalAddress: hosAdr });
      this.pharmacyForm.controls['regFile'].enable();
      this.pharmacyForm.controls['digitalsignature'].enable();
      
    }
  }

  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == 9) {
        if (+val[0] == environment.mobValdValSAGE)
          this.pharmacyForm.get(type).setValue('');
      }
      if (+this.maxLngth == 10) {
        if (+val[0] < environment.mobValdValIND)
          this.pharmacyForm.get(type).setValue('');
      }
    }
  }

  isHospitapharmacy(value) {
    if (!value)
      this.pharmacyForm.patchValue({ hospital_id: '', hospitalAddress: '', registeredPharmacy: '' })
  }

  singleFileUpload(event: any, fileNumber: number, fileType: string) {
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, 
      currentfile = Math.round((fsize / (1024 * 10)));
    
      // Checking type of file
      if (fileList.files[0]) {
        let validate: any= this.fileService.isValidFileName(fileList.files[0].name);
      if(!validate){
       this.toastr.warning(`Please give Valid File Format`);
       return;
      }
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        // fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          return false;
        }
      }
      // The size of the file.
      if (currentfile>= (1024 * 10)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 10MB', 'Upload File Size Limit ');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = fileList.files[0].name;
        this.uploadFileList[fileNumber].fileType = fileType;
        if (this.pharmaData) {
          let f = this.pharmaData.pharmacy_documents.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType);
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }

  saveFile(id: string, record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'PHARMACY', item.fileType)));
    });
    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  get f() { return this.pharmacyForm.controls; }

  onSubmit(event: any) {

   
    let whatsAppNo = this.pharmacyForm.get('whatsapp_number').value;
    // this.isWhatsAppInvalid = whatsAppNo != '' ? (whatsAppNo.length < this.maxLngth) : false;
    event.preventDefault(), this.submitted = true, this.isWhatsAppInvalid = whatsAppNo != '' ? (whatsAppNo.length < this.maxLngth) : false,
      this.getFormValidationErrors();

    if (this.pharmacyForm.value.registration_exp_date == '' || this.pharmacyForm.value.registration_exp_date == undefined || this.pharmacyForm.value.registration_exp_date == null) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if ((this.pharmacyForm.value.regFile == '' || this.pharmacyForm.value.regFile == undefined || this.pharmacyForm.value.regFile == null) && +this.pharmacyForm.get('id').value == 0) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if ((this.pharmacyForm.value.digitalsignature == '' || this.pharmacyForm.value.digitalsignature == undefined || this.pharmacyForm.value.digitalsignature == null) && +this.pharmacyForm.get('id').value == 0) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if (this.pharmacyForm.value.contact_person_name == "" || this.pharmacyForm.value.contact_person_name == undefined || this.pharmacyForm.value.contact_person_name == null) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if (this.pharmacyForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      this.pharmacyForm.patchValue({
        location_type_id: +this.pharmacyForm.value.location_type_id, state_id: +this.pharmacyForm.value.state_id, district_id: +this.pharmacyForm.value.district_id,
        city_id: +this.pharmacyForm.value.city_id, mandal_id: +this.pharmacyForm.value.mandal_id, village_id: +this.pharmacyForm.value.village_id,
        pin_code: +this.pharmacyForm.value.pin_code, hospital_id: +this.pharmacyForm.value.hospital_id, agent_id: +this.pharmacyForm.value.agent_id
      })

      let user = this.authServ.currentUserValue, roleID = +sessionStorage.getItem('logUsrRoleId'), crntTentId = +sessionStorage.getItem('currentTenant');
      this.pharmacyForm.patchValue({ login_name: this.pharmacyForm.value.email, password: +this.pharmacyForm.value.id == 0 ? '123456' : this.pharmaData ? this.pharmaData.user_registration.password : '' });
      const sendData = JSON.parse(JSON.stringify(this.pharmacyForm.getRawValue()));
      if (sendData.agent_id == 0) {
        sendData.agent_id = null
      }
      Object.assign(sendData, {
        "alternate_mobile_no": user?.alternate_mobile_no, "user_types": [{ user_type_id: "4" }],
        "registration_source_id": user?.master_registration_source?.id, "latitude": "60", "longitude": "60",
        "branch_id": user?.orgnization_branch?.id ?? 0, "organization_id": roleID == 1 ? crntTentId : user?.orgnization_registration?.id,
      });
      sendData.contact_person_last_name = '';
      sendData.registration_exp_date = this.datePipe.transform(sendData.registration_exp_date, 'yyyy-MM-dd HH:mm:ss')

      if (sendData.isLogistic) {
        let logData = [];
        logData.push({
          "id": sendData.pharmacy_delivery_person.length == 0 ? 0 :
            sendData.pharmacy_delivery_person[sendData.pharmacy_delivery_person.length - 1]?.id,
          "is_active": true, "person_name": sendData.person_name, "created_on": null,
          "delivery_person_mobile_no": sendData.delivery_person_mobile_no, "delivery_person_email": sendData.delivery_person_email
        });
        if (logData.length > 0)
          sendData.pharmacy_delivery_person = logData;
      }

      if (+sendData.id == 0)
        delete sendData.id, delete sendData.user_id;
      ['regFile', 'digitalsignature', 'isHospitalPharmacy', 'hospitalAddress', 'registeredPharmacy', 'isLogistic', 'person_name',
        'delivery_person_mobile_no', 'delivery_person_email'].forEach(el => { delete sendData[el]; })
        
     if(this.pharmacyForm.value.is_active==true){
      sendData.account_activated=true;
     }else{
      sendData.account_activated=false;
     }
      
      this.registrationService.savePharma(sendData).subscribe(res => {
        let apiResponse = res;
        if (apiResponse.status == "OK") {
          let id: number = +(sendData.id ?? 0);
          if (id == 0) {
            const notificationData = { email: sendData.email, mobile_no: +sendData.contact_person_mobile, name: sendData.pharmacy_name };
            this.send_Notification(notificationData);
          }
          // this.saveFile(apiResponse.data.id, id);
          // this.activeModal.close('changes saved');
          // this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Pharmacy Registration');
          if (this.uploadFileList.some(f => f.File != null))
            this.saveFile(apiResponse.data.id, id);
          else
            this.showMessage(id);
        }
      }, error => this.toastr.error(error.message));
    }
  }

  showMessage(id: number) {
    this.activeModal.close('changes saved');
    this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Pharmacy Registration');
  }

  setLocationTypeValidators() {
    const [city_idControl, mandal_idControl, village_idControl] = [this.pharmacyForm.get('city_id'), this.pharmacyForm.get('mandal_id'), this.pharmacyForm.get('village_id')];

    this.pharmacyForm.get('location_type_id').valueChanges
      .subscribe(locationType => {
        if (this.isNIG)
          village_idControl.setValue(''), village_idControl.setValidators(null);
        if (+locationType == 2) {
          city_idControl.setValidators(null);
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }
        else if (locationType == 1) {
          city_idControl.setValidators(null);
          city_idControl.setValue('');
          mandal_idControl.setValidators([Validators.required]);
          if (!this.isNIG)
            village_idControl.setValidators([Validators.required]);
        }
        city_idControl.updateValueAndValidity();
        mandal_idControl.updateValueAndValidity();
        village_idControl.updateValueAndValidity();
        // this.locationChanged('', 'location');
      });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.pharmacyForm.get('district_id').setValue('');
        this.pharmacyForm.get('mandal_id').setValue('');
        this.pharmacyForm.get('village_id').setValue('');
        this.pharmacyForm.get('city_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          //  query = `getstatebyid?stateId=36`;//QA Pointing
         query = `getstatebyid?stateId=${id}`;//Training
        //query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
        this.pharmacyForm.get('mandal_id').setValue('');
        this.pharmacyForm.get('city_id').setValue('');
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
        this.pharmacyForm.get('village_id').setValue('');
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  changeProperty(hashmap: any, existProp: string) {
    Object.keys(hashmap).forEach((key) => {
      hashmap['name'] = hashmap[key];
      delete hashmap[key];
    });
  }

  getFormValidationErrors() {
    Object.keys(this.pharmacyForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.pharmacyForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        for (let i = 0; i < res.data.length; i++) {

          if (res.data[i].feature_id.feature_name == "hideLogisticDetailsinLabReg") {
            if (res.data[i].patient) {
              this.hideLogisticDetailFields = true;
            } else {
              this.hideLogisticDetailFields = false;
            }

          }

        }
      }
    });
  }
  restFormValues() {
    this.initFormControls(), this.setLocationTypeValidators();
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  getAllMasters() {
    const locationType = this.masterService.getAll('getalllocationtypes');
    const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');
    const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
    const bankName = this.masterService.getAll('getallmasterbankdetails');
    forkJoin([locationType, hospital, pharmacy, bankName]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.locationTypeList = list.data,
                '1': () => this.hospitalList = list.data,
                '2': () => this.pharmacyList = list.data,
                '3': () => this.bankNamesList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => {
        this.pharmacyList=this.pharmacyList.sort((a,b)=>a.pharmacy_name.toLowerCase()>b.pharmacy_name.toLowerCase()?1:-1);
        this.hospitalList=this.hospitalList.sort((a,b)=>a.hospital_name.toLowerCase()>b.hospital_name.toLowerCase()?1:-1); 
       //kranthi 14-02-2023
        // this.asgnEditData(this.pharmaData);
        this.editPharmacy();
       }
    });
    

  }

  cancelFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null;
    view_File.file_name = '';
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    const doctor_id = +this.pharmacyForm.get('id').value == 0 ? +view_File.document_path.split('_')[0] : +this.pharmacyForm.get('id').value;
    const fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (fileExtension == 'pdf')
      (view_File.File) ? Common.showPDF_File(URL.createObjectURL(view_File.File)) : Common.showPDF_File(`${this.registrationService.viewFilePath}PHARMACY/${doctor_id}/${view_File.name}/${view_File.document_path}`);
    else if (['jpg', 'jpeg', 'bmp', 'png'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File);
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      else {
        imagePath = `${this.registrationService.viewFilePath}PHARMACY/${doctor_id}/${view_File.name}/${view_File.document_path}`;
        modalRef.componentInstance.image = imagePath;
      }
    }
  }

  editPharmacy() {
    // console.log("pharmadata",this.pharmaData);
    
    if (this.pharmaData) {
      this.exp_date=this.pharmaData.registration_exp_date;
    //   if (new Date(this.pharmaData.reg_expiry_date) < new Date())
    //   this.maxdate = new Date(this.pharmaData.reg_expiry_date);
    // else
    //   this.maxdate = new Date();
      this.pharmaData.pharmacy_documents.forEach(f => {
        const docu = this.uploadFileList.find(e => e.fileType == f.file_type_id);
        if (docu) {
          docu.id = f.id;
          docu.document_path = f.document_path;
          docu.file_name = f.file_name;
        }
      });
      this.pharmacyForm.controls.regFile.clearValidators(), this.pharmacyForm.controls.regFile.updateValueAndValidity(),
        //Commented below line for active inacative change 07-07-2022
        // this.pharmacyForm.controls.account_activated.disable(),
        this.pharmacyForm.controls.digitalsignature.clearValidators(),
        this.pharmacyForm.controls.digitalsignature.updateValueAndValidity();
      Promise.all([this.locationChanged(this.pharmaData.state_id, 'district'),
      this.pharmaData.location_type_id == 1 ? (this.locationChanged(this.pharmaData.district_id, 'mandal'),
        this.locationChanged(this.pharmaData.mandal_id, 'village')) : this.locationChanged(this.pharmaData.district_id, 'city')])
        .then(() => {
          let [data, address] = [this.pharmaData, ''];
          this.pharmacyForm.patchValue({
            isHospitalPharmacy: false, registeredPharmacy: '', state_id: data.state_id, location_type_id: data.location_type_id,
            district_id: data.district_id, mandal_id: data.mandal_id, village_id: data.village_id, city_id: data.city_id, pharmacy_name: data.pharmacy_name,
            address: data.address, pharmacy_conatct_no: data.pharmacy_conatct_no, pin_code: data.pin_code, registration_no: data.registration_no,
            registration_exp_date:new Date(data.registration_exp_date), shop_no: data.shop_no, contact_person_name: data.contact_person_name, email: data.email,
            id: data.id, contact_person_mobile: data.contact_person_mobile,
            whatsapp_number: data.whatsapp_number, bank_account_number: data.bank_account_number, branch_name: data.branch_name, branch_location:
              data.branch_location, ifsc_code: data.ifsc_code, is_headoffice: data.is_headoffice, account_activated: data.account_activated,
          }); //
          if (this.pharmaData.hospital_id) {
            address = this.hospitalList.filter(f => f.id == data.hospital_id.id)[0]?.address;
            this.pharmacyForm.patchValue({ isHospitalPharmacy: true, hospital_id: data.hospital_id.id, hospitalAddress: address });
          }
          setTimeout(() => {
            this.pharmacyForm.patchValue({
              agent_id: data.agent_id
            })
            var disable = document.getElementById('agent_id')
            disable.setAttribute("disabled", 'true');
          }, 2000);
          if (this.pharmaData.registration_exp_date)
          console.log(this.pharmaData.registration_exp_date)
          // console.log("pharmaregdata", new Date(this.pharmaData.registration_exp_date));
          
            // this.pharmacyForm.get('registration_exp_date').setValue(new Date(this.pharmaData.registration_exp_date));
          //  this.pharmacyForm.patchValue({registration_exp_date: new Date(this.pharmaData.registration_exp_date)});
          let pharmaLogData = this.pharmaData?.pharmacy_delivery_person;
          if (pharmaLogData.length > 0) {
            this.pharmacyForm.patchValue({
              isLogistic: true, person_name: pharmaLogData[pharmaLogData.length - 1]?.person_name,
              delivery_person_email: pharmaLogData[pharmaLogData.length - 1]?.delivery_person_email, pharmacy_delivery_person:
                pharmaLogData, delivery_person_mobile_no: pharmaLogData[pharmaLogData.length - 1]?.delivery_person_mobile_no,
            });
          }
          else
            this.pharmacyForm.patchValue({ isLogistic: false, person_name: '', delivery_person_email: '', delivery_person_mobile_no: '' });
        });
    }
  }

  asgnEditData(phrmcyData: any) {
    // console.log("ghdghfdgf654675465",JSON.stringify(phrmcyData));
    
    // alert('3333');
    this.pharmacyForm.get('isHospitalPharmacy').setValue(false);
    this.pharmacyForm.get('registeredPharmacy').setValue('');
    this.pharmacyForm.get('state_id').setValue(phrmcyData.state_id);
    this.pharmacyForm.get('location_type_id').setValue(phrmcyData.location_type_id);
    this.pharmacyForm.get('district_id').setValue(phrmcyData.district_id);
    this.pharmacyForm.get('mandal_id').setValue(phrmcyData.mandal_id);
    this.pharmacyForm.get('village_id').setValue(phrmcyData.village_id);
    this.pharmacyForm.get('city_id').setValue(phrmcyData.city_id);
    this.pharmacyForm.get('pharmacy_name').setValue(phrmcyData.pharmacy_name);
    this.pharmacyForm.get('address').setValue(phrmcyData.address);
    this.pharmacyForm.get('pharmacy_conatct_no').setValue(phrmcyData.pharmacy_conatct_no);
    this.pharmacyForm.get('pin_code').setValue(phrmcyData.pin_code);
    this.pharmacyForm.get('registration_no').setValue(phrmcyData.registration_no);
    this.pharmacyForm.get('registration_exp_date').setValue(phrmcyData.registration_exp_date);
    this.pharmacyForm.get('shop_no').setValue(phrmcyData.shop_no);
    this.pharmacyForm.get('contact_person_name').setValue(phrmcyData.contact_person_name);
    this.pharmacyForm.get('email').setValue(phrmcyData.email);
    this.pharmacyForm.get('contact_person_mobile').setValue(phrmcyData.contact_person_mobile);
    this.pharmacyForm.get('whatsapp_number').setValue(phrmcyData.whatsapp_number);
    this.pharmacyForm.get('bank_account_number').setValue(phrmcyData.bank_account_number);
    this.pharmacyForm.get('branch_name').setValue(phrmcyData.branch_name);
    this.pharmacyForm.get('branch_location').setValue(phrmcyData.branch_location);
    this.pharmacyForm.get('ifsc_code').setValue(phrmcyData.ifsc_code);
    this.pharmacyForm.get('is_headoffice').setValue(phrmcyData.is_headoffice);
    this.pharmacyForm.get('account_activated').setValue(phrmcyData.account_activated);
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string }) {
    const postData = {
      "email": payload.email,
      "mobile_no": payload.mobile_no,
      "user_id": 4,
      "user_type": "Pharmacy",
      "name": payload.name.toUpperCase(),
      "login_name": this.pharmacyForm.value.email,
    }
    this.notificationService.sendNotification(postData).subscribe();
  }

  isChngLog() {
    let logsLst = ['person_name', 'delivery_person_mobile_no', 'delivery_person_email'];
    if (this.pharmacyForm.get('isLogistic').value)
      logsLst.forEach(e => { this.pharmacyForm.get(e).setValidators([Validators.required]); });
    else
      logsLst.forEach(e => { this.pharmacyForm.get(e).setValidators(null); });
    logsLst.forEach(e => { this.pharmacyForm.get(e).updateValueAndValidity(), this.pharmacyForm.get(e).setValue(''); });
  }
  getallcentersGrid() {
    let payreqt = {
      "state_id": 0,
      "district_id": 0,
      "location_type_id": 0,
      "mandal_id": 0
    }
    this.registrationService.getAllCenters(payreqt).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.getAllcenters = res.data;
      }

    })
  }
  agentchange(agentid) {
    // this.pharmacyForm.reset();
    if (agentid != 'null') {
      this.registrationService.checkpharmacy(agentid).subscribe(res => {
        if (res.data.length == 0 || res.data == null) {
          this.registrationService.getallagentdatabyid(agentid).subscribe(res => {
            if (res.data) {
              let agentdata = res.data;
              var d = new Date();
              d.setMonth(d.getMonth() + 12);
              // var val=Math.floor(Math.random() * 9000000000) + 1
              //var val = Math.floor(1000 + Math.random() * 9000000000);
              //var val=Math.floor(Math.random() * 10000000000);
              var val = Math.floor(Math.random() * parseInt('1' + '5'.repeat(10 - 1)) + parseInt('6' + '9'.repeat(10 - 1)));
              this.locationChanged(agentdata.state_id, 'district')
              this.locationChanged(agentdata.district_id, 'mandal')
              this.locationChanged(agentdata.district_id, 'city')
              this.locationChanged(agentdata.mandal_id, 'village');
              setTimeout(() => {
                this.pharmacyForm.patchValue({
                  address: agentdata.address,
                  state_id: agentdata.state_id,
                  location_type_id: agentdata.location_type_id,
                  district_id: agentdata.district_id,
                  city_id: agentdata.city_id,
                  village_id: agentdata.village_id,
                  mandal_id: agentdata.mandal_id,
                  pharmacy_conatct_no: val,
                  contact_person_mobile: val,
                  //email:agentdata.mobile+agentdata.email,
                  email: agentdata.mobile + '@gmail.com',
                  pin_code: agentdata.pin_code,
                  bank_account_number: agentdata.bank_account_number,
                  bank_id: agentdata.bank_id,
                  branch_location: agentdata.branch_location,
                  ifsc_code: agentdata.ifsc_code,
                  is_active: agentdata.is_active,
                  name_as_per_bank: agentdata.name_as_per_bank,
                  pan_no: agentdata.pan_no,
                  pharmacy_name: agentdata.first_name,
                  registration_no: agentdata.mobile,
                  contact_person_name: agentdata.first_name,
                  registration_exp_date: d
                })
                // this.getallcentersGrid();
              }, 1000)
            }
          })
        } else {
          this.toastr.error("Pharmacy Already Exist With this Agent")
        }
      })

    } else {
      this.initFormControls();

    }

  }
}