import { Component, OnInit } from '@angular/core';
import { Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { error } from '@angular/compiler/src/util';
import { FormGroup, FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { FileService } from 'src/app/shared/services/file.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.css']
})

export class UploadLogoComponent extends BaseComponent implements OnInit {
  
  lstGrid = []; hospitalList = []; formValues : any;
  uploadForm : FormGroup; image: any; 
  submitted : boolean = false;
  logoUrl = ''; wmUrl =''; bannerUrl = '';

  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string,file_name:string, fileType: string }> = []

  // appSrce = (location.href.includes('sage') ? 'sage' : (location.href.includes('vidmedanh') ? 'anh' : (location.href.includes('genome') ? 'genome' : (location.href.includes('incipe') ? 'incipe' : 'dhanush'))));
  // logo = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.logo}`; // `${environment.logoPath}${this.appSrce}${environment.logo}`;
  appSrce = ''; logo = '';

  constructor(private regService: RegistrationService
    , private toastr: ToastrService
    , private fb: FormBuilder
    , private authSvc: AuthenticationService
    , private fileService: FileService ) {
      super();
  }

  ngOnInit(): void {
    this.authSvc.appSrceSubject.subscribe(s => { this.appSrce = s, this.logo = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.logo}` });
    this.initFormControls(), this.defaultLocations(), this.formValues = this.uploadForm.value;
  }

  initFormControls() {
    this.uploadForm = this.fb.group({
      tenant : [''],
      logoFile : [''],
      wmFile : [''],
      bannerFile : [''],
    });
  }

  onAdd() {
    this.initFormControls();
    this.logoUrl = this.wmUrl = this.bannerUrl = '';
    this.submitted = false;
  }

  get f() { return this.uploadForm.controls}

  defaultLocations() {
    ['logo', 'wm','banner'].forEach((element, ind) => this.uploadFileList.push({ id: 0, reg_id: ind, name: element, File: null,file_name:'', extensions: '', fileType: '' }));
    ['png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg'].forEach((item, i) => this.uploadFileList[i].extensions = item);

  }

  LoadGridData() {
    this.regService.getAll('').subscribe(res => { 
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Upload List');
        else
          this.lstGrid = res.data;
        Common.loadDataTable(this.lstGrid, '#uploadTable');
      }
    });
  }

  singleFileUpload(event: any, fileNumber: number, fileType: string) {
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
      let validate: any= this.fileService.isValidFileName(fileList.files[0].name);
      if(!validate){
       this.toastr.warning(`Please give Valid File Format`);
       return;
      }
      // Checking type of file
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        // fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          return false;
        }
      }
      // The size of the file.
      if (currentfile >= (1024 * 5)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Files');
        return;
      }
      else {
        this.dataUrlofImage(fileList, fileNumber);
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = fileList.files[0].name;
        this.uploadFileList[fileNumber].fileType = fileType;
      }
    }
  }

  saveFile(id: string) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, '', item.fileType)));
    });
    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log);
  }
  
  onSubmit() {
    // console.log(this.formValues);
  }

  dataUrlofImage(inputValue: any, fileNumber) {
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();
    
    myReader.onloadend = (e) => {
      this.image = myReader.result;

      let image_type = {
        '0': () => this.logoUrl = this.image,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
        '1': () => this.wmUrl = this.image,
        '2': () => this.bannerUrl = this.image
      };
      image_type[fileNumber]();
    }
    myReader.readAsDataURL(file);
  }

}