<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> 
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="labForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Is Hospital Lab</label>
                        <div>
                            <input type="checkbox" name="isHospitalLab" formControlName="isHospitalLab" #isHospital
                                (change)="isHospitalab($event.target.checked)">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="isHospital.checked">
                    <div class="form-group">
                        <label class="control-label">Hospital Name <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="hospital_id" id="hospital_id"
                                (change)="isHospitalChanged($event.target.value)"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let item of hospitalList" [value]="item.id">
                                    {{item.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_id.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_id.errors.required">Hospital is
                                    required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="isHospital.checked">
                    <div class="form-group">
                        <label class="control-label">Hosipital Address </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="hospitalAddress" id="hospitalAddress"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.hospitalAddress.errors }"
                                class="form-control form-control-sm text-uppercase">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="isHospital.checked">
                    <div class="form-group">
                        <label class="control-label">Registered Lab </label>
                        <div>
                            <select formControlName="registeredLab" id="registeredLab"
                                (change)="onRegLabChange($event.target.value)"
                                [ngClass]="{ 'is-invalid': submitted && f.registeredLab.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let item of labList" [value]="item.id">
                                    {{item.lab_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="state_id" id="state_id" #State
                                (change)="locationChanged(State.value, 'district')"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">
                                    {{isNIG ? 'States' : 'State'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="location_type_id" id="location_type_id" #locationId
                                (change)="locationChanged('','location')"
                                [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let location of locationTypeList" [value]="location.id">
                                    {{location.location_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.location_type_id.errors.required">
                                    Location Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District
                                (change)="locationChanged(District.value, (+locationId.value==2)?'city':'mandal')"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="labForm.value.location_type_id!=2 && labForm.value.location_type_id!=''">
                    <!-- Mandal -->
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'Local Government Areas' : 'Mandal'}}
                            <!-- <span class="text-danger">*</span> -->
                        </label>
                        <div>
                            <select formControlName="mandal_id" id="mandal_id" #Mandal
                                (change)="locationChanged(Mandal.value, 'village')"
                                [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                    {{mandal.mandal_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                <div *ngIf="f.mandal_id.errors.required">
                                    {{isNIG ? 'Local Government Areas ' : 'Mandal '}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="labForm.value.location_type_id!=2 && labForm.value.location_type_id!='' && !isNIG">
                    <div class="form-group">
                        <label class="control-label">Village
                            <!-- <span class="text-danger">*</span> -->
                        </label>
                        <div>
                            <select formControlName="village_id" id="village_id"
                                [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let village of villageList" [value]="village.id">
                                    {{village.village_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                <div *ngIf="f.village_id.errors.required">Village is required </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="(+labForm.value.location_type_id==2)">
                    <div class="form-group">
                        <label class="control-label">City </label>
                        <div>
                            <select formControlName="city_id" id="city_id"
                                [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let city of cityList" [value]="city.id">
                                    {{city.city_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                <div *ngIf="f.city_id.errors.required">City is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Lab Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="lab_name" id="lab_name"
                                [ngClass]="{ 'is-invalid': submitted && f.lab_name.errors }"
                                class="form-control form-control-sm text-uppercase" maxlength="50"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.lab_name.errors" class="invalid-feedback">
                                <div *ngIf="f.lab_name.errors.required">
                                    Lab Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>

                 <!-- facility Added start -->

                 <div class="col-xl-3" *ngIf="IsFacilityEnable">
                    <label class="control-label"> Select Facility</label>
                    <div>
                         <ng-multiselect-dropdown [settings]="dropdownSettings_facility" [placeholder]="'Select'" 
                         [data]="facilityDatalist"
                         [(ngModel)]="selectFacility"  (onSelect)="isFacilitySelect($event)"
                         (onDeSelect)="isFacilityDeSelect($event)"></ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="IsFacilityEnable">
                  <div class="form-group">
                    <label class="control-label"> Facility ID <span class="text-danger">*</span> </label>
                    <div>
                      <input type="text" autocomplete="off" formControlName="facility_id" id="facility_id" [attr.disabled]="facilitySelected?true:null"
                         maxlength="50"
                        class="form-control form-control-sm">
                    </div>
                  </div>
                </div>
                <div class="col-xl-3" *ngIf="IsFacilityEnable">
                  <div class="form-group">
                    <label class="control-label"> Facility Name <span class="text-danger">*</span> </label>
                    <div>
                      <input type="text" autocomplete="off" formControlName="facility_name" id="facility_name" [attr.disabled]="facilitySelected?true:null"
                         maxlength="50"
                        class="form-control form-control-sm">
                    </div>
                  </div>
                </div>
          <!-- facility added End -->
                <div class="col-xl-3">
                    <label class="control-label">Lab Type <span class="text-danger">*</span> </label>
                    <select formControlName="lab_type_id" id="lab_type_id"
                    class="form-control form-control-sm" (change)="mainLabChange($event.target.value)"
                    [ngClass]="{ 'is-invalid': submitted && f.lab_type_id.errors }">
                    <option value=''>Select</option>
                    <option value=1>Main Lab</option>
                    <option value=2>Sub Lab</option>
                    <option value=3>External Lab</option>
                    <!-- <option *ngFor="let item of storeTypeList" [value]="item.store_type">{{item?.name}}</option> -->
                
                  </select>
                  <div *ngIf="submitted && f.lab_type_id.errors" class="invalid-feedback">
                    <div *ngIf="f.lab_type_id.errors.required">Lab Type is required</div>
                  </div>
                </div>
                 
                  <div class="col-xl-3" *ngIf="mainstoreEnable">
                    <label class="control-label">Main Lab<span class="text-danger">*</span> </label>
                    <select formControlName="lab_id" id="Store" [ngClass]="{ 'is-invalid': submitted && f.lab_id.errors }"
                    class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let main of mainLabList" [value]="main.id">
                      {{main.lab_name}}</option>
                  </select>
                  <div *ngIf="submitted && f.lab_id.errors" class="invalid-feedback">
                    <div *ngIf="f.lab_id.errors.required">Main Lab is required</div>
                  </div>
                </div>
                <div class="col-xl-3">
                        <label class="control-label"> Department<span class="text-danger">*</span> </label>
                        <div>                            
                            <div tabindex="0" class="" >
                                <ng-multiselect-dropdown [settings]="dropdownSettings_department" [placeholder]="'All'"
                                    [data]="departmentList" [ngClass]="{ 'is-invalid': submitted && f.lab_department.errors }"
                                     formControlName="lab_department"></ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.lab_department.errors" class="invalid-feedback" >
                                    <div *ngIf="f.lab_department.errors.required">Department is required</div>
                                </div>
                            </div>
                        </div>
                   
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Lab Address <span class="text-danger">*</span>
                        </label>
                        <div>
                            <textarea type="text" autocomplete="off" formControlName="address" id="address"
                                (keyup)="keyUp('address')"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                                maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                class="form-control form-control-sm"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Address is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Lab Contact Number <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="lab_conatct_no" id="lab_conatct_no"
                                [maxlength]="maxLngth" (keyup)="onKeyNmbr('lab_conatct_no', $event.target.value)"
                                [ngClass]="{ 'is-invalid': submitted && f.lab_conatct_no.errors }"
                                class="form-control form-control-sm" [pattern]="mobPtrn"
                                onkeypress='return ((event.charCode >= 48 && event.charCode <= 57))'>
                                <!-- (keyup)="keyUp('lab_conatct_no')" -->
                            <div *ngIf="submitted && f.lab_conatct_no.errors" class="invalid-feedback">
                                <div *ngIf="f.lab_conatct_no.errors.required">
                                    Lab Contact Number is required</div>
                                <div *ngIf="f.lab_conatct_no.errors.pattern"> Lab Contact Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Shop Number 
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="shop_no" id="shop_no"
                                [ngClass]="{ 'is-invalid': submitted && f.shop_no.errors }"
                                class="form-control form-control-sm" (keyup)="keyUp('shop_no')" maxlength="10"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode >= 97 && event.charCode <= 122'>
                            <div *ngIf="submitted && f.shop_no.errors">
                                <div *ngIf="f.shop_no.errors.required">Shop No/
                                    Pin Code is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="pin_code" id="pin_code"
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }"
                                class="form-control form-control-sm" (keyup)="keyUp('pin_code')"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6">
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.minlength">Pincode should have 6 digits</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Registration Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="registration_no" id="registration_no"
                                maxlength="15" [ngClass]="{ 'is-invalid': submitted && f.registration_no.errors }"
                                class="form-control form-control-sm" (focus)="dp1.hide()"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && f.registration_no.errors" class="invalid-feedback">
                                <div *ngIf="f.registration_no.errors.required">
                                    Registration Number is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Registration Expiry Date<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" class="form-control form-control-sm pb-2" autocomplete="off" [minDate]="maxdate"
                                [ngClass]="{ 'is-invalid': submitted && f.registration_exp_date.errors }" id="registration_exp_date"
                                placement="up" bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" formControlName="registration_exp_date"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',containerClass: 'theme-dark-blue', showWeekNumbers: false }">
                            <div *ngIf="submitted && f.registration_exp_date.errors" class="invalid-feedback">
                                <div *ngIf="f.registration_exp_date.errors.required">Registration Expiry Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Lab Email<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="email" id="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="60"
                                class="form-control form-control-sm" (focus)="dp1.hide()"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode == 64) ||(event.charCode == 46) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.pattern">
                                    Sample Email address is x@yahoo.com
                                </div>
                                <div *ngIf="f.email.errors.required">
                                    Lab Email is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Lab Contact Person<span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="contact_person_name"
                                id="contact_person_name"
                                [ngClass]="{ 'is-invalid': submitted && f.contact_person_name.errors }"
                                class="form-control form-control-sm text-uppercase" maxlength="50"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.contact_person_name.errors" class="invalid-feedback">
                                <div *ngIf="f.contact_person_name.errors.required">
                                    Contact Person Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Contact Person No.
                            <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="contact_person_mobile"
                                id="contact_person_mobile" (keyup)="onKeyNmbr('contact_person_mobile', $event.target.value)"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' [maxlength]="maxLngth"
                                [ngClass]="{ 'is-invalid': submitted && f.contact_person_mobile.errors }"
                                class="form-control form-control-sm" [pattern]="mobPtrn">
                                <!-- (keyup)="keyUp('contact_person_mobile')" -->
                            <div *ngIf="submitted && f.contact_person_mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.contact_person_mobile.errors.required">
                                    Contact Person No. is required
                                </div>
                                <div *ngIf="f.contact_person_mobile.errors.pattern">
                                    Contact Person No. {{mobErrMsg}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Lab Whatsapp Number</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="whatsapp_number" id="whatsapp_number"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' [maxlength]="maxLngth"
                                [ngClass]="{ 'is-invalid': submitted && f.whatsapp_number.errors }"
                                class="form-control form-control-sm" [pattern]="mobPtrn"
                                (keyup)="onKeyNmbr('whatsapp_number', $event.target.value)">
                                <!-- (keyup)="keyUp('whatsapp_number')" -->
                            <div *ngIf="submitted && f.whatsapp_number.errors" class="invalid-feedback">
                                <div *ngIf="f.whatsapp_number.errors.required">Mobile Number should contain 10 digits</div>
                                <div *ngIf="f.whatsapp_number.errors.pattern">Lab Whatsapp Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!isHospital.checked">
                    <!-- *ngIf="!isHospital.checked" -->
                    <div class="form-group">
                        <label class="control-label">Lab Bank Account Number
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="bank_account_number"
                                id="bank_account_number" (keyup)="keyUp('bank_account_number')"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="20"
                                [ngClass]="{ 'is-invalid': submitted && f.bank_account_number.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.bank_account_number.errors" class="invalid-feedback">
                                <div *ngIf="f.bank_account_number.errors.required">
                                    Lab Bank Account Number is required
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!isHospital.checked">
                    <!-- *ngIf="!isHospital.checked" -->
                    <div class="form-group">
                        <label class="control-label">Bank Name
                        </label>
                        <div>
                            <select formControlName="branch_name" id="branch_name" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.branch_name.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let item of bankNamesList" [value]="item.id">
                                    {{item.bank_name}}</option>
                            </select>
                            <!-- <input type="text" autocomplete="off" formControlName="branch_name" id="branch_name"
                                (keyup)="keyUp('branch_name')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || 
                                (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)' maxlength="30"
                                 [ngClass]="{ 'is-invalid': submitted && f.branch_name.errors }"
                                class="form-control form-control-sm"> -->
                            <div *ngIf="submitted && f.branch_name.errors" class="invalid-feedback">
                                <!-- <div *ngIf="f.branch_name.errors.required">
                                    Bank Name is
                                    required
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!isHospital.checked">
                    <!-- *ngIf="!isHospital.checked" -->
                    <div class="form-group">
                        <label class="control-label">Bank Location</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="branch_location" id="branch_location"
                                (keyup)="keyUp('branch_location')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                          (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))' maxlength="50"
                                [ngClass]="{ 'is-invalid': submitted && f.branch_location.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.branch_location.errors" class="invalid-feedback">
                                <!-- <div *ngIf="f.branch_location.errors.required">
                                    Bank Location is
                                    required
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!isHospital.checked && !isNIG">
                    <div class="form-group">
                        <label class="control-label">IFSC Code</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="ifsc_code" id="ifsc_code"
                                (keyup)="keyUp('ifsc_code')" maxlength="11"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.ifsc_code.errors }" 
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.ifsc_code.errors" class="invalid-feedback">
                                <div *ngIf="f.ifsc_code.errors.pattern">
                                    Enter Valid IFSC, ex: SBIN0001050
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                      <label class="control-label">GSTIN No</label>
                      <div>
                        <input type="text" autocomplete="off" formControlName="gstin_no" id="gstin_no"
                           onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))' maxlength="15"
                          class="form-control form-control-sm  text-uppercase">
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> PAN Number </label>
                            <input type="text" autocomplete="off" maxlength="10" formControlName="pan_no" 
                            class="form-control form-control-sm  text-uppercase">
                            <!-- <div *ngIf="submitted && f.pan_no.errors" class="invalid-feedback">
                                <div *ngIf="f.pan_no.errors.pattern">
                                  Enter Valid PAN No, ex: AAAPZ1234C
                                </div>
                              </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" [hidden]="isNIG">
                    <div class="form-group">
                        <label class="control-label">NABL Certificate</label>
                        <div>
                            <input type="checkbox" name="certificate" value="one" formControlName="nabl" (focus)="dp.hide()"
                                (change)="onNabl($event.target.checked)" #nabl>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" [hidden]="isNIG">
                    <div class="form-group">
                        <label class="control-label">NABL Expiry Date <span *ngIf="nabl.checked" class="text-danger">*</span></label>
                        <div>
                            <input type="text" class="form-control form-control-sm pb-2" autocomplete="off"
                                placement="up" bsDatepicker #dp="bsDatepicker" formControlName="nabl_expiry_date"
                                [attr.disabled]="(nabl.checked)?null:true" (focus)="dp.show()"
                                [ngClass]="{'is-invalid': submitted && f.nabl_expiry_date.invalid }"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',containerClass: 'theme-dark-blue', showWeekNumbers: false }">
                            <div *ngIf="submitted && f.nabl_expiry_date.errors" class="invalid-feedback">
                                <div *ngIf="f.nabl_expiry_date.errors.required">
                                    NABL Expiry Date is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="isGAM">
                    <div class="form-group">
                        <label class="control-label">Center Mapping With Agent </label>
                        <div>
                          <select class="form-control form-control-sm" id="payment_type"
                          formControlName="center_id">
                              <option value=0>Select</option>
                              <option *ngFor="let center of getAllcenters" [value]="center.id">
                                {{center.center_name}}</option>
                          </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span></label>
                        <div >
                            <input type="radio" formControlName="account_activated" (focus)="dp.hide()" [value]="true">Active
                            <input type="radio" formControlName="account_activated" (focus)="dp.hide()" [value]="false">In Active
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">is HeadOffice</label>
                        <div>
                            <input type="checkbox" name="isheadOffice" value="one" formControlName="is_headoffice">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Is Logistic Info Required</label>
                        <div>
                            <input type="checkbox" name="isLogistic" formControlName="isLogistic" (change)="isChngLog()">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Logistic Person Name
                            <span *ngIf="labForm.get('isLogistic').value" class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="person_name" id="person_name"
                                [ngClass]="{ 'is-invalid': submitted && f.person_name.errors }"
                                class="form-control form-control-sm text-uppercase" maxlength="50"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32)'
                                [attr.disabled]="labForm.get('isLogistic').value ? null : true">
                            <div *ngIf="submitted && f.person_name.errors" class="invalid-feedback">
                                <div *ngIf="f.person_name.errors.required">Logistic Person Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Logistic Person Email
                            <span *ngIf="labForm.get('isLogistic').value" class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="collection_person_email"
                                [ngClass]="{ 'is-invalid': submitted && f.collection_person_email.errors }" maxlength="50"
                                class="form-control form-control-sm" id="collection_person_email"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode == 64) ||(event.charCode == 46) ||
                                    (event.charCode >= 97 && event.charCode <= 122)) ||
                                    (event.charCode >= 48 && event.charCode <= 57)'
                                [attr.disabled]="labForm.get('isLogistic').value ? null : true">
                            <div *ngIf="submitted && f.collection_person_email.errors" class="invalid-feedback">
                                <div *ngIf="f.collection_person_email.errors.collection_person_email">Sample Email address is x@yahoo.com</div>
                                <div *ngIf="f.collection_person_email.errors.required">Logistic Person Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Logistic Person Mobile No
                            <span *ngIf="labForm.get('isLogistic').value" class="text-danger">*</span>
                        </label>
                        <div>
                            <input id="collection_person_mobile_no" type="text" autocomplete="off"
                                formControlName="collection_person_mobile_no" [pattern]="mobPtrn"
                                (keyup)="onKeyNmbr('collection_person_mobile_no', $event.target.value)" [maxlength]="maxLngth"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.collection_person_mobile_no.errors }"
                                class="form-control form-control-sm" [attr.disabled]="labForm.get('isLogistic').value ? null : true">
                            <div *ngIf="submitted && f.collection_person_mobile_no.errors" class="invalid-feedback">
                                <div *ngIf="f.collection_person_mobile_no.errors.required">Logistic Person Mobile No is required</div>
                                <div *ngIf="f.collection_person_mobile_no.errors.pattern">Logistic Person Mobile No {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"></label>
                        <div>
                            <button type="button" class="btn btn-primary btn-sm">Registration Certificate</button>
                            <input type="file" accept="image/png, application/pdf" name="input-file-preview"
                                formControlName="regFile" (change)="singleFileUpload($event,0,'1')" 
                                [ngClass]="{'is-invalid': submitted && f.regFile.invalid }"/>
                                <div *ngIf="submitted && f.regFile.errors" class="invalid-feedback">
                                    <div *ngIf="f.regFile.errors.required">
                                       Registration Certificate is required
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"></label>
                        <div>
                            <button type="button" class="btn btn-primary btn-sm">NABL Certificate</button>
                            <input type="file" accept="image/png, application/pdf" name="input-file-preview"
                                formControlName="nablFile" [attr.disabled]="(nabl.checked)?null:true"
                                [ngClass]="{'is-invalid': submitted && f.nablFile.invalid }"
                                (change)="singleFileUpload($event,1,'3')">
                                <div *ngIf="submitted && f.nablFile.errors" class="invalid-feedback">
                                    <div *ngIf="f.nablFile.errors.required">
                                       NABL Certificate is required
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"></label>
                        <div>
                            <!--     <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Open Modal</button> -->
                            <button type="button" class="btn btn-primary btn-sm" data-toggle="modal"
                                data-target="#myModal">Lab Investigation</button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                        <tbody>
                            <tr>
                                <td>
                                    <input type="file" class="d-none" formControlName="regFile" #regFile
                                        name="input-file-preview" (change)="singleFileUpload($event,0,'1')"
                                        [ngClass]="{'is-invalid': submitted && f.regFile.invalid }" />
                                    <p class="btn badge badge-success badge-pill text-white" (click)="regFile.click()">
                                        Registration Certificate </p>   <span class="text-danger">*</span>
                                    <p class="small mb-1">Png,Jpg or pdf format only</p>
                                    <div *ngIf="submitted && f.regFile.errors" class="invalid-feedback">
                                        <div *ngIf="f.regFile.errors.required">Registration Certificate is required</div>
                                    </div>
                                </td>
                                <td [hidden]="isNIG">
                                    <input type="file" class="d-none" name="input-file-preview" #nablfile
                                        formControlName="nablFile" [attr.disabled]="nabl.checked?null:true"
                                        [ngClass]="{'is-invalid': submitted && f.nablFile.invalid }"
                                        (change)="singleFileUpload($event,1,'3')">
                                    <p class="btn badge badge-success badge-pill text-white" (click)="nablfile.click()">NABL
                                        Certificate</p> <span *ngIf="nabl.checked" class="text-danger">*</span>
                                    <p class="small mb-1">Png, jpg or pdf format only</p>
                                    <div *ngIf="submitted && f.nablFile.errors" class="invalid-feedback">
                                        <div *ngIf="f.nablFile.errors.required">
                                            NABL Certificate is required
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <input type="file" class="d-none" formControlName="digitalsignature" #digitalsignature
                                        name="input-file-preview" (change)="singleFileUpload($event,2,'8')"
                                        [ngClass]="{'is-invalid': submitted && f.digitalsignature.invalid }" />
                                    <p class="btn badge badge-success badge-pill text-white" (click)="digitalsignature.click()">
                                        Digital Signature </p>   <span class="text-danger">*</span>
                                    <p class="small mb-1">Png,Jpg or pdf format only</p>
                                    <div *ngIf="submitted && f.digitalsignature.errors" class="invalid-feedback">
                                        <div *ngIf="f.digitalsignature.errors.required">Digital Signature is required</div>
                                    </div>
                                </td>
                                <td  *ngIf="IsFacilityEnable">
                                    <input type="file" class="d-none" formControlName="facilityLogo" #facilityLogo
                                        name="input-file-preview" (change)="singleFileUpload($event,3,'85')">
                                    <p class="btn badge badge-success badge-pill text-white" (click)="facilityLogo.click()">
                                        Facility Logo </p>   <span class="text-danger">*</span>
                                    <p class="small mb-1">Png,Jpg,Jpeg only</p>
                                   
                                </td>
                                <td  *ngIf="IsFacilityEnable">
                                    <input type="file" class="d-none" formControlName="facilityWatermark" #facilityWatermark
                                        name="input-file-preview" (change)="singleFileUpload($event,4,'86')">
                                    <p class="btn badge badge-success badge-pill text-white" (click)="facilityWatermark.click()">
                                        facility Watermark </p>   <span class="text-danger">*</span>
                                    <p class="small mb-1">Png,Jpg,Jpeg only</p>
 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span> <a class="badge badge-danger badge-pill" title="Cancel File"
                                        *ngIf="uploadFileList[0].File" (click)="cancelFile(1)">
                                        <i class="fa fa-trash text-white"></i></a>&nbsp;
                                    </span>
                                    <a href="javascript:void(0)" (click)="viewFile(1)">{{uploadFileList[0].file_name}}</a></td>
                                <td [hidden]="isNIG">
                                    <span> <a class="badge badge-danger badge-pill" title="Cancel File"
                                        *ngIf="uploadFileList[1].File" (click)="cancelFile(2)">
                                        <i class="fa fa-trash text-white"></i></a>&nbsp;
                                    </span>
                                    <a href="javascript:void(0)" (click)="viewFile(2)">{{uploadFileList[1].file_name}}</a></td>
                                <td>
                                    <span> <a class="badge badge-danger badge-pill" title="Cancel File"
                                        *ngIf="uploadFileList[2].File" (click)="cancelFile(3)">
                                        <i class="fa fa-trash text-white"></i></a>&nbsp;
                                    </span>
                                    <a href="javascript:void(0)" (click)="viewFile(3)">{{uploadFileList[2].file_name}}</a></td>
                                    <td  *ngIf="IsFacilityEnable">
                                        <span> <a class="badge badge-danger badge-pill" title="Cancel File"
                                            *ngIf="uploadFileList[2].File" (click)="cancelFile(4)">
                                            <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)" (click)="viewFile(4)">{{uploadFileList[3].file_name}}</a></td>
                                        <td  *ngIf="IsFacilityEnable">
                                            <span> <a class="badge badge-danger badge-pill" title="Cancel File"
                                                *ngIf="uploadFileList[2].File" (click)="cancelFile(5)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                            </span>
                                            <a href="javascript:void(0)" (click)="viewFile(5)">{{uploadFileList[4].file_name}}</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                      <label class="control-label">Registration Certificates <span class="text-danger">*</span>
                      </label>
                      <div>
                        <input type="file" id="myfile" name="myfile">
                    
                      </div>
                    </div>
                  </div> -->
                <div class="col-xl-3">
                    <!-- <div class="form-group">
                            <label class="control-label"></label>
                            <div>
                                <input type="submit" class="btn btn-sm btn-primary"
                                    value="Submit">&nbsp;&nbsp;
                                    <input type="button" class="btn btn-sm btn-danger" value="Clear All" (click)="restFormValues()">
                            </div>
                        </div> -->
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" [disabled]="isSubmit_Disabled?true:null"
        value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" [formGroup]="labForm">
            <div class="modal-header">
                <h4>Lab Investigation </h4>
                <button type="button" class="close" id="myModalClose" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" formArrayName="lab_investigation">
                <div class="table-responsive">
                    <table datatable id="LabInvestigation" class="table table-striped table-bordered table-sm"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th style="width:10%;">S No</th>
                                <th>Checked</th>
                                <th>Lab Investigation Name</th>
                                <th class="text-center" style="width:10%;">Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let group of getRowFormControls.controls ; let i=index" [formGroupName]="i">
                                <td style="width:10%;" class="text-center">{{i+1}}</td>
                                <td style="width:10%;" class="text-center">
                                    <div class="custom-control-inline custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" [id]="'mcheckbox'+(i+1)"
                                            formControlName="chk" (change)="onCheckbox(i,$event.target.checked)">
                                        <label class="custom-control-label" [for]="'mcheckbox'+(i+1)"></label>
                                    </div>
                                </td>
                                <td>{{group.get('investigation_name').value}}</td>
                                <td style="width:10%;">
                                    <input type="text" class="form-control form-control-sm" formControlName="unit_price"
                                        [ngClass]="{ 'is-invalid': group.get('unit_price').errors }">
                                    <!-- <div class="badge badge-info badge-pill">
                                        <a> <i class="fa fa-edit text-white"></i></a>
                                    </div>&nbsp;&nbsp;&nbsp;
                                    <div class="badge badge-danger badge-pill">
                                        <a><i class="fa fa-trash text-white"></i></a>
                                    </div> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" [disabled]="isSubmit_Disabled?true:null"
                    (click)="onSaveInvestigations()" value="Submit">Submit</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>