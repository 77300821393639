import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CorporateEmployeeService } from 'src/app/shared/axaservices/corporate-employee.service';
declare let $: any;

@Component({
  selector: 'app-hr-evoluation',
  templateUrl: './hr-evoluation.component.html',
  styleUrls: ['./hr-evoluation.component.css']
})
export class HrEvoluationComponent implements OnInit {

  getAllSurveyList: any;
  tenantId: string;
  title: any;
  questionList: any;
  answerList: any;
  height: any;
  weight: any;
  bmi: number;
  BmiResult: any;
  constructor(private corpService: CorporateEmployeeService
    , private toastr: ToastrService
    , public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.tenantId = sessionStorage.getItem('TENANT_ID');
    this.query();
    this.surveyGetAll();
  }


  surveyGetAll() {
    this.corpService.getsrh(`survey/survey/getall?TENANT_ID=${this.tenantId}`).subscribe(res => {
      if (res?.status === "OK") {
        if (res?.data == null || res?.data.length == 0)
          this.toastr.info('No records available');
        else
          this.getAllSurveyList = res.data;
        // console.log(this.getAllSurveyList);
        this.questionList = this.getAllSurveyList[0]?.sections[0]?.questions;
        this.answerList = this.getAllSurveyList[0]?.sections[0]?.questions?.answers;

        console.log("qList",this.answerList);

      }
    },
      error => { this.toastr.error(error.message); }
    );
  }

  calculateBMI() {
    if (this.height && this.weight ) {
      this.bmi = this.weight / Math.pow(this.height / 100, 2);
    }
    if (this.bmi < 18.5) {
      this.BmiResult = 'Underweight';
    } else if (this.bmi >= 18.5 && this.bmi < 25) {
      this.BmiResult = 'Normal weight';
    } else if (this.bmi >= 25 && this.bmi < 30) {
      this.BmiResult = 'Overweight';
    } else if (this.bmi >= 30) {
      this.BmiResult = 'Morbid obesity';
    } else {
      //alert('Incorrect Input!');
      this.BmiResult = '';
    }
  }

calculation(){
  // if(){
  //   this.height= item.answer
  // }

  // if(){
  //   this.weight= item.answer
  // }

  // let result = (this.height)*10000/this.weight
  
  //       result = Number(result.toFixed(2));

}
  onSave() {
    // const payload = this.myform.getRawValue();
    const payload = [{
      "surveyTransMaster": {
        "created_by": 55683,
        "beneficiary_id": 8,
        "dependent_id": null,
        "survey_id": 3,
        "status_id": 1,
        "score": 0,
        "summary": {//Total question and answers Json
        },
        "visit_id": null,
        "language_id": 1
      },
      "surveyTransDetails": [
        {
          "created_by": 67858,
          "survey_id": 3,
          "status_id": 1,
          "question_id": 35,
          "answer": "sdfghjkhgfdsdfghjkhgfdghj"
        },
      ]
    }]
    this.corpService.save(`survey/survey-trans-master/save?TENANT_ID=${this.tenantId}`, payload).subscribe(res => {
      if (res?.status == 'OK') {
        this.toastr.success(res.message);

      }
    },
      err => { this.toastr.error(err.message); },
      () => { })
  }
  query() {
    $(document).ready(function () {
      // Rotate icon when accordion is collapsed/expanded
      $('.collapse').on('show.bs.collapse', function () {
        $(this).prev().find('.rotate-icon').css('transform', 'rotate(180deg)');
      });
      $('.collapse').on('hide.bs.collapse', function () {
        $(this).prev().find('.rotate-icon').css('transform', 'rotate(0deg)');
      });
    });



    $(document).ready(function () {
      $('.card1').click(function () {
        $('.card1').removeClass('active');
        $(this).addClass('active');
      });
    });

    $(document).ready(function () {
      // Initialize current tab index
      var currentTab = 0;

      // Next button click event
      $("#nextTab").on("click", function () {
        currentTab = (currentTab + 1) % 8; // 3 is the total number of tabs
        $("#nav-tab button:eq(" + currentTab + ")").tab("show");
      });

      // Previous button click event
      $("#prevTab").on("click", function () {
        currentTab = (currentTab - 1 + 8) % 8; // 3 is the total number of tabs
        $("#nav-tab button:eq(" + currentTab + ")").tab("show");
      });
    });

  }







}



