import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    const httpOptions2 = {
        headers: new HttpHeaders({ 'Content-Type': 'text/html;charset=UTF-8' }) };

    // const header = new HttpHeaders({
    //     'Content-Type': 'text/html;charset=UTF-8'
    //   });


@Injectable({ providedIn: 'root' })

export class ApiService {

    constructor(private http: HttpClient) { }

    get(url: string) {
        return this.http.get(url);
    }

    get2(url: string) {
        return this.http.get(url, httpOptions);
    }

    post(url: string, postData: Object) {
        return this.http.post(url, postData);
    }
    paymentPost(url: any, postData: Object,) {
        return this.http.post(url, postData,httpOptions2);
    }

    post2(url: string, postData: Object) {
        return this.http.post(url, postData, httpOptions);
    }

    post3(url: string, postData: Object, httpOptns: any) {
        return this.http.post(url, postData, httpOptns);
    }

    put(url: string, postData: Object) {
        return this.http.put(url, postData);
    }

    patch(url: string, postData: Object) {
        return this.http.patch(url, postData);
    }

    delete(url: string) {
        return this.http.delete(url);
    }

}