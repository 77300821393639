import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ReportService {
    reportApi: string = '';
    constructor(private api: ApiService) {
        this.reportApi = environment.reportApi;
    }

    // saveTimeSlots(data: any): Observable<any> { return this.api.post(`${this.reportApi}doctor/save_slot`, data); }
    // getTimeSlotsByDate(doctorId,fromDate,toDate): Observable<any> { return this.api.get(`${this.reportApi}doctor/slots/findByid_withdate_range?doctorid=${doctorId}&&fromDate=${fromDate}&&toDate=${toDate}`); }
}