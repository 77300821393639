<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Demo Video</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="card-body">
    <div class="row small">
        <div class="col-xl-12 videoWrapper" style="padding-bottom: 30% !important;">
            <iframe src="https://vidmed2.dhanushinfotech.com/media/Doctor_Patient_Consultation_Video_Vidmed2.0.mp4"></iframe>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>