<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="refundForm" (ngSubmit)="onSubmit($event)">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Transaction Amount </label>
                        <div>
                            <input type="text" disabled [ngModel]="transaction_amount"
                                [ngModelOptions]="{standalone: true}" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">

                    <label class="control-label">Status </label>
                    <br>
                    <div class="">
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="admin_approved" [value]="true" class="custom-control-input"
                            (change)="approvalStatusChanged(true)" [attr.disabled]="(+DataEdit.status==1)?true:null"
                                id="customRadio11" type="radio" name="admin_approved">
                            <label class="custom-control-label" for="customRadio11">Approved </label>
                        </div>
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="admin_approved" [value]="false" class="custom-control-input"
                            (change)="approvalStatusChanged(false)" [attr.disabled]="(+DataEdit.status==1)?true:null"
                                id="customRadio22" type="radio" name="admin_approved">
                            <label class="custom-control-label" for="customRadio22">Rejected </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Refund Status <span class="text-danger" >*</span> </label>
                        <div>
                            <select formControlName="refund_request_id" id="refund_request_id" 
                                [ngClass]="{ 'is-invalid': submitted && f.refund_request_id.errors }" (focus)="dp.hide()"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let item of refundStatusList" [value]="item.id">{{item.name}}</option>
                                <!-- <option value="1">In Progress</option>
                                <option value="3">Refunded</option> -->
                                <!-- <option value="2">Initiated</option> -->
                                <!-- <option value="4">Refund not Accepted</option> -->
                            </select>
                            <div *ngIf="submitted && f.refund_request_id.errors" class="invalid-feedback">
                                <div *ngIf="f.refund_request_id.errors.required">Refund Status is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Refund Amount <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" id="refund_amount" formControlName="refund_amount" (keyup)="keyUp('refund_amount')"
                                placeholder="Refund Amount" class="form-control form-control-sm" (focus)="dp1.hide()"
                                [ngClass]="{ 'is-invalid': submitted && f.refund_amount.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && f.refund_amount.errors" class="invalid-feedback">
                                <div *ngIf="f.refund_amount.errors.required">Refund Amount is required</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class='col-md-6'>
                    <div class="form-group">
                        <div class='date' id='datetimepicker6'>
                            <label class="control-label">Updated Date <span class="text-danger">*</span> </label>
                            <div>
                                <input id="approved_on" name="approved_on" type="text" autocomplete="off"
                                    onpaste="return false" placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" formControlName="approved_on"
                                    onkeypress='return event.charCode == 8' [minDate]="max_date" [maxDate]="max_date"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                    [ngClass]="{ 'is-invalid': submitted && f.approved_on.errors }"
                                    class="form-control form-control-sm">
                                <div *ngIf="submitted && f.approved_on.errors" class="invalid-feedback">
                                    <div *ngIf="f.approved_on.errors.required">Updated Date is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-md-6'>
                    <div class="form-group">
                        <div class='date' id='datetimepicker6'>
                            <label class="control-label">Refund Date <span class="text-danger">*</span> </label>
                            <div>
                                <input id="refunded_on" name="refunded_on" type="text" autocomplete="off"
                                    onpaste="return false" placeholder="Refund Date" bsDatepicker #dp1="bsDatepicker"
                                    (focus)="dp1.show()"    [minDate]="max_date" [maxDate]="max_date"
                                    formControlName="refunded_on" onkeypress='return event.charCode == 8'
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                    [ngClass]="{ 'is-invalid': submitted && f.refunded_on.errors }"
                                    class="form-control form-control-sm">
                                <div *ngIf="submitted && f.refunded_on.errors" class="invalid-feedback">
                                    <div *ngIf="f.refunded_on.errors.required">Refund Date is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-group">
                        <label class="control-label">Remarks <span class="text-danger">*</span></label>
                        <div>
                            <textarea autocomplete="off" id="admin_comments" formControlName="admin_comments"
                                class="form-control form-control-sm" rows="3" (focus)="dp.hide()" (focus)="dp1.hide()"
                                [ngClass]="{ 'is-invalid': submitted && f.admin_comments.errors }"
                                onkeypress='return ((event.charCode == 37)||(event.charCode >= 48 && event.charCode <= 57)||(event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'></textarea>
                            <div *ngIf="submitted && f.admin_comments.errors" class="invalid-feedback">
                                <div *ngIf="f.admin_comments.errors.required">Remarks is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input type="submit" id="btnsubmit" style="display: none;">
    </form>
</div>
<div class="modal-footer">
    <!-- <input type="submit" class="btn btn-sm btn-primary"  (click)="submitForm()" value="Submit">&nbsp;&nbsp; -->
    <input type="submit" class="btn btn-sm btn-primary" [attr.disabled]="(DataEdit.status==3)?true:null" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <!-- <input type="submit" class="btn btn-sm btn-primary" [attr.disabled]="isSubmitDsbld==true?null:true" (click)="submitForm()" value="Submit">&nbsp;&nbsp; -->
        <!-- [attr.disabled]="refundForm.get('admin_approved').value==true?null:true" -->
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>