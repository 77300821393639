import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { HttpClient } from '@angular/common/http';
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
@Injectable({ providedIn: 'root' })

export class RegistrationService {

 
  pocAyushAPI = '';
  appUrl: string = '';
  registrationAPI: string = '';
  regUpdtAPI: string = '';
  pocAPI: string = '';
  reportApi: string = '';
  userId: any;
  viewFilePath: string = '';
  orgFilePath: string = '';
  TENANT_ID: string = '';
  downloadPath: string = '';
  loginAPI: string = '';
  presAPI: string = '';
  logTnntId: number = null;
  centerregeditobj: any;
  hubregeditobj: any;
  dwnldUrl: any
  centersID: any;
  familyDetails: any;
  genderList: Array<any> = [];
  relationsList: Array<any> = [];
  publicKey: any;
  docDetails: any;
  
  // getcenterrecordsbyDistrictIds: any;
  constructor(private api: ApiService, private authSvc: AuthenticationService,private http: HttpClient) {
    this.appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/';
    this.registrationAPI = environment.registrationAPI;
    this.pocAPI = environment.pocAPI;
    this.loginAPI = environment.loginAPI;
    this.reportApi = environment.reportApi;
    this.viewFilePath = `${this.appUrl}${environment.viewFilePath}`;
    this.orgFilePath = `${this.appUrl}${environment.organizationFilePath}`;
    this.downloadPath = `${this.appUrl}${environment.downloadPath}`;
    this.TENANT_ID = sessionStorage.getItem('currentTenant')
    this.userId = sessionStorage.getItem('session_id')
    this.presAPI = environment.presAPI;
    this.regUpdtAPI = environment.regUpdtAPI;
    authSvc.tnntSbjct.subscribe(s => this.logTnntId = s);
    this.dwnldUrl = this.appUrl;
    
  }

  chkLimit(type: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/licenceplan/validatelicenseplan/${type}?TENANT_ID=${tntId}`);
  }
  // generate jasper prescription
  genPres(pocId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}poc-emr/jwt/getpresctiptionreport/${pocId}/true?TENANT_ID=${tntId}`);
  }
  getUserPermissionByOrgId(organizationId: number): Observable<any> {
    return this.api.get(`user/get-features/${organizationId}`);
  }
  getcenterrecordsbyDistrictIds(payload: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`registration/center/getcenterrecordsbydistrictids?TENANT_ID=${tntId}`, payload);
  }
  // pocapis
  // postChieflist(doctrId: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.post(`${environment.loginAPI}poc-emr/jwt/getcheifcomplaints?TENANT_ID=${tntId}&doctorId=${doctrId}`, {}); }
  postChieflist(doctrId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}poc-emr/jwt/getcheifcomplaints?TENANT_ID=${tntId}&doctorId=${doctrId}`);
  }
  // postPDlist(doctrId: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.post(`${environment.loginAPI}poc-emr/jwt/getalldiagonsis?TENANT_ID=${tntId}&doctorId=${doctrId}`, {}); }

  postPDlist(doctrId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}poc-emr/jwt/getalldiagonsis?TENANT_ID=${tntId}&doctorId=${doctrId}`);
  }
  postIVglist(doctrId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}poc-emr/jwt/getallinvestigations?TENANT_ID=${tntId}&doctorId=${doctrId}`, {});
  }
  postDruglist(doctrId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}poc-emr/jwt/getalldrugtypes?TENANT_ID=${tntId}&doctorId=${doctrId}`, {});
  }

  deleteSessionbydocId(docId: number): Observable<any> { return this.api.delete(`telecon/activesessiondeletebydoctorid?doctorId=${docId}`) };
  // new api added 10-02-2023 kranthi
  getNetworkMonthlyReport(reportName, data: any): Observable<any> {

    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      if (reportName === 'Centre') {
        return this.api.post(`registration/center/getcenterwisereportsbylistofcenterids?TENANT_ID=${tntId}`, data);
        //return;
      } else {
        return this.api.post(`registration/center/getdatewisenetworkreportbydates?TENANT_ID=${tntId}`, data);
        //return;

      }

  }
  networkStatusReport(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`dashboardapp/dashboard/networkstatusreport?TENANT_ID=${tntId}`, data);
  }

  // new api end

  // 22-07-22
  // gethandoverReport(data: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.post(`${environment.loginAPI}poc-emr/gethandoverreport?TENANT_ID=${tntId}`, data); }
  // gethandoverReport(data: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.post(`poc-emr/gethandoverreport?TENANT_ID=${tntId}`, data); }

  gethandoverReport(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }

  getAPI(apiMethod: string, id: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}${id}`);
  }
  postAPI(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  postAPI2(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${this.regUpdtAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  getAllRefHosp(hospId: any, hubId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/beneficiary/getreferralhospitals?hospitalTypeId=${hospId}&hubId=${hubId}&TENANT_ID=${tntId}`);
  }
  //Doctor API's
  saveDoctor(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}doctor/registration?TENANT_ID=${tntId}`, data);
  }
  savePhlebo(apiMethod:string,data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/${apiMethod}?TENANT_ID=${tntId}`, data);
  }
//New API added for phlebo details by id 16-03-2023
getByPhleboById(id: number): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}registration/phlebo/getById?TENANT_ID=${tntId}&Id=${id}`);
}
getByPhleboByLabId(id: number): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}registration/phlebo/getPhlebosByLabId?TENANT_ID=${tntId}&labId=${id}`);
}


getByDeliveryBoyByPharmaId(id: number): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}registration/phlebo/get-pharma-delivery-pharma-id/${id}?TENANT_ID=${tntId}`);
}

  getByDoctorId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}doctor/getdoctorrecordbyid?doctorId=${id}&TENANT_ID=${tntId}`);
  }
  getByAllDoctors(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}doctor/getdoctorrecords?TENANT_ID=${tntId}`);
  } //doctor/getdoctorrecords
  getByAllDoctorsNew(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}doctor/web_list?TENANT_ID=${tntId}`, null);
  }
  deleteByDoctorId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}doctor/deletedoctorbyid?doctorId=${id}&TENANT_ID=${tntId}`);
  }
  getMisCals(dId: any, fD: string, tD: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}missedcalllog/getmissedcalllogs?doctorId=${dId}&fromDate=${fD}&toDate=${tD}&TENANT_ID=${tntId}`);
  }
  getAllAgents(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}doctor/getdoctorrecords?TENANT_ID=${tntId}`);
  }
  delByAgentId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}agent/deleteagentbyid?agentId=${id}&TENANT_ID=${tntId}`);
  }
  saveHubCoord(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}hubcoordinator/registration?TENANT_ID=${tntId}`, data);
  }
  deleteCenter(id: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/center/deletebycenterid/` + id + `?TENANT_ID=${tntId}`);
  }
  getAllHubCoord(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}hubcoordinator/getallregistrations?TENANT_ID=${tntId}`, data);
  }
  getAPI2(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`);
  }
  getAPI3(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/${apiMethod}?TENANT_ID=${tntId}`);
  }
  // Doctor Module API's
  saveDctrMdule(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}saveandupdatedefaultmodule?TENANT_ID=${tntId}`, data);
  }
  DctrMduleById(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}getdefaultmodulebyid?id=${id}&TENANT_ID=${tntId}`);
  }
  getAllDctrMdule(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}getalldefaultmodules?TENANT_ID=${tntId}`);
  }
  getAllDctrMdules(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${this.registrationAPI.replace('/jwt','')}getalldefaultmodules?TENANT_ID=${tntId}`);
  }
  deleteDctrModuleById(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}deletedefaultmodule?id=${id}&TENANT_ID=${tntId}`);
  }

  // Hospital API's
  saveHospital(apiMethod: string, data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${this.registrationAPI}${apiMethod}`, data); }
  gethospitalref(stateid: any, districtId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/hospital/gethospitalrecordsbyhierarchy?stateId=` + stateid + `&districtId=` + districtId + `&TENANT_ID=${tntId}`);
  }
  //Lab API's
  save(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }

  //save nojwt
  saveNoJwt(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI.replace('/jwt','')}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  // save Pay Sum new API added 04-05-2023
  savePaysum(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${environment.registration_API}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
// Health Worker APIS
  // saveHlthWrkr(data: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //     return this.api.post(`${environment.loginAPI}${this.registrationAPI.replace('jwt/', '')}health_worker/registration?TENANT_ID=${tntId}`, data);
  // }



  getHWbyID(apiMethod: string, id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`); }
  saveHlthWrkr(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}health_worker/registration?TENANT_ID=${tntId}`, data);
  }

  getAllHlthWrkrs(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI.replace('jwt/', '')}health_worker/allhealthworkerrecords?TENANT_ID=${tntId}`);
  }

  deleteByHWId(id: number): Observable<any> { return this.api.get(`${environment.loginAPI}${this.registrationAPI}health_worker/deletehealthworkerbyid?healthWorkerId=${id}`); }

  getTransaction(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getAll(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`);
  }
  getAllJoJwtUkt(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${this.registrationAPI.replace('/jwt','')}${apiMethod}?TENANT_ID=${tntId}`);
  }
  getAllNoJWT(apiMethod: string): Observable<any> {
    // alert('######api')
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${this.registrationAPI.replace('/jwt','')}${apiMethod}?TENANT_ID=${tntId}`);
  }

  getBlance(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI.replace('jwt/', '')}${apiMethod}&TENANT_ID=${tntId}`);
  }
  // getBlance(apiMethod: string): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`); }

  getAll2(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getRefund(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getviewFile(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  //below line commented for API chang for View files 14-06-2022
  // getviewFileNew(apiMethod: string): Observable<any> { return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  getviewFileNew(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getdepartments(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  deleteById(apiMethod: string, id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`);
  }
  //Chandu
  getByID(apiMethod: string, id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`);
  }
  getByLabID(apiMethod: string, id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`);
  }

  //new API added for the lab registration edit 08-07-2022
  getLabByLabId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}lab/getlabregistrationsbyid?labId=${id}&TENANT_ID=${tntId}`);
  }
  //new API added for the het Hospital by id 11-07-2022
  getHospitalByhospId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}hospital/gethospitalrecordbyid?hospitalId=${id}&TENANT_ID=${tntId}`);
  }

  //HA SESSION Api's
  getagentByuserId(useragentId: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}agent/getagentrecordbyuserid?userId=${useragentId}&TENANT_ID=${tntId}`)
  }
  getAgentId(agentid: number): Observable<any> { return this.api.get(`telecon/findbyagentid?agentId=${agentid}`) };
  //POC API's
  saveData(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  getConsByDates(apiMethod: string, data: any): Observable<any> {
    // return this.api.post(`${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  getConsultationData(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  getDataById(apiMethod: string, id: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.pocAPI}${apiMethod}${id}&TENANT_ID=${tntId}`);
  }
  getAyushDataById(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.pocAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getFollowUp(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.pocAPI.replace('/doctor/poc','')}${apiMethod}&TENANT_ID=${tntId}`);
  }
  saveAyushPOC(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }

  getAyushById(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${apiMethod}&TENANT_ID=${tntId}`);
  }
  getByPtntIntrctnID(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${environment.loginAPI}${environment.registration_API}${apiMethod}?TENANT_ID=${tntId}`, data); }
  getAllnojwt(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${environment.registration_API}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getHWByDrId(docId: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${environment.registration_API}health_worker/gethealthworkerrecordbydoctorid?doctorId=${docId}&TENANT_ID=${tntId}`);
  }
  getDependentbyId(apiMethod: string): Observable<any> { return this.api.get(`${this.registrationAPI}${apiMethod}`); }
  getDocbyHWID(apiMethod: string, id: number): Observable<any> { return this.api.get(`${this.loginAPI}${this.registrationAPI}${apiMethod}${id}`); }
  updatePatIntrcn(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
    // return this.api.post2(`${environment.loginAPI}${this.registrationAPI}${apiMethod}`, data); }
  getBenHstryDataByDHId(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)

    return this.api.get(`${environment.loginAPI}${environment.pocAPI.replace('doctor/poc/', '')}${apiMethod}&TENANT_ID=${tntId}`); }

  saveAtchmnts(apiMethod: string, data: any): Observable<any> { return this.api.post(`${this.registrationAPI}${apiMethod}`, data); }

  //Pharma API's

  deleteBynodalId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}nodalofficer/deletebyid?id=${id}&TENANT_ID=${tntId}`);
  }

  savePharma(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}pharmacy/registerandupdate?TENANT_ID=${tntId}`, data);
  }
  getByPharmaId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}pharmacy/getrecordbybypharmacyid?pharmacyId=${id}&TENANT_ID=${tntId}`);
  }
  getByAllPharmas(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}pharmacy/getallrecords?TENANT_ID=${tntId}`);
  } //pharmacy/getallrecords
  //get All Pharma list new API 07-07-2022
  getByAllPharmasNew(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}pharmacy/newgetallrecords?TENANT_ID=${tntId}`);
  } //pharmacy/getallrecords

  deleteByPharmaId(apiMethod: string, id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`);
  }
  // AgentDruglist(data:any): Observable<any> { return this.api.post(`registration/getagentcheckdrugsList?TENANT_ID=${tntId}`,data); }
  AgentDruglist(agentId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/agentinventory/getagentdrugs/${agentId}?TENANT_ID=${tntId}`);
  }
  getAllPatients(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}beneficiary/getbeneficiaryrecords?TENANT_ID=${tntId}`);
  }
  getAllPatientsNew(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}`, null);
  }
  getByAllVitals(bdId: number, is_self: boolean): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}patient/getvitalbybeneficiaryid?TENANT_ID=${tntId}&${is_self ? 'beneficiaryid' : 'beneficiarymemberid'}=${bdId}`);
  }
  // getByAllVitals(bdId: number ,fmbdId:number): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //     return this.api.get(`${environment.loginAPI}${this.registrationAPI}patient/getvitalbybeneficiaryid?TENANT_ID=${tntId}&beneficiaryid=${bdId}&beneficiarymemberid=${fmbdId}`);
  // }
  //beneficiarymemberid pharmacy/getallrecords
  getBydoctorslots(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}doctor/getdoctorrecordbyid?doctorId=${id}&TENANT_ID=${tntId}`);
  }
  getAppointment(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${this.reportApi}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getrefund(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  generateConsulation(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}generateconsulation?TENANT_ID=${tntId}`, data);
  }
  getPatientsBySearch(apiMethod: string, data: any, isAgent = false, agentId = 0, doctorcenterid,facilityId): Observable<any> {
    if (doctorcenterid) {
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
        var url = `${environment.loginAPI}${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}&centerId=${doctorcenterid}`;
    } else {
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
        var url = `${environment.loginAPI}${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}&facilityId=${facilityId}`;
    }
    if (isAgent)
      url += `&agentId=${agentId}`;
    return this.api.post(url, data);
  }
  // new changes done for patient list 11-03-2023
  getPatientsBySearchNew(apiMethod: string, data: any, isAgent = false, agentId = 0,doctorcenterid,docId): Observable<any> {
    if(doctorcenterid){
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      var url = `${environment.loginAPI}${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}&centerId=${doctorcenterid}`;
      }else{
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
        if (tntId != null)
    var url = `${environment.loginAPI}${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}&doctorId=${docId}`;
      }
    if (isAgent)
      url += `&agentId=${agentId}`;
    return this.api.post(url, data);
  }


 // patient list vitals

  vitalpatientData(apiMethod: string, data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${this.registrationAPI}${apiMethod}`, data); }


  getAgentsBySearch(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}agent/web_list?TENANT_ID=${tntId}`, data);
  }
  getpatientSearch(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}`, data);
  }
  // get Phlebo by search 15-03-2023
  getPhleboBySearch(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/phlebo/web_list?TENANT_ID=${tntId}`, data);
  }

  initiateAudiocall(doctorMobile: string, patientMobile: string): Observable<any> {
    // https://vidmedplus.dhanushinfotech.com/registration/jwt/initiateaudiocall?doctorMobile=8520078507&patientMobile=7989132075&TENANT_ID=2
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null) {
      let url = `${environment.loginAPI}${this.registrationAPI}initiateaudiocall?doctorMobile=${doctorMobile}&patientMobile=${patientMobile}&TENANT_ID=${tntId}`;
      return this.api.post(url, null);
    }

  }

  ActiveBenf(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post2(`${environment.loginAPI}${this.registrationAPI}beneficiary/activebeneficiary?TENANT_ID=${tntId}`, data);
  }
  GetBenfbyComp(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}beneficiary/getbeneficiarycorporatebyid?corporateId=${id}&TENANT_ID=${tntId}`);
  }
  saveBenfbyCompy(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post2(`${environment.loginAPI}${this.registrationAPI.replace('jwt/', '')}beneficiary/registration?TENANT_ID=${tntId}`, data);
  }
  deleteBybeneficiaryId(beneficiaryId: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.delete(`${this.registrationAPI}beneficiary/deletebybeneficiaryid?beneficiaryId=${beneficiaryId}&TENANT_ID=${tntId}`);
  }

  saveTimeSlots(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${this.reportApi}doctor/save_slot?TENANT_ID=${tntId}`, data);
  }

  bookAppointment(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${this.reportApi}appointment/booking?TENANT_ID=${tntId}`, data);
  }
  getTimeSlotsByDate(doctorId, fromDate, toDate): Observable<any> {
    // let apiMethod: string=`doctor/slots/findByid_withdate_range?doctorid=${doctorId}&fromDate=${fromDate}&toDate=${toDate}`;
    let apiMethod: string = `doctor/slots/findByid_withdate_range?doctorid=${doctorId}&fromDate=${fromDate}&toDate=${toDate}`;
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${this.reportApi}${apiMethod}&TENANT_ID=${tntId}`);
  }
  //Service Masters API'S
  saveTypeservice(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}servicetype/saveandupdate?TENANT_ID=${tntId}`, data);
  }
  getallTypeofservice(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}servicetype/getall?TENANT_ID=${tntId}`);
  }
  getTypeofServiceById(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}servicetype/getbyid=${id}&TENANT_ID=${tntId}`);
  }
  deleteTypeofServiceById(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.delete(`${environment.loginAPI}${this.registrationAPI}servicetype/deletebyid?TENANT_ID=${tntId}&id=${id}`);
  }
  session(userId: any): Observable<any> {
    if (userId != null && userId != undefined && userId != 0 && userId != '')
      return this.api.post(`${this.loginAPI}logout?userId=${this.authSvc.userId == '' ? userId : this.authSvc.userId}`, userId);
  }
  //Drugs State Apis
  saveDrugDetails(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/stateinventorysave?TENANT_ID=${tntId}`, data);
  }
  getstatebydrugs(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getstatedrugs/${id}?TENANT_ID=${tntId}`);
  }
  AgentDrugDetails(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/agentreceivedrugchecksave?TENANT_ID=${tntId}`, data);
  }
  patientRegistration(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/beneficiary/registration?TENANT_ID=${tntId}`, data);
  }
  patientRegistrationNew(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/beneficiary/userregistration?TENANT_ID=${tntId}`, data);
  }
  getAgentByStateId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}agent/getagentrecordbystateid?TENANT_ID=${tntId}&stateId=${id}`);
  }
  getAgentByStateId2(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI.replace('jwt/', '')}agentinventory/getagentdrugs/${id}?TENANT_ID=${tntId}`);
  }
  getDataByAgent(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/getagentcheckdrugsList?TENANT_ID=${tntId}`, data);
  }
  getdrugsbystate(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getstatedrugs/${id}?TENANT_ID=${tntId}`);
  }
  getcheckdrug(id: number, drugmpid: number, btno: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getcheckstatedrug/${id}?drugMappingId=${drugmpid}&batchNo=${btno}&TENANT_ID=${tntId}`);
  }
  getagentcheckdrug(id: number, drugmpid: number, btno: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/agentinventory/getcheckagentdrug/${id}?drugMappingId=${drugmpid}&batchNo=${btno}&TENANT_ID=${tntId}`);
  }

  // Disconnect Call Api's
  disconnectCallApi(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}disconcalllog/save?TENANT_ID=${tntId}`, data);
  }


  //apartment approval api
  getSocietySearch(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/society/societysearch?TENANT_ID=${tntId}&isWeb=true`, data);
  }
  saveApartment(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/society/societyadminstatus?TENANT_ID=${tntId}`, data);
  }
  //Reports apis
  getDiagnosticReports(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/beneficiaryinventorytransaction/fetchtestreports?TENANT_ID=${tntId}`, data);
  }
  getotcReports(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/beneficiaryinventorytransaction/drugdispensingreposrt?TENANT_ID=${tntId}`, data);
  }


  // stockOtcReport(data: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/utilizationstockotcreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }



  // grtStockRTK(data: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //   return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/utilizationstockotcreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }



  //Vendor Api's
  getVendorList(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/vendor/web_list?TENANT_ID=${tntId}`);
  }
  saveVendorRegistration(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/vendor/registration?TENANT_ID=${tntId}`, data);
  }
  getAllhubs(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/hubregistration/getallhubs?TENANT_ID=${tntId}`, data);
  }
  saveHubRegistration(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/hubregistration/registration?TENANT_ID=${tntId}`, data);
  }
  getAllCenters(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/center/getallcenters?TENANT_ID=${tntId}`, data);
  }
  getAllCenter(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`registration/center/nojwt/getallcenters?TENANT_ID=${tntId}`, data);
  }
  //new APY for get centers list 07-07-2022
  getAllCentersNew(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/center/getallcentersNew?TENANT_ID=${tntId}`, data);
  }

  saveCenterRegistration(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/center/registration?TENANT_ID=${tntId}`, data);
  }
  activeDoctorsList(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`telecon/doctorstatus/getall?TENANT_ID=${tntId}`)
  };
  agenttypes(): Observable<any> { return this.api.get(`masters/getallactivecenters`) };
  getallhubs(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/hubregistration/getallhubs?TENANT_ID=${tntId}`, data);
  }
  getreferalpatientList(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/referral/getallreferralhospitals?TENANT_ID=${tntId}`);
  }
  savereferal(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/referral/savereferral?TENANT_ID=${tntId}`, data);
  }
  statusrefer(): Observable<any> { return this.api.get(`masters/getallreferraltypes`) };
  getalldsssummary(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/dss/getallsummary?TENANT_ID=${tntId}`)
  };
  checkpharmacy(agentid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/pharmacy/getpharmacybyagentid?agentId=` + agentid + `&TENANT_ID=${tntId}`);
  }
  getallagentdatabyid(agentid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/agent/getagentrecordbyid?agentId=` + agentid + `&TENANT_ID=${tntId}`);
  }
  getallagentspharmacy(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/agent/getagentrecords?TENANT_ID=${tntId}`);
  }
  checklab(agentid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/lab/getlabbyagentid?agentId=` + agentid + `&TENANT_ID=${tntId}`);
  }
  getbyCenterId(id: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/center/getbycenterid/${id}?TENANT_ID=${tntId}`);
  }
  getallUserTypes(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`masters/getallukusertypes?TENANT_ID=${tntId}`);
  }
  getAllUsers(user_type: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/uk/getusersbytype?user_type=${user_type}&TENANT_ID=${tntId}`);
  }
  getattendanceReport(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}uk/getattendancereport?TENANT_ID=${tntId}`, data);
  }
  getassessementReports(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/getreportforassessmentchecklist?TENANT_ID=${tntId}`, data);
  }
  getallrefhospbydocid(doctorid: any, fromdate: any, todate: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/referral/getreferralhospitalsbydoctorid?doctorId=${doctorid}&fromDate=${fromdate}&toDate=${todate}&TENANT_ID=${tntId}`);
  }
  recentdoctor(pid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getdoctorrecordbybeneficiaryid?benficiaryId=${pid}&TENANT_ID=${tntId}`);
  }
  getCenterByMemberID(memId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getcenterbymemberid?Id=${memId}&TENANT_ID=${tntId}`);
  }
  postvitals(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/patient/savevitals?TENANT_ID=${tntId}`, data);
  }
  getBeneficiaryFamilyList(benID: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}beneficiarymemberdetails/getallbybeneficiaryid/${benID}?TENANT_ID=${tntId}`);
  }
  saveFamilyMem(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.registrationAPI}beneficiarymemberdetails/saveandupdate?TENANT_ID=${tntId}`, data);
  }
  getMemID(benID: number): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getmemberhealthcard?memberId=${benID}&TENANT_ID=${tntId}`);
  }
  getagentdrugs(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/agentinventory/getagentdrugs/${sessionStorage.agentId}?TENANT_ID=${tntId}`);
  }
  saveagentdrugs(data: any, abenId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/beneficiaryinventorytransaction/beneficiarytransaction/${abenId}?TENANT_ID=${tntId}`, data);
  }
  getagentPatients(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/beneficiary/getbeneficiaryrecordsbyagentid?agentId=${sessionStorage.agentId}&TENANT_ID=${tntId}`);
  }
  getprevconsultations(benid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}poc-emr/jwt/getpreviousconsultation?TENANT_ID=${tntId}&beneficiaryId=${benid}`);
  }
  getprevconsultationsprescrp(constid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}poc-emr/jwt/getpocbyconsultationid?TENANT_ID=${tntId}&consultationId=${constid}`);
  }
  //NDHM API's
  getOTP(aadhar: any): Observable<any> { return this.api.get(`${environment.loginAPI}registration/jwt/apis/generateaadharotp?aadharnumber=${aadhar}`); }
  verifyOTP(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/jwt/apis/verifyotp`, data) }
  encryptOTP(otp: any): Observable<any> { return this.api.get(`ndhm/jwt/apis/generateencryptecode?otp=${otp}`); }
  // generateOTP(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/jwt/apis/generatemobileotp`, data) }
  generateOTP(data:any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/jwt/apis/checkandgeneratemobileotp`,data)}

  confirmAadhar(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/jwt/apis/confirmaadhar`, data) }
  verifyMobOtp(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/jwt/apis/verifymobileotp`, data) }
  createHealthID(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/jwt/apis/createhealthidwithpreverified`, data) }
  getassessment(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getallassessments?user_type=spoke&TENANT_ID=${tntId}`);
  }
  registerAsset(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/saveassessmentmapping?TENANT_ID=${tntId}`, data);
  }
  getassetmntchecklist(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getassessmentchecklistbyagentidanddates?agent_id=${sessionStorage.agentId}&TENANT_ID=${tntId}`);
  }
  saveviewregisterAsset(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/saveassessmentchecklist?TENANT_ID=${tntId}`, data);
  }
  //savereferal(data:any): Observable<any> { return this.api.post(`registration/referral/savereferral?TENANT_ID=${tntId}`,data); }
  downloadHealthCard(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/getPngCard?TENANT_ID=${tntId}`, data)
  }
  // new API added for download health card 07-06-2023
  downloadHealthCardNew(data: any,healthNo:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/getPngCard?healthIdNumber=${healthNo}&TENANT_ID=${tntId}`, data)
  }
  existHID(hid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/apis/existsbyhealthid?healthId=${hid}&TENANT_ID=${tntId}`);
  }
  searchWithHID(hid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/apis/searchbyhealthid?healthId=${hid}&TENANT_ID=${tntId}`);
  }
  authenticate(mtd: any, hid: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/apis/authinit?authMethod=${mtd}&healthId=${hid}&TENANT_ID=${tntId}`);
  }
  cnfrmAadharOtp(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/confirmwithaadharotp?TENANT_ID=${tntId}`, data)
  }
  cnfrmMobOtp(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/confirmwithmobileotp?TENANT_ID=${tntId}`, data)
  }
  password(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/loginviapassword?TENANT_ID=${tntId}`, data)
  }
  getAcntProfile(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/getaccountprofile?TENANT_ID=${tntId}`, data)
  }
  forgotHidAadhr(adhr: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/apis/forgothealthidgenerateaadharotps?aadharnumber=${adhr}&TENANT_ID=${tntId}`);
  }
  frgtvrfyotp(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/forgotverifyaadharotps?TENANT_ID=${tntId}`, data);
  }
  forgotHidmob(mob: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/apis/forgothealthidgeneratemobileotp?mobilenumber=${mob}&TENANT_ID=${tntId}`);
  }
  frgtvrfyotpmob(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/forgotverifymobileotp?TENANT_ID=${tntId}`, data);
  }
  verifyDemographics(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/apis/verifythroughdemographicdetails??TENANT_ID=${tntId}`, data);
  }
  //Inventory
  getDrugsList(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/getagentcheckdrugsList?TENANT_ID=${tntId}`, data);
  }
  viewStock(id: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/agentinventory/getagentdrugs/${id}?TENANT_ID=${tntId}`);
  }
  updateStock(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/agentdrugcheck?TENANT_ID=${tntId}`, data);
  }
  saveStartTime(payload: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/uk/saveattendancereport?TENANT_ID=${tntId}`, payload);
  }
  getAttendanceCapture(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/uk/getattendancereport?TENANT_ID=${tntId}`, data);
  }
  //Doctor Availability slots in a month 30-03-2022
  getdoctoravailableslotsinamonth(doctorId, month, year): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/doctor/getdoctoravailableslotsinamonth?month=${month}&year=${year}&doctor_id=${doctorId}&TENANT_ID=${tntId}`);
  }
  // Get EHR Report 31-03-2022
  getehr(beneficinayId): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getehrreports?organizationId=${tntId}&beneficirayId=${beneficinayId}&TENANT_ID=${tntId}`);
  }
  // Get Hospital types New API 04-04-2022
  // get Ehr details for family member #chandu 25-05-2022
  getmemberehr(dependentId): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getehrreports?organizationId=${tntId}&dependentId=${dependentId}&TENANT_ID=${tntId}`);
  }
  // end line of family member ehr 25-05-2022
  getallhospitaltype(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getallhospitaltype?TENANT_ID=${tntId}`);
  }
  getallhospitaltypes(): Observable<any> { return this.api.get(`masters/getallahospitaltypes`); }
  //Get All Center Hospital API integration 05-04-2022
  getallcenterhospitals(center): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${center}`);
  }
  gethospitalrefNew(referral): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${referral}`);
  }

  //Doctor Registration new API integrations 05-04-2022
  getdoctorreghubs(hub): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${hub}`);
  }
  getallhospitalcentertypes(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getallhospitalcentertype?TENANT_ID=${tntId}`);
  }
  getreferralHospitals(referral, hospitalId): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${referral}&hospitalTypeId=${hospitalId}`);
  }

  //POC new API for referral hospital drop down 06-04-2022
  getPOCAllRefHosp(hospId: any, centerId: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/jwt/beneficiary/getreferralhospitals?hospitalTypeId=${hospId}&centerId=${centerId}&TENANT_ID=${tntId}`);
  }

  //New lines added for vitals API 10-04-2022
  getVitals(beneficinayId): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}registration/getvitals?organizationId=${tntId}&beneficinayId=${beneficinayId}&TENANT_ID=${tntId}`);
  }
  //New API added for interaction for update 18-07-2022
  updatePatientInteraction(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${environment.loginAPI}registration/jwt/updatepatentinteraction?TENANT_ID=${tntId}`, data);
  }
  //savereferal(data:any): Observable<any> { return this.api.post(`registration/referral/savereferral?TENANT_ID=${tntId}`,data); }
  // searchAppartments(data: any): Observable<any> {
  //   this.TENANT_ID = JSON.parse(sessionStorage.tenantDtls).data.id || '';
  //   return this.api.post2(`${this.registrationAPI}society/searchbyfileds?TENANT_ID=${this.TENANT_ID}`, data);
  // }
  // updateByTENANT_ID(url: string): string {
  //   let id = JSON.parse(sessionStorage.getItem('TENANT_ID'));
  //   const ind = url.includes('?') ? url.indexOf('?') : -1;
  //   this.TENANT_ID = id;
  //   // console.log('Index:=', ind);
  //   if (ind > -1)
  //     return url.replace('?', `?TENANT_ID=${this.TENANT_ID}&`);
  //   else
  //     return `${url}?TENANT_ID=${this.TENANT_ID}`;
  // }
  //  AYUSH SERVICES...
  getStoreName(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`);
  }
  getDHDetails(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${environment.loginAPI}${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  // doctorhealthworker/saveandupdate health_worker/registration
  mapDoctorHW(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${this.registrationAPI}doctorhealthworker/saveandupdate`, data); }
  unMapDoctorHW(id: any): Observable<any> { return this.api.delete(`${environment.loginAPI}${this.registrationAPI}doctorhealthworker/deletebyid?health_worker_id=${id}`); }
  getUnasgndHlthWrkrs(): Observable<any> { return this.api.get(`${environment.loginAPI}${this.registrationAPI}health_worker/getunassignedhealthworkerrecords?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`); }
  getUnasgndHlthWrkrsWithoutLogin(): Observable<any> { return this.api.get(`${this.registrationAPI.replace('/jwt','')}health_worker/getunassignedhealthworkerrecords?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`); }

  // Mopnthly Reports

  healthMisReport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/healthmisreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  grtStockRTK(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/getutilizationstockrtkreport?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  stockOtcReport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/utilizationstockotcreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  comparisonreport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/beneficiaryinventorytransaction/getnandghardetailscomparisonreport?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  getParaMediconFacilityReport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/getparamediconwheelsfacilityreport?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  medicalSpecialistReport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/availabilityofmedicalspecialistreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  healthCampsReport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/conductinghealthcampsreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  getRtkbReport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/getconsumablesforrtkbreport?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  hmisReport(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/hmisreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  availabilityOfOtcMedicinesreposrt(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}beneficiaryinventorytransaction/availabilityofotcmedicinesreposrt?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }

  // GAM APIS
  getbeneficiarysubscription(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registration_API}jwt/getrecordsforbeneficiarysubscription?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  getagentsbasedoncenter(centerid: any): Observable<any> { return this.api.get(`registration/getagentrecordsbycenterid?center_id=` + centerid + `&TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`); }
  getcentersbasedongap(gapid: any): Observable<any> { return this.api.get(`registration/center/getcenterrecordsbygapid?gap_id=` + gapid + `&TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`); }

  getAllGPAs(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${this.registrationAPI}getallgapregistrations?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  saveGap(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${this.registrationAPI}gap/registration?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }

  getAyushmanBen(): Observable<any> { return this.api.get(`${environment.loginAPI}registration/getrecordsforsevabharatiyojana?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`); }
  getDrugName(apimethod): Observable<any> { return this.api.get(`${environment.loginAPI}${apimethod}`); }
  getFeatures(apimethod): Observable<any> { return this.api.get(`${environment.loginAPI}${environment.userApi}${apimethod}`); }
  getAyushmanBenByfilter(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}registration/getayushmanrecords?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }


  // REPORTS
  getdetailedpayReport(data): Observable<any> { return this.api.post(`registration/jwt/getalldetailedpaymentreports?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  getpaystatusReport(data): Observable<any> { return this.api.post(`${environment.loginAPI}registration/jwt/getallpaymentsumary?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  getpaymentstatus(data: any): Observable<any> { return this.api.post(`registration/jwt/getallpaymentstatus?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  getPrescribedDrugReport(data: any): Observable<any> { return this.api.post(`pharma/Reports/getPrescribedDrugsReport?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }
  // retail reports API added 04-08-2023 #kranthi
  getretailReports(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}tpa/retail/reports?TENANT_ID=${this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId}`, data); }

 // new method added for available slots 11-03-2023 #chandu
 getTimeSlotsByDateAndDocId(docId:any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  // return this.api.get(`${this.reportApi}doctor/available_slots?TENANT_ID=${tntId}&doctor_id=${docId}&slot_for_day=${true}`); }
  return this.api.get(`${environment.loginAPI}${this.reportApi}doctor/available_dates?TENANT_ID=${tntId}&doctor_id=${docId}`); }

// new lines added for update status of beneficiaryconsultationhistory 21-03-2023
saveBeneficiaryconsultationhistory(data: any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.post(`${environment.loginAPI}poc-emr/beneficiaryconsultationhistory/save?TENANT_ID=${tntId}`, data);
}
saveAndUpdateDeliveryBoy(data: any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.post(`${environment.loginAPI}pharma/pharmacy/updatepharmadelivery?TENANT_ID=${tntId}`, data);
}
// end lines 21-03-2023
// new lines added for the status history 29-03-2023
getBeneficiaryconsultationhistory(id: any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}poc-emr/beneficiaryconsultationhistory/gethistory/${id}?TENANT_ID=${tntId}`);
}

// new API for drug list in poc 13-04-2023
getDrugsListNew(drugId: any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}poc-emr/jwt/getdrugbydrugtypeid?TENANT_ID=${tntId}&drugTypeId=${drugId}`);
}
// new API added for search by drug name 07-07-2023
getDrugsListBySearch(drugId: any,drugName:any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}poc-emr/jwt/getdrugbydrugtypeid?TENANT_ID=${tntId}&drugTypeId=${drugId}&drugName=${drugName}`);
}
// end of search by drug name
// new API for drug list in poc 27-05-2023
getAyushDrugsListNew(): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}poc-emr/jwt/getallayushdrugs?TENANT_ID=${tntId}`);
}
getLabServicesList(): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null){
    if(sessionStorage.DoctorDetails){
      let docDetails:any = JSON.parse(sessionStorage.getItem('DoctorDetails'))
      if(docDetails?.qualification_id==22){
        return this.api.get(`${environment.loginAPI}poc-emr/jwt/labservices/getall?is_ayush=true&TENANT_ID=${tntId}`);
      }else{
        return this.api.get(`${environment.loginAPI}poc-emr/jwt/labservices/getall?TENANT_ID=${tntId}`);
      }  
    }else{
      return this.api.get(`${environment.loginAPI}poc-emr/jwt/labservices/getall?TENANT_ID=${tntId}`);
    }
  }
  
}

// new lines added for the get ayush services 29-05-2023
getAyushServices(id: any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`${environment.loginAPI}poc-emr/jwt/ayushservicesubgroup/getbyid?id=${id}&TENANT_ID=${tntId}`);
}
  // save Pay Sum new API added 17-05-2023
  // savePaysum(apiMethod: string, data: any): Observable<any> {
  //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //   if (tntId != null)
  //     return this.api.post(`${environment.loginAPI}${environment.registration_API}${apiMethod}?TENANT_ID=${tntId}`, data);
  // }
  // new API added for verify abha 31-05-2023
  verifyAbha(payload:any,interactionId:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    // return this.api.post(`vidmed-login/registration/m2/v0.5/users/auth/fetch-modes?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
    return this.api.post(`abha/m2/v0.5/users/auth/fetch-modes?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}

  // new APIs added for link care context 31-05-2023
  fetchNodes(requestId:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
     return this.api.get(`vidmed-login/registration/milestone2/getCallBack?requestId=${requestId}&api-type=FETCH&TENANT_ID=${tntId}`);}

  //get init after mode select 31-05-2023
  initMode(payload:any,interactionId:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    // return this.api.post(`vidmed-login/registration/m2/v0.5/users/auth/init?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
    return this.api.post(`abha/m2/v0.5/users/auth/init?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}

  //Call back init 31-05-2023
  callbackInit(requestId:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`vidmed-login/registration/milestone2/getCallBack?requestId=${requestId}&api-type=INIT&TENANT_ID=${tntId}`);}

  //Confirm Auth 31-05-2023
  authConfirm(payload:any,interactionId:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  // return this.api.post(`vidmed-login/registration/m2/v0.5/users/auth/confirm?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
  return this.api.post(`abha/m2/v0.5/users/auth/confirm?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
//Call back Confirm 31-05-2023
confirmCallback(requestId:any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
if (tntId != null)
  return this.api.get(`vidmed-login/registration/milestone2/getCallBack?requestId=${requestId}&api-type=CONFIRM&TENANT_ID=${tntId}`);}
//getlatestcarecontext API integration 31-05-2023
getLatestCareContext(benID:any,interactionId:any): Observable<any> {
let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
if (tntId != null)
if(sessionStorage.logUsrRoleId=='7'){
  return this.api.get(`vidmed-login/registration/milestone2/getlatestcarecontext?beneficiaryId=${benID}&interactionId=${interactionId}&TENANT_ID=${tntId}`);
}
if(sessionStorage.logUsrRoleId=='5'){
  return this.api.get(`vidmed-login/registration/milestone2/getlatestcarecontext?beneficiaryId=${benID}&lab_interactionId_id=${interactionId}&TENANT_ID=${tntId}`);
}
}

addCareContext(payload:any,interactionId:any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
if (tntId != null)
  // return this.api.post(`vidmed-login/registration/m2/v0.5/links/link/add-contexts?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
  return this.api.post(`abha/m2/v0.5/links/link/add-contexts?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
  //Demographic integration 31-05-2023

   initDemographicMode(payload:any,interactionId:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    // return this.api.post(`vidmed-login/registration/m2/v0.5/users/auth/confirm?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
    return this.api.post(`abha/m2/v0.5/users/auth/confirm?interactionId=${interactionId}&TENANT_ID=${tntId}`,payload);}
//Direct 31-05-2023
directCallbackInit(requestId:any): Observable<any> {
let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
if (tntId != null)
// return this.api.get(`registration/milestone2/getCallBack?healthId=${healthId}&api-type=DIRECT-AUTH,ON-DIRECT-AUTH&TENANT_ID=${tntId}`);}
return this.api.get(`vidmed-login/registration/milestone2/getCallBack?requestId=${requestId}&api-type=DIRECT-AUTH,ON-DIRECT-AUTH&TENANT_ID=${tntId}`);}

careContextCallback(requestId:any): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
if (tntId != null)
  return this.api.get(`vidmed-login/registration/milestone2/getCallBack?requestId=${requestId}&api-type=ADD-CONTEXT&TENANT_ID=${tntId}`);}

  // New API added for fetch demography 31-05-2023
  getDemograpyDtls(benID:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`vidmed-login/registration/beneficiary/get-demography-object?beneficiaryId=${benID}&TENANT_ID=${tntId}`);}
    // return this.api.get(`registration/beneficiary/get-demography-object?beneficiaryId=${benID}&TENANT_ID=${tntId}`);}

    // new API added for get mem details with from patient list mem to POC 31-05-2023
    getBeneficiaryFamilyMember(benID:number,memId:number ): Observable<any> {
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      return this.api.get(`${this.registrationAPI}beneficiarymemberdetails/getallbybeneficiaryid?beneficiaryId=${benID}&memberId=${memId}&TENANT_ID=${tntId}`); }

      // new method added for available slots 31-05-2023
      // getTimeSlotsByDateAndDocId(docId:any): Observable<any> {
      //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      //   if (tntId != null)
      //   // return this.api.get(`${this.reportApi}doctor/available_slots?TENANT_ID=${tntId}&doctor_id=${docId}&slot_for_day=${true}`); }
      //   return this.api.get(`${this.reportApi}doctor/available_dates?TENANT_ID=${tntId}&doctor_id=${docId}`); }

      // new APi Added for consent list 12-06-2023
      consentNdhm(payload:any,benId:any): Observable<any> {
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
        // return this.api.post(`vidmed-login/registration/m3/v0.5/consent-requests/init?beneficiaryId=${benId}&TENANT_ID=${tntId}`,payload);}
        return this.api.post(`abha/m3/v0.5/consent-requests/init?beneficiaryId=${benId}&doctor_id=${sessionStorage.loginId}&TENANT_ID=${tntId}`,payload);}

      getAbhaAddress(payload:any,caseId:any): Observable<any> {
       let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
       if (tntId != null)
        // return this.api.post(`vidmed-login/registration/m3/v0.5/patients/find?caseId=${caseId}&TENANT_ID=${tntId}`,payload);}
        return this.api.post(`abha/m3/v0.5/patients/find?caseId=${caseId}&TENANT_ID=${tntId}`,payload);}

        // getConsentsList(docId:any): Observable<any> {
        //   let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
        //   if (tntId != null)
        //   return this.api.get(`vidmed-login/registration/milestone2/get-consents-list-by-doctor?doctorId=${docId}&TENANT_ID=${tntId}`);
        // }
        getConsentsList(docId:any,benId:any): Observable<any> {
          let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
          if (tntId != null)
          return this.api.get(`vidmed-login/registration/milestone2/get-consents-list-by-doctor?doctorId=${docId}&beneficiary_id=${benId}&TENANT_ID=${tntId}`);
        }
        // new method added for the get callback m3 04-07-2023
        getCallback(request:any):Observable<any>{
          let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
          if (tntId != null)
          return this.api.get(`vidmed-login/registration/milestone3/get-m3-call-back?requestId=${request}&TENANT_ID=${tntId}`);

        }
        // new method added for get consent view files 05-07-2023
        getConsentViewFiles(consentId:any):Observable<any>{
          let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
          if (tntId != null)
          return this.api.get(`vidmed-login/registration/milestone3/get-document-by-consent-id?consentId=${consentId}&TENANT_ID=${tntId}`);

        }

        getConsentViewFilesexample(consentId:any):Observable<any>{
          let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
          if (tntId != null)
          return this.api.get(`abha/milestone3/get-document-by-consent-id/get-simplified-bundle?consentId=${consentId}&TENANT_ID=${tntId}`);

        }
        // new API Added for resent OTP for M1 24-05-2023
        resendOtp(data: any,type:any): Observable<any> {
          let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
          if (tntId != null)
          if(type==='mobile'){
            return this.api.post(`${environment.loginAPI}registration/jwt/apis/mobile/resend-mobile-otp?TENANT_ID=${tntId}`, data)

          }else if(type==='aadhaar'){
            return this.api.post(`${environment.loginAPI}registration/jwt/apis/aadhaar/resend-aadhaar-otp?TENANT_ID=${tntId}`, data)

          }
          }
          resendOtps(queryString:any,data: any): Observable<any> {
            let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
            if (tntId != null)
              return this.api.post(`${environment.loginAPI}${queryString}`, data)
            }

          ///emr new API
          getEmr(patientId: any): Observable<any> {
            let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
            if (tntId != null)
              return this.api.get(`${environment.loginAPI}poc-emr/hims/getemrdata?beneficiaryId=${patientId}&TENANT_ID=${tntId}`);
          }
          jasperGenerate(payload: any): Observable<any> {
            let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
            if (tntId != null)
              return this.api.post(`${environment.loginAPI}poc-emr/hims/gethimsfiles?TENANT_ID=${tntId}`, payload);
          }

          // getall

          getAllData(queryString: string): Observable<any> {
            let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
            return this.api.get(`${environment.loginAPI}${queryString}?TENANT_ID=${tntId}`); }

          saveApi(apiMethod: string, data: any): Observable<any> {
            let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
            if (tntId != null)
              return this.api.post(`${environment.loginAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
          }
          postApiWithoutTenant(apiMethod: string, data: any): Observable<any> {
            let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
            if (tntId != null)
              return this.api.post(`${environment.loginAPI}${apiMethod}`, data);
          }
          powerBiUrl(queryString: string): Observable<any> { return this.api.get(`${environment.userApi}${queryString}`); }
          paymentGateWay(payload: any): Observable<any> {
            return this.api.paymentPost(`${environment.payubiz}`, payload); }
            getwithoutlogin(apiPoints): Observable<any> {
              const p= apiPoints.includes('?')?'&':'?';
              let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
              return this.api.get(`${apiPoints}${p}TENANT_ID=${tntId}`); }
            get(apiPoints): Observable<any> {
              const p= apiPoints.includes('?')?'&':'?';
              let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
              return this.api.get(`${environment.loginAPI}${apiPoints}${p}TENANT_ID=${tntId}`); }
              post(apiMethod: string, data: any): Observable<any> {
                const p= apiMethod.includes('?')?'&':'?';
                let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
                if (tntId != null)
                  return this.api.post(`${environment.loginAPI}${apiMethod}${p}TENANT_ID=${tntId}`, data);
              }
              getWithoutTnt(apiPoints): Observable<any> {
                // const p= apiPoints.includes('?')?'&':'?';
                let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
                return this.api.get(`${environment.loginAPI}${apiPoints}`); }
              postWithoutTnt(apiMethod: string, data: any): Observable<any> {
                // const p= apiMethod.includes('?')?'&':'?';
                let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
                if (tntId != null)
                  return this.api.post(`${environment.loginAPI}${apiMethod}`, data);
              }

            }

