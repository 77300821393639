import { UploadConsentFormsComponent } from './modules/configuration/upload-consent-forms/upload-consent-forms.component';
import { PaymentGatewayComponent } from './modules/configuration/payment-gateway/payment-gateway.component';
import { CustomisePrescriptionComponent } from './modules/configuration/customise-prescription/customise-prescription.component';
import { UploadLogoComponent } from './modules/configuration/upload-logo/upload-logo.component';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { PlanOfCareComponent } from './modules/plan-of-care/plan-of-care.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { PocComponent } from './modules/poc/poc.component';
import { ManualprescriptionComponent } from './modules/modals/manualprescription/manualprescription.component';
import { MatersResolver } from './shared/resolver/masters.resolver';
import { AuthGuard } from './shared/guards/auth.guard';
import { NewPocComponent } from './modules/new-poc/new-poc.component';
import { PocAyushComponent } from './modules/ayush/poc-ayush/poc-ayush.component';
import { InActiveListComponent } from './modules/pro/registrations/in-active-list/in-active-list.component';
import { TenantwiseScreensComponent } from './modules/pro/tenantwise-screens/tenantwise-screens.component';
import { NewPocAyushComponent } from './modules/ayush/new-poc-ayush/new-poc-ayush.component';
import { OfflinePocComponent } from './modules/offline-poc/offline-poc.component';
import { UserManualGlobalComponent } from './modules/user-manual-global/user-manual-global.component';
import { MvupocOfflineComponent } from './modules/mvupoc-offline/mvupoc-offline.component';
import { MvupocOnlineComponent } from './modules/mvupoc-online/mvupoc-online.component';



const routes: Routes = [
  {
    path: 'home', component: HomeComponent,canActivate:[AuthGuard],
    children: [
      { path: 'MP', component: ManualprescriptionComponent },
      { path: 'usg/:lable/:id/:mid', component: UserManualGlobalComponent },
      // { path: 'agent', component: AgentListComponent },
      // { path: 'hospitalDetailList', component: HospitalDetailListComponent },
      { path: 'inActivelist', component: InActiveListComponent },
      // { path: 'vendor', component: VendorListComponent },
      {path: 'tenanatwisescreens' , component:TenantwiseScreensComponent},
      // Consultation
      {path:'ayushpocnew', component: NewPocAyushComponent},
      { path: 'ayushpoc', component: PocAyushComponent },
      // { path: 'poc', component: NewPocComponent},
      // { path: 'poc', component: PocComponent},
      { path: 'poc', component: NewPocComponent },
      {path:'offlinepoc',component:OfflinePocComponent},

      
      {path:'mvupocoffline',component:MvupocOfflineComponent},
      {path:'mvupoconline',component:MvupocOnlineComponent},

      { path: 'logo', component: UploadLogoComponent },
      { path: 'prescription', component: CustomisePrescriptionComponent },
      { path: 'paymentGateway', component: PaymentGatewayComponent },
      { path: 'tpa', loadChildren: () => import('./modules/axa/tpa/tpa.module').then(m => m.TpaModule) },
      { path: 'insurer', loadChildren: () => import('./modules/axa/insurer/insurer/insurer.module').then(m => m.InsurerModule) },
      { path: 'crpt', loadChildren: () => import('./modules/axa/corporate/corporate.module').then(m => m.CorporateModule) },
      { path: 'usagechrg', loadChildren: () => import('./modules/axa/usage-charge/usage-charge.module').then(m => m.UsageChargeModule) },
      { path: 'corplanmap', loadChildren: () => import('./modules/axa/corporate/corporate.module').then(m => m.CorporateModule) },
      { path: 'pharma', loadChildren: () => import('./modules/axa/pharmacy/pharmacy.module').then(m => m.PharmacyModule) },
      { path: 'lab', loadChildren: () => import('./modules/axa/lab/lab.module').then(m => m.LabModule) },
      {path:'campReg',loadChildren:() =>import('./modules/pro/registrations/camp-registration/camp-registration.module').then(m =>m.CampRegistrationModule)},
      { path: 'user', loadChildren: () => import('./modules/axa/user/user.module').then(m => m.UserModule) },
      // { path: 'drugs', loadChildren: () => import('./modules/axa/user/user.module').then(m =>m.UserModule) }
      { path: 'doctorReg', loadChildren: () => import('./modules/pro/registrations/doctor-reg/doctor-reg.module').then(m => m.DoctorRegModule) },
      {path:'hfrReg',loadChildren: ()=> import('./modules/pro/registrations/hfr-registration/hfr-registration.module').then(m => m.HfrRegistrationModule)},
      { path: 'agentReg', loadChildren: () => import('./modules/pro/registrations/agent-reg/agent-reg.module').then(m => m.AgentRegModule) },
      { path: 'hubReg', loadChildren: () => import('./modules/pro/registrations/hub-reg/hub-reg.module').then(m => m.HubRegModule) },
      { path: 'hospitalReg', loadChildren: () => import('./modules/pro/registrations/hospital-reg/hospital-reg.module').then(m => m.HospitalRegModule) },
      { path: 'vendorReg', loadChildren: () => import('./modules/pro/registrations/vendor-reg/vendor-reg.module').then(m => m.VendorRegModule) },
      { path: 'labPharReg', loadChildren: () => import('./modules/pro/registrations/lab-phar-reg/lab-phar-reg.module').then(m => m.LabPharRegModule) },
      { path: 'consultation', loadChildren: () => import('./modules/pro/consulatation/consulatation/consulatation.module').then(m => m.ConsulatationModule) },
      { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
      { path: 'inventory', loadChildren: () => import('./modules/masters/inventory/inventory.module').then(m => m.InventoryModule) },
      { path: 'reports', loadChildren: () => import('./modules/pro/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'organization', loadChildren: () => import('./modules/configuration/organization/organization.module').then(m => m.OrganizationModule) },
      { path: 'rakthadhan', loadChildren: () => import('./modules/Rakthadhan/blood-camp/blood-camp.module').then(m => m.BloodCampModule) },
      { path: 'gam', loadChildren: () => import('./modules/GAM/gam/gam.module').then(m => m.GamModule) },
      { path: 'master', loadChildren: () => import('./modules/masters/master/master.module').then(m => m.MasterModule) },
      { path: 'pharmaReports', loadChildren: () => import('./modules/axa/pharmacy/pharma-reports/pharma-reports.module').then(m => m.PharmaReportsModule) },
      {path:'reports',loadChildren:() => import('./modules/axa/lab/lab-reports/lab-reports.module').then(m =>m.LabReportsModule)},
      { path: 'ndhm', loadChildren: () => import('./modules/ndhm/ndhm/ndhm.module').then(m => m.NdhmModule) },
      { path: 'configsettings', loadChildren: () => import('./modules/ayush/configuration-settings/configuration-settings.module').then(m => m.ConfigurationSettingsModule)},
      {path:'support', loadChildren: () =>import('./modules/ayush/support/support.module').then(m => m.SupportModule)},
      { path: 'ndhm/consentlist', loadChildren: () => import('./modules/ndhm/ndhm/ndhm.module').then(m => m.NdhmModule) },
      { path: 'labmaster', loadChildren: () => import('./modules/masters/laboratory-master/laboratory-master.module').then(m => m.LaboratoryMasterModule) },
      { path: 'radiologymaster', loadChildren: () => import('./modules/masters/radiology-master/radiology-master.module').then(m => m.RadiologyMasterModule) },
      { path: 'labtransactions', loadChildren: () => import('./modules/axa/laboratory-transactions/laboratory-transactions.module').then(m => m.LaboratoryTransactionsModule) },
      { path: 'rdlgytransactions', loadChildren: () => import('./modules/axa/radiology-transactions/radiology-transactions.module').then(m => m.RadiologyTransactionsModule) },
      { path: 'gnrlstore', loadChildren: () => import('./modules/pro/master-general-store/master-general-store.module').then(m => m.MasterGeneralStoreModule) },
      { path: 'mstrPharmacy', loadChildren: () => import('./modules/pro/master-pharmacy/master-pharmacy.module').then(m => m.MasterPharmacyModule) },
      { path: 'txngnrlstrs', loadChildren: () => import('./modules/pro/transaction-general-store/transaction-general-store.module').then(m => m.TransactionGeneralStoreModule) },
      { path: 'txnpharmacy', loadChildren: () => import('./modules/pro/transaction-pharmacy/transaction-pharmacy.module').then(m => m.TransactionPharmacyModule) },
      {path:'userGuideConfig',loadChildren:()=>import('./modules/pro/user-guife-config/user-guife-config.module').then(m =>m.UserGuifeConfigModule)},
      {path:'integrated',loadChildren:()=>import('./modules/pro/integrated-module/integrated-module.module').then(m=>m.IntegratedModuleModule)},
      {path:'vehReg',loadChildren:()=>import('./modules/pro/registrations/vehicle-registration/vehicle-registration.module').then(m=>m.VehicleRegistrationModule)},
      {path:'mvuReports',loadChildren:()=>import('./modules/pro/mvu-reports/mvu-reports.module').then(m=>m.MvuReportsModule)},
      {path:'vts',loadChildren:() =>import('./modules/pro/veh-track-syatem/veh-track-syatem.module').then(m =>m.VehTrackSyatemModule)},
      {path:'vtsRpt',loadChildren:()=>import('./modules/pro/vts-reports/vts-reports.module').then(m=>m.VtsReportsModule)}
      

    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },

  { path: '', component: LoginComponent },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [MatersResolver]
})

export class AppRoutingModule { }