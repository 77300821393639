import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameFormatter } from './directives/project-name-format.directive';
import { CustomDerDirective } from './directives/custom-der.directive';
import { SpacesDirective } from './directives/spaces.directive';



@NgModule({
  declarations: [CustomDerDirective,NameFormatter, SpacesDirective],
  imports: [
     CommonModule
  ],
  exports: [CustomDerDirective,NameFormatter,SpacesDirective,CommonModule]
})
export class DirectiveModuleModule { }
