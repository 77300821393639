import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, EmailValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';

declare let $: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  @Input() title: string;
  @Input() userId: string;
  @Input() tenantId: string;
  // oldPassword: string = '';
  newPassword: string = '';
  confirmed_password: string = '';

  num: number = 0;
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private router: Router
    , private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    // Common.setFocus('oldPassword');
  }

  submit() {
    // if (this.oldPassword === '') {
    //   this.toastr.error('Please enter Old Password', this.title);
    //   Common.setFocus('oldPassword');
    //   return;
    // }
     if (this.newPassword === '') {
      this.toastr.error('Please enter New Password', this.title);
      Common.setFocus('newPassword');
      return;
    }
    else if (this.confirmed_password === '') {
      this.toastr.error('Please enter Confirm Password', this.title);
      Common.setFocus('confirmed_password');
      return;
    }

    // if (this.oldPassword == this.newPassword) {
    //   [this.oldPassword, this.newPassword] = ['', ''];
    //   Common.setFocus('oldPassword');
    //   this.toastr.error('Old Password and New Password should not be equal', this.title);
    //   return;
    // }
     if (this.confirmed_password != this.newPassword) {
      [this.confirmed_password, this.newPassword] = ['', ''];
      Common.setFocus('newPassword');
      this.toastr.error('Cofirm Password and New Password should be equal', this.title);
      return;
    }
    else if (this.confirmed_password == '123456' && this.newPassword == '123456') {
      [this.confirmed_password, this.newPassword] = ['', ''];
      Common.setFocus('newPassword');
      this.toastr.error('Default password 123456 is not allowed');
      return;
    }

    let changeData = {
      "userId": this.userId,
      // "oldPassword": this.oldPassword,
      "newPassword": this.newPassword,
      "confirmPassword":this.confirmed_password
    },  tenantData = JSON.parse(sessionStorage.getItem('tenantDtls')), tnt = tenantData ? `?TENANT_ID=${tenantData?.data?.id}` : '';

    // console.log(changeData);
    // return;

    this.authenticationService.forgotpassword(changeData, tnt).subscribe(res => {
      if (res.status === "success" && res.response != null) {
        this.toastr.success('Your password Updated successfully', this.title);        
        setTimeout(() => {
          this.activeModal.close('changes saved');
          this.authenticationService.logout();
          this.router.navigate(['']);
        })
      }
    });

  }
}