import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-emergency-model',
  templateUrl: './emergency-model.component.html',
  styleUrls: ['./emergency-model.component.css']
})
export class EmergencyModelComponent implements OnInit {

  emergencyComment: string;
  @Output() emergencyExit = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void { }

  emergency_Exit(): void {
    this.emergencyExit.emit(this.emergencyComment);
  }

}

