import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbActiveModal, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule, FormsModule, FormGroupDirective } from '@angular/forms';
import { LoginComponent } from './modules/login/login.component';
import { HomeComponent } from './modules/home/home.component';
import { PlanOfCareComponent } from './modules/plan-of-care/plan-of-care.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { UserprofileComponent } from './modules/userprofile/userprofile.component'
import { ChiefComplaintsComponent } from './modules/modals/chief-complaints/chief-complaints.component';
import { HistoryOfPresentIllnessComponent } from './modules/modals/history-of-present-illness/history-of-present-illness.component';
import { VitalsComponent } from './modules/modals/vitals/vitals.component';
import { DrugsComponent } from './modules/modals/drugs/drugs.component';
import { AdviceComponent } from './modules/modals/advice/advice.component';
import { Covid19Component } from './modules/modals/covid19/covid19.component';
import { ConsultationPreviewComponent } from './modules/modals/consultation-preview/consultation-preview.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { WebcamModule } from 'ngx-webcam';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PocComponent } from './modules/poc/poc.component';
import { VerifyOTPComponent } from './modules/verify-otp/verify-otp.component';
import { ConfigurationComponent } from './modules/configuration/configuration.component';
import { UploadLogoComponent } from './modules/configuration/upload-logo/upload-logo.component';
import { CustomisePrescriptionComponent } from './modules/configuration/customise-prescription/customise-prescription.component';
import { PaymentGatewayComponent } from './modules/configuration/payment-gateway/payment-gateway.component';
import { UploadConsentFormsComponent } from './modules/configuration/upload-consent-forms/upload-consent-forms.component';
import { PrescriptionComponent } from './modules/modals/prescription/prescription.component';
import { NgxTinymceModule } from 'ngx-tinymce';
import { ViewFileComponent } from './modules/modals/view-file/view-file.component';
import { ImagePreviewComponent } from './modules/modals/image-preview/image-preview.component';
import { CovidComponent } from './modules/configuration/covid/covid.component';
import { RefundListComponent } from './modules/admin/refund-list/refund-list.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ManualprescriptionComponent } from './modules/modals/manualprescription/manualprescription.component';
import { MissedcallComponent } from './modules/modals/missedcall/missedcall.component';
import { IvideoComponent } from './modules/modals/ivideo/ivideo.component';
import { IvideosComponent } from './modules/modals/ivideos/ivideos.component';
import { LoginOldComponent } from './modules/login-old/login-old.component';
import { OrgPlansControlsComponent } from './modules/configuration/organization/org-plans-controls/org-plans-controls.component';
import { CorporateplanscontrolsComponent } from './modules/configuration/corporateplanscontrols/corporateplanscontrols.component';
import { TenantFeaturesComponent } from './modules/configuration/tenant-features/tenant-features.component';

//import { Referal } from './modules/consultation/referal-patient-list/referal-patient-list.component';

import { SafePipe } from './shared/pipes/safe.pipe';

import { ExcelService } from './shared/services/excel.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NewPocComponent } from './modules/new-poc/new-poc.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { DirectiveModuleModule } from './directive-module/directive-module.module';
//import { Ayush } from './modules/registration/ayush/ayush-doctor-registration/ayush-doctor-registration.component';
import { PocAyushComponent } from './modules/ayush/poc-ayush/poc-ayush.component';
import { POCConfirmMesssageModelComponent } from './modules/poc-confirm-messsage-model/poc-confirm-messsage-model.component';
import { DssFlowComponent } from './modules/pro/registrations/dss-flow/dss-flow.component';
import { FamilyMemberRegistrationComponent } from './modules/pro/registrations/family-member-registration/family-member-registration.component';
import { PipeModule } from './shared/pipes/pipe/pipe.module';
import { InActiveListComponent } from './modules/pro/registrations/in-active-list/in-active-list.component';
import { BloodCampDetailsComponent } from './modules/Rakthadhan/blood-camp/blood-camp-details/blood-camp-details.component';
import { TenantwiseScreensComponent } from './modules/pro/tenantwise-screens/tenantwise-screens.component';
import { TabsModule, TooltipModule } from 'ngx-bootstrap';
import { HwconsultationComponent } from './modules/pro/consulatation/hwconsultation/hwconsultation.component';
import { ConfirmMessageModelComponent } from './modules/ayush/confirm-message-model/confirm-message-model.component';
import { EmergencyModelComponent } from './modules/ayush/emergency-model/emergency-model.component';
import { PatientModelComponent } from './modules/ayush/patient-model/patient-model.component';
import { PocLeftMenuComponent } from './modules/ayush/poc-left-menu/poc-left-menu.component';
import { PrimaryBeneficiaryComponent } from './modules/ayush/primary-beneficiary/primary-beneficiary.component';
import { CovidForm19Component } from './modules/ayush/covid-form19/covid-form19.component';
import { DataTablesModule } from 'angular-datatables';
import { NewPocAyushComponent } from './modules/ayush/new-poc-ayush/new-poc-ayush.component';
import { DatePipe } from '@angular/common';
import { BookAppointmentsComponent } from './modules/modals/book-appointments/book-appointments.component';
import { OfflinePocComponent } from './modules/offline-poc/offline-poc.component';
import { UserManualGlobalComponent } from './modules/user-manual-global/user-manual-global.component';
import { AgmCoreModule } from '@agm/core';
import { AppMaterialModule } from './app-material.module';
import { MvupocOfflineComponent } from './modules/mvupoc-offline/mvupoc-offline.component';
import { MvupocOnlineComponent } from './modules/mvupoc-online/mvupoc-online.component';




// import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    AppComponent,
    RefundListComponent,
    LoginComponent,
    ModalContentComponent,
    HomeComponent,
    PlanOfCareComponent,
    ForgotPasswordComponent,
    ViewFileComponent,
    ChiefComplaintsComponent,
    HistoryOfPresentIllnessComponent,
    VitalsComponent,
    ImagePreviewComponent,
    DrugsComponent,
    AdviceComponent,
    Covid19Component,
    ConsultationPreviewComponent,
    PocComponent,
    VerifyOTPComponent,
    ConfigurationComponent,
    UploadLogoComponent,
    CustomisePrescriptionComponent,
    PaymentGatewayComponent,
    UploadConsentFormsComponent,
    PrescriptionComponent,
    CovidComponent,
    ManualprescriptionComponent,
    MissedcallComponent,
    IvideoComponent,
    IvideosComponent,
    LoginOldComponent,
    OrgPlansControlsComponent,
    CorporateplanscontrolsComponent,
    TenantFeaturesComponent,
    InActiveListComponent,
    // SafePipe,
    NewPocComponent,
    DssFlowComponent,
    FamilyMemberRegistrationComponent,
    // Ayush,
    PocAyushComponent,
    POCConfirmMesssageModelComponent,
    BloodCampDetailsComponent,
    TenantwiseScreensComponent,
    ConfirmMessageModelComponent,
    EmergencyModelComponent,
    PatientModelComponent,
    PocLeftMenuComponent,
    PrimaryBeneficiaryComponent,
    CovidForm19Component,
    NewPocAyushComponent,
    BookAppointmentsComponent,
    OfflinePocComponent,
    UserManualGlobalComponent,
    MvupocOfflineComponent,
    MvupocOnlineComponent
    
   
 

   
   
  ],
  imports: [
    NgbTimepickerModule,
    HttpClientModule,
    SharedModule,
    BrowserModule,
    DataTablesModule,
    NgSelectModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgxAudioPlayerModule,
    DirectiveModuleModule,
    NgxTinymceModule.forRoot({
      // baseURL: './assets/tinymce/',
      // or cdn
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/'
    }),
    BrowserAnimationsModule, // required animations module
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    ReactiveFormsModule, FormsModule,
    TimepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot({ preventDuplicates: true }), // ToastrModule added
    WebcamModule,
    PipeModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    AppMaterialModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA8vkaSmcx8wMeJuy8WELQEDjc_wy74T3o'
    })
    // NgxLoadingModule.forRoot({})
  ],
  //exports: [CustomDerDirective],
  providers: [NgbActiveModal, ExcelService,DatePipe,FormGroupDirective],
  // providers: [NgbActiveModal, { provide: LocationStrategy, useClass: HashLocationStrategy },],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }