<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Upload Logo
                        <button class="btn btn-blue btn-icon float-right" data-toggle="modal" data-target="#myModal1"  (click)="onAdd()"
                        *ngIf="setPrivilege(Screen_Name.UploadConsentForm, Permission.Add)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">

                            <table datatable class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Filename</th>
                                        <th style="width:40%;">Hospital Name & Address</th>
                                        <th>Uploaded date</th>
                                        <th>Image </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>test.png</td>
                                        <td>Apollo Spectra Hospitals<br> Kothaguda X Roads, Kondapur, near Harsha Toyota
                                            Showroom, Hyderabad, Telangana 500032</td>
                                        <td>03/05/2020</td>
                                        <td><img [src]="logo" /></td>
                                        <td>
                                            <span> <a class="badge badge-info badge-pill" data-toggle="modal"
                                                    data-target="#myModal1"  *ngIf="setPrivilege(Screen_Name.UploadConsentForm, Permission.Add)">
                                                    <i class="fa fa-edit text-white"></i></a>&nbsp;
                                            </span>
                                            <span>
                                                <a class="badge badge-danger badge-pill"  *ngIf="setPrivilege(Screen_Name.UploadConsentForm, Permission.Add)">
                                                    <i class="fa fa-trash text-white"></i></a>
                                            </span>
                                            &nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle"> Upload Logo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="uploadForm">
                <div class="form-group">
                    <label class="control-label">Organization Name
                    </label>
                    <div class="col-xl-3">
                        <select class="form-control form-control-sm" formControlName="tenant">
                            <option value=''>Select</option>
                            <option>Tenant Name1</option>
                            <option>Tenant Name2</option>
                            <option>Tenant Name3</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="row small">

                    <div class="col-xl-4">
                        <div class="form-group">
                            <label class="control-label">Hospital Name
                            </label>
                            <div>
                                <input type="text" class="form-control form-control-sm" placeholder="Hospital Name">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="form-group">
                            <label class="control-label">Hospital Address
                            </label>
                            <div>
                                <textarea class="form-control form-control-sm" rows="1" placeholder="Hospital Address">

                            </textarea>

                            </div>
                        </div>
                    </div>


                </div> -->

                <div class="table-responsive">

                    <table class="table table-bordered">
                        <tr>
                            <td>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="customFile" formControlName="logoFile" (change)="singleFileUpload($event,0,'1')">
                                    <label class="custom-file-label form-control-sm" for="customFile">Upload logo
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="customFile" formControlName="wmFile" (change)="singleFileUpload($event,1,'2')">
                                    <label class="custom-file-label form-control-sm" for="customFile">Upload Watermark
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="customFile" formControlName="bannerFile" (change)="singleFileUpload($event,2,'3')">
                                    <label class="custom-file-label form-control-sm" for="customFile">Upload Banner
                                    </label>
                                </div>
                            </td>
                        </tr>

                        <tr class="text-center">
                            <td>
                                <div *ngIf="logoUrl"> <img [src]="logoUrl" style="width: 300px;height: 80px;" />
                                </div>
                            </td>
                            <td>
                                <div *ngIf="wmUrl"> <img [src]="wmUrl" style="width: 300px;height: 80px;" /></div>
                            </td>
                            <td>
                                <div *ngIf="bannerUrl"> <img [src]="bannerUrl" style="width: 300px;height: 80px;" />
                                </div>
                            </td>
                        </tr>


                    </table>

                </div>

                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-sm btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>