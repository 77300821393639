import { Injectable } from '@angular/core';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs/dist/exceljs.min.js';


@Injectable({
  providedIn: 'root'
})
export class ExcelDownloadService {

  constructor() { }


  public downloadExcel(excel_header,internal_header,file_name,list) {
// console.log('excel_header',excel_header)
// console.log('internal_header',internal_header)
// console.log('file_name',file_name);
// console.log('file_name',list)
//     return;
const excelHeaders = excel_header;
    const internalHeaders =  internal_header
    //const excelHeaders = ['Invoice No', 'Date', 'Name', 'Mobile No', 'Invoice Amount', 'Cash', 'Card', 'Cheque', 'Online', 'Total', 'Balance Amount'];
    //const internalHeaders = ['invoiceno', 'date', 'name', 'mobileno', 'invoiceAmt', 'cash', 'card', 'cheque', 'online', 'total', 'balanceAmt'];
    // const internalHeaders = ['name', 'mobileno', { k1: 'ra', k2: 'value' }, { k1: 'asm', k2: 'value' }];
    this.exportAsExcelFileWithFormat(list, file_name, excelHeaders, internalHeaders);
  }

  private exportAsExcelFileWithFormat(json: any[], excelFileName: string, headersArray: any[], internalHeaders: any[]): void {

    const header = headersArray;
    const data = json;
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(excelFileName);
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.font = { bold: true }
    })
    if (data) {
      data.forEach((element) => {
        let eachRow = [];
        internalHeaders.forEach((header) => {
          if (typeof header == 'object') {
            try {
              const k1 = header['k1'];
              const k2 = header['k2'];
              eachRow.push(element[k1][k2]);

            } catch (error) {
              eachRow.push(null);
            }
          }
          else {
            eachRow.push(element[header]);
          }
        })
        worksheet.addRow(eachRow);
      });
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    })
  }
}
