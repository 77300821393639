<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button class="btn btn-blue btn-icon float-right"
                            (click)="openAddSpecis()" *ngIf="showTable">
                            <i class="fa fa-plus"></i>
                         
                        </button>
</div>
<div class="modal-body">
    <div class="row" [formGroup]="speciesForm" *ngIf="showTable">
        <div class='col-xl-3 col-md-3'>
            <div class="form-group">
                <div class='date' id='datetimepicker6'>
                    <label class="control-label">From Date <span class="text-danger">*</span>
                    </label>
                    <div>
                        <input id="from_date" name="from_date" type="text" autocomplete="off"
                            onpaste="return false" placeholder="From" bsDatepicker
                            #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                            formControlName="from_date" [maxDate]="max_date"
                            onkeypress='return event.charCode == 8'
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                            [ngClass]="{ 'is-invalid': submitted && f.from_date.errors }"
                            class="form-control form-control-sm">
                        <div *ngIf="submitted && f.from_date.errors" class="invalid-feedback">
                            <div *ngIf="f.from_date.errors.required">From Date is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='col-xl-3 col-md-3'>
            <div class="form-group">
                <div class='date' id='datetimepicker7'>
                    <label class="control-label">To Date <span class="text-danger">*</span>
                    </label>
                    <div>
                        <input id="to_date" name="to_date" type="text" autocomplete="off"
                            onpaste="return false" placeholder="To" bsDatepicker
                            formControlName="to_date" [minDate]="f.from_date.value"
                            [maxDate]="max_date" (focus)="dp.hide()" bsDatepicker
                            #dp1="bsDatepicker" (focus)="dp1.show()"
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                            [ngClass]="{ 'is-invalid': submitted && f.to_date.errors }"
                            class="form-control form-control-sm"
                            onkeypress='return event.charCode == 8'>
                        <div *ngIf="submitted && f.to_date.errors" class="invalid-feedback">
                            <div *ngIf="f.to_date.errors.required">To Date is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-1 col-md-1 mt-4">
            <button type="submit" class="btn btn-sm btn-primary mt-2" value="Submit"
                (click)="loadGrid()">Submit</button>
        </div>
    </div>
    <div class="row ml-2 mr-2" *ngIf="showTable">
        <div class="table-responsive">
            <table datatable [dtOptions]="dtOptions" *ngIf="dtRender"
                class="table table-striped table-bordered table-sm" style="width: 100%;">
                <thead>
                    <tr>
                       <th>S.No</th>
                       <th>Farmer Name</th>
                       <th>Case Number</th>
                       <th>Animal</th>
                       <th>Breed Type</th>
                       <th>Gender</th>
                       <th>Status</th>
                       <th *ngIf="isPoc">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of lstGrid;let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{item?.farmer_name}}</td>
                        <td>{{item?.case_number}}</td>
                        <td>{{item?.animal_type}}</td>
                        <td>{{item?.breed_type}}</td>
                        <td>{{item?.gender}}</td>
                        <td>{{item?.status}}</td>
                        <td *ngIf="isPoc"><button class="btn btn-sm btn-primary" style="text-wrap: nowrap;" [attr.disabled]="item?.status=='Closed'?true : null" (click)="onAnimalDetails(item)">POC</button>&nbsp;&nbsp;
                            </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
<!-- Adding species -->
<div class="row mt-4" [formGroup]="animalForm" *ngIf="!showTable">
    <div class="col-xl-3 col-md-3 mb-2">
        <label class="control-label"> Tag No </label>
        <input type="text" autocomplete="off" class="form-control form-control-sm" formControlName="luid">
    </div>

    <div class="col-xl-3 col-md-3 mb-2">
        <label class="control-label">Animal <span class="text-danger">*</span></label>
        <div>
            <select id="branch_name" class="form-control form-control-sm" 
            formControlName="animal_type_id"
            [ngClass]="{ 'is-invalid': submitted1 && g.animal_type_id.errors }" (change)="typeOfAnimal($event.target.value)">
                <option value="">Select</option>
                <option *ngFor="let item of animalTypes" [value]="item.id">
                    {{item.name}}</option>
            </select>
            <div *ngIf="submitted1 && g.animal_type_id.errors" class="invalid-feedback">
                <div *ngIf="g.animal_type_id.errors.required">Animal  is required</div>
            </div>

        </div>
   
    </div>
    <div class="col-xl-3 col-md-3 mb-2">
        <label class="control-label">Breed <span class="text-danger">*</span></label>
        <div>
            <select id="branch_name" class="form-control form-control-sm" 
            formControlName="breed_type_id" (change)="typeOfBreed($event.target.value)"
            [ngClass]="{ 'is-invalid': submitted1 && g.breed_type_id.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of breedTyp" [value]="item.id">
                    {{item.breed_type}}</option>
            </select>
           <div *ngIf="submitted1 && g.breed_type_id.errors" class="invalid-feedback">
            <div *ngIf="g.breed_type_id.errors.required">Breed  is required</div>
        </div>
        </div>
        
    </div>
    <div class="col-xl-3 col-md-3 mb-2">
        <label class="control-label"> Gender <span class="text-danger">*</span></label>
        <div>
            <select id="branch_name" class="form-control form-control-sm" formControlName="gender_id"
            [ngClass]="{ 'is-invalid': submitted1 && g.gender_id.errors }">
                <option value="">Select</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
               
            </select>
            <div *ngIf="submitted1 && g.gender_id.errors" class="invalid-feedback">
                <div *ngIf="g.gender_id.errors.required">Gender  is required</div>
            </div>
        </div>
       
    </div>
    <div class="col-xl-3 col-md-3 mb-2">
        <label class="control-label">Service Type </label>
        <div>
            <select id="branch_name" class="form-control form-control-sm" formControlName="service_type_id">
                <option value="">Select</option>
                <option *ngFor="let item of srvcTyp" [value]="item.id">
                    {{item.service_type}}</option>
            </select>
        </div>
    </div>
    <div class="col-xl-3 col-md-3 mb-2">
        <label class="control-label"> Age <span class="text-danger">*</span></label>
        <input type="text" autocomplete="off" class="form-control form-control-sm" formControlName="age"
        (keypress)="keyPressNumbers($event)" maxlength="2" [ngClass]="{ 'is-invalid': submitted1 && g.age.errors }"> 
        <div *ngIf="submitted1 && g.age.errors" class="invalid-feedback">
            <div *ngIf="g.age.errors.required">Age is required</div>
        </div>      
    </div>

    
    <div class="col-xl-3 col-md-3 mb-2">
        <label>Complaint</label>
        <textarea nameFormatter class="form-control form-control-sm" autocomplete="off" formControlName="complaint"
            id="" cols="2" rows="2" maxlength="200"></textarea>
    </div>


    <div class="col-xl-3 col-md-3 mt-4">
        <button class="btn btn-sm btn-primary mt-2" (click)="addAnimal()">Add</button> &nbsp;&nbsp;              
    </div>
</div>
<div *ngIf="!showTable">
    <div class="card-body" *ngIf="tableData?.length!=0">
        <div class="row mt-4" >
            <div class="table-responsive">  
                <table 	datatable *ngIf="dtRender1"
                class="table table-striped table-bordered table-sm" style="width: 100%;">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Animal</th>
                            <th>Breed</th>
                            <th>Gender</th>
                            <th>Age</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of tableData;let i=index;">
                            <td>{{i+1}}</td>
                             <td>{{item?.animalName}}</td>
                             <td>{{item?.breedName}}</td>
                             <td>{{item?.gender_id == 1 ? 'Male' : 'Female'}}</td>
                             <td>{{item?.age}}</td>
                             <td><span>
                                <a class="badge badge-danger badge-pill pointer" title="Delete"
                                (click)="delete(i)">
                                    <i class="fa fa-trash text-white"></i></a>
                            </span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
<div class="modal-footer">
    <input type="submit"  class="btn btn-sm btn-primary" [attr.disabled]="tableData?.length>0?null:true" (click)="animalSave()"
        value="Submit">&nbsp;&nbsp; 
    <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="!showTable"  (click)="cancel('table')">Back</button>
    <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="showTable"  (click)="cancel('n')">Close</button>
</div>




