import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})

export class CompanyComponent implements OnInit {

  @Input() title: string;
  @Input() DataEdit: any;
  @Input() isSubmit_Disabled: boolean = false;
  @Input() isCorp: boolean = false;
  sd: any;
  ed: any;
  employeeList: Array<any> = [];
  corporateList: Array<any> = [];
  companyForm: FormGroup;
  corporateForm: FormGroup;
  level: FormArray;
  submitted = false;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  cities: Array<any> = [];
  plansdata: Array<any> = [];
  isECLINIC = environment.isNIG;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = '';
  corpDDStngs = {};
  selectedList = [];
  empLvlLst = []; allEmpLvls = []; lstCorps = []; lstSlctdECL = []; slctPlan = ''; lstClr = [];
  plansList: Array<any> = []; allPlansList: Array<any> = [];
  showTestField = 0;
  selectedPlan: any;
  showdataa: boolean = false;
  isShow = false; isPlnError = false;
  minDate: Date; minToDate: Date; logUsr: any;

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private userServicedata: UserService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private authServ: AuthenticationService
    , private masterService: MasterService) {
    this.minDate = new Date(), this.minDate.setDate(this.minDate.getDate());
    this.minToDate = new Date(), this.minToDate.setDate(this.minToDate.getDate());
  }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.logUsr = this.authServ.currentUserValue, this.initFormControls(),
      this.locationChanged('', 'state'), this.sd = '', this.getAllMasters();
    this.corpDDStngs = {
      singleSelection: false,
      idField: 'id',
      textField: 'employee_level',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      // itemsShowLimit: 2,
      // limitSelection: 2,
      enableCheckAll: false,
      allowSearchFilter: false,
    };
    this.getMasterdata();
  }

  getMasterdata() {
    const employeelevel = this.userServicedata.getAll('masteremployeelevel/getall');
    const plansdata = this.userServicedata.getAll('mastersubscriptionplan/getall');
    forkJoin([employeelevel, plansdata]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => { this.employeeList = list.data, this.empLvlLst = list.data, this.allEmpLvls = list.data; },
                '1': () => { this.plansList = list.data, this.allPlansList = list.data; },
              }
              assignList[ind]();
            }
          }
        });
      }
    })
  }

  onItemSelect(item: any) {
    if (this.selectedList.filter(f => f.item_id == item.item_id).length == 0)
      this.selectedList.push(item);
    this.selectedList = [...this.selectedList];
    if (this.selectedList.some(a1 => a1.item_text == 'Other'))
      this.showTestField = 1;
    else
      this.showTestField = 0;
  }

  onItemDeSelect(item: any) {
    let data = this.selectedList;
    data.forEach((x, i) => { if (x.item_id == item.item_id) data.splice(i, 1); });
    this.selectedList = [...data];
    if (this.selectedList.some(a1 => a1.item_text == 'Other'))
      this.showTestField = 1;
    else
      this.showTestField = 0;
  }

  showcorporatedata() {
    this.showdataa = true;
  }

  editCompany() {
    if (this.DataEdit) {
      if (this.isCorp) {
        Promise.all([this.locationChanged(this.DataEdit.state_id, 'district')]).then(() => {
          let data = this.DataEdit;
          this.companyForm.patchValue(data), this.companyForm.patchValue({
            state_id: data.state_id, district_id: data.district_id
          });
        });
      }
      // this.companyForm.patchValue({
      //   id: data.id, company_name: data.company_name, company_email: data.company_email, company_whatsapp_number: data.company_whatsapp_number
      //       , contact_person_first_name: data.contact_person_first_name, contact_person_last_name: data.contact_person_last_name, contact_number: data.contact_number
      //       , contact_person_company_email: data.contact_person_company_email, contact_person_phone_number: data.contact_person_phone_number
      //       , state_id: data.state_id, district_id: data.district_id, pin_code: data.pin_code, address: data.address
      //       , is_active: data.is_active, registration_no: data.registration_no
      // });
      else {
        this.userServicedata.getById('corporate/getcorporateplanbycorpid?corporateId=' + this.DataEdit.id).subscribe(s => {
          let cP: any[] = s.data, cpPlns = [], cpLvls = [], cpRslt = [], cpSD: any, cpED: any;
          sessionStorage.setItem('editCorpPlans', JSON.stringify(cP));
          cP.forEach(e => {
            if (cpPlns.filter(f => f.plnId == e.plan_id).length == 0)
              cpPlns.push({ plnId: e.plan_id });
            if (cpLvls.filter(f => f.lvlId == e.employee_level_id).length == 0)
              cpLvls.push({ lvlId: e.employee_level_id });
          });
          cpPlns.forEach(e => {
            let lvlEmp = '', objLvls = [], plnName = '';
            cP.filter(f => f.plan_id == e.plnId).forEach((p, i) => {
              lvlEmp += `${i == 0 ? '' : ','}${this.allEmpLvls.filter(f => f.id == p.employee_level_id)[0]?.employee_level}`,
                objLvls.push({ id: p.employee_level_id, employee_level: this.allEmpLvls.filter(f => f.id == p.employee_level_id)[0]?.employee_level ?? '' });
              cpSD = new Date(p.start_date), cpED = new Date(p.end_date);
              if (plnName == '') {
                plnName = this.allPlansList.filter(f => f.id == e.plnId)[0]?.plan;
                this.plansList = this.plansList.filter(f => f.plan != plnName);
              }
            }), cpRslt.push({ empLvl: lvlEmp, lvl: objLvls, pln: plnName });
          });
          cpLvls.forEach(e => { this.empLvlLst = this.empLvlLst.filter(f => f.id != e.lvlId); });
          this.corporateForm.patchValue({
            id: this.DataEdit.id, corporate_name: this.DataEdit.corporate_name, corporate_code: this.DataEdit.corporate_code,
            main_address: this.DataEdit.main_address, primary_contact_name: this.DataEdit.primary_contact_name, primary_contact_no:
              this.DataEdit.primary_contact_no, alternate_contact_no: this.DataEdit.alternate_contact_no, email: this.DataEdit.email,
            fax: this.DataEdit.fax, is_active: this.DataEdit.is_active
          }), this.sd = cpSD, this.lstCorps = cpRslt, this.empLvlLst = [...this.empLvlLst], setTimeout(() => { this.ed = cpED; }, 100);
        });
      }
    }
  }

  initFormControls() {
    this.companyForm = this.formBuilder.group({
      id: [0],
      company_name: ['', Validators.required],
      company_email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      company_whatsapp_number: ['', Validators.required],
      contact_person_first_name: ['', Validators.required],
      contact_person_last_name: ['', Validators.required],
      contact_number: ['', Validators.required],
      contact_person_company_email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      contact_person_phone_number: ['', [Validators.required, Validators.minLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
      registration_no: ['', Validators.required],
      // location_type_id: ['', Validators.required],
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      // mandal_id: ['', Validators.required],
      // city_id: [''],
      // village_id: [''],
      orgnization_id: [+sessionStorage.getItem('logUsrRoleId') == 1 ? +sessionStorage.getItem('currentTenant') : (this.logUsr?.orgnization_registration?.id ?? 1)],
      pin_code: ['', Validators.required],
      address: ['', Validators.required],
      is_active: [true],
      // level: this.formBuilder.array([this.createcorporateplans()]),
    });
    this.corporateForm = this.formBuilder.group({
      id: [0],
      corporate_code: ['CORP'],
      corporate_name: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      main_address: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
      primary_contact_name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      primary_contact_no: ['', Validators.required],
      alternate_contact_no: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      start_date: [''],
      end_date: [''],
      organization_id: [+sessionStorage.getItem('logUsrRoleId') == 1 ? +sessionStorage.getItem('currentTenant') : (this.logUsr?.orgnization_registration?.id ?? 1)],
      fax: ['', [Validators.minLength(5), Validators.maxLength(12)]],
      is_active: [false],
    })
  }

  createcorporateplans() {
    return this.formBuilder.group({
      levl: [[]],
      plann: ['']
    })
  }

  onFromDateChange(val: any) {
    this.ed = '', this.minToDate = new Date(), sessionStorage.setItem('frmDtCC', val);
    if (val != null) {
      let edate = this.datePipe.transform(val, 'yyyy-MM-dd').split('-'),
        cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
      cnvDate.setMonth(cnvDate.getMonth() + 1), cnvDate.setDate(cnvDate.getDate() - 1);
      this.minToDate = new Date(cnvDate);
    }
  }

  addcorporateplans() {
    let addList = []
    this.level = this.companyForm.get('level') as FormArray
    // this.level.push(this.createcorporateplans())
    addList.push({ levl: this.allEmpLvls, plann: this.companyForm.get('level')['controls'][0]?.value['plann'] ?? '' })
    this.companyForm.get('level')['controls'][0].value?.push
    this.companyForm.get('level').setValue(addList); // this.level.push(addList)
   // console.log(addList), console.log(this.companyForm.get('level').value);
  }

  deletecorporateplans(index: any) {
    let hobbiesArray = this.companyForm.get('level') as FormArray
    hobbiesArray.removeAt(index)
  }

  get f() { return this.companyForm.controls; }

  get c() { return this.corporateForm.controls; }

  onSubmit(event: any) {
    if (this.isCorp) {
      this.submitted = true, Common.getFormValidationErrors(this.companyForm);
      if (this.companyForm.invalid) {
        this.toastr.info('Please Enter All Mandatory Fields');
        return;
      }
      const postData = JSON.parse(JSON.stringify(this.companyForm.getRawValue()));
      this.userServicedata.save2('orgnizationcompany/saveandupdate', JSON.stringify(postData)).subscribe(res => {
        if (res.status === 'OK')
          this.toastr.success(res.message, 'Coupon Company'), this.activeModal.close('changes saved');
        else
          this.toastr.error(res.message);
      });
    }
    else {
      this.submitted = true, Common.getFormValidationErrors(this.corporateForm), this.isPlnError = this.lstCorps.length == 0 && this.lstSlctdECL.length == 0;
      if (this.corporateForm.invalid) {
        this.toastr.info('Please Enter All Mandatory Fields');
        return;
      }
      if (this.lstCorps.length == 0) {
        this.toastr.info('Please add corporate plans for your company');
        return;
      }
      const postData = JSON.parse(JSON.stringify(this.corporateForm.getRawValue()));
      ['start_date', 'end_date'].forEach(e => { delete postData[e]; });
      this.userServicedata.save2('corporate/registration', JSON.stringify(postData)).subscribe(res => {
        if (res.status === 'OK') {
          let corpLst = [];
          this.lstCorps.forEach(e => {
            let empLvlIds = [];
            e.lvl.forEach(l => { empLvlIds.push({ employee_level_id: l.id }); });
            corpLst.push({
              corporate_id: res.data.id, employee_level_id: empLvlIds, start_date: this.datePipe.transform(sessionStorage.getItem('frmDtCC'), "yyyy-MM-dd"),
              end_date: this.datePipe.transform(this.ed, "yyyy-MM-dd"), plan_id: this.allPlansList.filter(f => f.plan == e.pln)[0]?.id // end_date: this.corporateForm.get('end_date').value
            });
          });
          if (corpLst.length > 0) {
            this.userServicedata.save2('corporate/savecorporateplan', JSON.stringify(corpLst)).subscribe(ecl => {
              if (ecl.status == "OK")
                this.toastr.success(ecl.message, 'Corporate Company'), this.activeModal.close('changes saved');
              else
                this.toastr.error(ecl.message);
            });
          }
        }
        else
          this.toastr.error(res.message);
      });
    }
  }



  getAllMasters() {
    const locationType = this.masterService.getAll('getalllocationtypes');

    forkJoin([locationType]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              this.toastr.info('No records available', 'Company Details');
            else {
              const assignList = {
                '0': () => this.locationTypeList = list.data,
                // '1': () => this.hospitalList = list.data,
                // '2': () => this.labList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { this.editCompany(); }
    });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '', locations = {
      'state': () => { this.districtList = [], query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`; },
      'district': () => {
        this.districtList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      }
    };
    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          // 'mandal': () => this.mandalList = arr.mandals,
          // 'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  onMultiSelect(evnt: any) {
    if (this.lstSlctdECL.filter(f => f.id == evnt.id).length == 0)
      this.lstSlctdECL.push(evnt);
    else
      this.lstSlctdECL = this.lstSlctdECL.filter(f => f.id != evnt.id);
    this.isPlnError = this.lstSlctdECL.length == 0 && this.lstSlctdECL.length == 0;
  }

  onAddECL() {
    let lvls = '';
    this.lstSlctdECL.forEach((e, i) => {
      lvls += `${i == 0 ? '' : ','}${e.employee_level}`,
        this.plansList = this.plansList.filter(f => f.plan != this.slctPlan), this.empLvlLst = this.empLvlLst.filter(f => f.id != e.id);
    }), this.lstCorps.push({ lvl: this.lstSlctdECL, pln: this.slctPlan, empLvl: lvls }), this.slctPlan = '', this.lstClr = [],
      this.lstSlctdECL = [], this.isPlnError = this.lstCorps.length == 0 && this.lstSlctdECL.length == 0;
  }

  onDelCP(indx: number) {
    this.plansList.push(this.allPlansList.filter(f => f.plan == this.lstCorps[indx].pln)[0]),
      this.lstCorps[indx].lvl.forEach(e => { this.empLvlLst.push(e); }), this.empLvlLst = [...this.empLvlLst],
      this.lstCorps = this.lstCorps.filter(f => f.pln != this.lstCorps[indx].pln);
  }

}