<div class="modal fade" style="z-index: 99999 !important;" id="patientModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="overflow-y: auto; max-height: 500px;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Patient {{haType == 'h' ? 'History' : 'Attachments'}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-md-4"></div>
                    <div class="form-group col-md-4" style="text-align: center;">
                        <label><input type="radio" name="haType" [(ngModel)]="haType"
                                value="h">&nbsp;&nbsp;History</label>&nbsp;&nbsp;
                        <label><input type="radio" name="haType" [(ngModel)]="haType"
                                value="a">&nbsp;&nbsp;Attachments</label>
                    </div>
                    <div class="form-group col-md-4"></div>
                </div>
                <table class="table table-bordered table-sm" *ngIf="haType=='h'">
                    <tr>
                        <th>Sl. No.</th>
                        <th>Visit Date</th>
                        <th>Doctor Name</th>
                        <th>Diagnosis</th>
                        <th>History</th>
                    </tr>
                    <tr *ngFor="let item of lstPH1; let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item.appointment_date_and_time}}</td>
                        <td>{{item.doctor_name}}</td>
                        <td>{{item.diagnosis.provisionalDiagnosis[0]?.name}}</td>
                        <td><a style="cursor: pointer; text-decoration: underline; text-align: center"
                                (click)="onViewPH(item.poc_id, item.patient_history_records, 'h')">View</a></td>
                    </tr>
                </table>
                <table class="table table-bordered table-sm" *ngIf="haType=='a'">
                    <tr>
                        <th>Sl. No.</th>
                        <th>Date & Time</th>
                        <th>Attachments</th>
                    </tr>
                    <tr *ngFor="let item of lstPH2; let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item.created_on | date: "dd-MM-yyyy HH:mm:ss"}}</td>
                        <td>
                            <a style="cursor: pointer; text-decoration: underline; text-align: center"
                                (click)="onViewDocPH(item, 'a')">View</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>